import React, { Component } from "react";
class IconCheck extends Component {
  render() {
    return (
      <svg
        height="17px"
        width="17px"
        viewBox="0 0 3.4757568 3.4749867"
        version="1.1"
        id="icon_delete_fill"
      >
        <defs id="defs2" />
        <g
          style={{
            fill: this.props.color ? this.props.color : "#000000",
            strokeWidth: "1.00157",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            fillOpacity: "1",
          }}
          id="layer1"
          transform="matrix(2.6806283,0,0,2.6806283,-786.8335,-471.70054)"
        >
          <path
            transform="matrix(0.11585856,0,0,0.11585856,293.01629,175.45599)"
            d="M 7.1054688,7.8886719 A 0.6775589,0.6775589 0 0 0 6.4316406,8.5664062 v 5.3027348 c 0,0.942269 0.7699532,1.726562 1.7167969,1.726562 h 3.7109375 c 0.947362,0 1.726563,-0.779113 1.726563,-1.726562 V 8.5664062 A 0.6775589,0.6775589 0 0 0 12.912109,7.8886719 Z M 9.0761719,9.9121094 A 0.42343172,0.42343172 0 0 1 9.5,10.335938 v 2.802734 A 0.42343172,0.42343172 0 0 1 9.0761719,13.5625 0.42343172,0.42343172 0 0 1 8.6523438,13.138672 V 10.335938 A 0.42343172,0.42343172 0 0 1 9.0761719,9.9121094 Z m 1.8789061,0 a 0.42343172,0.42343172 0 0 1 0.423828,0.4238286 v 2.802734 A 0.42343172,0.42343172 0 0 1 10.955078,13.5625 0.42343172,0.42343172 0 0 1 10.525391,13.138672 v -2.802734 a 0.42343172,0.42343172 0 0 1 0.429687,-0.4238286 z"
            style={{
              fill: this.props.color ? this.props.color : "#000000",
              strokeWidth: "1.00157",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fillOpacity: "1",
            }}
            id="path979"
          />
          <path
            transform="matrix(0.11585856,0,0,0.11585856,293.01629,175.45599)"
            d="m 8.7246094,4.4042969 c -0.7500503,0 -1.3691406,0.6183113 -1.3691406,1.3691406 V 6.4121094 H 5.9453125 A 0.4234319,0.4234319 0 0 0 5.5214844,6.84375 0.4234319,0.4234319 0 0 0 5.9453125,7.2675781 H 7.765625 a 0.42343197,0.42343197 0 0 0 0.00781,0.00586 0.42343197,0.42343197 0 0 0 0.00977,-0.00586 h 4.5625 a 0.42343197,0.42343197 0 0 0 0.0098,0.00586 0.42343197,0.42343197 0 0 1 0.0039,-0.00586 h 1.681641 A 0.4234319,0.4234319 0 0 0 14.464844,6.84375 0.4234319,0.4234319 0 0 0 14.041016,6.4121094 H 12.779297 V 5.7734375 c 0,-0.7500526 -0.617622,-1.3691406 -1.369141,-1.3691406 z m 0,0.84375 h 2.6933596 c 0.296914,0 0.521484,0.230203 0.521484,0.5253906 V 6.4121094 H 8.203125 V 5.7734375 c 0,-0.2969139 0.2262988,-0.5253906 0.5214844,-0.5253906 z"
            style={{
              fill: this.props.color ? this.props.color : "#000000",
              strokeWidth: "1.00157",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fillOpacity: "1",
            }}
            id="path975"
          />
          <g
            style={{
              fill: this.props.color ? this.props.color : "#000000",
              strokeWidth: "1.00157",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fillOpacity: "1",
            }}
            id="g869"
            transform="matrix(0.39735388,0,0,0.39735388,178.62674,105.23104)"
          />
          <g
            style={{
              fill: this.props.color ? this.props.color : "#000000",
              strokeWidth: "1.00157",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              fillOpacity: "1",
            }}
            id="g941"
            transform="matrix(0.26458333,0,0,0.26458333,299.63119,174.13775)"
          />
        </g>
      </svg>
    );
  }
}
export default IconCheck;
