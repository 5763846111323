/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { state_update } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import generate_uuid from "../../App/validation/generate_uuid";

/*NON IMPORT*/

class SEARCH_LOCATION extends Component {
  state = {};

  on_fly_to = (feature) => {
    this.props.state_update();
    this.props.set_value_properties({
      key: "modal_input",
      value: false,
    });
    const long = feature?.geometry?.coordinates?.[0];
    const lat = feature?.geometry?.coordinates?.[1];
    this.props.set_value_properties({
      key: "search_long",
      value: long,
    });
    this.props.set_value_properties({
      key: "search_lat",
      value: lat,
    });
    this.props.set_value_properties({
      key: "uuid_search",
      value: generate_uuid(),
    });
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.flyTo({
        center: [long, lat],
        zoom: 13,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { location_result_list, search_input } = this.props.properties;

    //content
    return (
      <main>
        <p className="text_small">
          {dict?.["Search for locations by name"]?.[language]}
        </p>
        {location_result_list.length === 0 && !!search_input && (
          <p className="text_medium">{dict?.["No result"]?.[language]}</p>
        )}
        {location_result_list.length > 0 && (
          <section>
            <span className="button_pill_small background_blue margin_right inline">
              {location_result_list.length}
            </span>
            <p className="text_medium inline">{dict?.["Result"]?.[language]}</p>
          </section>
        )}
        <section
          style={{
            overflowY: "auto",
            height: "500px",
            maxHeight: "calc((100vh - 300px))",
            marginTop: "20px",
          }}
        >
          {location_result_list.map((feature, idx) => {
            return (
              <main
                key={idx}
                className="button_white margin_bottom cursor_pointer"
                onClick={this.on_fly_to.bind(this, feature)}
              >
                <p className="text_bold margin_bottom">{feature?.place_name}</p>
                <br />
              </main>
            );
          })}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties, state_update })(
  SEARCH_LOCATION
);
