/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class Progress extends Component {
  state = {};
  render() {
    const current_number = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(parseInt(this.props.current_number));
    const total_number = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(parseInt(this.props.total_number));
    let percent = parseInt(
      (this.props.current_number / this.props.total_number) * 100
    );
    if (percent > 100) {
      percent = 100;
    }
    return (
      <main>
        <div
          style={{
            height: "11px",
            backgroundColor: "#ffffff",
            border: "1px solid rgb(154, 154, 154)",
            borderRadius: "2rem",
            padding: "1px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${percent}%`,
              backgroundColor: this.props.color ? this.props.color : "#0ca5eb",
              borderRadius: "2rem",
              transition: "width 0.05s linear",
            }}
          />
        </div>
        {!this.props.is_hide_text && (
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td className="text_left text_inferior">
                  {this.props.name || "Proses"}: {current_number}/{total_number}
                </td>
                <td className="text_right text_medium">{percent}%</td>
              </tr>
            </tbody>
          </table>
        )}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Progress);
