/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class FOLDER_CHILD extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const folder_id_selected_move = this.props.folder_id_selected_move;
    const folder_list_child = this.props.folder_list_child;
    const folder_project_list_all = this.props.folder_project_list_all;
    const folder_id_selected = this.props.folder_id_selected;

    //content
    return (
      <main>
        {folder_list_child.map((item, idx) => {
          const folder_list_child = folder_project_list_all.filter(
            (child) => child?.folder_parent_id === item?._id
          );
          return (
            <div
              key={idx}
              className="margin_bottom"
              style={{ marginLeft: "50px" }}
            >
              <div onClick={this.props.on_click_folder.bind(this, item?._id)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16.581}
                  height={24.994}
                  viewBox="0 0 4.387 6.613"
                  className="inline_bottom margin_right"
                >
                  <path
                    d="M4.189 4.189H1.373c-.65 0-1.175-.524-1.175-1.175V.198"
                    style={{
                      fill: "none",
                      paintOrder: "stroke markers fill",
                      strokeDashoffset: 11.411,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 0.39688,
                      stroke:
                        folder_id_selected_move === item?._id
                          ? "#000"
                          : "#a5b3b6",
                    }}
                  />
                  <path
                    d="M2.986 2.986 4.19 4.19 2.986 5.39"
                    style={{
                      fill: "none",
                      paintOrder: "stroke markers fill",
                      strokeDashoffset: 11.411,
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 0.39688,
                      stroke:
                        folder_id_selected_move === item?._id
                          ? "#000"
                          : "#a5b3b6",
                    }}
                  />
                </svg>
                <span
                  className={`cursor_pointer inline_bottom max_one_lines button ${
                    folder_id_selected_move === item?._id
                      ? "background_green"
                      : "button_white"
                  }`}
                >
                  {item?.name}
                </span>
              </div>
              {folder_id_selected === item?._id && (
                <p className="badge">Current</p>
              )}

              {folder_list_child?.length > 0 && (
                <FOLDER_CHILD
                  folder_parent_id={item?._id}
                  folder_list_child={folder_list_child}
                  folder_project_list_all={folder_project_list_all}
                  on_click_folder={this.props.on_click_folder}
                  folder_id_selected_move={this.props.folder_id_selected_move}
                />
              )}
            </div>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, {})(FOLDER_CHILD);
