import React, { Component } from "react";
import { connect } from "react-redux";
import terbilang from "../../../App/validation/terbilang";
import { dot_every_3_digits } from "../../../App/validation/format_number";
import moment from "moment/moment";

import {
  C1Logo,
  C2LaporanHasilPenilaianAgunan,
  C3RingkasanHasilPenilaian,
  C4SesuaiFisikPeraturan,
  C5SesuaiDenganInspeksi,
  C6LaporanHasilPemeriksaan,
  C7DataLegal,
  C8Kondisi,
  C9Lingkungan,
  C10InfrastrukturDanFasilitas,
  C11ProgresPenyelesaianBangunan,
  C12KeteranganTambahan,
  C13PembandingDataPasar,
} from ".";
import { edit_laporan_pdf } from "../../../App/actions/propertiesActions";
import is_equal_object from "../../../App/validation/is_equal_object";
import {
  getAllChildrenRows,
  getAllFields,
  getChildrenFeaturesByColumnParentUuidAndRowParentUuid,
  getFeatureByKey,
  getGeoLayer,
  getGeoLayerById,
  getGeoLayersByArrayOfId,
} from "../../../App/reduxHelper/layer";
import { calculateWithoutConvert } from "../../../App/validation/convert_data";

// assets
import { getDetailLayerById } from "../../../App/actions/layerNewActions";
import { createTd } from "./CreateTd";
import createC7SpecialTable from "./C7_data_legal/CreateC7SpecialTable";
import createC10SpecialTable from "./C10_infrastruktur_dan_fasilitas/createC10SpecialTable";
import createSpecialC13TablePembanding from "./C13_pembanding_data_pasar/CreateSpecialC13TablePembanding";
import { c13_setup_object_penilaian_dan_data_pembanding } from "./C13_pembanding_data_pasar/ListOfC13id";
import createSpecialC11TableSaranaPelengkap from "./C11_progres_penyelesaian_bangunan/CreateSpecialC11TableSaranaPelengkap";
import { get_country_code_and_phone_number } from "../../../App/validation/phone_number";
import is_not_empty from "../../../App/validation/is_not_empty";
import format_date_formal from "../../../App/validation/format_date_formal";

const keys_that_use_table = {
  c3_setup_ringkasan_hasil_penilaian_pdf: [
    "c3_object_pdf",
    "c3_luas_m2_pdf",
    "c3_nilai_pasar_nilai_per_meter_kuadrat_pdf",
    "c3_nilai_pasar_total_pdf",
    "c3_indikasi_nilai_likuidasi_nilai_per_meter_kuadrat_pdf",
    "c3_indikasi_nilai_likuidasi_total_pdf",
  ],
  c4_setup_sesuai_fisik_peraturan_pdf: [
    "c4_object_pdf",
    "c4_luas_m2_pdf",
    "c4_nilai_pasar_nilai_per_meter_kuadrat_pdf",
    "c4_nilai_pasar_total_pdf",
    "c4_indikasi_nilai_likuidasi_nilai_per_meter_kuadrat_pdf",
    "c4_indikasi_nilai_likuidasi_total_pdf",
  ],
  c7_setup_data_legal_pdf: [
    "c7_jenis_dokumen_pdf",
    "c7_no_dokumen_pdf",
    "c7_atas_nama_pdf",
    "c7_tgl_terbit_pdf",
    "c7_tgl_akhir_hak_pdf",
    "c7_no_tgl_gs_su_pdf",
    "c7_luas_pdf",
    "c7_hubungan_dengan_debitur_pdf",
    "c7_pengurangan_tata_kota_pdf",
  ],
  c10_setup_kumpulan_rumah_pdf: [
    "c10_pondasi_pdf",
    "c10_struktur_pdf",
    "c10_rangka_atap_pdf",
    "c10_penutup_atap_pdf",
    "c10_plafon_pdf",
    "c10_dinding_pdf",
    "c10_pintu_pdf",
    "c10_jendela_pdf",
    "c10_lantai_pdf",
    "c10_utilitas_pdf",
    "c10_kondisi_bangunan_pdf",
  ],
};

const total_that_will_show_id = {
  c3_setup_ringkasan_hasil_penilaian_pdf: [
    "c3_nilai_pasar_total_pdf",
    "c3_indikasi_nilai_likuidasi_total_pdf",
  ],
  c4_setup_sesuai_fisik_peraturan_pdf: [
    "c4_nilai_pasar_total_pdf",
    "c4_indikasi_nilai_likuidasi_total_pdf",
  ],
  c7_setup_data_legal_pdf: ["c7_luas_pdf", "c7_pengurangan_tata_kota_pdf"],
};

const list_date_id = [
  "tanggal_order_pdf",
  "tanggal_penilaian_pdf",
  "tanggal_laporan_pdf",
  "c7_tanggal_penerbitan_imb_pdf",
];

const list_year_id = [
  "c6_dihuni_sejak_pdf",
  "c6_disewa_hingga_pdf",
]

const all_keys_that_use_table = [];
Object.keys(keys_that_use_table).forEach((key) =>
  all_keys_that_use_table.push(...keys_that_use_table[key])
);

class SetupDownloadLaporanPdfDanamas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "danamas",
      body: {},
    };
  }

  componentDidMount = async () => {
    await this.getDataPembanding();

    // biar dapet semua data formula pada child muncul
    const { layer, row, mode } = this.props;
    const geo_layer = getGeoLayer(layer);
    if (mode === "preview") {
      const feature = getFeatureByKey({ geo_layer, feature_key: row?.key });
      const columns = getAllFields(geo_layer);
      const children_rows = getAllChildrenRows(feature);
      const id_pembanding = geo_layer?.layer_data_list?.[0]?._id;
      const layer_pembanding = getGeoLayerById({
        layer,
        layer_id: id_pembanding,
      });

      const source = "setup danama";
      calculateWithoutConvert({
        features: children_rows,
        fields: columns,
        layer_pembanding,
        source,
        feature_parent: feature,
      });
    }

    this.getSetupLaporanPdf();
  };

  getDataPembanding = async () => {
    const { layer } = this.props;
    const geo_layer = getGeoLayer(layer);
    const list_data_pembanding_id = geo_layer?.layer_data_list;
    const geo_layers =
      getGeoLayersByArrayOfId({
        layer,
        ids: list_data_pembanding_id,
      }) || [];

    if (list_data_pembanding_id?.length) {
      for (let item of geo_layers) {
        if (!item?.geo_layer?.geojson?.features?.length) {
          const { geo_project } = this.props.project;
          const body = {
            geo_layer_id: item?.geo_layer._id,
            geo_project_id: geo_project._id,
            is_ignore_set_layer_id: true,
          };
          await this.props.getDetailLayerById(body);
        }
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mode, layer } = this.props;
    const { body } = this.state;
    if (mode === "preview") {
      if (!is_equal_object(prevState.body, body)) {
        const { row } = this.props;

        const geo_layer = getGeoLayer(layer);

        const feature = getFeatureByKey({ geo_layer, feature_key: row?.key });

        this.createTable({
          feature,
          parent_table_id: "c3_table_ringkasan_hasil_penilaian_pdf",
          new_body_table_id: "c3_body_ringkasan_hasil_penilaian_pdf",
          setup_table_id: "c3_setup_ringkasan_hasil_penilaian_pdf",
          body,
        });

        const {
          nilai_pasar_total_sesuai_fisik_real,
          indikasi_nilai_likuiditas_sesuai_fisik_real,
        } = this.createTable({
          feature,
          parent_table_id: "c4_table_sesuai_fisik_peraturan_pdf",
          new_body_table_id: "c4_body_sesuai_fisik_peraturan_pdf",
          setup_table_id: "c4_setup_sesuai_fisik_peraturan_pdf",
          body,
        });

        const element_nilai_pasar_total_sesuai_fisik_real =
          document.getElementById("nilai_pasar_pdf");
        if (
          element_nilai_pasar_total_sesuai_fisik_real &&
          !isNaN(nilai_pasar_total_sesuai_fisik_real)
        ) {
          element_nilai_pasar_total_sesuai_fisik_real.innerHTML = `Rp${dot_every_3_digits(
            Number(nilai_pasar_total_sesuai_fisik_real)?.toFixed(0)
          )}`;

          const nilai_pasar_element = document.getElementById(
            "nilai_pasar_terbilang_pdf"
          );
          if (nilai_pasar_element) {
            nilai_pasar_element.innerHTML = `${terbilang(
              nilai_pasar_total_sesuai_fisik_real
            )} Rupiah`.toUpperCase();
          }
        }

        const element_indikasi_nilai_likuiditas_sesuai_fisik_real =
          document.getElementById("indikasi_nilai_likuiditas_pdf");
        if (
          element_indikasi_nilai_likuiditas_sesuai_fisik_real &&
          !isNaN(indikasi_nilai_likuiditas_sesuai_fisik_real)
        ) {
          element_indikasi_nilai_likuiditas_sesuai_fisik_real.innerHTML = `Rp${dot_every_3_digits(
            Number(indikasi_nilai_likuiditas_sesuai_fisik_real)?.toFixed(0)
          )}`;

          const nilai_pasar_element = document.getElementById(
            "indikasi_nilai_likuiditas_terbilang_pdf"
          );
          if (nilai_pasar_element) {
            nilai_pasar_element.innerHTML = `${terbilang(
              indikasi_nilai_likuiditas_sesuai_fisik_real
            )} Rupiah`.toUpperCase();
          }
        }

        const luas_tanah = this.createTable({
          feature,
          parent_table_id: "c7_table_data_legal_pdf",
          new_body_table_id: "c7_body_data_legal_pdf",
          setup_table_id: "c7_setup_data_legal_pdf",
          body,
        });

        createC7SpecialTable({
          feature,
          parent_table_id: "c7_table_legalitas_bangunan_pdf",
          new_body_table_id: "c7_body_legalitas_bangunan_pdf",
          setup_table_id: "c7_setup_legalitas_bangunan_pdf",
          setup_child_table_id: "c7_setup_child_legalitas_bangunan_pdf",
          body,
        });

        const luas_bangunan = createC10SpecialTable({
          feature,
          parent_table_id: "c10_table_kumpulan_rumah_pdf",
          new_body_table_id: "c10_body_kumpulan_rumah_pdf",
          setup_table_id: "c10_setup_kumpulan_rumah_pdf",
          setup_child_table_id: "c10_setup_child_bangunan_rumah_pdf",
          body,
        });

        createSpecialC11TableSaranaPelengkap({
          feature,
          body_table_id: "c11_tbody_sarana_pelengkap_pdf",
          body,
        });

        createSpecialC13TablePembanding({
          layer,
          feature,
          parent_table_id: "c13_table_data_pembanding_pasar_pdf",
          body,
          luas_tanah,
          luas_bangunan,
        });

        const c2_merge_alamat = {
          alamat_object_sesuai_fisik_pdf: "",
          c2_kelurahan_object_sesuai_fisik_pdf: "",
          c2_kecamatan_object_sesuai_fisik_pdf: "",
          c2_kabupaten_object_sesuai_fisik_pdf: "",
          c2_provinsi_object_sesuai_fisik_pdf: "",
        };

        const c6_merge_alamat = {
          c6_alamat_sesuai_fisik_pdf: "",
          c6_kelurahan_object_sesuai_fisik_pdf: "",
          c6_kecamatan_object_sesuai_fisik_pdf: "",
          c6_kabupaten_object_sesuai_fisik_pdf: "",
          c6_provinsi_object_sesuai_fisik_pdf: "",
        };

        Object.keys(body).forEach((key) => {
          if (key === "c1-logo") {
            const element = document.getElementById(key);
            if (element) {
              element.src = row[body[key]];
            }
          } else if (key === "email_pdf") {
            const id_name = document.getElementById(key);
            if (id_name) {
              id_name.href = `mailto:${row[body[key]]}`;
              id_name.innerHTML = row[body[key]] ? `:${row[body[key]]}` : ": -";
            }
          } else if(list_year_id.includes(key)){
            const element = document.getElementById(key);
            if (element) {
              const value = row[body[key]];
              element.innerHTML = value;
            }

          }else if (key === "c6_pic_mobile_phone_pdf") {
            const element = document.getElementById(key);
            if (element) {
              const { countryCode, phoneNumber } =
                get_country_code_and_phone_number(row[body[key]]);
              element.innerHTML =
                `${countryCode && "+" + countryCode + " "}${phoneNumber}` ||
                "-";
            }
          } else if (Object.keys(c2_merge_alamat).includes(key)) {
            const element = document.getElementById(
              "alamat_object_sesuai_fisik_pdf"
            );
            c2_merge_alamat[key] = row[body[key]];

            let value = "";
            Object.keys(c2_merge_alamat).forEach((alamat, idx) => {
              if (idx === 0) {
                value += c2_merge_alamat[alamat] || "";
              } else {
                value += c2_merge_alamat[alamat]
                  ? `, ${c2_merge_alamat[alamat]}`
                  : "";
              }
            });
            if (element) {
              element.innerHTML = value;
            }
          } else if (Object.keys(c6_merge_alamat).includes(key)) {
            const element = document.getElementById(
              "c6_alamat_sesuai_fisik_pdf"
            );
            c6_merge_alamat[key] = row[body[key]];

            let value = "";
            Object.keys(c6_merge_alamat).forEach((alamat, idx) => {
              if (idx === 0) {
                value += c6_merge_alamat[alamat] || "";
              } else {
                value += c6_merge_alamat[alamat]
                  ? `, ${c6_merge_alamat[alamat]}`
                  : "";
              }
            });
            if (element) {
              element.innerHTML = value;
            }
          } else if (list_date_id.includes(key)) {
            const element = document.getElementById(key);
            const value = row[body[key]]
              ? format_date_formal(row[body[key]])
              : "-";
            if (element) {
              element.innerHTML = value;
            }
          } else if (all_keys_that_use_table.includes(key)) {
            // do nothing
          } else {
            const element = document.getElementById(key);
            if (element) {
              let value = "-";
              if (is_not_empty(row[body[key]], [""])) {
                if (!isNaN(row[body[key]])) {
                  value = new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(row[body[key]]);
                } else {
                  value = row[body[key]];
                }
              }
              element.innerHTML = value;
            }
          }
        });
      }
    }
  };

  createTable = ({
    feature,
    parent_table_id,
    new_body_table_id,
    setup_table_id,
    body,
  }) => {
    let final_result_luas_tanah = 0;
    let nilai_pasar_total_sesuai_fisik_real = 0;
    let indikasi_nilai_likuiditas_sesuai_fisik_real = 0;
    let total_mv = 0;
    let total_likuiditas = 0;

    // tbody
    const parent_table = document.getElementById(parent_table_id);
    const tbl = document.createElement("tbody");
    tbl.setAttribute("id", new_body_table_id);

    // children feature
    const column_parent_uuid = body?.[setup_table_id];
    const children = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
      feature,
      column_parent_uuid: column_parent_uuid,
    });

    const keys_that_needed_for_the_table = Object.keys(body).filter((key) =>
      keys_that_use_table[setup_table_id]?.includes(key)
    );

    // value per kolom
    let total = {};
    children?.forEach((child, index) => {
      const tr = tbl.insertRow();

      createTd({
        tr,
        text: `${index + 1}.`,
        pClassName: "text_center",
      });

      keys_that_needed_for_the_table?.forEach((key) => {
        const value = child?.properties?.[body?.[key]];
        let readable_value;
        // !isNaN(value)
        //   ? dot_every_3_digits(value)
        //   : value || "";

        if (!isNaN(value)) {
          readable_value = dot_every_3_digits(value);
        } else if (moment(value, "YYYY-MM-DD", true).isValid()) {
          readable_value = value || "";
        } else {
          readable_value = value || "";
        }

        let align_text = "";
        if (is_not_empty(value) && !isNaN(value)) {
          align_text = "text_right";
          if (!total[body[key]]) {
            total[body[key]] = 0;
          }

          if (setup_table_id === "c3_setup_ringkasan_hasil_penilaian_pdf") {
            if (total_that_will_show_id?.[setup_table_id]?.includes(key)) {
              total[body[key]] = total?.[body[key]] + Number(value);
            }
          } else if (setup_table_id === "c4_setup_sesuai_fisik_peraturan_pdf") {
            if (total_that_will_show_id?.[setup_table_id]?.includes(key)) {
              total[body[key]] = total?.[body[key]] + Number(value);
            }
          } else if (setup_table_id === "c7_setup_data_legal_pdf") {
            if (total_that_will_show_id?.[setup_table_id]?.includes(key)) {
              total[body[key]] = total?.[body[key]] + Number(value);
            }
          }
        } else {
          align_text = "text_left";
        }

        if (key !== "c7_pengurangan_tata_kota_pdf") {
          let output = readable_value;

          // kalo misal ada syarat khusus pada kolom tertentu
          // misal: kolom No.Dokumen yang berupa angka tidak perlu diberi titik setipa 3 digit
          if (key === "c7_no_dokumen_pdf") {
            output = value;
          }

          createTd({
            tr,
            text: output,
            pClassName: align_text,
          });
        }
      });
    });

    // menghitung + bikin row total di table
    if (
      setup_table_id === "c4_setup_sesuai_fisik_peraturan_pdf" ||
      setup_table_id === "c3_setup_ringkasan_hasil_penilaian_pdf"
    ) {
      let tr = tbl.insertRow();
      let text_total = "";

      if (setup_table_id === "c3_setup_ringkasan_hasil_penilaian_pdf") {
        const nilai_pasar_yang_sudah_dijumlah =
          total[body[total_that_will_show_id[setup_table_id][0]]] || 0;
        if (!isNaN(nilai_pasar_yang_sudah_dijumlah)) {
          nilai_pasar_total_sesuai_fisik_real += Number(
            nilai_pasar_yang_sudah_dijumlah
          );
        }

        const indikasi_nilai_likuiditas_yang_sudah_dijumlah =
          total[body[total_that_will_show_id[setup_table_id][1]]] || 0;
        if (!isNaN(indikasi_nilai_likuiditas_yang_sudah_dijumlah)) {
          indikasi_nilai_likuiditas_sesuai_fisik_real += Number(
            indikasi_nilai_likuiditas_yang_sudah_dijumlah
          );
        }

        // hitung nilai pasar total imb
        const nilai_pasar_value =
          feature?.properties?.[body?.["c3_total_rp_tanah_imb_pdf"]] || 0;
        if (body?.["c3_total_rp_tanah_imb_pdf"] && !isNaN(nilai_pasar_value)) {
          nilai_pasar_total_sesuai_fisik_real += Number(nilai_pasar_value);
        }

        // hitung indikasi nilai likuiditas imb
        const indikasi_nilai_likuiditas_value =
          feature?.properties?.[
            body?.["c3_total_rp_tanah_likuidasi_imb_pdf"]
          ] || 0;
        if (
          body?.["c3_total_rp_tanah_likuidasi_imb_pdf"] &&
          !isNaN(indikasi_nilai_likuiditas_value)
        ) {
          indikasi_nilai_likuiditas_sesuai_fisik_real += Number(
            indikasi_nilai_likuiditas_value
          );
        }
      }

      if (setup_table_id === "c4_setup_sesuai_fisik_peraturan_pdf") {
        const nilai_pasar_value =
          total[body[total_that_will_show_id[setup_table_id][0]]] || 0;
        if (!isNaN(nilai_pasar_value)) {
          nilai_pasar_total_sesuai_fisik_real += Number(nilai_pasar_value);
        }

        const indikasi_nilai_likuiditas_value =
          total[body[total_that_will_show_id[setup_table_id][1]]] || 0;
        if (!isNaN(indikasi_nilai_likuiditas_value)) {
          indikasi_nilai_likuiditas_sesuai_fisik_real += Number(
            indikasi_nilai_likuiditas_value
          );
        }

        if (body?.["c4_total_rp_tanah_fisik_peraturan_pdf"]) {
          // console.log(
          //   "tanah",
          //   typeof feature?.properties?.[
          //     body?.["c4_total_rp_tanah_fisik_peraturan_pdf"]
          //   ]
          // );
          nilai_pasar_total_sesuai_fisik_real += Number(
            feature?.properties?.[
              body?.["c4_total_rp_tanah_fisik_peraturan_pdf"]
            ]
          );
        }

        if (body?.["c4_total_rp_tanah_likuidasi_fisik_peraturan_pdf"]) {
          indikasi_nilai_likuiditas_sesuai_fisik_real += Number(
            feature?.properties?.[
              body?.["c4_total_rp_tanah_likuidasi_fisik_peraturan_pdf"]
            ]
          );
        }

        if (body?.["c4_tabel_sarana_pelengkap_pdf"]) {
          const rows_sarana_pelengkap =
            getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
              feature,
              column_parent_uuid: body?.["c4_tabel_sarana_pelengkap_pdf"],
            });

          rows_sarana_pelengkap?.forEach((row) => {
            total_mv += row?.properties?.[body?.["c4_mv_pdf"]];
            total_likuiditas +=
              row?.properties?.[body?.["c4_likuidasi_rp_pdf"]];
          });

          nilai_pasar_total_sesuai_fisik_real += total_mv;
          indikasi_nilai_likuiditas_sesuai_fisik_real += total_likuiditas;
        }

        createTd({
          tr,
          text: "Sarana Pelengkap",
          pClassName: "subtitle_pdf center_perfect",
          colspan: 4,
        });
        createTd({
          tr,
          text: dot_every_3_digits(total_mv),
          pClassName: "text_right",
        });
        createTd({
          tr,
          text: "",
        });
        createTd({
          tr,
          text: dot_every_3_digits(total_likuiditas),
          pClassName: "text_right",
        });
        tr = tbl.insertRow();

        text_total = "TOTAL SETELAH PENYESUAIAN PERATURAN";
      } else if (setup_table_id === "c3_setup_ringkasan_hasil_penilaian_pdf") {
        text_total = "TOTAL SESUAI IMB";
      }

      createTd({
        tr,
        text: text_total,
        pClassName: "subtitle_pdf center_perfect",
        colspan: 4,
      });

      createTd({
        tr,
        text: dot_every_3_digits(nilai_pasar_total_sesuai_fisik_real),
        pClassName: "text_right",
      });

      tr.insertCell();

      createTd({
        tr,
        text: dot_every_3_digits(indikasi_nilai_likuiditas_sesuai_fisik_real),
        pClassName: "text_right",
      });
    } else if (setup_table_id === "c7_setup_data_legal_pdf") {
      // Pengurangan Tata Kota
      let tr = tbl.insertRow();
      let text_total = "Pengurangan Tata Kota";
      createTd({
        tr,
        text: text_total,
        pClassName: "subtitle_pdf center_perfect",
        colspan: 7,
      });

      createTd({
        tr,
        text: dot_every_3_digits(
          total[body["c7_pengurangan_tata_kota_pdf"]] / children?.length || 0
        ),
        pClassName: "text_right",
      });

      createTd({
        tr,
        text: "",
        pClassName: "text_right",
      });

      // total
      tr = tbl.insertRow();
      text_total = "TOTAL";
      createTd({
        tr,
        text: text_total,
        pClassName: "subtitle_pdf center_perfect",
        colspan: 7,
      });

      const result =
        (total[body["c7_luas_pdf"]] || 0) -
        (total[body["c7_pengurangan_tata_kota_pdf"]] || 0) / children?.length;

      final_result_luas_tanah = result;

      createTd({
        tr,
        text: dot_every_3_digits(result),
        pClassName: "text_right",
      });

      createTd({
        tr,
        text: "",
        pClassName: "text_right",
      });
    }

    if (parent_table) {
      parent_table.appendChild(tbl);
    }

    if (setup_table_id === "c7_setup_data_legal_pdf") {
      return final_result_luas_tanah;
    } else if (setup_table_id === "c4_setup_sesuai_fisik_peraturan_pdf") {
      return {
        nilai_pasar_total_sesuai_fisik_real,
        indikasi_nilai_likuiditas_sesuai_fisik_real,
      };
    }
  };

  submitTheSetup = () => {
    const layer_id = this.props?.layer?.layer_id;
    const { template, body } = this.state;
    const content = {
      geo_layer_id: layer_id,
      laporan_pdf: { template, body },
    };
    this.props.edit_laporan_pdf(content);
  };

  setValue = (key, value) => {
    const { body, template } = this.state;
    body[key] = value;
    this.setState(
      {
        template,
        body,
      },
      () => this.submitTheSetup()
    );
  };

  getSetupLaporanPdf = () => {
    const { layer } = this.props;
    const geo_layer = getGeoLayer(layer);
    const laporan_pdf = geo_layer?.["laporan_pdf"];
    this.setState({
      ...laporan_pdf,
    });
  };

  render() {
    const layer_id = this.props?.layer?.layer_id;
    const { mode = "setup", fields, layer } = this.props;
    const { body } = this.state;
    const geo_layer = getGeoLayer(layer);
    const { row } = this.state;

    return (
      <section>
        <div>
          <C1Logo
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
          />
          <C2LaporanHasilPenilaianAgunan
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
          <br />
          <C3RingkasanHasilPenilaian
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
          <br />
          <C4SesuaiFisikPeraturan
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
        </div>
        <div className="break_before_pdf">
          <C5SesuaiDenganInspeksi
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            user={geo_layer?.user}
            if_string_is_empty="-"
          />
        </div>
        <div className="break_before_pdf">
          <C6LaporanHasilPemeriksaan
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
          <br />
          <C7DataLegal
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
          <br />
          <C8Kondisi
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
        </div>
        <div className="break_before_pdf">
          <C9Lingkungan
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
        </div>
        <div className="break_before_pdf">
          <C10InfrastrukturDanFasilitas
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
        </div>
        <div className="break_before_pdf">
          <C11ProgresPenyelesaianBangunan
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
          <C12KeteranganTambahan
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            if_string_is_empty="-"
          />
          <br />
          <C13PembandingDataPasar
            mode={mode}
            fields={fields}
            layer_id={layer_id}
            onChange={this.setValue}
            body={body}
            row={row}
            c13_setup_object_penilaian_dan_data_pembanding={
              c13_setup_object_penilaian_dan_data_pembanding
            }
            if_string_is_empty="-"
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
});

export default connect(
  mapStateToProps,
  {
    getDetailLayerById,
    edit_laporan_pdf,
  },
  null,
  { forwardRef: true }
)(SetupDownloadLaporanPdfDanamas);
