/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { searchInside } from "../../App/actions/layerActions";
import { set_item_on_layer } from "../../App/actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const ENTER_KEY = 13;

class SEARCH_INSIDE_LAYER extends Component {
  state = { search_inside: "" };

  on_back = () => {
    this.props.set_value_properties({
      key: "mode_search",
      value: "layer",
    });
  };

  on_change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeLayer = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleKeyDownLayer(e) {
    if (e.keyCode === ENTER_KEY) {
      this.on_search_inside();
    }
  }

  on_search_inside = () => {
    this.props.set_value_properties({
      key: "modal_input",
      value: false,
    });
    const { search_inside } = this.state;
    const { layer_id_search_inside } = this.props.properties;
    this.props.searchInside(layer_id_search_inside, search_inside);
  };

  on_reset_inside = () => {
    this.props.set_value_properties({
      key: "modal_input",
      value: false,
    });
    const { layer_id_search_inside } = this.props.properties;
    const geojson = {
      type: "FeatureCollection",
      features: [],
    };
    this.props.set_item_on_layer({
      key: "geojson",
      value: geojson,
      layer_id: layer_id_search_inside,
    });
    this.props.set_item_on_layer({
      key: "geojson_filtered",
      value: geojson,
      layer_id: layer_id_search_inside,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { search_inside } = this.state;

    //global props
    const {
      // layer_id_search_inside,
      layer_name_search_inside,
    } = this.props.properties;

    //content
    return (
      <main>
        <button className="button margin_bottom" onClick={this.on_back}>
          {dict?.["Back"]?.[language]}
        </button>
        <section className="container_light">
          <p className="text_small">
            Cari kata di dalam layer
            <br />
            <b>{layer_name_search_inside}</b>
          </p>
          <input
            type="text"
            name="search_inside"
            value={search_inside}
            placeholder={`${dict["Search data in layer"][language]} : ${layer_name_search_inside}`}
            onChange={this.handleChangeLayer.bind(this)}
            onKeyDown={this.handleKeyDownLayer.bind(this)}
            autoFocus
          />
          <button
            className="button_small margin_right"
            onClick={this.on_search_inside}
          >
            {dict?.["Search"]?.[language]}
          </button>
          <button
            className="button_small margin_right background_red"
            onClick={this.on_reset_inside}
          >
            Reset
          </button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  searchInside,
  set_item_on_layer,
})(SEARCH_INSIDE_LAYER);
