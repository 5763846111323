/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

const limit_char = 20;

class TableEditViaForm extends Component {
  state = {};
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { domain } = this.props.auth;

    const domain_temp = domain.includes("192.168.1")
      ? domain_list?.["localhost"]?.form_link
      : domain_list?.[domain]?.form_link;

    let { geo_layer } = this.props.layer;
    geo_layer = geo_layer?.geo_layer ? geo_layer.geo_layer : {};
    const fields = geo_layer?.fields ? geo_layer?.fields : [];
    const fields_filtered = fields.filter(
      (field) =>
        !field.isHide &&
        !field.isStyle &&
        !["section"].includes(field?.type) &&
        !field.parent_uuid
    );
    const features = geo_layer?.geojson?.features
      ? geo_layer.geojson.features
      : [];
    let csvData = features.map((feature) => {
      const { properties } = feature;
      let object = {
        link: `${domain_temp}${geo_layer.link}/step_1/${feature.key}`,
      };
      if (fields_filtered.length > 0) {
        object = {
          [fields_filtered[0].name]: properties[fields_filtered[0].key],
          link: `${domain_temp}${geo_layer.link}/step_1/${feature.key}`,
        };
      }
      return object;
    });
    let headers = [
      {
        label: "Link untuk edit",
        key: "link",
      },
    ];
    if (fields_filtered.length > 0) {
      headers = [
        {
          label: fields_filtered[0].name,
          key: fields_filtered[0].name,
        },
        {
          label: "Link untuk edit",
          key: "link",
        },
      ];
    }
    const content = (
      <div>
        <div style={{ overflow: "scroll" }}>
          <div
            style={{
              maxHeight: "calc(( 100vh - 110px  ))",
            }}
          >
            <table className="table_lite" style={{ minWidth: "100%" }}>
              <thead>
                <tr>
                  <th style={{ minWidth: "100px" }}>
                    <CSVLink
                      filename={geo_layer.name}
                      data={csvData}
                      headers={headers}
                      style={{ color: "#fff", display: "block" }}
                    >
                      {dict["Download links as CSV"][language]}
                    </CSVLink>
                  </th>
                  {fields_filtered.map((field, idx) => {
                    return (
                      <th key={idx} style={{ minWidth: "100px" }}>
                        {field?.name?.slice(0, limit_char)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {features.map((feature, idx) => {
                  return (
                    <tr key={idx}>
                      <th
                        style={{
                          backgroundColor: "#fff",
                          color: "#000",
                          fontWeight: "bold",
                        }}
                      >
                        {idx + 1}
                        <br />
                        <a
                          href={`${domain_temp}${geo_layer.link}/step_3/${feature.key}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button_very_small"
                          id="blue"
                        >
                          Link edit
                        </a>
                      </th>
                      {fields_filtered.map((field, idx) => {
                        let content;
                        if (isNaN(Number(feature?.properties?.[field?.key]))) {
                          content = String(
                            feature?.properties?.[field?.key]
                          )?.slice(0, limit_char);
                        } else {
                          content = feature?.properties?.[field?.key];
                        }
                        return <td key={idx}>{content}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <style>{`
        body{overflow-y: hidden;}
        table {
          position: relative;
          border-collapse: collapse;
        }
        td,
        th {
          padding: 0.25em;
        }
        thead th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          top: 0;
          background: #000;
          color: #FFF;
        }
        thead th:first-child {
          left: 0;
          z-index: 1;
        }
        tbody th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          left: 0;
          background: #FFF;
          border-right: 1px solid #CCC;
        }
        
        `}</style>
      </div>
    );
    return <div>{content}</div>;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(TableEditViaForm);
