/*LIBRARY MODULE*/
import React, { Component } from "react";

/*PERSONAL COMPONENT*/
import Modal from "../reuseable/Modal";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import ApiInput from "./ApiInput";

export default class ModalApiInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_api_input: false,

      layer_name: "",
      description: "",
      folder: "",
      modal_layer_name: false,
      geometry_type: "Point",
      page: "Test",
      response: {},
      is_feature_valid: false,
      read_open_api: {
        api_endpoint: "",
        http_method: "get",
        headers: [],
        params: [],
        body: [],
      },
    };
  }

  handle_open_api = (value) => {
    this.setState({
      read_open_api: value
    })
  }

  toggle_modal_api_input = () => {
    const { modal_api_input } = this.state;
    this.setState({
      modal_api_input: !modal_api_input,
    });
  };

  handle_save = () => {
    const { layer_name, geometry_type, description, folder, read_open_api } =
      this.state;
    const { geo_project } = this.props.project;

    const { headers, params, body } = read_open_api;

    const body_request = {
      name: layer_name,
      description,
      type: geometry_type,
      folder,
      user_id: this.props.auth.user._id,
      geo_project_id: geo_project._id,
      is_custom_endpoint: true,
      read_open_api: {
        ...read_open_api,
        headers: headers.slice(0, headers?.length - 1),
        params: params.slice(0, params?.length - 1),
        body: body.slice(0, body?.length - 1),
      },
      status: "original",
      architecture: "api",
      is_show_and_fly: true,
      properties: [
        {
          key: "fill_color",
          name: "color",
          type: "color",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: "#1E5E96",
        },
        {
          key: "opacity",
          name: "opacity",
          type: "range",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: 0.5,
          min: 0,
          max: 1,
          step: 0.1,
        },
        {
          key: "outline_color",
          name: "outline_color",
          type: "color",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: "#1E5E96",
        },
        {
          key: "outline_width",
          name: "outline_width",
          type: "number",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: 1,
        },
        {
          key: "text_field",
          name: "label",
          type: "text",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: "point",
        },
        {
          key: "is_using_label",
          name: "Use label",
          type: "checkbox",
          array_templates: [],
          isHighlight: false,
          isHide: true,
          isStyle: true,
          defaultValue: false,
        },
      ],
    };

    this.props.create_layer_custom_api(body_request);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modal_api_input, read_open_api } = this.state;

    //global props

    //content

    const modal_api_input_content = modal_api_input && (
      <Modal
        title={dict["Connect to API"][language]}
        modalSize="normal"
        id="modal_api_input"
        isOpen={modal_api_input}
        onClose={this.toggle_modal_api_input}
      >
        <main className="box-body">
          <ApiInput
            name={"read_open_api"}
            open_api={read_open_api}
            handle_open_api={this.handle_open_api}
            toggle_modal_api_input={this.toggle_modal_api_input}
          />
        </main>
      </Modal>
    );

    return (
      <main>
        <button
          className="button rounded_25"
          onClick={this.toggle_modal_api_input}
        >
          {dict["Create Layer by API"][language]}
        </button>
        {modal_api_input_content}
      </main>
    );
  }
}
