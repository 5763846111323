import React, { Component } from "react";
import SetupTableC4SesuaiFisikPeraturan from "./SetupTableC4SesuaiFisikPeraturan";
import DropdownOrData from "../DropdownOrData";

export default class C4_SesuaiFisikPeraturan extends Component {
  render() {
    const { mode } = this.props;

    return (
      <>
        {mode === "setup" && (
          <SetupTableC4SesuaiFisikPeraturan
            id="c4_setup_sesuai_fisik_peraturan_pdf"
            {...this.props}
          />
        )}

        <table
          id="c4_table_sesuai_fisik_peraturan_pdf"
          className="table_pdf side_border"
          cellSpacing={0}
        >
          <thead>
            <tr style={{ height: "16pt" }}>
              <td colSpan={7}>
                <p className="subtitle_pdf">Sesuai Fisik Peraturan</p>
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>
                <p>No.</p>
              </td>
              <td rowSpan={2}>
                <p>Object</p>
              </td>
              <td rowSpan={2}>
                <p>Luas (m²)</p>
              </td>
              <td colSpan={2}>
                <p>Nilai Pasar</p>
              </td>
              <td colSpan={2}>
                <p>Indikasi Nilai Likuidasi</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Nilai/m² (Rp.)</p>
              </td>
              <td>
                <p>Total (Rp.)</p>
              </td>
              <td>
                <p>Nilai/m² (Rp.)</p>
              </td>
              <td>
                <p>Total (Rp.)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>-</p>
              </td>
              <td>
                <p className="text_left">Tanah</p>
                {/* <DropdownOrData
                  id="object_tanah_fisik_peraturan_pdf"
                  className="highlight"
                  {...this.props}
                /> */}
              </td>
              <td>
                <DropdownOrData
                  id="c4_luas_tanah_fisik_peraturan_pdf"
                  className="text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c4_nilai_m2_rp_tanah_fisik_peraturan_pdf"
                  className="text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c4_total_rp_tanah_fisik_peraturan_pdf"
                  className="text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c4_nilai_m2_rp_tanah_likuidasi_fisik_peraturan_pdf"
                  className=" text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c4_total_rp_tanah_likuidasi_fisik_peraturan_pdf"
                  className=" text_right"
                  {...this.props}
                />
              </td>
            </tr>
          </thead>
        </table>
      </>
    );
  }
}
