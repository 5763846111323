import colors from "../../Data/st_colors.json";

export const color_setup = ({
  feature,
  colorStatus = "#1a8bc0",
  idx,
  geometry_type,
}) => {
  let color_feature;
  if (colorStatus === "random") {
    color_feature = colors[idx % 16];
    // feature.circle_color = colors[idx % 16];
  } else if (
    colorStatus[0] === "#" &&
    (colorStatus.length === 7 || colorStatus.length === 4)
  ) {
    color_feature = colorStatus;
    // feature.circle_color = colorStatus;
  } else {
    if (feature?.properties) {
      if (feature?.properties?.[colorStatus]) {
        color_feature = feature?.properties?.[colorStatus];
        // feature.circle_color = feature?.properties?.[colorStatus];
      } else if (feature?.[colorStatus]) {
        color_feature = feature?.[colorStatus];
        // feature.circle_color = feature?.[colorStatus];
      } else {
        color_feature = colors[idx % 16];
        // feature.circle_color = colors[idx % 16];
      }
    } else {
      if (feature?.[colorStatus]) {
        color_feature = feature?.[colorStatus];
        // feature.circle_color = feature?.[colorStatus];
      } else if (feature?.[colorStatus]) {
        color_feature = feature?.[colorStatus];
        // feature.circle_color = feature?.[colorStatus];
      } else {
        color_feature = colors[idx % 16];
        // feature.circle_color = colors[idx % 16];
      }
    }
  }

  let prop_default;
  if (geometry_type === "Point" || geometry_type === "MultiPoint") {
    prop_default = {
      icon_image: "museum",
      text_field: "icon_image",
      circle_color: color_feature,
      circle_radius: 5,
      circle_stroke_width: 1,
      circle_stroke_color: "#ffffff",
    };
  } else if (geometry_type === "Polygon" || geometry_type === "MultiPolygon") {
    prop_default = {
      color: color_feature,
      opacity: 0.5,
    };
  } else {
    prop_default = {
      color: color_feature,
      width: 3,
      line_width: 3,
      opacity: 1,
      line_gap_width: 0,
    };
  }

  return prop_default;
};

export const geometry_setup = ({
  feature,
  geometry_type,
  lat_key,
  long_key,
}) => {
  let coordinates;
  if (geometry_type === "Point") {
    if (feature?.geometry?.coordinates) {
      coordinates = feature?.geometry?.coordinates;
    } else if (feature?.[lat_key] && feature?.[long_key]) {
      coordinates = [Number(feature[long_key]), Number(feature[lat_key]), 0];
    } else {
      coordinates = [0, 0, 0];
    }
  } else {
    coordinates = feature?.geometry?.coordinates;
  }
  return coordinates;
};
