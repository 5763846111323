const get_child_nested = ({
  parent_value,
  parent_key,
  result = [],
  cabang = [],
  all_list,
  result_key,
}) => {
  const children = all_list
    .filter((row) => row?.[parent_key] === parent_value)
    .map((row) => String(row?.[result_key]));
  const num_childred = children.length;
  const num_cabang = cabang?.length;
  if (num_childred === 0) {
    const last_cabang = cabang[num_cabang - 1];
    if (last_cabang && !result.includes(last_cabang)) {
      result.push(last_cabang);
    } else if (parent_value && !result.includes(parent_value)) {
      result.push(parent_value);
    }
    const new_cabang = cabang.filter((cab) => cab !== last_cabang);
    if (num_cabang > 0)
      return get_child_nested({
        parent_value: last_cabang,
        parent_key,
        result: result,
        cabang: new_cabang,
        all_list,
        result_key,
      });
    return result;
  } else {
    const active = children.pop();
    if (!result.includes(parent_value)) result.push(parent_value);
    if (!result.includes(active)) result.push(active);
    cabang.push(...children);
    return get_child_nested({
      parent_value: active,
      parent_key,
      result,
      cabang,
      all_list,
      result_key,
    });
  }
};

export default get_child_nested;
