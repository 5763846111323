/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  setTabelStatus,
  setEditGeometry,
  setRealtimeStatus,
} from "../../App/actions/layerActions";
import {
  view_layer,
  set_layer_id,
  view_heatmap,
} from "../../App/actions/dataActions";
import { get_pda, get_pch } from "../../App/actions/iotActions";
import { getDetailLayerById } from "../../App/actions/layerNewActions";
import { iotLastDataAll, getSDATelemetry } from "../../App/actions/iotActions";
import { get_layer_detail_custom_api } from "../../App/actions/openApiActions";
import { set_value_toolbox } from "../../App/actions/toolboxActions";

/*PICTURE ASSET*/
import icon_edit_properties from "../../Assets/svg/icon_edit_properties.svg";
import icon_edit_properties_white from "../../Assets/svg/icon_edit_properties_white.svg";
import icon_view from "../../Assets/svg/icon_view.svg";
import icon_view_white from "../../Assets/svg/icon_view_white.svg";
import icon_refresh from "../../Assets/svg/refresh.svg";
import icon_heatmap_black from "../../Assets/svg/icon_heatmap_black.svg";
import icon_heatmap_white from "../../Assets/svg/icon_heatmap_white.svg";

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class LayerListRender extends Component {
  interval = null;
  state = {
    modalEdit: false,
    modalDelete: false,
    realtimeState: false,
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  //1
  toggle_fly_layer = (geo_layer_item, folder_id) => {
    const { layer_id } = this.props.layer;
    const { geo_project } = this.props.project;

    if (
      //layer yang dipilih bukanlah layer yang sedang aktif
      layer_id !== geo_layer_item._id
    ) {
      if (
        //belum ada geojson di dalam layer yang diklik, akses data dahulu
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              flyStatus: true,
              folder_id,
              capex_value: this.props.properties.capex_value,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        //sudah ada geojson hanya fly saja
        this.props.set_layer_id(geo_layer_item._id);
        //kemungkinan 2: sudah ada geojson, langsung toggle view
        const body = { geo_layer_id: geo_layer_item._id, value: true };
        this.props.view_layer(body);
      }
    } else {
      this.props.set_layer_id("");
    }
  };

  //2
  on_view_layer = (geo_layer_item, value, folder_id) => {
    //layer yang saat ini sedang aktif (layer yang diklik bisa jadi sama, bisa jadi beda)
    const { layer_id } = this?.props?.layer;
    if (
      (layer_id !== geo_layer_item?._id &&
        geo_layer_item?.geojson?.features?.length === 0) ||
      !geo_layer_item?.geojson?.features
    ) {
      //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
      if (geo_layer_item?.type_2 === "pch") {
        this.props.get_pch(geo_layer_item);
      } else if (geo_layer_item?.type_2 === "pda") {
        this.props.get_pda(geo_layer_item);
      } else {
        if (geo_layer_item?.is_custom_endpoint) {
          this.props.get_layer_detail_custom_api(geo_layer_item._id);
        } else {
          const { geo_project } = this.props.project;
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            folder_id: folder_id,
            capex_value: this.props.properties.capex_value,
            geo_layer_item,
          };
          this.props.getDetailLayerById(body);
        }
      }
    } else {
      //kemungkinan 2: sudah ada geojson, langsung toggle view
      const body = { geo_layer_id: geo_layer_item._id, value };
      this.props.view_layer(body);
    }
  };

  //3
  on_view_heatmap = (geo_layer_item, value, folder_id) => {
    //layer yang saat ini sedang aktif (layer yang diklik bisa jadi sama, bisa jadi beda)
    const { layer_id } = this?.props?.layer;
    if (
      //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
      (layer_id !== geo_layer_item?._id &&
        geo_layer_item?.geojson?.features?.length === 0) ||
      !geo_layer_item?.geojson?.features
    ) {
      if (geo_layer_item?.type_2 === "pch") {
        this.props.get_pch(geo_layer_item);
      } else if (geo_layer_item?.type_2 === "pda") {
        this.props.get_pda(geo_layer_item);
      } else {
        if (geo_layer_item?.is_custom_endpoint) {
          this.props.get_layer_detail_custom_api(geo_layer_item._id);
        } else {
          const { geo_project } = this.props.project;
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            folder_id,
            capex_value: this.props.properties.capex_value,
            geo_layer_item,
          };
          this.props.getDetailLayerById(body);
        }
      }
    } else {
      //kemungkinan 2: sudah ada geojson, langsung toggle view
      const body = { geo_layer_id: geo_layer_item._id, value };
      this.props.view_heatmap(body);
    }

    if (value === false) {
      const { map_object } = this.props.layer;
      const layer_id_heatmap = `heatmap_${geo_layer_item?._id}`;
      if (map_object.getLayer(layer_id_heatmap)) {
        map_object.removeLayer(layer_id_heatmap);
      }
      if (map_object.getSource(layer_id_heatmap)) {
        map_object.removeSource(layer_id_heatmap);
      }
    }
  };

  //4
  toggle_edit_geometry = (geo_layer_item, folder_id) => {
    this.props.set_value_toolbox({
      key: "draw_toolbox_status",
      value: false,
    });
    let { geo_layer, geometryStatus } = this.props.layer;
    geo_layer = geo_layer?.geo_layer;
    const { geo_project } = this.props.project;

    if (geo_layer?._id !== geo_layer_item._id) {
      if (!geo_layer_item?.geojson?.features?.length) {
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          const { geo_project } = this.props.project;
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            folder_id: folder_id,
            capex_value: this.props.properties.capex_value,
            geometryStatus: !geometryStatus,
            geo_layer_item,
          };
          this.props.getDetailLayerById(body);
        }
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setEditGeometry(!geometryStatus);
      }
    } else {
      if (geo_layer_item?.geojson?.features?.length) {
        if (geo_layer_item?.is_custom_endpoint) {
          this.props.get_layer_detail_custom_api(geo_layer_item._id);
        } else {
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            folder_id,
            capex_value: this.props.properties.capex_value,
            geometryStatus: !geometryStatus,
            geo_layer_item,
          };
          this.props.getDetailLayerById(body);
        }
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setEditGeometry(!geometryStatus);
      }
    }
  };

  //5
  toggle_edit_property = (geo_layer_item, folder_id) => {
    const { tableStatus, layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    //Jika layer yang diklik bukanlah layer yang sedang aktif--> aktifkan layer
    if (layer_id !== geo_layer_item._id) {
      //Jika layer belum memiliki geojson, ambil geojson via API
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(true);
        //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              folder_id,
              capex_value: this.props.properties.capex_value,
              tableStatus: true,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        //Jika layer sudah memiliki geojson --> aktifkan layer tanpa mengambil geojson lagi
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(true);
      }
    } else {
      //Jika layer yang diklik adalah layer yang sedang aktif
      //Jika layer awalnya kosong, coba cek ke API lagi, barangkali sekarang ada isinya
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(!tableStatus);
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              folder_id,
              capex_value: this.props.properties.capex_value,
              tableStatus: !tableStatus,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(!tableStatus);
      }
    }
  };

  toggle_refresh_property = (geo_layer_item, folder_id) => {
    const { geo_project } = this.props.project;
    this.props.setTabelStatus(true);

    if (geo_layer_item?.type_2 === "pch") {
      this.props.get_pch(geo_layer_item);
    } else if (geo_layer_item?.type_2 === "pda") {
      this.props.get_pda(geo_layer_item);
    } else {
      if (geo_layer_item?.is_custom_endpoint) {
        this.props.get_layer_detail_custom_api(geo_layer_item._id);
      } else {
        const body = {
          geo_layer_id: geo_layer_item._id,
          geo_project_id: geo_project._id,
          folder_id,
          capex_value: this.props.properties.capex_value,
          tableStatus: true,
          geo_layer_item,
        };
        this.props.getDetailLayerById(body);
      }
    }
  };

  render() {
    let {
      tableStatus,
      flyStatus,
      layer_id_active,
      layer_id_heatmap,
      layer_id,
    } = this.props.layer;
    let { geo_layer_list_local } = this.props;

    return (
      <main>
        <main className="carousell">
          {geo_layer_list_local.map((l, idx) => {
            const layer_item = l.geo_layer;
            const { _id, name, type } = layer_item;
            let id_css = "box_non_active";
            if (_id === layer_id && flyStatus) {
              id_css = "box_active";
            } else {
              id_css = "box_non_active";
            }
            let icon_v = icon_view;

            const viewStatus = layer_id_active.includes(layer_item._id);
            const view_heatmap = layer_id_heatmap.includes(layer_item._id);

            if (viewStatus) {
              icon_v = icon_view_white;
            }
            let icon_p = icon_edit_properties;
            if (_id === layer_id && tableStatus) {
              icon_p = icon_edit_properties_white;
            }

            let main_item = (
              <main
                key={idx}
                className="layerBoxBottom"
                id={id_css}
                title={name}
              >
                <section
                  onClick={this.toggle_fly_layer.bind(this, layer_item)}
                  className="layer_box_section"
                >
                  {name}
                  {type === "IoT" && (
                    <div
                      className="badge_normal"
                      id="blue"
                      style={{ marginLeft: "0.3rem" }}
                    >
                      IoT
                    </div>
                  )}
                </section>
                <div className="row_button_view">
                  <div
                    className="buttonCenter"
                    style={{
                      background: `${
                        _id === layer_id && tableStatus
                          ? "#606f8dff"
                          : "#e5eefdff"
                      }`,
                    }}
                    onClick={this.toggle_edit_property.bind(this, layer_item)}
                  >
                    <img
                      src={icon_p}
                      alt="Table"
                      className="icon_list_render"
                    />
                  </div>
                  {viewStatus ? (
                    <button
                      className="buttonCenter"
                      style={{
                        background: "#606f8d",
                      }}
                      onClick={this.on_view_layer.bind(this, layer_item, false)}
                    >
                      <img
                        src={icon_v}
                        alt="View"
                        className="icon_list_render"
                      />
                    </button>
                  ) : (
                    <button
                      className="buttonCenter"
                      style={{
                        background: "#e5eefdff",
                      }}
                      onClick={this.on_view_layer.bind(this, layer_item, true)}
                    >
                      <img
                        src={icon_v}
                        alt="View"
                        className="icon_list_render"
                      />
                    </button>
                  )}
                  {view_heatmap ? (
                    <button
                      className="buttonCenter"
                      style={{
                        background: "#606f8d",
                      }}
                      onClick={this.on_view_heatmap.bind(
                        this,
                        layer_item,
                        false
                      )}
                    >
                      <img
                        src={icon_heatmap_white}
                        alt="View"
                        className="icon_list_render"
                      />
                    </button>
                  ) : (
                    <button
                      className="buttonCenter"
                      style={{
                        background: "#e5eefdff",
                      }}
                      onClick={this.on_view_heatmap.bind(
                        this,
                        layer_item,
                        true
                      )}
                    >
                      <img
                        src={icon_heatmap_black}
                        alt="View"
                        className="icon_list_render"
                      />
                    </button>
                  )}
                  {l.geo_layer?.tracking?.status === "on" && (
                    <button
                      className="buttonCenter"
                      style={{
                        background: `#e5eefdff`,
                      }}
                      onClick={this.toggle_refresh_property.bind(
                        this,
                        layer_item
                      )}
                    >
                      <img
                        src={icon_refresh}
                        alt="View"
                        className="icon_list_render"
                      />
                    </button>
                  )}
                </div>
              </main>
            );
            return main_item;
          })}
          {geo_layer_list_local.length >= 6 ? (
            <div className="layerBoxBottom_transparent"></div>
          ) : null}
        </main>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_layer_id,
  setTabelStatus,
  setEditGeometry,
  view_layer,
  setRealtimeStatus,
  getDetailLayerById,
  iotLastDataAll,
  getSDATelemetry,
  get_pda,
  get_pch,
  view_heatmap,
  get_layer_detail_custom_api,
  set_value_toolbox,
})(LayerListRender);
