import React, { Component } from "react";
import UploadImage from "./UploadImage";
import dict from "../../Data/dict.json";
import Modal from "../common_modal/Modal";

export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalImagePreview: false,
      image: "",
    };
  }

  componentDidMount = () => {
    this.setState({
      image: this.props.params?.value,
    });
  };

  toggleImagePreview = () => {
    this.setState({ modalImagePreview: !this.state.modalImagePreview });
  };

  handlePreviewImage = (params) => {
    this.setState({
      image: params.value,
    });
  };

  set_image = (value) => {
    this.setState({
      image: value,
    });
  };

  render() {
    const modalImagePreview = this.state.modalImagePreview && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalImagePreview}
        onClose={this.toggleImagePreview}
      >
        <div
          className="box-body"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={this.state.image}
            alt="modal"
            style={{
              maxWidth: "80vw",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>
    );

    const fileNameOnServer = this.state.image?.split("/")?.at(-1);
    const arrayName = fileNameOnServer?.split("_%25");
    const originalName =
      arrayName?.at(0)?.split("_")?.at(1) === "img"
        ? arrayName?.at(1)?.replaceAll("%20", " ")
        : "";
    return (
      <section
        style={{
          height: "100%",
          width: "100%",
          padding: 5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <UploadImage
          imageUrl={this.state.image}
          geo_layer_id={this.props.layer_id}
          feature_key={this.props.params.row.key}
          properties_key={this.props.params.field}
          handlePreviewImage={this.set_image}
          params={
            this.props.params?.row?.nested_attributes
              ? this.props.params
              : undefined
          }
        />
        {this.state?.image ? (
          <div className="flex gap_5 h_2">
            <div title={originalName} className="w_150 center_perfect">
              <p className="truncate">{originalName}</p>
            </div>
            <div
              onClick={(onClickParams) => {
                if (this.props.params?.value) {
                  this.handlePreviewImage(this.props.params);
                  this.toggleImagePreview();
                }
              }}
              style={{ cursor: "pointer", display: "inline" }}
            >
              <img
                src={this.state?.image}
                style={{
                  width: 35,
                  height: 35,
                  objectFit: "cover",
                }}
                alt=""
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              fontSize: "10px",
              width: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {dict["No image"][this.props.language]}
          </div>
        )}
        {modalImagePreview}
      </section>
    );
  }
}
