/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { setProject } from "../../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
class ProjectList extends Component {
  state = {};
  componentDidMount() {}
  setGeoProject = (geo_project) => {
    this.props.setProject(geo_project);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { geo_project_list_public } = this.props;
    const { loading, itemLoading } = this.props.project;
    let itemContent;
    if (
      loading &&
      itemLoading === "get_list_public_by_domain" &&
      geo_project_list_public.length === 0
    ) {
      itemContent = <div>{dict["Loading public project"][language]} ...</div>;
    } else {
      itemContent = (
        <main
          style={{
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          {geo_project_list_public.map((geo_project) => {
            const { _id, link, name, description, user } = geo_project;
            return (
              <section className="container_blog_card" id="white" key={_id}>
                <main className="container_square">
                  <section className="container_first_half_blog">
                    <h1
                      className="text_bold margin_bottom"
                      style={{
                        overflow: "hidden",
                        maxHeight: "4.8em",
                        lineHeight: "1.2em",
                      }}
                    >
                      {name}
                    </h1>
                    <p
                      className="text_small"
                      style={{
                        overflow: "hidden",
                        maxHeight: "7.2em",
                        lineHeight: "1.2em",
                      }}
                    >
                      {description}
                    </p>
                  </section>
                  <section className="container_card_footer">
                    <div className="text_inferior">{user.full_name}</div>
                    <Link
                      to={`/analyzer/${link}`}
                      onClick={this.setGeoProject.bind(this, geo_project)}
                      className="button_very_small"
                      id="edit"
                      style={{ marginRight: "5px" }}
                    >
                      {dict["3D Map"][language]}
                    </Link>
                    <Link
                      to={`/view/${link}`}
                      onClick={this.setGeoProject.bind(this, geo_project)}
                      className="button_very_small"
                      id="edit"
                    >
                      {dict["Data Insight"][language]}
                    </Link>
                  </section>
                </main>
              </section>
            );
          })}
        </main>
      );
    }
    return (
      <main>
        <div style={{ marginBottom: "30px", textAlign: "center" }}>
          <h1 className="text_big margin_bottom">
            {dict?.["Public Project"]?.[language]}
          </h1>
          {itemContent}
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  setProject,
})(ProjectList);
