/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

/*PERSONAL COMPONENT*/
import { Draggable } from "../../Components/reuseable";
import BI_SIDEBAR_PARENT from "../../Components/bi_sidebar/BI_SIDEBAR_PARENT";
import BI_MAP_PARENT from "../../Components/bi_map/BI_MAP_PARENT";
import BI_CARD_CONTAINER_PARENT from "../../Components/bi_card/BI_CARD_CONTAINER_PARENT";
import PARENT_SINI from "../../Components/sini_v2/PARENT_SINI";

/*REDUX FUNCTION*/
import {
  get_bi_object_by_link,
  reset_bi,
  set_value_bi,
} from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import css_string_init from "../../Components/bi_sidebar/css_string_init";

/*NON IMPORT*/
const padding_sini = 70;

class BI_EDIT_PAGE extends Component {
  componentDidMount() {
    if (this?.props?.match?.params?.link) {
      const query = { link: this.props.match.params.link };
      this.props.get_bi_object_by_link(query);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.bi.full_card !== prevProps.bi.full_card) {
      if (this.props.bi.full_card) {
        document.querySelectorAll(".bi_sidebar_left").forEach((element) => {
          element.style.display = "none";
        });
        document.querySelectorAll(".bi_map").forEach((element) => {
          element.style.display = "none";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.height = "calc(100vh - 20px)";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.width = "calc(100vw - 20px)";
        });
      } else {
        document.querySelectorAll(".bi_sidebar_left").forEach((element) => {
          element.style.display = "";
        });
        document.querySelectorAll(".bi_map").forEach((element) => {
          element.style.display = "";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.height = "";
        });
        document.querySelectorAll(".bi_card_container").forEach((element) => {
          element.style.width = "";
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.reset_bi();
  }

  toggle_sini = () => {
    this.props.set_value_bi({
      key: "modal_sini",
      value: !this?.props?.bi?.modal_sini,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { bi_object, modal_sini } = this.props.bi;
    const link = this?.props?.match?.params?.link;

    //content
    let content = null;

    if (bi_object?._id) {
      const css_string = bi_object?.css_string || css_string_init();
      content = (
        <>
          <main className="bi_parent">
            <section className="bi_sidebar_left">
              <BI_SIDEBAR_PARENT link={link} />
            </section>
            <section className="bi_content">
              <div className="bi_map">
                <BI_MAP_PARENT link={link} />
              </div>
              <div className="bi_card_container">
                <BI_CARD_CONTAINER_PARENT link={link} />
              </div>
            </section>
          </main>
          <style>{css_string}</style>
        </>
      );
    } else {
      content = <div>Loading</div>;
    }

    return (
      <main>
        {content}
        {modal_sini && (
          <Draggable
            title="SINI AI"
            toggle_close={this.toggle_sini}
            style={{
              background: "white",
              width: 370,
              zIndex: 2,
              paddingBottom: 20,
            }}
            initialPosition={{
              x: window.innerWidth - 370 - padding_sini,
              y: 25,
            }}
          >
            <main
              style={{
                overflowY: "auto",
                height: `calc( 80vh - ${padding_sini}px )`,
                padding: "10px",
              }}
            >
              <PARENT_SINI />
            </main>
          </Draggable>
        )}
        <style>{`
    body{
      padding-top: 0px;
      overflow-y: hidden;
    }
    #navbarDefault{ visibility: hidden;}
    .top_nav{ visibility: hidden;}
    .sidebar_home{ visibility: hidden;}
    .nav_bottom{ visibility: hidden;}
 `}</style>
        <Helmet>
          <title>{bi_object?.name || "BI EDITOR"}</title>
          <meta name="description" content={bi_object?.name || "BI EDITOR"} />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  get_bi_object_by_link,
  reset_bi,
  set_value_bi,
})(BI_EDIT_PAGE);
