const list_id_that_contain_file_url = [
  "cilicis_surat_permohonan_pdf",
  "cilicis_gambar_lokasi_pdf",
  "cilicis_gambar_desain_bangunan_pdf",
  "cilicis_spesifikasi_teknis_bangunan_pdf",
  "cilicis_proposal_teknis_penjelasan_penggunaan_air_pdf",
  "cilicis_manual_operasi_dan_pemeliharaan_pdf",
  "cilicis_sertifikat_tanah_pdf",
  "cilicis_izin lingkungan dan persetujuan amdal_pdf",
  "cilicis_fotocopy_ktp_kk_ketua_kelompok_pdf",
  "cilicis_fotocopy_kk_akta_bukti_pendiri_kelompok_pdf",
];

export default list_id_that_contain_file_url;
