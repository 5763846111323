/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { edit_folder_project } from "../../App/actions/folderProjectAction";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class EDIT_FOLDER_PROJECT extends Component {
  state = {
    name: "",
  };

  componentDidMount() {
    const folder = { ...this.props.folder };
    this.setState({ ...folder });
  }

  on_change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  on_submit = (e) => {
    e.preventDefault();
    const { _id } = this.state;
    const { folder_object_selected_list } = this.props.project;
    const key =
      folder_object_selected_list.length > 0
        ? "folder_project_child_list"
        : "folder_project_list";
    const body = {
      folder_id: _id,
      key,
      ...this.state,
    };
    this.props.edit_folder_project(body);
    this.props.toggle_edit_folder();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { name } = this.state;

    //global props

    //content
    return (
      <main>
        <form onSubmit={this.on_submit}>
          <h2 className="margin_bottom">{dict?.["Edit folder"]?.[language]}</h2>
          <p>{dict?.["Enter a folder name"]?.[language]}</p>
          <input
            name="name"
            value={name}
            onChange={this.on_change}
            type="text"
            className="margin_bottom"
          />
          <br />
          <button type="submit" className="button background_blue margin_top">
            Submit
          </button>
        </form>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, { edit_folder_project })(
  EDIT_FOLDER_PROJECT
);
