//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

//Personal Component
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";

//Redux function
import { actionPayment } from "../../App/actions/paymentActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";

class PaymentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {};
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { user } = this.props.auth;
    const user_name = user.name;
    const file = this.fileInput.current.files[0];
    const date_now = moment(Date.now()).format("X");
    const file_name = date_now;
    const objParams = {
      file: file,
      file_name: file_name,
      dirName: "foto_resi/" + user_name,
    };
    const { payment } = this.props.payment;
    const body = {
      payment_id: payment._id,
      status: "user_purchase",
    };
    let finalObject;
    finalObject = {
      objParams,
      body,
    };
    this.props.actionPayment(finalObject);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { loadingProcess } = this.props.payment;
    let buttonContent;
    let textContent;
    if (loadingProcess) {
      buttonContent = (
        <div className="upload_container vertical_center">
          <div
            className="vertical_horizontal_center_child"
            style={{ fontSize: "20px" }}
          >
            <SpinnerSimpleBlue
              width={40}
              unik="loading_item_list"
              marginTop="0px"
              center={true}
              border={0.1}
            />
          </div>
        </div>
      );
      textContent = (
        <div style={{ marginTop: "10px" }}>{dict["Uploading"][language]}</div>
      );
    } else {
      buttonContent = (
        <label className="fileContainer">
          <div className="upload_container vertical_center">
            <div
              className="vertical_horizontal_center_child"
              style={{ fontSize: "20px" }}
            >
              +
              <input
                type="file"
                accept="image/*"
                ref={this.fileInput}
                onChange={this.handleSubmit}
              />
            </div>
          </div>
        </label>
      );
      textContent = (
        <div style={{ marginTop: "10px" }}>
          {dict["Click to upload a picture of proof of payment"][language]}
        </div>
      );
    }
    const content = (
      <div>
        {buttonContent}
        {textContent}
      </div>
    );
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});

export default connect(mapStateToProps, { actionPayment })(PaymentConfirmation);
