import React, { Component } from "react";
class EditIcon extends Component {
  render() {
    return (
      <svg
        id="icon_edit"
        width={`${this.props.width || 20}px`}
        height={`${this.props.height || 20}px`}
        viewBox="0 0 2.3166545 2.3166547"
        version="1.1"
      >
        <defs id="defs2" />
        <g id="layer1" transform="translate(-299.80451,-174.28785)">
          <path
            id="edit_path"
            style={{
              strokeWidth: 1.00157,
              strokeLinecap: "round",
              strokeLinejoin: "round",
            }}
            d="m 33.119141,2.2226562 a 4.3780562,4.3780562 0 0 0 -4.376953,4.3769532 4.3780562,4.3780562 0 0 0 4.376953,4.3789066 4.3780562,4.3780562 0 0 0 4.378906,-4.3789066 4.3780562,4.3780562 0 0 0 -4.378906,-4.3769532 z m 1.376953,1.8769532 a 0.19900897,0.19900897 0 0 1 0.140625,0.058594 l 1.048828,1.0527344 a 0.19900897,0.19900897 0 0 1 0,0.2832031 l -0.433594,0.4335938 a 0.19900897,0.19900897 0 0 1 -0.28125,0 L 33.917969,4.8769531 a 0.19900897,0.19900897 0 0 1 0,-0.28125 l 0.4375,-0.4375 a 0.19900897,0.19900897 0 0 1 0.140625,-0.058594 z m -0.976563,0.9765625 a 0.19900897,0.19900897 0 0 1 0.140625,0.058594 l 1.050782,1.0507813 a 0.19900897,0.19900897 0 0 1 0,0.28125 l -2.154297,2.15625 a 0.19900897,0.19900897 0 0 1 -0.111329,0.054687 l -1.25,0.1992187 A 0.19900897,0.19900897 0 0 1 30.966797,8.6503906 l 0.199219,-1.25 a 0.19900897,0.19900897 0 0 1 0.05664,-0.109375 l 2.15625,-2.15625 a 0.19900897,0.19900897 0 0 1 0.140625,-0.058594 z"
            transform="matrix(0.26458333,0,0,0.26458333,292.19981,173.69977)"
          />
          <g
            id="g869"
            style={{ opacity: 1, strokeWidth: 2.51664 }}
            transform="matrix(0.39735388,0,0,0.39735388,178.62674,105.23104)"
          />
          <g
            id="g941"
            transform="matrix(0.26458333,0,0,0.26458333,299.63119,174.13775)"
            style={{ opacity: 1, fill: "#ff2a2a" }}
          />
          <g id="g956" transform="translate(-0.01211312,-0.00814589)" />
        </g>
      </svg>
    );
  }
}

export default EditIcon;
