/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ChartTemplate from "../viewer_chart/ChartTemplate";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class TimeseriesArrayItem extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { layer_id, geo_layer_list } = this.props.layer;

    //content
    const geo_layer =
      geo_layer_list.find((e) => e?.geo_layer?._id === layer_id)?.geo_layer ||
      {};

    const timeseries_komponen_array =
      geo_layer?.timeseries_komponen_array || [];

    let content;

    if (timeseries_komponen_array?.length > 0) {
      content = (
        <main>
          {timeseries_komponen_array.map((item, idx) => {
            return (
              <div key={idx} className="container_light margin_bottom">
                <h2 className="badge_pill" id="orange">
                  {item.name}
                </h2>
                <br />
                <div style={{ overflowX: "scroll" }}>
                  <ChartTemplate
                    type="bar"
                    width={600}
                    height={200}
                    style={{ width: "600px" }}
                    options={{
                      hoverBackgroundColor: "#f38026",
                      animation: false,
                      maintainAspectRatio: false,
                      responsive: false,
                      legend: {
                        display: false,
                      },
                      indexAxis: "x",
                      title: {
                        display: false,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={item.chart_js_data}
                  />
                </div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>y_max</td>
                      <td>
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(item?.chart_js_data?.y_max)}
                      </td>
                    </tr>
                    <tr>
                      <td>x_max</td>
                      <td>{item?.chart_js_data?.x_max}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </main>
      );
    }

    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {})(TimeseriesArrayItem);
