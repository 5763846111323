import React, { Component } from 'react'
import { Alert, Snackbar } from '@mui/material';
import { connect } from 'react-redux';


import dict from "../../Data/dict.json";
import { snackbar } from '../../App/actions/snackbarActions';

class SNACKBAR_PARENT extends Component {
  render() {
    // Local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    // props
    const snackbar_reducer = this.props.snackbar_props;

    return (
      <Snackbar
        open={snackbar_reducer?.is_open}
        autoHideDuration={3000}
        onClose={this.props.snackbar}
      >
        <Alert
          onClose={this.props.snackbar}
          severity={snackbar_reducer?.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {dict?.[snackbar_reducer?.message]?.[language] || snackbar_reducer?.message}
        </Alert>
      </Snackbar>
    )
  }
}

const mapStateToProps = (state) => ({
  snackbar_props: state.snackbar
});

export default connect(mapStateToProps, {
  snackbar
})(SNACKBAR_PARENT);