import React, { Component } from "react";
import dict from "../../Data/dict.json";

export default class Selection extends Component {
  render() {
    const valueOptions = this.props.column.array_templates.map(
      (option) => option.name
    );
    const { language } = this.props;
    return (
      <select
        className="dropdown_in_table"
        value={this.props.params.value}
        onChange={(e) => {
          let newParams = this.props.params;
          newParams.value = e.target.value;
          newParams.row.value = e.target.value;
          this.props.handleEditCellChange(
            this.props.editProperties,
            this.props.layer_id,
            newParams
          );
        }}
      >
        <option value="">{dict["Select"][language]}</option>
        {valueOptions?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
        {!valueOptions?.includes(this.props.params.value) &&
          this.props.params.value && <option>{this.props.params.value}</option>}
      </select>
    );
  }
}
