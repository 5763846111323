//Library modul
import React, { Component } from "react";
import { Reorder, useDragControls } from "framer-motion";

//Personal Component
import UploadPictureField from "../../Components/form/UploadPictureField";
import InputDummy from "./InputDummy";
import AddButtonBetweenList from "../../Components/form/AddButtonBetweenList";

//Redux function

//Picture Asset
import icon_reorder from "../../Assets/svg/icon_reorder.svg";
import icon_reorder_white from "../../Assets/svg/icon_reorder_white.svg";
import icon_delete_2 from "../../Assets/svg/icon_delete_2.svg";
import icon_delete_image from "../../Assets/svg/icon_delete_image.svg";
import icon_edit_text from "../../Assets/svg/icon_edit_text.svg";
import icon_hamburger from "../../Assets/svg/icon_hamburger.svg";

//General Function
import { data_types } from "../../App/validation/data_types";
import dict from "../../Data/dict.json";

class FieldCard extends Component {
  render() {
    const {
      idx,
      field_element,
      fields,
      field,
      from_uuid,
      modal_reorder,
      language,
      // style_length,
      setFromUuid,
      is_hold,
    } = this.props;

    const { type, uuid, name, description = "", formula } = field_element;

    const type_current = data_types(language).find(
      (type_element) => type_element.value === type
    );

    const icon_type = type_current?.icon;
    const name_type = type_current?.name;

    const rumus = formula.map((f, idx) => {
      if (f.type === "operator") {
        return (
          <p key={idx} className="inline" style={{ color: "red" }}>
            {f.content}
          </p>
        );
      } else
        return (
          <p key={idx} className="inline" style={{ color: "blue" }}>
            {f.content}
          </p>
        );
    });

    return (
      <Reorder.Item
        id={uuid}
        value={field_element}
        style={{
          listStyleType: "none",
        }}
        dragListener={false}
        dragControls={this.props.dragControls}
      >
        <AddButtonBetweenList
          idx={idx}
          field_element={field_element}
          toggle_push_in_the_middle={this.props.toggle_push_in_the_middle}
        />
        {field_element.picture_url && (
          <section
            className="container_icon"
            style={{
              backgroundColor: "#ffffff",
              marginBottom: "10px",
            }}
          >
            <img
              src={field_element.picture_url}
              alt="pertanyaan"
              style={{ width: "100%", height: "auto" }}
            />
          </section>
        )}

        {/* {type !== "section" ? ( */}
        <main
          className="two_container_small_right"
          style={{
            marginBottom: "10px",
          }}
        >
          <section
            className="container_flat"
            style={{
              display: "flex",
              gap: "30px",
              backgroundColor:
                field_element.uuid === field?.uuid ||
                (uuid === from_uuid && modal_reorder)
                  ? "#c0e4ff"
                  : "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "2px solid #dedede",
              }}
            >
              <div
                style={{
                  cursor: is_hold ? "grabbing" : "grab",
                  marginRight: "10px",
                }}
                onPointerDown={(event) => {
                  setFromUuid(uuid);
                  this.props.toggle_is_hold(true);
                  this.props.dragControls.start(event);
                }}
              >
                <img
                  alt="draggable"
                  draggable={false}
                  src={icon_hamburger}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>

            <div>
              {modal_reorder && uuid !== from_uuid ? (
                <button
                  className="button_standard_tight"
                  onClick={() => {
                    this.props.submit_reorder(uuid);
                  }}
                >
                  <img
                    src={icon_reorder_white}
                    alt="Reorder"
                    width="20px"
                    height="auto"
                  />{" "}
                  {dict["Switch to this"][language]}
                </button>
              ) : (
                <button>
                  <img
                    onClick={() => {
                      this.props.toggle_reorder(uuid);
                    }}
                    style={{
                      cursor: "pointer",
                      verticalAlign: "top",
                      marginRight: "5px",
                    }}
                    src={icon_reorder}
                    alt="Reorder"
                    width="20px"
                    height="auto"
                  />
                  <div
                    className="button_standard_tight"
                    id="grey_light"
                    onClick={() => this.props.toggle_term(field_element)}
                    style={{ verticalAlign: "top" }}
                  >
                    {dict["Term"][language]}
                  </div>
                </button>
              )}

              {/* Nomor pertanyaan */}
              <p>
                {dict["Question"][language]} {idx + 1}
              </p>
              {/* Nama pertanyaan */}
              <p className="text_bold">
                {name}{" "}
                <img
                  onClick={() => this.props.toggle_sidebar(field_element)}
                  style={{ cursor: "pointer" }}
                  src={icon_edit_text}
                  alt={type}
                  width="15px"
                  height="auto"
                />
              </p>

              {/* nampilin rumus */}
              {formula?.length > 0 &&
                field_element?.type === "math_operators" && (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      width: "100%",
                    }}
                  >
                    {rumus}
                  </div>
                )}

              {/* Deskripsi pertanyaan */}
              <p
                className="text_medium"
                style={{
                  fontSize: "16px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {description}
              </p>
              {/* dibuat {...field_element} supaya terupdate */}
              <InputDummy
                field_element={{ ...field_element }}
                fields={[...fields]}
                toggle_delete_field={this.props.toggle_delete_field}
                toggle_sidebar={this.props.toggle_sidebar}
                field_state={{ ...field }}
              />
            </div>
          </section>

          {/* ICON SAMPING KANAN */}
          <section>
            <button title={name_type} style={{ marginBottom: "10px" }}>
              <div
                onClick={() => this.props.toggle_sidebar(field_element)}
                className="container_icon"
                style={{
                  backgroundColor:
                    field_element.uuid === field?.uuid ? "#c0e4ff" : "#fff",
                  width: "20px",
                }}
              >
                <img
                  src={icon_type}
                  alt={type}
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              {/* <p className="text_inferior">{name_type}</p> */}
            </button>
            <button
              title={dict["Delete"][language]}
              style={{ marginBottom: "10px" }}
            >
              <div
                onClick={() => this.props.toggle_delete_field(field_element)}
                className="container_icon"
                style={{
                  cursor: "pointer",
                  width: "20px",
                  backgroundColor: "#fff",
                }}
              >
                <img
                  src={icon_delete_2}
                  alt={type}
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              {/* <p className="text_inferior">{dict["Delete"][language]}</p> */}
            </button>
            {/* GAMBAR PERTANYAAN */}
            {!field_element.picture_url ? (
              <UploadPictureField field_current={field_element} />
            ) : (
              <button
                title={dict["Delete Picture"][language]}
                style={{ marginBottom: "10px" }}
              >
                <div
                  onClick={() =>
                    this.props.submit_delete_picture_field(field_element)
                  }
                  className="container_icon"
                  style={{
                    cursor: "pointer",
                    width: "20px",
                    backgroundColor: "#fff",
                  }}
                >
                  <img
                    src={icon_delete_image}
                    alt={type}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
                {/* <p className="text_inferior">
                    {dict["Delete Picture"][language]}
                  </p> */}
              </button>
            )}
          </section>
        </main>
        {/* // ) : (
        //   <main
        //     onMouseDown={() => {
        //       this.toggleIsHold(true);
        //       setFromUuid(uuid);
        //     }}
        //     onMouseUp={() => {
        //       this.toggleIsHold(false);
        //     }}
        //   >
        //     <div
        //       style={{
        //         display: "flex",
        //         paddingRight: "10px",
        //         paddingLeft: "10px",
        //       }}
        //     >
        //       <div
        //         style={{
        //           display: "flex",
        //           justifyContent: "center",
        //           alignItems: "center",
        //         }}
        //       >
        //         <div
        //           style={{
        //             cursor: is_hold ? "grabbing" : "grab",
        //             marginRight: "10px",
        //           }}
        //           onPointerDown={(event) =>
        //             this.props.dragControls.start(event)
        //           }
        //           // dragControls={this.props.dragControls}
        //           onMouseDown={() => {
        //             this.toggleIsHold(true);
        //             setFromUuid(uuid);
        //           }}
        //           onMouseUp={() => {
        //             this.toggleIsHold(false);
        //           }}
        //         >
        //           <img
        //             alt="draggable"
        //             draggable={false}
        //             src={icon_hamburger}
        //             style={{
        //               width: 20,
        //               height: 20,
        //             }}
        //           />
        //         </div>
        //       </div>

        //       <div
        //         style={{
        //           display: "flex",
        //           justifyContent: "space-between",
        //           width: "100%",
        //         }}
        //       >
        //         <div
        //           style={{
        //             display: "flex",
        //             height: "100%",
        //             alignItems: "center",
        //           }}
        //         >
        //           <p className="text_bold">
        //             {name}{" "}
        //             <img
        //               onClick={() => this.props.toggle_sidebar(field_element)}
        //               style={{ cursor: "pointer" }}
        //               src={icon_edit_text}
        //               alt={type}
        //               width="15px"
        //               height="auto"
        //             />
        //           </p>
        //         </div>

        //         <div
        //           style={{
        //             display: "flex",
        //             justifyContent: "flex-end",
        //             alignItems: "center",
        //             height: "100%",
        //           }}
        //         >
        //           <div>
        //             <button
        //               style={{ marginBottom: "10px", marginRight: "10px" }}
        //             >
        //               <div
        //                 onClick={() => this.props.toggle_sidebar(field_element)}
        //                 className="container_icon"
        //                 style={{
        //                   backgroundColor:
        //                     field_element.uuid === field?.uuid
        //                       ? "#c0e4ff"
        //                       : "#fff",
        //                   width: "20px",
        //                 }}
        //               >
        //                 <img
        //                   src={icon_type}
        //                   alt={type}
        //                   style={{ width: "100%", height: "auto" }}
        //                 />
        //               </div>
        //               <p className="text_inferior">{name_type}</p>
        //             </button>

        //             <button style={{ marginBottom: "10px" }}>
        //               <div
        //                 onClick={() =>
        //                   this.props.toggle_delete_field(field_element)
        //                 }
        //                 className="container_icon"
        //                 style={{
        //                   cursor: "pointer",
        //                   width: "20px",
        //                   backgroundColor: "#fff",
        //                 }}
        //               >
        //                 <img
        //                   src={icon_delete_2}
        //                   alt={type}
        //                   style={{ width: "100%", height: "auto" }}
        //                 />
        //               </div>
        //               <p className="text_inferior">
        //                 {dict["Delete"][language]}
        //               </p>
        //             </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>

        //     <hr
        //       style={{
        //         border: "1px solid",
        //       }}
        //     />
        //   </main>
        // )} */}
      </Reorder.Item>
    );
  }
}

// export default FieldCard;

function FieldCardParent(props) {
  // const y = useMotionValue(0);
  // const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  return <FieldCard {...props} dragControls={dragControls} />;
}

export default FieldCardParent;
