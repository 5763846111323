import React, { Component } from "react";
import Modal from "../common_modal/Modal";

export default class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEditLongText: false,
      value: props.params.value || "",
    };
  }

  toggleModalEditLongText = () => {
    this.setState({ modalEditLongText: !this.state.modalEditLongText });
  };

  handleOnChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSave = () => {
    let newParams = { ...this.props.params };
    newParams["value"] = this.state.value;
    newParams.row.value = this.state.value;
    this.props.handleEditCellChange(
      this.props.editProperties,
      this.props.layer_id,
      newParams
    );
  };

  render() {
    const moduleEditLongText = this.state.modalEditLongText && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalEditLongText}
        onClose={(event) => {
          this.handleSave();
          this.toggleModalEditLongText(event);
        }}
      >
        <textarea
          value={this.state.value}
          onChange={this.handleOnChange}
          onBlur={this.handleSave}
          style={{ height: "500px", padding: "20px", border: "none" }}
        />
      </Modal>
    );
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => this.toggleModalEditLongText()}
            style={{ height: 25 }}
            className="button_small"
          >
            Edit
          </button>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            display: "inline",
            paddingRight: "10px",
            marginLeft: "10px",
          }}
          className="hide-scrollbar"
        >
          {this.state.value}
        </div>
        {moduleEditLongText}
      </div>
    );
  }
}
