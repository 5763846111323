/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class BI_FEATURES_LIST_CARD extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { fields, features, layer_sidebar_config } = this.props;

    //content
    let {
      is_use_image,
      array_image_field_to_show,
      field_show_mode,
      n_field_to_show,
      array_text_to_show,
    } = layer_sidebar_config;
    let fields_sliced = [];
    if (field_show_mode === "only_first_n_field") {
      n_field_to_show = !isNaN(n_field_to_show) ? n_field_to_show : 3;
      fields_sliced = fields.filter(
        (item) => item.type !== "image" && !item?.isStyle
      );
      fields_sliced = fields_sliced.slice(0, n_field_to_show);
    } else {
      fields_sliced = fields.filter((item) =>
        array_text_to_show.includes(item.key)
      );
    }
    const field_key_image_first = array_image_field_to_show?.[0];
    return (
      <main>
        {features.map((feature, idx) => {
          return (
            <div
              key={idx}
              className="container_light background_white outline_grey margin_bottom"
            >
              <table className="full_width" style={{ padding: "0px" }}>
                <tbody style={{ padding: "0px" }}>
                  <tr key={idx} style={{ padding: "0px" }}>
                    {is_use_image && (
                      <td style={{ verticalAlign: "top", padding: "0px" }}>
                        <div
                          style={{
                            width: "140px",
                            height: "110px",
                            borderRadius: "7px",
                            marginRight: "5px",
                            backgroundImage: `url(${feature?.properties?.[field_key_image_first]})`,
                            backgroundSize: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                    )}
                    <td
                      style={{
                        verticalAlign: "top",
                        padding: "0px",
                      }}
                      className="full_width"
                    >
                      <table className="full_width">
                        <tbody>
                          {fields_sliced.map((field, index) => {
                            let value = feature?.properties?.[field.key];
                            if (
                              value === undefined ||
                              value === "undefined" ||
                              value === ""
                            ) {
                              value = "-";
                            } else if (
                              ["number", "math_operators", "currency"].includes(
                                field.type
                              ) &&
                              !isNaN(value)
                            ) {
                              value = new Intl.NumberFormat("id-ID", {
                                style: "decimal",
                              }).format(value);
                            }
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    verticalAlign: "top",
                                    padding: "0px",
                                  }}
                                >
                                  <p className="text_small text_grey">
                                    {field.name}
                                  </p>
                                  <p className="text_medium">
                                    <b>{value}</b>
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(BI_FEATURES_LIST_CARD);
