/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { get_insight } from "../../App/actions/siniActions";

/*PICTURE ASSET*/
import checklist from "../../Assets/svg/checklist.svg";
import checklist_grey from "../../Assets/svg/checklist_grey.svg";

/*GENERAL FUNCTION & DATA*/
import sort_array from "../../App/validation/sort_array";
import hirarki_tipe_list from "../../Data/hirarki_tipe_list.json";
import tipe_1_string_array from "../../Data/tipe_1_string_array.json";
import tipe_2_string_array from "../../Data/tipe_2_string_array.json";
import uuid from "../../App/validation/uuid";

/*NON IMPORT*/

class InputTipe extends Component {
  state = {
    modal_tipe_2: false,
    tipe_1_opened: "",
  };

  on_get_insight = () => {
    this.props.toggle_choose_poi();
    const { poi_tipe_2_selected, poi_all_selected } = this.props.properties;
    const { provinsi, kota, kecamatan, kelurahan } = this.props.map;

    let poi_tipe_2_selected_string = "";
    if (poi_tipe_2_selected.length > 0) {
      poi_tipe_2_selected_string = poi_tipe_2_selected.join(",");
    }

    const params = {
      request_id: uuid(),

      provinsi,
      kota,
      kecamatan,
      kelurahan,
      poi_tipe_2_selected_string,

      poi_all_selected,
    };

    this.props.get_insight(params);
  };

  toggle_all_tipe = () => {
    const { poi_all_selected } = this.props.properties;
    if (poi_all_selected) {
      this.props.set_value_properties({
        key: "poi_tipe_1_selected",
        value: [],
      });
      this.props.set_value_properties({
        key: "poi_tipe_2_selected",
        value: [],
      });
      this.props.set_value_properties({
        key: "poi_all_selected",
        value: false,
      });
    } else {
      this.props.set_value_properties({
        key: "poi_tipe_1_selected",
        value: tipe_1_string_array,
      });
      this.props.set_value_properties({
        key: "poi_tipe_2_selected",
        value: tipe_2_string_array,
      });
      this.props.set_value_properties({
        key: "poi_all_selected",
        value: true,
      });
    }
  };

  toggle_tipe_2 = (tipe_1_opened) => {
    this.setState({ modal_tipe_2: !this.state.modal_tipe_2 });
    if (tipe_1_opened) this.setState({ tipe_1_opened });
    if (tipe_1_opened !== this.state.tipe_1_opened)
      this.setState({ modal_tipe_2: true });
  };

  on_toggle_tipe_2 = (tipe_2) => {
    this.props.set_value_properties({
      key: "poi_all_selected",
      value: false,
    });
    let { poi_tipe_2_selected } = this.props.properties;
    if (poi_tipe_2_selected.includes(tipe_2)) {
      poi_tipe_2_selected = poi_tipe_2_selected.filter(
        (item) => item !== tipe_2
      );
    } else {
      poi_tipe_2_selected.push(tipe_2);
    }
    this.props.set_value_properties({
      key: "poi_tipe_2_selected",
      value: poi_tipe_2_selected,
    });
  };

  on_toggle_tipe_1 = (tipe_1) => {
    this.props.set_value_properties({
      key: "poi_all_selected",
      value: false,
    });
    let { poi_tipe_1_selected, poi_tipe_2_selected } = this.props.properties;
    const tipe_2_clicked = hirarki_tipe_list
      .find((item) => item.TIPE_1 === tipe_1)
      .tipe_2_list.map((tipe_2) => tipe_2.TIPE_2);
    if (poi_tipe_1_selected.includes(tipe_1)) {
      poi_tipe_1_selected = poi_tipe_1_selected.filter(
        (item) => item !== tipe_1
      );
      poi_tipe_2_selected = poi_tipe_2_selected.filter(
        (tipe_2) => !tipe_2_clicked.includes(tipe_2)
      );
    } else {
      poi_tipe_1_selected.push(tipe_1);
      tipe_2_clicked.forEach((tipe_2) => {
        if (!poi_tipe_2_selected.includes(tipe_2)) {
          poi_tipe_2_selected.push(tipe_2);
        }
      });
    }
    this.props.set_value_properties({
      key: "poi_tipe_1_selected",
      value: poi_tipe_1_selected,
    });
    this.props.set_value_properties({
      key: "poi_tipe_2_selected",
      value: poi_tipe_2_selected,
    });
  };

  render() {
    //local storage

    //local state
    const { modal_tipe_2, tipe_1_opened } = this.state;

    //global props
    const { poi_tipe_2_selected, poi_tipe_1_selected } = this.props.properties;

    //content
    return (
      <div>
        <h1 className="text_header margin_bottom">Pilih tema POI</h1>

        <button
          className="button background_black margin_bottom"
          onClick={this.toggle_all_tipe}
        >
          All
        </button>
        <br />
        <section className="text_left">
          {hirarki_tipe_list.map((tipe_1, idx) => {
            const logic_open = modal_tipe_2 && tipe_1_opened === tipe_1.TIPE_1;
            const logic_selected_tipe_1 = poi_tipe_1_selected.includes(
              tipe_1.TIPE_1
            );
            let tipe_2_content = null;
            if (logic_open) {
              tipe_2_content = (
                <main>
                  <table>
                    <tbody>
                      {sort_array(tipe_1.tipe_2_list, "TIPE_2", true).map(
                        (tipe_2, idx) => {
                          const logic_open_tipe_2 =
                            poi_tipe_2_selected.includes(tipe_2.TIPE_2);
                          return (
                            <tr key={idx}>
                              <td className="inline">
                                <img
                                  onClick={this.on_toggle_tipe_2.bind(
                                    this,
                                    tipe_2.TIPE_2
                                  )}
                                  className="cursor_pointer margin_right"
                                  width="17px"
                                  alt="POI TIPE"
                                  src={
                                    logic_open_tipe_2
                                      ? checklist
                                      : checklist_grey
                                  }
                                  data-mapid={`selectionDrop-${idx}`}
                                />
                              </td>
                              <td>
                                <p className="text_small">{tipe_2.TIPE_2}</p>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </main>
              );
            }
            return (
              <section
                key={idx}
                className="container_light margin_bottom margin_right text_left"
                style={{
                  width: "300px",
                  maxWidth: "100%",
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                <table className="full_width">
                  <tbody>
                    <tr>
                      <td style={{ width: "23px" }} className="vertical_top">
                        <button
                          className={`button_pill ${
                            logic_selected_tipe_1
                              ? "background_green"
                              : "background_white"
                          }`}
                          onClick={this.on_toggle_tipe_1.bind(
                            this,
                            tipe_1.TIPE_1
                          )}
                          data-mapid="clickTarget"
                        >
                          ✔
                        </button>
                      </td>
                      <td className="vertical_top">
                        <p className="text_medium">{tipe_1.TIPE_1}</p>
                      </td>
                      <td className="text_right vertical_top">
                        <button
                          className={`button_pill ${
                            logic_open ? "background_black" : "background_white"
                          }`}
                          onClick={this.toggle_tipe_2.bind(this, tipe_1.TIPE_1)}
                          data-mapid="clickDrop"
                        >
                          ▼
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {tipe_2_content}
              </section>
            );
          })}
        </section>

        <button
          className="button"
          onClick={this.on_get_insight}
          data-mapid="clickCari"
        >
          Cari data
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  get_insight,
})(InputTipe);
