import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import { getUserPaymentList } from "./paymentActions";
import {
  get_quota_access,
  get_quota_access_sini_ai,
  get_quota_ai_chat,
} from "./authActions";

const SERVER_URL = geoServerBaseUrl;

export const redeem_code = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(SERVER_URL + "/redeem/use", body, config);
    dispatch({
      type: "redeem_code",
      payload: body,
    });
    dispatch(getUserPaymentList());
    dispatch(get_quota_access());
    dispatch(get_quota_access_sini_ai());
    dispatch(get_quota_ai_chat());
    const response_payment = res?.data?.response_payment || {};
    const payment_type = response_payment?.payment_type;

    if (payment_type === "buy_coin") {
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "modal_sini_ai_trial",
          value: true,
        },
      });
    }

    return {
      status: res?.status,
      data: res.data,
    };
  } catch (e) {
    return {
      status: e.response?.status || 400,
      message: e.response?.data?.error || e,
    };
  }
};
