// library
import React, { Component } from "react";

/*GENERAL*/
import dict from "../../Data/dict.json";

export default class ModalLayerCustomApi extends Component {
  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    const {
      //   modal_layer_name,
      //   isOpen,
      //   onClose,
      //   modal_api_input,
      //   toggle_modal_api_input,
      handle_change,
      layer_name,
      description,
      handle_back,
      handle_save,
      folders,
      folder,
    } = this.props;

    return (
      <main className="flex flex_col gap_5">
        <input
          name="layer_name"
          value={layer_name}
          onChange={handle_change}
          type="text"
          placeholder={dict["Layer name"][language]}
        />
        <select name="folder" value={folder} onChange={handle_change}>
          <option key="outside-folders" value="">
            {dict["Outside folders"][language]}
          </option>
          {folders?.map((item) => (
            <option key={item?._id} value={item?._id}>
              {item?.name}
            </option>
          ))}
        </select>
        <textarea
          name="description"
          className="h_200"
          value={description}
          onChange={handle_change}
          type="text"
          placeholder={dict["Description"][language]}
        />
        <div className="flex justify_between">
          <button
            onClick={() => handle_back()}
            className="bg_blue text_white button_inactive padding_5 rounded_5 font_18"
          >
            Back
          </button>
          <button
            onClick={() => handle_save()}
            className="bg_blue text_white button_inactive padding_5 rounded_5 font_18"
          >
            Save
          </button>
        </div>
      </main>
    );
  }
}
