import is_empty from "./is-empty";

export const generate_array_templates = function (field, geojson) {
  /* 
    cek apakah array templates kosong? apabila kosong, code berjalan, selain itu langsung return aja.  
  */
  if (
    is_empty(field.array_templates) ||
    (field.array_templates.length === 1 && !field.array_templates?.[0]?.name)
  ) {
    // code
    // checklist
    if (field.type === "checklist" && !field.tag) {
      let array_templates = [];
      geojson.features.forEach((row) => {
        const checklist_data = row.properties[field.key]?.split("\\");
        if (checklist_data?.length > 0)
          array_templates = array_templates.concat(
            row.properties[field.key]?.split("\\")
          );
      });
      array_templates = [...new Set(array_templates)];
      field.tag = array_templates.length > 0 ? array_templates.join("\n") : "";
      field.array_templates = array_templates.map((value, idx) => {
        return { name: value, index: idx };
      });
    }
    // selection
    else if (
      (field.type === "selection" || field.type === "radio_button") &&
      !field.tag
    ) {
      let array_templates = [];
      geojson.features.forEach((row) => {
        const checklist_data = row.properties[field.key];
        if (checklist_data) {
          array_templates.push(row.properties[field.key]);
        }
      });
      array_templates = [...new Set(array_templates)];
      field.tag = array_templates.length > 0 ? array_templates.join("\n") : "";
      field.array_templates = array_templates.map((value, idx) => {
        return { name: value, index: idx };
      });
    }
  }
  return field;
};
