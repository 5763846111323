const format_date_short = function (timestamp) {
  const language = localStorage?.language || "ina";
  const date = new Date(timestamp);
  const months_ina = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MEI",
    "JUNI",
    "JULI",
    "AGT",
    "SEP",
    "OKT",
    "NOV",
    "DES",
  ];

  const months_eng = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];
  let months_array;
  if (language === "ina") {
    months_array = months_ina;
  } else {
    months_array = months_eng;
  }

  const tanggal = date.getDate();
  let month_final = months_array[date.getMonth()];
  let date_final = tanggal + " " + month_final + " " + date.getFullYear();
  return date_final;
};

export default format_date_short;
