/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { edit_label_config } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const array_style = [
  {
    name: "Style 1",
    key: "style_1",
  },
  {
    name: "Style 2",
    key: "style_2",
  },
];

class EDIT_LABEL extends Component {
  state = { label_config: {}, fields_text: [], fields_image: [] };

  componentDidMount() {
    const { geo_layer_list, layer_id } = this.props.layer;
    const geo_layer =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_id)
        ?.geo_layer || {};
    const label_config = geo_layer?.label_config || {};
    const fields = geo_layer?.fields || [];
    const fields_text = fields.filter((item) => item.type !== "image");
    const fields_image = fields.filter((item) => item.type === "image");
    this.setState({
      label_config: { ...label_config },
      fields_text: [...fields_text],
      fields_image: [...fields_image],
    });
  }

  on_close_modal_edit_label_config = () => {
    this.props.set_value_properties({
      key: "modal_edit_label_config",
      value: false,
    });
  };

  on_toggle = (item) => {
    let { label_config } = this.state;
    label_config[item] = !label_config[item];
    this.setState({ label_config });
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let { label_config } = this.state;
    label_config[name] = value;
    this.setState({ label_config });
  };

  on_toggle_array_item = (item, value) => {
    let { label_config } = this.state;
    let array = label_config[item] || [];
    if (array.includes(value)) {
      array = array.filter((element) => element !== value);
    } else {
      array.push(value);
    }
    label_config[item] = array;
    this.setState({ label_config });
  };

  on_save = () => {
    const { layer_id } = this.props.layer;
    let { label_config } = this.state;
    const is_use_label_text = label_config?.is_use_label_text || false;
    const is_use_label_image = label_config?.is_use_label_image || false;
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const style_mode = label_config?.style_mode || "style_1";
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const background_color_secondary =
      label_config?.background_color_secondary || "#ee6b1e";
    const outline_color_secondary =
      label_config?.outline_color_secondary || "#ffffff";
    const text_color_secondary =
      label_config?.text_color_secondary || "#ffffff";
    label_config = {
      ...label_config,
      is_use_label_text,
      is_use_label_image,
      label_text_field_key_array,
      label_image_field_key_array,
      style_mode,
      background_color_primary,
      outline_color_primary,
      text_color_primary,
      background_color_secondary,
      outline_color_secondary,
      text_color_secondary,
    };
    const body = {
      geo_layer_id: layer_id,
      label_config,
    };
    this.props.edit_label_config(body);
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { label_config, fields_text, fields_image } = this.state;

    //global props
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const is_use_label_text = label_config?.is_use_label_text || false;
    const is_use_label_image = label_config?.is_use_label_image || false;
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const style_mode = label_config?.style_mode || "style_1";
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const background_color_secondary =
      label_config?.background_color_secondary || "#ee6b1e";
    const outline_color_secondary =
      label_config?.outline_color_secondary || "#ffffff";
    const text_color_secondary =
      label_config?.text_color_secondary || "#ffffff";

    return (
      <main
        style={{ maxHeight: "calc( 100vh - 200px )", overflowY: "auto" }}
        className="container_light"
      >
        <section className="container_light background_white margin_bottom">
          <div className="two_container_list margin_bottom">
            <div
              className={
                is_use_label_text ? "checklist_active" : "checklist_not"
              }
              onClick={this.on_toggle.bind(this, "is_use_label_text")}
            />
            <p className="text_bold">{dict?.["Use text labels"]?.[language]}</p>
          </div>

          {is_use_label_text && (
            <section className="margin_bottom">
              <p className="text_medium">
                {dict?.["Select the column used as the label"]?.[language]}
              </p>
              <div
                style={{ maxHeight: "calc( 50vh - 200px )", overflowY: "auto" }}
              >
                <table>
                  <tbody>
                    {fields_text.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ width: "20px" }}>
                            <div
                              className={
                                label_text_field_key_array.includes(item?.key)
                                  ? "checklist_active"
                                  : "checklist_not"
                              }
                              onClick={this.on_toggle_array_item.bind(
                                this,
                                "label_text_field_key_array",
                                item?.key
                              )}
                            />
                          </td>
                          <td>{item?.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </section>
          )}
        </section>

        <section className="container_light background_white margin_bottom">
          <div className="two_container_list margin_bottom">
            <div
              className={
                is_use_label_image ? "checklist_active" : "checklist_not"
              }
              onClick={this.on_toggle.bind(this, "is_use_label_image")}
            />
            <p className="text_bold">
              {dict?.["Use image labels"]?.[language]}
            </p>
          </div>

          {is_use_label_image && (
            <section className="margin_bottom">
              <p className="text_medium">
                {dict?.["Select the image column"]?.[language]}
              </p>
              <div
                style={{ maxHeight: "calc( 50vh - 200px )", overflowY: "auto" }}
              >
                <table>
                  <tbody>
                    {fields_image.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ width: "20px" }}>
                            <div
                              className={
                                label_image_field_key_array.includes(item?.key)
                                  ? "checklist_active"
                                  : "checklist_not"
                              }
                              onClick={this.on_toggle_array_item.bind(
                                this,
                                "label_image_field_key_array",
                                item?.key
                              )}
                            />
                          </td>
                          <td>{item?.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </section>
          )}
        </section>

        <section className="margin_bottom container_light background_white">
          <p className="text_bold">
            {dict?.["Select visual mode"]?.[language]}
          </p>
          <select
            name="style_mode"
            value={style_mode}
            onChange={this.on_change}
          >
            {array_style.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <section className="container_light margin_bottom">
            <p className="text_medium">
              {dict?.["Main background color"]?.[language]}
            </p>
            <input
              type="color"
              name="background_color_primary"
              value={background_color_primary}
              onChange={this.on_change}
            />
            <p className="text_medium">
              {dict?.["Main outline color"]?.[language]}
            </p>
            <input
              type="color"
              name="outline_color_primary"
              value={outline_color_primary}
              onChange={this.on_change}
            />
            <p className="text_medium">
              {dict?.["Main text color"]?.[language]}
            </p>
            <input
              type="color"
              name="text_color_primary"
              value={text_color_primary}
              onChange={this.on_change}
            />
          </section>

          <section className="container_light">
            <p className="text_medium">
              {dict?.["Secondary background color"]?.[language]}
            </p>
            <input
              type="color"
              name="background_color_secondary"
              value={background_color_secondary}
              onChange={this.on_change}
            />
            <p className="text_medium">
              {dict?.["Secondary outline color"]?.[language]}
            </p>
            <input
              type="color"
              name="outline_color_secondary"
              value={outline_color_secondary}
              onChange={this.on_change}
            />
            <p className="text_medium">
              {dict?.["Secondary text color"]?.[language]}
            </p>
            <input
              type="color"
              name="text_color_secondary"
              value={text_color_secondary}
              onChange={this.on_change}
            />
          </section>
        </section>

        {loading_item_array.includes("edit_label_config") ? (
          <div className="button">{dict?.["Saving"]?.[language]}...</div>
        ) : (
          <button className="button" onClick={this.on_save}>
            {dict?.["Save"]?.[language]}
          </button>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { edit_label_config })(EDIT_LABEL);
