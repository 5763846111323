import uuid from "../validation/uuid";

const initialState = {
  loadingDetail: false,
  loadingList: false,
  loadingProcess: false,
  itemLoading: "",

  pictureUrlSelected: "",
  pictureIndex: 0,

  layer: {},
  project: {},

  blog: {},
  blog_list: [],
  blog_list_popular: [],
  blog_list_personal: [],
  blog_personal: {},

  layer_blog_list: [],

  cart: {},
  wait_purchase: {},
  import_status: "pending",
  geocode: {},
  blog_list_search: [],
  project_list_search: [],
  categories: [],
  data_premium_count: [],
  version_log: {},
};

export default function blogReducer(state = initialState, action) {
  switch (action.type) {
    case "get_version_log":
      return {
        ...state,
        version_log: action.payload,
      };
    case "get_data_premium_count":
      return {
        ...state,
        data_premium_count: action.payload,
      };
    case "get_categories":
      return {
        ...state,
        categories: action.payload,
      };
    case "search_blog":
      return {
        ...state,
        blog_list_search: action.payload.blogs,
        project_list_search: action.payload.projects,
      };
    //personal
    case "get_geocode":
      return {
        ...state,
        geocode: action.payload,
      };
    case "edit_location":
      return {
        ...state,
        blog_list_personal: edit_location(
          state.blog_list_personal,
          action.payload
        ),
      };
    case "IMPORT_LAYER":
      return {
        ...state,
        import_status: action.payload,
      };
    //1. Create Blog Empty
    case "CREATE_BLOG":
      return {
        ...state,
        blog_list_personal: [action.payload, ...state.blog_list_personal],
      };
    //3. Edit blog non block editor
    case "EDIT_BLOG_GENERAL":
      return {
        ...state,
        blog_personal: editBlogGeneral(state.blog_personal, action.payload),
      };
    //4. Edit blog block editor
    case "EDIT_BLOG_EDITOR":
      return {
        ...state,
        blog_personal: editBlogEditor(state.blog_personal, action.payload),
      };
    //4. Push layer to blog
    case "PUSH_LAYER_TO_BLOG":
      return {
        ...state,
        blog_personal: pushLayer(state.blog_personal, action.payload),
      };
    //5. Remove layer from blog
    case "REMOVE_LAYER_FROM_BLOG":
      return {
        ...state,
        blog_personal: removeLayer(state.blog_personal, action.payload),
      };
    //6. Push project to blog
    case "PUSH_PROJECT_TO_BLOG":
      return {
        ...state,
        blog_personal: pushProject(state.blog_personal, action.payload),
      };
    //7. Remove project from blog
    case "REMOVE_PROJECT_FROM_BLOG":
      return {
        ...state,
        blog_personal: removeProject(state.blog_personal, action.payload),
      };
    //6. Delete blog
    case "DELETE_BLOG":
      return {
        ...state,
        blog_list_personal: state.blog_list_personal.filter(
          (blog) => blog._id !== action.payload
        ),
        blog: {},
      };
    //7. Propose data exchange paid
    case "PROPOSE_PAID_DE":
      return {
        ...state,
        blog_personal: proposePaid(state.blog_personal, action.payload),
      };
    //8. Change price data exchange
    case "CHANGE_PRICE":
      return {
        ...state,
        blog_personal: changePrice(state.blog_personal, action.payload),
      };
    //9. Propose data exchange free
    case "PROPOSE_FREE":
      return {
        ...state,
        blog_personal: proposeFree(state.blog_personal, action.payload),
      };
    //10. Set blog public
    case "SET_PUBLIC":
      return {
        ...state,
        blog_personal: setPublic(state.blog_personal, action.payload),
      };
    //11. Set blog private
    case "SET_PRIVATE":
      return {
        ...state,
        blog_personal: setPrivate(state.blog_personal, action.payload),
      };
    //13. Action (accepted/rejected) request free import
    case "ACTION_REQUEST_FREE_IMPORT":
      return {
        ...state,
        blog_personal: actionRequestFreeImport(
          state.blog_personal,
          action.payload
        ),
      };

    /*GETY*/
    //1. Get blog detail by link & author
    case "GET_BLOG_DETAIL_PERSONAL":
      return {
        ...state,
        blog_personal: action.payload,
      };
    //2. Get blog detail by id layer form (publik)
    case "GET_BLOG_DETAIL":
      return {
        ...state,
        blog: action.payload,
      };
    //3. [NOPE] Get blog detail by id layer publication
    //4. Get blog list by author
    case "GET_BLOG_LIST_PERSONAL":
      return {
        ...state,
        blog_list_personal: action.payload,
      };
    //5. [NOPE] Get blog list publish (OLD)
    //6. [NOPE] Get blog list publish by domain (OLD)
    //7. [NOPE] Get blog detail public (OLD)
    //8. Get blog list public / accepted_paid / accepted_free (publik)

    case "GET_BLOG_LIST":
      return {
        ...state,
        blog_list: action.payload,
      };
    //8.1 Get blog list popular / accepted_paid / accepted_free (publik)
    case "GET_BLOG_POPULAR":
      return {
        ...state,
        blog_list_popular: action.payload,
      };
    //9. [SAME] Get blog detail public / accepted_paid / accepted_free
    //10. Get layer from project from blog
    case "GET_LAYER_BLOG":
      return {
        ...state,
        layer_blog_list: action.payload,
      };
    //11. Get cart data exchange
    case "GET_CART":
      return {
        ...state,
        cart: action.payload,
      };
    //12. Get wait_purchase data exchange
    case "GET_WAIT_PURCHASE":
      return {
        ...state,
        wait_purchase: action.payload,
      };
    /*NON API*/
    case "SET_BLOG":
      return {
        ...state,
        blog: action.payload,
      };
    case "SET_BLOG_PERSONAL":
      return {
        ...state,
        blog_personal: action.payload,
      };
    case "CLEAR_BLOG":
      return {
        ...state,
        blog: {},
      };
    case "CLEAR_BLOG_LIST":
      return {
        ...state,
        blog_list: [],
      };
    case "BLOG_LOADING_DETAIL":
      return {
        ...state,
        loadingDetail: true,
      };
    case "BLOG_LOADING_LIST":
      return {
        ...state,
        loadingList: true,
      };
    case "BLOG_LOADING_PROCESS":
      return {
        ...state,
        loadingProcess: true,
        itemLoading: action.payload,
      };
    case "CLEAR_BLOG_LOADING":
      return {
        ...state,
        loadingDetail: false,
        loadingList: false,
        loadingProcess: false,
        itemLoading: "",
      };
    case "SELECT_PICTURE":
      return {
        ...state,
        pictureUrlSelected: action.payload,
        pictureIndex: uuid(),
      };
    case "SELECT_LAYER":
      return {
        ...state,
        layer: action.payload,
      };
    case "SELECT_PROJECT":
      return {
        ...state,
        project: action.payload,
      };
    case "CLEAR_LAYER_BLOG_LIST":
      return {
        ...state,
        layer_blog_list: [],
      };
    //action public
    //5. Vote / like blog
    case "VOTE_BLOG":
      return {
        ...state,
        blog: voteBlog(state.blog, action.payload),
      };
    //12. Request free import
    case "REQUEST_FREE_IMPORT":
      return {
        ...state,
        blog: requestFreeImport(state.blog, action.payload),
      };
    //14. Add to cart data exchange
    case "ADD_TO_CART":
      return {
        ...state,
        cart: addToCart(state.cart, action.payload),
      };
    //15. Delete cart
    case "DELETE_CART":
      return {
        ...state,
        cart: {},
      };
    //16. Delete blog from cart
    case "DELETE_FROM_CART":
      return {
        ...state,
        cart: deleteFromCart(state.cart, action.payload),
      };
    //17. Add bank & generate price, snapshot blog yang dibeli
    case "GENERATE_PRICE":
      return {
        ...state,
        wait_purchase: action.payload,
        cart: {},
      };
    //18. Cancel wait_purchase, delete or move to cart
    case "MOVE_WAIT_PURCHASE_TO_CART":
      return {
        ...state,
        cart: action.payload,
      };
    default:
      return state;
  }
}

const edit_location = (list, body) => {
  const { blog_id, long, lat, PROVINSI, KAB_KOT, KECAMATAN, DESA } = body;
  const new_list = list.slice();
  //get index
  const index = new_list.findIndex((e) => e._id === blog_id);
  let new_item = new_list[index];
  const location = {
    type: "Point",
    coordinates: [long, lat],
  };
  const address = {
    PROVINSI,
    KAB_KOT,
    KECAMATAN,
    DESA,
  };
  new_item.location = location;
  new_item.address = address;
  //replace item
  new_list.splice(index, 1, new_item);
  return new_list;
};

const editBlogGeneral = (blog, payload) => {
  const new_blog = { ...blog }; //clone state
  const { title, sub_title, picture_url, layers_original, projects_original } =
    payload;
  new_blog.title = title;
  new_blog.sub_title = sub_title;
  new_blog.picture_url = picture_url;
  new_blog.layers = layers_original;
  new_blog.projects = projects_original;
  return new_blog;
};

const editBlogEditor = (blog, payload) => {
  const new_blog = { ...blog }; //clone state
  const { editorState } = payload;
  new_blog.editorState = editorState;
  return new_blog;
};

const voteBlog = (state, body) => {
  const newState = { ...state };
  const { user } = body;
  const vote = {
    user,
    date: Date.now(),
  };
  newState.votes_count.push(vote);
  return newState;
};

//4. Push layer to blog
const pushLayer = (state, body) => {
  const newState = { ...state };
  const { layer } = body;
  const item = {
    geo_layer: layer,
    date: Date.now(),
    _id: layer._id,
  };
  newState.layers.push(item);
  return newState;
};

//5. Remove layer from blog
const removeLayer = (state, body) => {
  const newState = { ...state };
  const { layer } = body;
  const layers = newState.layers ? newState.layers : [];
  const layers_new = layers.filter((e) => e.geo_layer._id !== layer._id);
  newState.layers = layers_new;
  return newState;
};

//6. Push project to blog
const pushProject = (state, body) => {
  const newState = { ...state };
  const { project } = body;
  const item = {
    geo_project: project,
    date: Date.now(),
    _id: project._id,
  };
  newState.projects.push(item);
  return newState;
};

//7. Remove project from blog
const removeProject = (state, body) => {
  try {
    const newState = { ...state };
    const { project } = body;
    const projects = newState.projects ? newState.projects : [];

    const projects_new = projects
      .filter((e) => e.geo_project)
      .filter((e) => e.geo_project._id !== project._id);
    newState.projects = projects_new;
    return newState;
  } catch (e) {
    return state;
  }
};

//7. Propose data exchange paid
const proposePaid = (blog, payload) => {
  const new_blog = { ...blog }; //clone state
  const { price } = payload;
  const market_status = {
    status: "paid",
    report_status: "no_action",
    price: price,
    date_in: Date.now(),
  };
  new_blog.market_status = market_status;
  return new_blog;
};

//8. Change price data exchange
const changePrice = (blog, payload) => {
  const new_blog = { ...blog }; //clone state
  const { price } = payload;
  new_blog.market_status.price = price;
  return new_blog;
};

//9. Propose data exchange free
const proposeFree = (blog) => {
  const new_blog = { ...blog }; //clone state
  const market_status = {
    status: "free",
    report_status: "no_action",
    date_in: Date.now(),
  };
  new_blog.market_status = market_status;
  return new_blog;
};

//10. Set blog public
const setPublic = (blog) => {
  const new_blog = { ...blog }; //clone state
  const market_status = {
    status: "public",
    report_status: "no_action",
    date_in: Date.now(),
  };
  new_blog.market_status = market_status;
  return new_blog;
};

//11. Set blog private
const setPrivate = (blog) => {
  const new_blog = { ...blog }; //clone state
  const market_status = {
    status: "private",
    report_status: "no_action",
    date_in: Date.now(),
  };
  new_blog.market_status = market_status;
  return new_blog;
};

//12. Request free import
const requestFreeImport = (blog, payload) => {
  const new_blog = { ...blog }; //clone state
  const { user_id, free_message } = payload;
  const request = {
    user: user_id,
    date_in: Date.now(),
    status: "free_pending",
    free_message: free_message,
  };
  new_blog.request_list.push(request);
  return new_blog;
};

//13. Action (accepted/rejected) request free import [To Do]
const actionRequestFreeImport = (blog, payload) => {
  const new_blog = { ...blog };
  const { request_id, status, seller_message } = payload;
  let { request_list } = new_blog;
  const index_request = request_list.findIndex(
    (request) => String(request._id) === String(request_id)
  );
  let new_request = request_list[index_request];
  new_request.date_action = Date.now();
  new_request.status = status;
  new_request.seller_message = seller_message;
  request_list.splice(index_request, 1, new_request);
  new_blog.request_list = request_list;
  return new_blog;
};

//14. Add to cart data exchange
const addToCart = (cart, body) => {
  let new_cart = { ...cart };
  const { blog } = body;
  const blog_content = { blog: blog };
  let blogs = new_cart.blogs ? new_cart.blogs : [];
  blogs.push(blog_content);
  new_cart.blogs = blogs;
  return new_cart;
};

//16. Delete blog from cart
const deleteFromCart = (cart, body) => {
  let new_cart = { ...cart };
  const { blog_id } = body;
  const blogs = new_cart.blogs ? new_cart.blogs : [];
  const new_blogs = blogs.filter((e) => e.blog._id !== blog_id);
  new_cart.blogs = new_blogs;
  return new_cart;
};
