/* eslint-disable */
import React, { Component } from "react";
import { Dropdown } from "../../reuseable";

export default class DropdownOrData extends Component {
  render() {
    const {
      mode,
      fields,
      id,
      className,
      type = "text",
      placeholder = "Pilih",
      body,
    } = this.props;

    if (mode === "setup") {
      return (
        <Dropdown
          className="w_100"
          placeholder={placeholder}
          filter_on
          is_enable_empty
          value={body?.[id] || ""}
          onChange={(value) => {
            if (this.props.onChange) {
              this.props.onChange(id, value);
            }
          }}
        >
          {fields &&
            fields?.map((field) => (
              <option key={field.key} value={field.key}>
                {field.name}
              </option>
            ))}
        </Dropdown>
      );
    } else {
      if (type === "file") {
        return <a id={id}>-</a>;
      } else {
        return (
          <p id={id} className={className}>
            -
          </p>
        );
      }
    }
  }
}
