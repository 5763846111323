import React, { useEffect, useState } from "react";

const BarProcess = () => {
  const [scroll, setScroll] = useState(0);

  const progresBar = () => {
    const scrollTop = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const value = (scrollTop / height) * 100;
    setScroll(value);
  };

  useEffect(() => {
    window.addEventListener("scroll", progresBar);
    return () => window.removeEventListener("scroll", progresBar);
  }, []);

  return (
    <div className="barprocess-background">
      <div
        className="barprocess"
        style={{
          width: `${scroll}%`,
        }}
      />
    </div>
  );
};

export default BarProcess;
