import st_colors from "../../Data/st_colors.json";
const generate_array_color = function (array_name) {
  const array_name_new = array_name?.length > 0 ? array_name : [];
  let array_color = array_name_new.map((e, idx) => {
    let index = idx;
    if (idx > 15) {
      index = idx % 16;
    }
    return st_colors[index];
  });
  array_color.push("#575757");
  return array_color;
};
export default generate_array_color;
