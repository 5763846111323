/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_bi,
  edit_inside_object,
  push_array_inside_object,
  delete_array_string_inside_object,
} from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_ADD_STEP_4_FEATURE_KEY extends Component {
  state = {};

  on_next_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step + 1,
    });
  };

  on_prev_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step - 1,
    });
  };

  on_change_card_add_object = (id_key, value) => {
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: id_key,
      value: value,
    });
  };

  on_click_row = (id_key, value) => {
    const { card_add_object } = this.props.bi;
    const feature_key_list = card_add_object?.feature_key_list || [];
    if (feature_key_list.includes(value)) {
      this.props.delete_array_string_inside_object({
        key: "card_add_object",
        id_key: id_key,
        value: value,
      });
    } else {
      this.props.push_array_inside_object({
        key: "card_add_object",
        id_key: id_key,
        value: value,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { project_object_selected, card_add_object } = this.props.bi;

    //content
    const layer_list = project_object_selected?.layer_list || [];
    const layer_id = card_add_object?.layer_id;
    const layer = layer_list.find((item) => item?.geo_layer?._id === layer_id);
    const geo_layer = layer?.geo_layer || {};
    const features = geo_layer?.geojson?.features || [];
    const fields = geo_layer?.fields || [];
    const field_key_value = card_add_object?.field_key_value;
    const field_key_selection = card_add_object?.field_key_selection;
    const fields_filtered = fields.filter(
      (item) =>
        item.key === field_key_value || item?.key === field_key_selection
    );
    const feature_key_list = card_add_object?.feature_key_list || [];

    return (
      <main>
        <h1 className="text_bold margin_bottom">Pilih baris</h1>
        <table className="table margin_bottom">
          <thead>
            <tr>
              <th></th>
              <th>No</th>
              {fields_filtered.map((item, idx) => {
                return <th key={idx}>{item?.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {features.map((item, idx) => {
              // const is_open = item?.key === feature_key;
              const is_open = feature_key_list.includes(item?.key);
              return (
                <tr
                  key={idx}
                  className="cursor_pointer"
                  // className={`cursor_pointer ${
                  //   is_open ? "background_blue" : "background_white"
                  // }`}
                >
                  <td>
                    <div
                      className={is_open ? "checklist_active" : "checklist_not"}
                      onClick={this.on_click_row.bind(
                        this,
                        "feature_key_list",
                        item.key
                      )}
                    />
                  </td>
                  <td>{idx + 1}</td>
                  {fields_filtered.map((field, idx_child) => {
                    return (
                      <td key={idx_child}>{item?.properties?.[field.key]}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          onClick={this.on_prev_step}
          className="button margin_bottom margin_right inline"
        >
          {dict?.["Previous step"]?.[language]}
        </button>
        {feature_key_list.length > 0 ? (
          <button onClick={this.on_next_step} className="button">
            {dict?.["Next step"]?.[language]}
          </button>
        ) : (
          <button className="button background_grey">
            {dict?.["Next step"]?.[language]}
          </button>
        )}
        {!feature_key_list.length === 0 && (
          <>
            <br />
            <p className="text_small text_red">
              {dict?.["Select the row to show"]?.[language]}
            </p>
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  layer: state.layer,
  properties: state.properties,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_bi,
  edit_inside_object,
  push_array_inside_object,
  delete_array_string_inside_object,
})(BI_ADD_STEP_4_FEATURE_KEY);
