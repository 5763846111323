const unique_list_layer_by_id = function (array) {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (!map.has(item?.geo_layer?._id)) {
      map.set(item?.geo_layer?._id, true);
      result.push(item);
    }
  }
  return result;
};
export default unique_list_layer_by_id;
