/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import BI_SIDEBAR_LIST from "./BI_SIDEBAR_LIST";
import BI_SIDEBAR_LAYER_DETAIL from "./BI_SIDEBAR_LAYER_DETAIL";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class BI_SIDEBAR_PARENT extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { layer_id } = this.props.bi;
    const link = this.props.link;

    //content
    let content;

    if (layer_id) {
      content = <BI_SIDEBAR_LAYER_DETAIL />;
    } else {
      content = <BI_SIDEBAR_LIST link={link} />;
    }

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, {})(BI_SIDEBAR_PARENT);
