/** 
 * Panggil saja ketika selesai melakukan request api
 * 
 * parameter:
 * - is_open : boolean
   - status : string
   - message : string
 * 
 * macam-macam status:
 *  success, info, warning, error
 */
export const snackbar = (params) => (dispatch) => {
  let is_open = false,
    status = "info",
    message = "";

  if (params) {
    is_open = params?.is_open;
    status = params?.status;
    message = params?.message || "";
  }

  dispatch({
    type: "snackbar",
    payload: {
      is_open,
      status,
      message,
    },
  });
};
