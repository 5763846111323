import { calculateWithoutConvert } from "./convert_data";
import uuid from "./uuid";

const fields_simple_map = ({
  features,
  poi_tipe_1_selected,
  demographic_field_list,
  min_demografi,
  max_demografi,
  min_poi,
  max_poi
}) => {
  /*
  "ATM DAN BANK",
  "FASILITAS UMUM",
  "RETAIL",
  "TEMPAT MAKAN/MINUM",
  */

  /*
  {
    "key": "6b6b8b3e-0753-42e2-a1b2-09fb059702d0",

    

    "kelurahan": "SUMUR BATU",
    "label": 70,

    "total_population_initial",
    "total_poi_initial",
  }
  */

  let fields_non_poi = [
    {
      uuid: uuid(),
      key: "label",
      name: "label",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    },
    {
      uuid: uuid(),
      key: "kelurahan",
      name: "KELURAHAN",
      description: "",
      unit: "",
      type: "text",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    },
    {
      uuid: uuid(),
      key: "grid_area_km",
      name: "LUAS GRID (km²)",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    },
    {
      uuid: uuid(),
      key: "total_poi_initial",
      name: "TOTAL POI",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    },
    {
      uuid: uuid(),
      key: "total_population_initial",
      name: "TOTAL DEMOGRAFI",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    },
  ];

  const generate_conditional_statement_list = ({
    min,
    max,
    classes,
    content,
    ref_key,
  }) => {
    let range_array = [];
    let step = (max - min) / classes;
    const array_status = [
      "Sangat Tidak Sesuai",
      "Tidak Sesuai",
      "Cukup Sesuai",
      "Sesuai",
      "Sangat Sesuai",
    ];
    for (let i = 0; i < classes; i++) {
      let then_value = (i + 1) * 10;
      if (content === "SKOR AKHIR") {
        then_value = array_status[i];
      }
      const object = {
        function: "if",
        if: [
          {
            type: "variable",
            content: content,
            ref_key: ref_key,
          },
          { type: "operator", content: ">=" },
          { type: "constant", content: min + i * step },
          { type: "operator", content: "&&" },
          {
            type: "variable",
            content: content,
            ref_key: ref_key,
          },
          { type: "math_symbol", content: "<" },
          { type: "constant", content: min + (i + 1) * step },
        ],
        then: String(then_value),
      };
      range_array.push(object);
    }
    return range_array;
  };

  //scoring range for POI (Point of Interest)

  const conditional_statement_list_poi = generate_conditional_statement_list({
    min: min_poi,
    max: max_poi,
    classes: 5,
    content: "TOTAL POI",
    ref_key: "total_poi_initial",
  });

  //scoring range for demographic

  const conditional_statement_list_people = generate_conditional_statement_list(
    {
      min: min_demografi,
      max: max_demografi,
      classes: 5,
      content: "TOTAL DEMOGRAFI",
      ref_key: "total_population_initial",
    }
  );

  const fields_formula = [
    {
      uuid: "SKOR POI",
      key: "SKOR POI",
      name: "SKOR POI",
      description: "",
      unit: "",
      type: "conditional_statement",
      array_templates: [],
      isHighlight: false,
      isRequired: true,
      isAllowOtherAnswer: true,
      isHide: false,
      reference_list: [],
      reference_key: "",
      formula: [],
      reference_list_2d: {
        row_name: "",
        column_name: "",
        headers: [],
        features: [],
      },
      decimal_digits: "",
      conditional_statement_list: conditional_statement_list_poi,
      term: [],
      digit_term: [],
    },
    {
      uuid: "SKOR DEMOGRAFI",
      key: "SKOR DEMOGRAFI",
      name: "SKOR DEMOGRAFI",
      description: "",
      unit: "",
      type: "conditional_statement",
      array_templates: [],
      isHighlight: false,
      isRequired: true,
      isAllowOtherAnswer: true,
      isHide: false,
      reference_list: [],
      reference_key: "",
      formula: [],
      reference_list_2d: {
        row_name: "",
        column_name: "",
        headers: [],
        features: [],
      },
      decimal_digits: "",
      conditional_statement_list: conditional_statement_list_people,
      term: [],
      digit_term: [],
    },
    {
      uuid: "SKOR AKHIR",
      key: "SKOR AKHIR",
      name: "SKOR AKHIR",
      description: "",
      unit: "",
      type: "math_operators",
      array_templates: [],
      isHighlight: false,
      isRequired: true,
      isAllowOtherAnswer: true,
      isHide: false,
      reference_list: [],
      reference_key: "",
      formula: [
        {
          type: "variable",
          content: "SKOR POI",
          ref_key: "SKOR POI",
        },
        { type: "operator", content: "+" },
        {
          type: "variable",
          content: "SKOR DEMOGRAFI",
          ref_key: "SKOR DEMOGRAFI",
        },
      ],
      reference_list_2d: {
        row_name: "",
        column_name: "",
        headers: [],
        features: [],
      },
      decimal_digits: "",
      conditional_statement_list: [],
    }
  ]

  let fields = [...fields_non_poi, ...fields_formula];


  // get min and max skor akhir
  calculateWithoutConvert({ features, fields })
  const initial_skor_akhir = features?.[0]?.properties?.["SKOR AKHIR"]
  let min_skor_akhir = initial_skor_akhir;
  let max_skor_akhir = initial_skor_akhir;
  features.forEach((item) => {
    const skor_akhir = item?.properties?.["SKOR AKHIR"];
    if (min_skor_akhir > skor_akhir) min_skor_akhir = skor_akhir;
    if (max_skor_akhir < skor_akhir) max_skor_akhir = skor_akhir;
  });

  //generate range hasil
  const conditional_statement_list_hasil = generate_conditional_statement_list({
    min: min_skor_akhir,
    max: max_skor_akhir + 1,
    classes: 5,
    content: "SKOR AKHIR",
    ref_key: "SKOR AKHIR",
  });

  const hasil_field = {
    uuid: uuid(),
    key: "HASIL",
    name: "HASIL",
    description: "",
    unit: "",
    type: "conditional_statement",
    array_templates: [],
    isHighlight: false,
    isRequired: true,
    isAllowOtherAnswer: true,
    isHide: false,
    reference_list: [],
    reference_key: "",
    formula: [],
    reference_list_2d: {
      row_name: "",
      column_name: "",
      headers: [],
      features: [],
    },
    decimal_digits: "",
    conditional_statement_list: conditional_statement_list_hasil,
  }
  fields = [...fields, hasil_field]

  return fields;
};
export default fields_simple_map;
