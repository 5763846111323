/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { delete_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class DELETE_BI extends Component {
  state = {};

  on_delete = () => {
    const { bi_selected } = this.props.bi;
    const query = { _id: bi_selected._id };
    this.props.delete_bi(query);
    this.props.toggle_delete();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { bi_selected } = this.props.bi;

    //content
    return (
      <main>
        <h2 className="margin_bottom">
          {dict?.["Delete BI Dasboard"]?.[language]}
        </h2>
        <br />
        <p className="margin_bottom">
          {
            dict?.[
              "Are you sure you want to delete this BI? For security reasons, all projects & folders inside the BI will not be deleted."
            ]?.[language]
          }
        </p>
        <br />
        <section className="container_light">
          <p>{dict?.["BI name"]?.[language]}</p>
          <h4>{bi_selected?.name}</h4>
        </section>
        <br />
        <button
          className="button background_red margin_top"
          onClick={this.on_delete}
        >
          {dict?.["Delete"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
});

export default connect(mapStateToProps, { delete_bi })(DELETE_BI);
