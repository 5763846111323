//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

import PaymentConfirmation from "../../Components/payment/PaymentConfirmation.jsx";
import PaymentCancellation from "../../Components/payment/PaymentCancellation.jsx";
import CheckOut from "../../Components/payment/CheckOut.jsx";
import Modal from "../../Components/common_modal/Modal.jsx";

//Redux function
import {
  getUserPaymentDetail,
  user_action_qris,
} from "../../App/actions/paymentActions.js";
import { cancelWaitPurchase } from "../../App/actions/blogAction.js";

//Picture Asset
import IconCheck from "../../Components/common_icon/IconCheck.jsx";
import IconCancel from "../../Components/common_icon/IconCancel.jsx";
import logo_mandiri from "../../Assets/png_jpg/logo_mandiri.png";
import qris from "../../Assets/png_jpg/qris.png";
import qris_info from "../../Assets/png_jpg/qris_info.png";

//General Function
import isEqual from "../../App/validation/is-equal.js";
import dict from "../../Data/dict.json";
import history from "../../App/actions/history.js";
import { snackbar } from "../../App/actions/snackbarActions.js";

class PaymentInstruction extends Component {
  state = {
    modalConfirm: false,
    modalCancel: false,
    modalCancelDataExchange: false,
    rekeningCopied: false,
    amountCopied: false,
    modalCheckOut: false,
  };

  componentDidMount = async () => {
    if (this.props.match?.params?.payment_id) {
      const { payment_id } = this.props.match.params;
      this.props.getUserPaymentDetail(payment_id);
    } else if (this.props.payment_id) {
      const { payment_id } = this.props;
      this.props.getUserPaymentDetail(payment_id);
    }
  };

  componentDidUpdate(prevProps) {
    const { modalConfirm, modalCheckOut } = this.state;
    if (!isEqual(this.props.payment.payment, prevProps.payment.payment)) {
      if (modalConfirm) {
        this.toggleConfirm();
      }
      if (modalCheckOut) {
        this.toggleCheckOut();
      }
    }
  }

  on_user_action_qris = () => {
    const { payment_id } = this.props.match.params;
    const body = { payment_id };
    this.props.user_action_qris(body);
  };

  handleCancelDataExchange = (action) => {
    const { payment } = this.props.payment;
    const payment_id = payment._id;
    const body = { payment_id, action };
    this.props.cancelWaitPurchase(body);
  };

  handleCopyToClipBoard = (text, type) => {
    navigator.clipboard.writeText(text);
    this.setState({
      [type]: true,
    });
    setTimeout(
      function () {
        this.setState({ [type]: false });
      }.bind(this),
      500
    );
  };

  toggleConfirm = () => {
    this.setState({ modalConfirm: !this.state.modalConfirm });
  };

  toggleCancel = () => {
    this.setState({ modalCancel: !this.state.modalCancel });
  };

  toggleCancelDataExchange = () => {
    this.setState({
      modalCancelDataExchange: !this.state.modalCancelDataExchange,
    });
  };

  toggleCheckOut = () => {
    this.setState({ modalCheckOut: !this.state.modalCheckOut });
  };

  toogleMidtrans = () => {
    const { payment } = this.props.payment;

    window.snap.pay(payment.midtrans.token, {
      onSuccess: function (result) {
        /* You may add your own implementation here */
        // alert("payment success!");
        this.props.snackbar({
          is_open: true,
          status: "success",
          message: "Payment success",
        });

        history.push("/user_profile");
      },
      onPending: function (result) {
        /* You may add your own implementation here */
        // alert("wating your payment!");

        history.push("/purchase_list");
      },
      onError: function (result) {
        /* You may add your own implementation here */
        // alert("payment failed!");
        this.props.snackbar({
          is_open: true,
          status: "error",
          message: "Payment Failed",
        });
        history.push("/purchase_list");
      },
      onClose: function () {
        /* You may add your own implementation here */
        // history.push("/purchase_list");
      },
    });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const {
      modalConfirm,
      modalCancel,
      rekeningCopied,
      amountCopied,
      modalCheckOut,
      modalCancelDataExchange,
    } = this.state;

    const { payment } = this.props.payment;

    const { status, payment_type, payment_methode } = payment;
    // const amount = payment.amount ? payment.amount : 0;
    // const rupiah_amount = new Intl.NumberFormat("id-ID", {
    //   style: "decimal",
    // }).format(amount);
    const amount = payment.price ? payment.price : 0;
    const rupiah_amount = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(amount);
    let header_text;
    let button_content;
    let footer_content;
    let item_content;
    if (
      payment_type === "license_1" ||
      payment_type === "license_2" ||
      payment_type === "license_3" ||
      payment_type === "data_exchange"
    ) {
      if (status === "wait_purchase") {
        header_text = dict["Complete your payment"][language];
        button_content = (
          <main style={{ textAlign: "center", marginTop: "20px" }}>
            <button onClick={this.toggleConfirm} className="button_standard">
              {dict["Upload Payment Receipt"][language]}
            </button>
            <br />
            {payment_type === "data_exchange" ? (
              <button
                onClick={this.toggleCancelDataExchange}
                style={{ marginTop: "40px" }}
                className="text_danger"
              >
                {dict["Cancel Transaction"][language]}
              </button>
            ) : (
              <button
                onClick={this.toggleCancel}
                style={{ marginTop: "40px" }}
                className="text_danger"
              >
                {dict["Cancel Transaction"][language]}
              </button>
            )}
          </main>
        );
        footer_content = (
          <div>
            <div>{dict["Payment method"][language]}</div>
            <hr />
            <div style={{ marginBottom: "5px" }}>
              •{" "}
              {
                dict[
                  "Make sure your payment has been SUCCESSFUL and upload proof of payment immediately."
                ][language]
              }
            </div>
            <div style={{ marginBottom: "5px" }}>
              •{" "}
              {
                dict[
                  "The license will be active after you upload proof of payment."
                ][language]
              }
            </div>
            <div style={{ marginBottom: "5px" }}>
              •{" "}
              {
                dict[
                  "Checking will be done manually, if we don't receive payment, your license will be cancelled."
                ][language]
              }
            </div>
          </div>
        );
      } else if (status === "user_purchase") {
        header_text =
          dict?.["Proof of payment has been uploaded successfully."]?.[
            language
          ];
        if (payment_methode === "qris") {
          header_text = dict?.["Payment has been made with QRIS"]?.[language];
        }
        if (payment_type === "license_1") {
          header_text =
            dict["You have successfully uploaded your KTM."][language];
        }
        button_content = (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <IconCheck height="100px" />
          </div>
        );
        if (payment_type === "license_1") {
          button_content = (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <IconCheck height="100px" />
              <section style={{ marginTop: "10px" }}>
                <img
                  alt="ktm"
                  src={payment?.picture_url}
                  style={{ width: "300px", maxWidth: "100%" }}
                />
              </section>
            </div>
          );
        }
        footer_content = (
          <div>
            <p>{dict["Information"][language]}</p>
            <hr />
            <p>
              {
                dict[
                  "The proof of payment has been uploaded successfully, while our team checks the payment, you can already use the GEO MAPID license."
                ][language]
              }
            </p>
          </div>
        );
        if (payment_type === "license_1") {
          footer_content = (
            <div>
              <p>{dict["Information"][language]}</p>
              <hr />
              <p>
                {
                  dict?.[
                    "Your KTM has been uploaded successfully, while our team checks the correctness of your student status, you can already use the GEO MAPID license."
                  ]?.[language]
                }
              </p>
            </div>
          );
        }
        if (payment_type === "data_exchange") {
          footer_content = (
            <div>
              <p>{dict["Information"][language]}</p>
              <hr />
              <p>
                {
                  dict[
                    "Proof of payment has been successfully uploaded, we will check your funds and we will forward it to the seller."
                  ][language]
                }
              </p>
            </div>
          );
        }
      } else if (status === "user_cancel") {
        header_text = dict["Your payment has been cancelled."][language];
        button_content = (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <IconCancel height="100px" />
          </div>
        );
        footer_content = (
          <div>
            <p>{dict["Information"][language]}</p>
            <hr />
            <p>{dict["You have canceled this transaction."][language]}</p>
          </div>
        );
      } else if (status === "success") {
        header_text = dict["We have received payment."][language];
        button_content = (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <IconCheck height="100px" />
          </div>
        );
        footer_content = (
          <div>
            <p>{dict["Information"][language]}</p>
            <hr />
            <p>
              {
                dict[
                  "We have confirmed the payment, the overpayment as a unique code has been returned as MAPID Balance."
                ][language]
              }
            </p>
          </div>
        );
      } else if (status === "rejected") {
        header_text = dict["We didn't find the payment."][language];
        button_content = (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              onClick={this.toggleConfirm}
              className="button_standard"
              style={{ marginBottom: "20px" }}
            >
              {dict["Upload Payment Receipt Again"][language]}
            </button>
            <br />
            <IconCancel height="100px" />
          </div>
        );
        footer_content = (
          <div>
            <p>{dict["Information"][language]}</p>
            <hr />
            <p>
              {
                dict[
                  "We did not find the payment with the proof of payment that you uploaded."
                ][language]
              }
            </p>
          </div>
        );
      }

      let payment_type_content;
      switch (payment_type) {
        case "license_1":
          payment_type_content = "PERSONAL";
          break;
        case "license_2":
          payment_type_content = "BUSINESS";
          break;
        case "license_3":
          payment_type_content = "ENTERPRISE";
          break;
        case "data_exchange":
          payment_type_content = dict["Payment data exchange"][language];
          break;
        default:
          payment_type_content = dict["Others"][language];
          break;
      }
      const headerContent = (
        <main
          className="container_standard"
          id="white"
          style={{
            width: "600px",
            maxWidth: "calc(( 100% - 20px ))",
            margin: "auto",
            marginBottom: "30px",
          }}
        >
          <section style={{ marginBottom: "5px" }}>{header_text}</section>
          <hr />
          <section>
            <div className="two-container">
              <div className="flex flex_col">
                <label>{dict["Total price"][language]}</label>
                <label className="bold font_24 text_darkBlue">
                  Rp {rupiah_amount}
                </label>
              </div>
              <div>
                <div style={{ textAlign: "right", fontWeight: "bolder" }}>
                  {payment_type_content}
                </div>
              </div>
            </div>
          </section>
        </main>
      );
      let box_transfer_info = <div></div>;
      if (["wait_purchase", "rejected"].includes(status)) {
        box_transfer_info = (
          <main>
            <main
              className="container_standard"
              id="white"
              style={{
                width: "600px",
                maxWidth: "calc(( 100% - 20px ))",
                margin: "auto",
              }}
            >
              <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                {dict?.["Payment method"]?.[language]} 1 : QRIS
              </div>
              <hr />
              <section className="text_center">
                <img
                  src={qris_info}
                  alt="qris_info"
                  style={{ width: "70%", marginBottom: "10px" }}
                />
                <br />
                <img
                  src={qris}
                  alt="qris"
                  style={{
                    width: "350px",
                    maxWidth: "100%",
                    marginBottom: "10px",
                  }}
                />
                <p className="text_bold">MAPID</p>
                <p className="text_bold">ID2022174374084</p>
              </section>

              <section
                className="two_container_equal"
                style={{ marginTop: "30px" }}
              >
                <div>
                  <p>{dict["Total price"][language]}</p>
                  <p className="header_container">Rp {rupiah_amount}</p>
                  {amountCopied && (
                    <p
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#000",
                        display: "inline-block",
                      }}
                    >
                      {dict["Copied"][language]}
                    </p>
                  )}
                  <div
                    className="arrow_up"
                    style={{ marginLeft: "60px" }}
                  ></div>
                  <div className="badge_normal">
                    {
                      dict[
                        "Important! Transfers exactly to the last 3 digits."
                      ][language]
                    }
                  </div>
                </div>
                <div
                  onClick={this.handleCopyToClipBoard.bind(
                    this,
                    payment.amount,
                    "amountCopied"
                  )}
                  className="text_normal"
                  style={{
                    textAlign: "right",
                    marginTop: "auto",
                    marginBottom: "auto",
                    cursor: "pointer",
                  }}
                >
                  {dict["Copy"][language]}
                </div>
              </section>
              <section className="text_center" style={{ marginTop: "10px" }}>
                <button
                  className="button_standard"
                  onClick={this.on_user_action_qris}
                >
                  Klik di sini jika sudah scan & bayar
                </button>
              </section>
            </main>
            <br />
            <br />
            <br />
            <main
              className="container_standard"
              id="white"
              style={{
                width: "600px",
                maxWidth: "calc(( 100% - 20px ))",
                margin: "auto",
              }}
            >
              <section className="two_container_equal">
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  {dict?.["Payment method"]?.[language]} 2 :{" "}
                  {dict?.["Bank transfer"]?.[language]}
                </div>
                <div style={{ textAlign: "right" }}>
                  <img src={logo_mandiri} height="25px" alt="logo_mandiri" />
                </div>
              </section>
              <hr />
              <section className="two_container_equal">
                <div>
                  <p>{dict["Account number"][language]}</p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0575e6",
                      display: "inline-block",
                    }}
                  >
                    130 008 877 8785 (Mandiri)
                  </p>
                  {rekeningCopied && (
                    <p
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#000",
                        display: "inline-block",
                      }}
                    >
                      {dict["Copied"][language]}
                    </p>
                  )}
                  <p>{dict["Account name"][language]}</p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#0575e6",
                      display: "inline-block",
                    }}
                  >
                    PT Multi Areal Planing Indonesia
                  </p>
                </div>
                <button
                  onClick={this.handleCopyToClipBoard.bind(
                    this,
                    "1300088778785",
                    "rekeningCopied"
                  )}
                  className="text_normal"
                  style={{
                    textAlign: "right",
                    marginTop: "auto",
                    marginBottom: "auto",
                    cursor: "pointer",
                  }}
                >
                  {dict["Copy"][language]}
                </button>
              </section>
              <section
                className="two_container_equal"
                style={{ marginTop: "30px" }}
              >
                <div>
                  <p>{dict["Total price"][language]}</p>
                  <p className="header_container">Rp {rupiah_amount}</p>
                  {amountCopied && (
                    <p
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#000",
                        display: "inline-block",
                      }}
                    >
                      {dict["Copied"][language]}
                    </p>
                  )}
                  <div
                    className="arrow_up"
                    style={{ marginLeft: "60px" }}
                  ></div>
                  <div className="badge_normal">
                    {
                      dict[
                        "Important! Transfers exactly to the last 3 digits."
                      ][language]
                    }
                  </div>
                </div>
                <div
                  onClick={this.handleCopyToClipBoard.bind(
                    this,
                    payment.amount,
                    "amountCopied"
                  )}
                  className="text_normal"
                  style={{
                    textAlign: "right",
                    marginTop: "auto",
                    marginBottom: "auto",
                    cursor: "pointer",
                  }}
                >
                  {dict["Copy"][language]}
                </div>
              </section>
              {button_content}
              <section
                style={{
                  width: "600px",
                  maxWidth: "calc(( 100% - 20px ))",
                  margin: "auto",
                  marginTop: "40px",
                }}
              >
                {footer_content}
              </section>
            </main>
          </main>
        );
      } else {
        box_transfer_info = <main>{button_content}</main>;
      }
      let payment_button;
      if (["wait_purchase", "rejected"].includes(status)) {
        payment_button = (
          <section
            className="container_standard"
            style={{ textAlign: "center" }}
          >
            <button
              className="button_inactive bg_darkBlue text_white h_40 w_150 rounded_5"
              onClick={this.toogleMidtrans}
            >
              {dict["Pay Now"][language]}
            </button>
          </section>
        );
      } else {
        payment_button = <main>{button_content}</main>;
      }

      item_content = (
        <main>
          <section>
            {headerContent}
            <div style={{ display: "none" }}>{box_transfer_info}</div>
            {payment_button}
          </section>
        </main>
      );
    } else {
      item_content = (
        <main>
          <h3>
            {
              dict["This page is only for license payments and data exchange"][
                language
              ]
            }
          </h3>
        </main>
      );
    }
    const modalConfirmContent = modalConfirm && (
      <Modal
        modalSize="small"
        id="modal_confirmation"
        isOpen={modalConfirm}
        onClose={this.toggleConfirm}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <PaymentConfirmation />
        </div>
      </Modal>
    );
    const modalCancelContent = modalCancel && (
      <Modal
        modalSize="small"
        id="modal_confirmation"
        isOpen={modalCancel}
        onClose={this.toggleCancel}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <PaymentCancellation />
        </div>
      </Modal>
    );
    const modalCheckOutContent = modalCheckOut && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalCheckOut}
        onClose={this.toggleCheckOut}
      >
        <div className="box-body">
          <CheckOut />
        </div>
      </Modal>
    );
    const modalCancelDataExchangeContent = modalCancelDataExchange && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalCancelDataExchange}
        onClose={this.toggleCancelDataExchange}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <button
            onClick={this.handleCancelDataExchange.bind(this, "move_to_cart")}
            className="button_standard"
            id="green"
          >
            {dict["Move to cart"][language]}
          </button>
          <p style={{ marginTop: "10px", marginBottom: "10px" }}>
            {dict["Or"][language]}
          </p>
          <button
            onClick={this.handleCancelDataExchange.bind(this, "delete")}
            className="button_standard"
            id="grey_light"
          >
            {dict["Delete"][language]}
          </button>
        </div>
      </Modal>
    );
    return (
      <main>
        {item_content}
        {modalConfirmContent}
        {modalCancelContent}
        {modalCheckOutContent}
        {modalCancelDataExchangeContent}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});
export default connect(mapStateToProps, {
  getUserPaymentDetail,
  cancelWaitPurchase,
  user_action_qris,
  snackbar,
})(PaymentInstruction);
