import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";

export default class C8_Kondisi extends Component {
  render() {
    return (
      <table className="table_pdf">
        <thead>
          <tr>
            <td colSpan={6} className="tr_border_bottom_pdf">
              <p className="subtitle_pdf center_perfect">KONDISI</p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <p>Kondisi tanah</p>
            </td>
            <td colSpan={2} className="border_right">
              <DropdownOrData id="c8_kondisi_tanah_pdf" {...this.props} />
            </td>
            <td>
              <p className="subtitle_pdf">BATAS BIDANG</p>
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <p>Peruntukan</p>
            </td>
            <td colSpan={2} className="border_right">
              <DropdownOrData id="c8_peruntukan_pdf" {...this.props} />
            </td>
            <td>
              <p>- Depan</p>
            </td>
            <td>
              <DropdownOrData id="c8_depan_pdf" {...this.props} />
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <p>Bentuk Lahan</p>
            </td>
            <td colSpan={2} className="border_right">
              <DropdownOrData id="c8_bentuk_lahan_pdf" {...this.props} />
            </td>
            <td>
              <p>- Belakang</p>
            </td>
            <td>
              <DropdownOrData id="c8_belakang_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Kontur Lahan</p>
            </td>
            <td>
              <DropdownOrData id="c8_kontur_lahan_pdf" {...this.props} />
            </td>
            <td>
              <p>Lebar Depan Bidang</p>
            </td>
            <td className="border_right">
              <DropdownOrData id="c8_lebar_depan_bidang_pdf" {...this.props} />
            </td>
            <td>
              <p>- Kanan</p>
            </td>
            <td>
              <DropdownOrData id="c8_kanan_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Letak Bidang</p>
            </td>
            <td>
              <DropdownOrData id="c8_letak_bidang_pdf" {...this.props} />
            </td>
            <td>
              <p>Panjang Bidang</p>
            </td>
            <td className="border_right">
              <DropdownOrData id="c8_panjang_bidang_pdf" {...this.props} />
            </td>
            <td>
              <p>- Kiri</p>
            </td>
            <td>
              <DropdownOrData id="c8_kiri_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Ketinggian dari Jalan</p>
            </td>
            <td>
              <DropdownOrData
                id="c8_ketinggalan_dari_jalan_pdf"
                {...this.props}
              />
            </td>
            <td colSpan={2} className="border_right">
              <p>diatas permukaan jalan</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
