/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_layer,
  pushFeature,
  pushFeatureV2,
  editGeometry,
  editGeometryV2,
  deleteFeature,
} from "../../App/actions/layerActions";
import {
  set_value_toolbox,
  generate_elevation,
  push_value_toolbox,
  generate_isokron,
} from "../../App/actions/toolboxActions";
import { get_sini_data, set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import draw_initiation from "./draw_initiation";
import on_draw_toolbox from "./on_draw_toolbox";
import on_draw_sini from "./on_draw_sini";
import domain_list from "../../Data/domain_list";
import { getAllFields, getGeoLayer } from "../../App/reduxHelper/layer";
import { generate_feature_base_value } from "../../App/validation/generateFeature";

/*NON IMPORT*/
const type_to_draw_mode = {
  Polygon: "draw_polygon",
  Point: "draw_point",
  LineString: "draw_line_string",
};

class DRAW_GEO extends Component {
  state = {};

  componentDidMount() {
    const map_object = this.props.layer.map_object;
    if (map_object) {
      map_object.on("load", () => {
        draw_initiation({
          map_object,
          on_create: this.on_create,
          on_update: this.on_update,
          on_delete: this.on_delete,
        });
      });
    }
    document.addEventListener("keydown", this.handle_esc_key); //listen for the "Esc" key
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handle_esc_key); //remove the "Esc" key listener
  }

  componentDidUpdate(prevProps) {
    const { map_object } = this.props.layer;
    if (this.props.layer.map_object !== prevProps.layer.map_object) {
      if (map_object) {
        map_object.on("load", () => {
          draw_initiation({
            map_object,
            on_create: this.on_create,
            on_update: this.on_update,
            on_delete: this.on_delete,
          });
        });
      }
    }
    //check if the geometryStatus or layer_id has changed
    const geometryStatus_before = prevProps.layer.geometryStatus;
    const geometryStatus_after = this.props.layer.geometryStatus;
    const layer_id_before = prevProps.layer.layer_id;
    const layer_id_after = this.props.layer.layer_id;

    const draw_state_platform_after = this.props.layer.draw_state_platform;
    const draw_state_platform_before = prevProps.layer.draw_state_platform;

    if (
      (geometryStatus_before !== geometryStatus_after ||
        layer_id_before !== layer_id_after ||
        draw_state_platform_after !== draw_state_platform_before) &&
      draw_state_platform_after !== "toolbox"
    ) {
      if (geometryStatus_after && layer_id_after) {
        const { geo_layer_list } = this.props.layer;
        if (map_object?._controls?.[1]) {
          map_object?._controls?.[1]?.deleteAll();
        }
        const geo_layer = geo_layer_list.find(
          (item) => item?.geo_layer?._id === layer_id_after
        )?.geo_layer;
        const geojson = geo_layer?.geojson || {
          type: "FeatureCollection",
          features: [],
        };
        map_object?._controls?.[1]?.add(geojson);
        map_object?._controls?.[1]?.changeMode("simple_select");
      }
    }
  }

  on_create = (event) => {
    this.props.set_value_layer({
      key: "draw_active",
      value: false,
    });
    const { map_object, draw_state_platform } = this.props.layer;

    if (map_object) {
      map_object.getCanvas().style.cursor = ""; //reset cursor on map canvas
    }
    //panggil API pushFeature
    if (draw_state_platform === "geometry") {
      const geometry = event?.features?.[0]?.geometry;
      const id = event?.features?.[0]?.id;
      const { layer_id, current_architecture } = this.props.layer;
      if (layer_id) {
        // nambahin data default (0 utk number dan "" utk string) pada properties
        const geo_layer = getGeoLayer(this.props.layer);
        const fields = getAllFields(geo_layer).filter(
          (field) => !field?.parent_uuid
        );
        const prop = { key: id, ...generate_feature_base_value(fields) };

        let feature = {
          type: "Feature",
          properties: prop,
          geometry: geometry,
          key: id,
          isHide: false,
          formStatus: { status: "digitasi" },
        };
        const body = {
          geo_layer_id: layer_id,
          feature,
        };
        if (current_architecture === "v2") {
          this.props.pushFeatureV2(body);
        } else {
          this.props.pushFeature(body);
        }
      }
    } else if (draw_state_platform === "toolbox") {
      if (map_object?._controls?.[1]) {
        map_object?._controls?.[1]?.deleteAll();
        map_object?._controls?.[1]?.changeMode("simple_select");
      }
      on_draw_toolbox({
        event,
        toolbox: this.props.toolbox,
        set_value_toolbox: this.props.set_value_toolbox,
        generate_elevation: this.props.generate_elevation,
        push_value_toolbox: this.props.push_value_toolbox,
        generate_isokron: this.props.generate_isokron,
      }); //fungsi untuk handle toolbox dipisahkan supaya mudah untuk maintenance
    } else if (draw_state_platform === "sini") {
      const { domain } = this.props.auth;
      const show_survey_1 = domain_list?.[domain]?.show_survey_1;

      on_draw_sini({
        event,
        sini: this.props.sini,
        get_sini_data: this.props.get_sini_data,
        set_value_sini: this.props.set_value_sini,
        set_value_layer: this.props.set_value_layer,
        show_survey_1,
      }); //fungsi untuk handle sini dipisahkan supaya mudah untuk maintenance
      if (map_object?._controls?.[1]) {
        map_object?._controls?.[1]?.deleteAll();
      }
    }
  };

  on_update = (event) => {
    //panggil API editGeometry
    if (["change_coordinates", "move"].includes(event?.action)) {
      const feature_key =
        event?.features?.[0]?.properties?.key || event?.features?.[0]?.id;
      const geometry = event?.features?.[0]?.geometry;
      const { layer_id, current_architecture } = this.props.layer;
      if (feature_key && geometry && layer_id) {
        const body = {
          feature_key: feature_key,
          geo_layer_id: layer_id,
          geometry: geometry,
        };
        if (current_architecture === "v2") {
          this.props.editGeometryV2(body);
        } else {
          this.props.editGeometry(body);
        }
      }
    } else {
    }
  };

  on_delete = (event) => {
    const map_object = this.props.layer.map_object;
    if (map_object) {
      map_object.getCanvas().style.cursor = ""; //reset cursor on map canvas
    }
    const { layer_id } = this.props.layer;
    const feature_key =
      event?.features?.[0]?.properties?.key || event?.features?.[0]?.id;
    const body = {
      feature_key: feature_key,
      geo_layer_id: layer_id,
    };
    if (layer_id && feature_key) {
      //panggil API deleteFeature
      this.props.deleteFeature(body);
    }
  };

  on_selectionchange = (event) => {
    const { map_object } = this.props.layer;
    const features_length = event?.features?.length || 0;
    if (features_length > 0) {
      //jika klik feature lain
      const { current_type } = this.props.layer;
      const draw_mode = type_to_draw_mode?.[current_type] || "";
      if (map_object && draw_mode) {
        map_object.getCanvas().style.cursor = "crosshair";
      }
    } else {
      //jika klik area kosong di peta
      if (map_object) {
        map_object.getCanvas().style.cursor = ""; //reset cursor on map canvas
      }
    }
  };

  on_modechange = (event) => {};

  handle_esc_key = (event) => {
    this.props.set_value_layer({
      key: "draw_active",
      value: false,
    });
    this.props.set_value_toolbox({ key: "mode_draw", value: "simple_select" });
    if (event.key === "Escape") {
      const map_object = this.props.layer.map_object;
      if (map_object?._controls?.[1]) {
        map_object._controls[1].changeMode("simple_select");
        map_object.getCanvas().style.cursor = "";
      }
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  toolbox: state.toolbox,
  sini: state.sini,
});

export default connect(mapStateToProps, {
  set_value_layer,
  pushFeature,
  pushFeatureV2,
  editGeometry,
  editGeometryV2,
  deleteFeature,
  set_value_toolbox,
  generate_elevation,
  push_value_toolbox,
  generate_isokron,
  get_sini_data,
  set_value_sini,
})(DRAW_GEO);
