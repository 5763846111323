/**
 * Keterangan: 
 * 
 * Function ini akan mengecek apakah value menggunakan format UUID V4 atau bukan
 */
const is_uuid = (str) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
}

export default is_uuid;