import React, { Component } from "react";

class IconCheck extends Component {
  render() {
    return (
      <svg
        id="svg8"
        version="1.1"
        viewBox="0 0 118.61263 118.61263"
        height={this.props.height}
      >
        <defs id="defs2" />
        <g transform="translate(1.8539402,-51.818682)" id="layer1">
          <circle
            r="56.660484"
            cy="111.125"
            cx="57.452377"
            id="path833"
            style={{
              fill: "none",
              stroke: "#0acf9bff",
              strokeWidth: 4,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
          />
          <path
            id="path835"
            d="m 29.992732,109.0877 19.66465,19.66465 35.25464,-35.254643"
            style={{
              fill: "none",
              stroke: "#0acf9bff",
              strokeWidth: 4,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
          />
        </g>
      </svg>
    );
  }
}

export default IconCheck;
