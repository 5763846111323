import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_COLLAPSE extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width || 10}
        height={this.props.height || 10}
        viewBox="0 0 3.925 3.925"
        className="margin_left"
      >
        <path
          d="m.64 2.624 1.323-1.323 1.323 1.323z"
          style={{
            fillRule: "evenodd",
            fill: "#000",
            paintOrder: "stroke markers fill",
          }}
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_COLLAPSE);
