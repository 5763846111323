import axios from "axios";
import imageCompression from "browser-image-compression";
import his from "./history";
import { geoServerBaseUrl } from "./baseUrl";
const DATA_URL = "https://data.mapid.io";
const SERVER_URL = geoServerBaseUrl;

export const get_version_log = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_version_log"));
    const res = await axios.get(SERVER_URL + `/blog/get_version_log`);
    dispatch({
      type: "get_version_log",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const get_categories = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_categories"));
    const res = await axios.get(SERVER_URL + `/blog/cateogry`);
    dispatch({
      type: "get_categories",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const search_blog = (keywords) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("search_blog"));
    // const config_header = {
    //   headers: {
    //     accesstoken: localStorage.token_mapid,
    //   },
    // };
    // https://geoserver.mapid.io/blog/search?query=data%20bencana%20longsor
    const res = await axios.get(
      SERVER_URL + `/blog/search?query=${keywords}`
      // config_header
    );
    dispatch({
      type: "search_blog",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const setLoadingDetail = () => {
  return {
    type: "BLOG_LOADING_DETAIL",
  };
};

export const setLoadingList = () => {
  return {
    type: "BLOG_LOADING_LIST",
  };
};

export const setLoadingProcess = (itemLoading) => {
  return {
    type: "BLOG_LOADING_PROCESS",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_BLOG_LOADING",
  };
};

export const selectPicture = (picture_url) => (dispatch) => {
  dispatch({
    type: "SELECT_PICTURE",
    payload: picture_url,
  });
};

export const selectLayer = (layer) => (dispatch) => {
  dispatch({
    type: "SELECT_LAYER",
    payload: layer,
  });
};

export const selectProject = (project) => (dispatch) => {
  dispatch({
    type: "SELECT_PROJECT",
    payload: project,
  });
};

export const clearBlog = () => (dispatch) => {
  dispatch({
    type: "CLEAR_BLOG",
  });
};

export const clearBlogList = () => (dispatch) => {
  dispatch({
    type: "CLEAR_BLOG_LIST",
  });
};

export const clearLayerBlogList = () => (dispatch) => {
  dispatch({
    type: "CLEAR_LAYER_BLOG_LIST",
  });
};

export const setBlog = (blog) => (dispatch) => {
  dispatch({
    type: "SET_BLOG",
    payload: blog,
  });
};

export const setBlogPersonal = (blog) => (dispatch) => {
  dispatch({
    type: "SET_BLOG_PERSONAL",
    payload: blog,
  });
};

/*POSTY*/
//get_geocode
export const get_geocode = (body) => async (dispatch) => {
  try {
    const { lat, long } = body;
    dispatch(setLoadingProcess("get_geocode"));
    const res = await axios.get(
      DATA_URL + `/sini_new?lat=${lat}&long=${long}&mode=geocode`
    );
    dispatch({
      type: "get_geocode",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Edit lokasi blog
export const edit_location = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("edit_location"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/edit_location", body, config);
    dispatch({
      type: "edit_location",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//1. Create Blog Empty
export const createEmpty = (domain) => async (dispatch) => {
  dispatch(setLoadingProcess("create_empty"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.post(
      SERVER_URL + "/blog/create_empty",
      { domain: domain },
      config_header
    );
    dispatch(clearLoading());
    his.push(`/blog_editor/${axios_response.data.link}`);
    dispatch({
      type: "CREATE_BLOG",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//1. Create blog form
export const createFormDoc = (body) => async (dispatch) => {
  dispatch(setLoadingDetail());
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const { geo_layer_id, title, layers, domain } = body;
    const res = await axios.get(
      SERVER_URL + `/blog/get_detail_by_layer_form/${geo_layer_id}/FORM`,
      config_header
    );
    if (res.data === null) {
      const body_new = {
        title,
        layers,
        document_type: "FORM",
        domain,
      };
      const res_create = await axios.post(
        SERVER_URL + "/blog/create_empty",
        body_new,
        config_header
      );
      his.push(`/blog_editor/${res_create.data.link}`);
      dispatch({
        type: "CREATE_BLOG",
        payload: res_create.data,
      });
    } else {
      his.push(`/blog_editor/${res.data.link}`);
    }
    dispatch(clearLoading());
    dispatch({
      type: "GET_BLOG_DETAIL",
      payload: res.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//2. Edit blog (old, will be replace)

//3. Edit blog non block editor
export const editBlogGeneral = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("edit_blog_general"));
  //handle layers, yang ditembak _id nya saja
  //yang di atur direducer layer lengkapnya
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/blog/edit_blog_general",
      body,
      config_header
    );
    if (res.status === 200) {
      const special_characters = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/g;
      let slug = body.title.replace(special_characters, "");
      slug = slug.toLowerCase().split(" ").join("-");
      window.history.replaceState(null, "", `/blog_editor/${slug}`);
    }
    dispatch({
      type: "EDIT_BLOG_GENERAL",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    alert("Title has already in another publications");
    dispatch(clearLoading());
  }
};

//4. Push layer to blog
export const pushLayer = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("push_layer"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/push_layer", body, config_header);
    dispatch({
      type: "PUSH_LAYER_TO_BLOG",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//5. Remove layer from blog
export const removeLayer = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("remove_layer"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/remove_layer", body, config_header);
    dispatch({
      type: "REMOVE_LAYER_FROM_BLOG",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//6. Push project to blog
export const pushProject = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("push_project"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/push_project", body, config_header);
    dispatch({
      type: "PUSH_PROJECT_TO_BLOG",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//7. Remove project from blog
export const removeProject = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("remove_project"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios
      .post(SERVER_URL + "/blog/remove_project", body, config_header)
      .then(() => {
        dispatch({
          type: "REMOVE_PROJECT_FROM_BLOG",
          payload: body,
        });
      });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//4. Edit blog block editor
export const editBlogEditor = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("edit_blog_editor"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + "/blog/edit_blog_editor",
      body,
      config_header
    );
    dispatch({
      type: "EDIT_BLOG_EDITOR",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//5. Vote / like blog
export const voteBlog = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("vote"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/vote", body, config_header);
    dispatch({
      type: "VOTE_BLOG",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//6. Delete blog
export const deleteBlog = (blog_id) => async (dispatch) => {
  dispatch(setLoadingProcess("delete_blog"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      SERVER_URL + `/blog/delete_new/${blog_id}`,
      config_header
    );
    dispatch({
      type: "DELETE_BLOG",
      payload: blog_id,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//7. Propose data exchange paid
export const proposePaid = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("propose_paid"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/propose_paid", body, config_header);
    dispatch({
      type: "PROPOSE_PAID_DE",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//8. Change price data exchange
export const changePrice = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("change_price"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/change_price", body, config_header);
    dispatch({
      type: "CHANGE_PRICE",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//9. Propose data exchange free
export const proposeFree = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("propose_free"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.post(
      SERVER_URL + "/blog/propose_free",
      body,
      config_header
    );
    dispatch({
      type: "PROPOSE_FREE",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//10. Set blog public
export const setPublic = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("set_public"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.post(
      SERVER_URL + "/blog/set_public",
      body,
      config_header
    );
    dispatch({
      type: "SET_PUBLIC",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//11. Set blog private
export const setPrivate = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("set_private"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.post(
      SERVER_URL + "/blog/set_private",
      body,
      config_header
    );
    dispatch({
      type: "SET_PRIVATE",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//12. Request free import
export const requestFreeImport = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("request_free_import"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + "/blog/request_free_import",
      body,
      config_header
    );
    dispatch({
      type: "REQUEST_FREE_IMPORT",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//13. Action (accepted/rejected) request free import
export const actionRequestFreeImport = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("action_request_free_import"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + "/blog/action_request_free_import",
      body,
      config_header
    );
    dispatch({
      type: "ACTION_REQUEST_FREE_IMPORT",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//14. Add to cart data exchange
export const addToCart = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("add_to_cart"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/add_to_cart", body, config_header);
    //jangan lupa blog di body adalah blog dengan detail)
    dispatch({
      type: "ADD_TO_CART",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//15. Delete cart
export const deleteCart = () => async (dispatch) => {
  dispatch(setLoadingProcess("delete_cart"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(SERVER_URL + "/blog/delete_cart", config_header);
    dispatch({
      type: "DELETE_CART",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//16. Delete blog from cart
export const deleteFromCart = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("delete_blog_from_cart"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + "/blog/delete_blog_from_cart",
      body,
      config_header
    );
    dispatch({
      type: "DELETE_FROM_CART",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//17. Add bank & generate price, snapshot blog yang dibeli
export const generatePrice = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("add_bank_generate_price"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/blog/add_bank_generate_price",
      body,
      config_header
    );
    dispatch({
      type: "GENERATE_PRICE",
      payload: res.data,
    });
    dispatch(clearLoading());
    his.push(`/payment/${res.data._id}`);
  } catch (e) {
    dispatch(clearLoading());
  }
};

//18. Cancel wait_purchase, delete or move to cart
export const cancelWaitPurchase = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("cancel_wait_purchase"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/blog/cancel_wait_purchase",
      body,
      config_header
    );
    const action = body.action;
    if (action === "move_to_cart") {
      dispatch({
        type: "MOVE_WAIT_PURCHASE_TO_CART",
        payload: res.data,
      });
    }
    dispatch(clearLoading());
    his.push(`/cart`);
  } catch (e) {
    dispatch(clearLoading());
  }
};

//19. Confirm payment wait_purchase to user_purchase
export const actionPayment = (item) => async (dispatch) => {
  try {
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.token_mapid,
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch(setLoadingProcess("add_bank_generate_price"));
    const body = item.body;
    const status = body.status;
    if (status === "user_purchase") {
      //status === "user_purchase"
      const { objParams } = item;
      const file = objParams.file;
      const file_name = objParams.file_name;
      //Compressed file big
      const options_compression = {
        maxSizeMB: 1.5,
        maxWidthOrHeight: 1500,
        useWebWorker: true,
      };
      const file_compres = await imageCompression(file, options_compression);
      // form data upload gambar
      const formData = new FormData();
      formData.append("original_file_name", `${file_name}`);
      formData.append("file", file_compres);
      const result_upload = await axios.post(
        SERVER_URL + "/upload",
        formData,
        config_header_upload
      );
      const body = item.body;
      const picture_url = result_upload.data.data.Location;
      body.picture_url = picture_url;
      const header_token = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.post(
        SERVER_URL + "/blogs/confirm_payment",
        body,
        header_token
      );
      dispatch({
        type: "BLOG_CONFIRM_PAYMENT",
        payload: res.data,
      });
      dispatch(clearLoading());
      his.push(`/user_purchase/${res.data._id}`);
    } else {
      //status === "user_cancel"
      const body = item.body;
      const header_token = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.post(
        SERVER_URL + "/blogs/confirm_payment",
        body,
        header_token
      );
      dispatch({
        type: "BLOG_CANCEL_PAYMENT",
        payload: res.date,
      });
      dispatch(clearLoading());
      his.push(`/`);
    }
  } catch (error) {
    dispatch(clearLoading());
  }
};

//20. Import layer
export const importLayer = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("import_layer"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/blog/import_layer", body, config_header);
    dispatch({
      type: "IMPORT_LAYER",
      payload: "success",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch({
      type: "IMPORT_LAYER",
      payload: "failed",
    });
    dispatch(clearLoading());
  }
};

/*GETY*/
//1. Get blog detail by link & author
export const getBlogDetail = (link) => async (dispatch) => {
  dispatch(setLoadingDetail());
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_detail_new/${link}`,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_BLOG_DETAIL",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//1. Get blog detail by link & author
export const getBlogDetailPersonal = (link) => async (dispatch) => {
  dispatch(setLoadingDetail());
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_detail_new/${link}`,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_BLOG_DETAIL_PERSONAL",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//2. Get blog detail by id layer form
export const getBlogDetailForm = (body) => async (dispatch) => {
  dispatch(setLoadingDetail());
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const { geo_layer_id } = body;
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_detail_by_layer_form/${geo_layer_id}/FORM`,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_BLOG_DETAIL",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//3. Get blog detail by id layer publication (untuk FORM MAPID)

//4. Get blog list by author
export const getBlogList = () => async (dispatch) => {
  dispatch(setLoadingList());
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_by_user`,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_BLOG_LIST_PERSONAL",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//5. Get blog list publish (OLD)

//6. Get blog list publish by domain

//7. Get blog detail public

//8. Get blog list public / accepted_paid / accepted_free
export const getBlogListPublic = (domain) => async (dispatch) => {
  dispatch(setLoadingProcess("get_list_data_exchange"));
  try {
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_list_data_exchange/${domain}`
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_BLOG_LIST",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//8.1 Get blog list popular / accepted_paid / accepted_free
export const getBlogListPopular = () => async (dispatch) => {
  dispatch(setLoadingProcess("get_list_data_exchange"));
  try {
    const axios_response = await axios.get(SERVER_URL + `/blog/most_popular`);
    dispatch(clearLoading());
    dispatch({
      type: "GET_BLOG_POPULAR",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//9. Get blog detail public / accepted_paid / accepted_free
export const getBlogDetailPublicNew = (link) => async (dispatch) => {
  dispatch(setLoadingProcess("get_detail_data_exchange"));
  try {
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_detail_data_exchange/${link}`
    );
    dispatch({
      type: "GET_BLOG_DETAIL",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//10. Get layer from project from blog
export const getLayerProjectBlog = (link) => async (dispatch) => {
  dispatch(setLoadingProcess("get_layer_project_blog"));
  try {
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_layer_project_blog/${link}`
    );
    dispatch({
      type: "GET_LAYER_BLOG",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//11. Migrate project inside blog
export const migrateBlog = (link) => async (dispatch) => {
  dispatch(setLoadingProcess("migrate_project_blog"));
  try {
    const axios_response = await axios.get(
      SERVER_URL + `/blog/migrate_project_blog/${link}`
    );
    dispatch({
      type: "GET_LAYER_BLOG",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//12. Get layer inside project new
export const getLayerProjectBlogNew = (link) => async (dispatch) => {
  dispatch(setLoadingProcess("get_layer_project_blog_new"));
  try {
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_layer_project_blog_new/${link}`
    );
    dispatch({
      type: "GET_LAYER_BLOG",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//13. Get cart data exchange
export const getCart = () => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_cart"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const axios_response = await axios.get(
      SERVER_URL + `/blog/get_cart`,
      config_header
    );
    dispatch({
      type: "GET_CART",
      payload: axios_response.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//14. Get wait_purchase data exchange
export const getWaitPurchase = (payment_id) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("get_wait_purchase"));
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/blog/get_wait_purchase/${payment_id}`,
      config_header
    );
    dispatch({
      type: "GET_WAIT_PURCHASE",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const postEditFAQ = (body) => async (dispatch) => {
  dispatch(setLoadingProcess("edit_blog_general"));
  try {
    const config_header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/blog/edit_blog_faq",
      body,
      config_header
    );
    console.log(res.data);
  } catch (error) {
    dispatch(clearLoading());
  }
};
