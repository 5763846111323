/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { commentToLayer, setChatStatus } from "../../App/actions/layerActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import uuid from "../../App/validation/uuid";

/*NON IMPORT*/
class ChatLayer extends Component {
  state = { text: "" };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.layer?.geo_layer_wo_geojson?.geo_layer?._id !==
      prevProps?.layer?.geo_layer_wo_geojson?.geo_layer?._id
    ) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  toggleChat = () => {
    this.props.setChatStatus(false);
  };

  //Local change
  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  //Submit
  handleSubmit = (e) => {
    e.preventDefault();
    const { geo_layer_wo_geojson } = this.props.layer;
    const { user } = this.props.auth;
    const { _id, name, full_name, profile_picture } = user;
    const { text } = this.state;
    const geo_layer_id = geo_layer_wo_geojson?.geo_layer?._id;
    const comment = {
      text: text,
      key: uuid(),
      isEdited: false,
      images: [],
      user_tag: [],
      user: {
        _id,
        name,
        full_name,
        profile_picture,
      },
    };
    const body = {
      geo_layer_id: geo_layer_id,
      comment,
    };
    this.props.commentToLayer(body);
    this.setState({ text: "" });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { text } = this.state;

    //global props
    const { loadingProcess, itemLoading, geo_layer_wo_geojson } =
      this.props.layer;
    const { user } = this.props.auth;

    //content
    const user_id = user._id;
    const geo_layer = geo_layer_wo_geojson?.geo_layer;
    const name = geo_layer?.name;
    const comments = geo_layer?.comments ? geo_layer?.comments : [];

    let button_content;
    if (loadingProcess && itemLoading === "commentToLayer") {
      button_content = (
        <div className="button">{dict["Submit"][language]}...</div>
      );
    } else {
      button_content = (
        <button type="submit" className="button">
          {dict["Submit"][language]}
        </button>
      );
    }

    const comments_content = (
      <main style={{ marginBottom: "300px" }}>
        {comments.map(({ user, text }, idx) => {
          const { name, full_name, _id } = user;
          const name_string = full_name ? full_name : name;
          const right_or_left = _id === user_id ? "chat_right" : "chat_left";
          const backgroundColor = _id === user_id ? "chat_user" : "chat_other";
          const colorUserName =
            _id === user_id ? "chat_user_color" : "chat_other_color";
          return (
            <div className={right_or_left} key={idx}>
              <div className={`chat_bubble ${backgroundColor}`}>
                <div className={`chat_name ${colorUserName}`}>
                  {name_string}
                </div>
                <div className="chat_text">{text}</div>
              </div>
            </div>
          );
        })}
      </main>
    );

    return (
      <main className="chat_main">
        <section className="chat_title">
          <div>
            <button
              className="button_very_small h_30 w_30"
              id="deleteBright"
              onClick={this.toggleChat}
            >
              X
            </button>
          </div>
          <div className="h_35 scroll_hidden">
            <label title={name} className="h_full">
              {dict["Notes"][language]}: {name}
            </label>
          </div>
        </section>
        <section className="chat_conversation">
          {comments_content}
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          ></div>
        </section>
        <section className="chat_writing">
          <form onSubmit={this.handleSubmit.bind(this)}>
            <textarea
              type="text"
              name="text"
              id="text"
              value={text}
              onChange={this.handleChange}
              rows="4"
            />
            {button_content}
          </form>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  commentToLayer,
  setChatStatus,
})(ChatLayer);
