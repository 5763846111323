import axios from "axios";
// import { geoServerBaseUrl } from "./baseUrl";

// const SERVER_URL = geoServerBaseUrl;
const SERVER_URL = "https://alphaserver.mapid.io";

/*GET Methods*/

/**
 * @name : get_api_keys
 * @endpoint : /basemap/key
 * @method : GET
 */
export const get_api_keys = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(SERVER_URL + "/basemap/key", config);
    dispatch({
      type: "set_value_map_service",
      payload: {
        key: "api_keys",
        value: res.data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @name : get_basemap_styles
 * @endpoint : /basemap/styles
 * @method : GET
 * @query : key (API key)
 */
export const get_basemap_styles = (key) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
      params: {
        key,
      },
    };
    const res = await axios.get(SERVER_URL + "/basemap/styles", config);
    dispatch({
      type: "set_value_map_service",
      payload: {
        key: "basemap_styles",
        value: res.data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/*POST Methods*/

/**
 * @name : create_api_key
 * @endpoint : /basemap/key
 * @method : POST
 * @body : { name, description }
 */
export const create_api_key = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(SERVER_URL + "/basemap/key", body, config);
    // Add the new API key to the state
    dispatch({
      type: "push_value_map_service",
      payload: {
        key: "api_keys",
        value: res.data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @name : get_monthly_report
 * @endpoint : /basemap/log/month
 * @method : POST
 * @body : { api_key, month, type }
 */
export const get_monthly_report = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/basemap/log/month",
      body,
      config
    );
    dispatch({
      type: "set_value_map_service",
      payload: {
        key: "monthly_report",
        value: res.data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @name : get_report_by_date_range
 * @endpoint : /basemap/log/report
 * @method : POST
 * @body : { api_key, date, date_end, type }
 */
export const get_report_by_date_range = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/basemap/log/report",
      body,
      config
    );
    dispatch({
      type: "set_value_map_service",
      payload: {
        key: "report_by_date_range",
        value: res.data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @name : get_daily_report_detail
 * @endpoint : /basemap/log/report
 * @method : POST
 * @body : { api_key, date, type }
 */
export const get_daily_report_detail = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/basemap/log/report",
      body,
      config
    );
    dispatch({
      type: "set_value_map_service",
      payload: {
        key: "daily_report_detail",
        value: res.data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/*DELETE Methods*/

/**
 * @name : delete_api_key
 * @endpoint : /basemap/key
 * @method : DELETE
 * @query : _id (API key ID)
 */
export const delete_api_key = (_id) => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
      params: {
        _id,
      },
    };
    await axios.delete(SERVER_URL + "/basemap/key", config);

    // Remove the API key from the state
    // const { api_keys } = getState().mapServiceReducer;
    dispatch({
      type: "pull_value_map_service_object",
      payload: {
        key: "api_keys",
        value: _id,
        id: "_id", // Assuming `_id` is the key identifier for API keys
      },
    });
  } catch (e) {
    console.error(e);
  }
};


/**
 * @name : get_users_who_can_access_map_service
 * @endpoint : basemap/users
 * @method : GET
 * @body : { }
 */
export const get_users_who_can_access_map_service = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      // SERVER_URL + "/basemap/log/report",
      "https://alphaserver.mapid.io/basemap/users",
      config
    );
    dispatch({
      type: "set_value_map_service",
      payload: {
        key: "users_who_can_access_map_service",
        value: res.data,
      },
    });
  } catch (e) {
    console.error(e);
  }
};

/* SET VALUE METHODS */

/**
 * @name : set_value_map_service
 * @param : { key, value }
 * @desc : Set a specific value in the map service state
 */
export const set_value_map_service = (payload) => (dispatch) => {
  dispatch({
    type: "set_value_map_service",
    payload,
  });
};