/* LIBRARY MODULE */
import React, { Component } from "react";
import { connect } from "react-redux";

/* REDUX FUNCTION */
import { set_value_layer } from "../../App/actions/layerActions";

/* NON IMPORT */
const heatmap_paint = {
  // Increase the heatmap weight based on frequency and property magnitude
  "heatmap-weight": [
    "interpolate",
    ["linear"],
    ["get", "density"], // Replace 'density' with the appropriate property in your data
    0,
    0,
    6,
    1,
  ],
  // Increase the heatmap intensity based on zoom level
  "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
  // Adjust the heatmap color for zoom levels, denser = red, less dense = green
  "heatmap-color": [
    "interpolate",
    ["linear"],
    ["heatmap-density"],
    0,
    "rgba(0,107,61,0)", // Transparent for very low density
    0.2,
    "rgb(0,107,61)", // Green (Low density)
    0.4,
    "rgb(128,181,30)", // Transition between Green and Yellow
    0.6,
    "rgb(255,255,0)", // Yellow (Moderate density)
    0.8,
    "rgb(246,137,11)", // Transition between Yellow and Red
    1,
    "rgb(237,19,22)", // Red (High density)
  ],
  // Adjust the heatmap radius by zoom level
  "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 20],
  // Adjust the heatmap opacity by zoom level
  "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0],
};

class LAYER_HEATMAP extends Component {
  componentDidUpdate(prevProps) {
    const { status_action } = this.props.map;
    if (status_action !== prevProps.map.status_action) {
      this.on_render_layer_list();
    }
  }

  on_render_layer_list = () => {
    const { map_object, geo_layer_list, layer_id_heatmap } = this.props.layer;
    const layer_list_heatmap = geo_layer_list.filter((item) =>
      layer_id_heatmap.includes(item?.geo_layer?._id)
    );

    if (map_object) {
      layer_list_heatmap.forEach((layer) => {
        const layer_id_heatmap = `heatmap_${layer?.geo_layer?._id}`;
        const features_filtered =
          layer?.geo_layer?.geojson_filtered?.features || [];
        const geojson = {
          type: "FeatureCollection",
          features: features_filtered,
        };
        if (!map_object.getSource(layer_id_heatmap)) {
          map_object.addSource(layer_id_heatmap, {
            type: "geojson",
            data: geojson,
          });
        }
        if (!map_object.getLayer(layer_id_heatmap)) {
          map_object.addLayer({
            id: layer_id_heatmap,
            source: layer_id_heatmap,
            type: "heatmap",
            paint: heatmap_paint,
          });
        }
      });
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
});

export default connect(mapStateToProps, { set_value_layer })(LAYER_HEATMAP);
