/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import PaymentTable from "../payment/PaymentTable";
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/
import icon_product from "../../Assets/png_jpg/icon_product.png";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class NO_LICENSE extends Component {
  state = {
    modal_payment_table: false,
  };

  toggle_payment_table = () => {
    this.setState({
      modal_payment_table: !this.state.modal_payment_table,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modal_payment_table } = this.state;

    //global props

    //content
    const modal_payment_table_content = modal_payment_table && (
      <Modal
        modalSize="large"
        isOpen={modal_payment_table}
        onClose={this.toggle_payment_table}
      >
        <div className="box-body" id="box-body">
          <div className="marginBottom_20">
            <label className="font_30 bold">
              {dict["Best license price for you"][language]}
            </label>
            <p>
              {
                dict[
                  "Get features at the best price on the market! Limited timeoffer to boost your productivity."
                ][language]
              }
            </p>
          </div>
          <PaymentTable />
        </div>
      </Modal>
    );

    return (
      <main className="main_container">
        {modal_payment_table_content}
        <section className="container_light background_white">
          <img src={icon_product} alt="MAPID PRODUCT" width="100" />
          <h1>Fitur tidak dimuat</h1>
          <p>Beli lisensi untuk mengakses fitur ini</p>
          <br />
          <br />
          <br />
          <button
            className="button background_green"
            onClick={this.toggle_payment_table}
          >
            Beli lisensi
          </button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(NO_LICENSE);
