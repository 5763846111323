//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { reject_feature } from "../../App/actions/layerNewActions";

//Picture Asset

//General Function

class ModalReject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      fields: [],
      data: {},
    };
  }

  componentDidMount() {
    const { layer_id, geo_layer_list } = this.props.layer;
    const { modal_data } = this.props;

    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;

    let fields = geo_layer.fields;

    let features = geo_layer?.geojson?.features
      ? geo_layer?.geojson?.features
      : [];

    let data = features.find((d) => d.key === modal_data.key).properties;

    fields = fields.map((d) => {
      let find_id = this.props.modal_data?.formStatus?.revision_list?.find(
        (id) => id?.field_uuid === d.uuid
      );
      return {
        ...d,
        rev: d?.uuid === find_id?.field_uuid ? true : false,
        message: d?.uuid === find_id?.field_uuid ? find_id?.message : "",
      };
    });

    this.setState({
      fields,
      data,
    });
  }

  on_change_inside = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const id = e.target.id;
    const value = e.target.value;
    if (name === "message_rev") {
      let { fields } = this.state;
      const index = fields.findIndex((item) => item.uuid === id);
      fields[index].message = value;
      this.setState({ fields });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  toggle_rev_field = (uuid) => {
    let { fields } = this.state;
    const index = fields.findIndex((f) => f.uuid === uuid);
    fields[index].rev = !fields[index].rev;
    this.setState({ fields });
  };

  on_save_rev_satu = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    let { fields } = this.state;
    const { modal_data } = this.props;

    fields = fields.filter((item) => !item.isStyle);

    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;

    let features = geo_layer?.geojson?.features
      ? geo_layer?.geojson?.features
      : [];

    const feature_key = features.find((f) => f.key === modal_data.key).key;

    const revision_list = fields
      .filter((f) => f.rev === true)
      .map((f) => {
        return { field_uuid: f.uuid, message: f.message ? f.message : "" };
      });

    const body = {
      geo_layer_id: layer_id,
      feature_key,
      revision_list,
    };

    this.props.reject_feature(body);
    this.props.toggleReject();
  };

  render() {
    let { fields, data } = this.state;
    fields = fields.filter(
      (f) => !f.parent_uuid && f.type !== "section" && !f.isStyle
    );
    return (
      <div className="modal_reject">
        <h2 style={{ fontWeight: "600" }}>
          Pilih pertanyaan & pesan revisi, hanya pertanyaan yang pilih saja yang
          akan muncul di form survei revisi
        </h2>
        <div className="modal_reject_row">
          {fields.map((d, index) => {
            return (
              <div className="kotak_revisi" key={index}>
                <h2>{index + 1}</h2>
                <div style={{ width: "50%" }}>
                  <h3>{d.name}</h3>
                  <p>{data[d.key]}</p>
                  <button
                    className="button_small"
                    id={d.rev ? "red" : "green"}
                    onClick={this.toggle_rev_field.bind(this, d.uuid)}
                  >
                    {d.rev ? "batal" : "revisi"}
                  </button>
                </div>
                {d.rev ? (
                  <textarea
                    className="input_new"
                    name="message_rev"
                    placeholder="Masukan catatan disini!"
                    id={d.uuid}
                    value={d.message}
                    onChange={this.on_change_inside}
                    type="text"
                  />
                ) : null}
              </div>
            );
          })}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button className="button" id="green" onClick={this.on_save_rev_satu}>
            Ajukan Revisi
          </button>
          <div
            className="button"
            id="red"
            onClick={(e) => this.props.toggleReject(e)}
          >
            Batalkan Revisi
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  reject_feature,
})(ModalReject);
