import React, { useState } from "react";

const NavTitleBlog = ({ data }) => {
  const [title, setTitle] = useState(false);

  const navTitle = () => {
    if (window.scrollY >= 300) {
      setTitle(true);
    } else {
      setTitle(false);
    }
  };

  window.addEventListener("scroll", navTitle);
  const language = localStorage?.language ? localStorage?.language : "ina";

  return (
    <div
      className="row_blog_title"
      title={language === "ina" ? data?.title : data?.title_en}
    >
      <h1 className={title ? "title_blog_nav" : "title_blog_nav_hide"}>
        {language === "ina" ? data?.title : data?.title_en}
      </h1>
    </div>
  );
};

export default NavTitleBlog;
