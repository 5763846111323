/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
// import EDIT_LAYER_STYLE_1 from "./EDIT_LAYER_STYLE_1";
// import EDIT_LAYER_STYLE_2 from "./EDIT_LAYER_STYLE_2";
// import EDIT_LAYER_STYLE_3 from "./EDIT_LAYER_STYLE_3";

/*REDUX FUNCTION*/
import { getDetailLayerById } from "../../App/actions/layerNewActions";
import {
  set_value_properties,
  pull_style_array,
  set_is_cluster,
  set_default_style_key,
} from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/
import cluster_on from "../../Assets/png_jpg/cluster_on.png";
import cluster_off from "../../Assets/png_jpg/cluster_off.png";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class EDIT_SYMBOL_ICON extends Component {
  state = {
    layer_type: "",
    fields_style: [],
    features_length: 0,
    style_array: [],
    style_key_clicked: "",
    anchor: null,
    modal_menu: false,
    modal_delete: false,
  };

  componentDidMount() {
    this.on_aggregate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.map.status_action !== prevProps.map.status_action) {
      this.on_aggregate();
    }
  }

  on_set_default_style_key = () => {
    const { style_key_clicked } = this.state;
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      style_key: style_key_clicked,
    };
    this.props.set_default_style_key(body);
    this.setState({ modal_menu: false });
  };

  on_set_is_cluster = (is_cluster) => {
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      is_cluster: is_cluster,
    };
    this.props.set_is_cluster(body);
  };

  toggle_menu = (e) => {
    this.setState({ modal_menu: !this.state.modal_menu });
    if (e) {
      const name = e.target.name;
      this.setState({ anchor: e.currentTarget, style_key_clicked: name });
    }
  };

  on_edit = () => {
    this.setState({
      modal_menu: false,
    });
    const { style_array } = this.props.properties;
    const { style_key_clicked } = this.state;
    const style_object = style_array.find(
      (item) => item.key === style_key_clicked
    );
    const paint_object = style_object?.paint_object || {};
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: paint_object,
    });
    this.props.set_value_properties({
      key: "style_parameter",
      value: style_object,
    });
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 3,
    });
  };

  toggle_is_preview_on = () => {
    this.props.set_value_properties({
      key: "is_preview_on",
      value: !this.props.properties.is_preview_on,
    });
    this.props.status_action();
  };

  toggle_delete = () => {
    this.setState({
      modal_delete: !this.state.modal_delete,
      modal_menu: false,
    });
  };

  on_pull_style_array = () => {
    const { style_key_clicked } = this.state;
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      style_key: style_key_clicked,
    };
    this.props.pull_style_array(body);
    this.setState({ modal_delete: false });
  };

  on_back = () => {
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 0,
    });
  };

  on_next_step = () => {
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 1,
    });
    this.props.set_value_properties({
      key: "style_parameter",
      value: {
        paint_object: {},
        key: "",
        field_key: "",
        case_recomendation: "",
        color_logic: "",
        color_mode: "",
        value_array: [],
        type_of_object: {},
        type_of_array: [],
        min: 0,
        max: 0,
        steps: 5,
        is_number_count: [],
        constant_color: "#a0d062",
        style_type: "",
        is_cluster: false,
      },
    });
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: {},
    });
  };

  on_aggregate = () => {
    const { geo_project } = this.props.project;
    const { geo_layer_list, layer_id } = this.props.layer;
    const geo_layer =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_id)
        ?.geo_layer || {};
    const layer_type = geo_layer?.type;
    let style_type;
    if (["Point", "MultiPoint"].includes(layer_type)) {
      style_type = "circle";
    } else if (["LineString", "MultiLineString"].includes(layer_type)) {
      style_type = "line";
    } else if (["Polygon", "MultiPolygon"].includes(layer_type)) {
      style_type = "fill";
    }
    const features = geo_layer?.geojson?.features || [];
    const style_array = geo_layer?.style_array || [];
    const default_style_key = geo_layer?.default_style_key;
    const is_cluster = geo_layer?.is_cluster || false;
    let fields = geo_layer?.fields || [];
    if (features.length === 0) {
      const body = {
        geo_layer_id: geo_layer._id,
        geo_project_id: geo_project._id,
        flyStatus: true,
        folder_id: "",
        capex_value: this.props.properties.capex_value,
        geo_layer_item: geo_layer,
      };
      this.props.getDetailLayerById(body);
    } else {
      /*
      yang perlu di agregat untuk semua field:
      min: Number, agregat angka minimal untuk rekomendasi range color tipe angka
      max: Number, agregat angka maksimak untuk rekomendasi range color tipe angka
      value_array: Array, agregat semua value untuk rekomendasi color style tipe teks
      type_of_object: Object, untuk melihat presentase tipe data apakah cenderung ke angka atau teks,
                      juga untuk memberikan peringatan jika ada data campuran ada kemungkinan style tidak berjalan semestinya
                      nextnya bisa memberikan rekomendasi untuk penggantian tipe field dan juga update many feature properties untuk menyamakan semua tipe data (biasa konversi dari teks ke angka)
                      misal value= "5000" menjadi 5000
      */
      fields = fields.map((field) => {
        let value_array = [];
        let type_of_object = {};
        let type_of_array = [];
        let min;
        let max;
        let is_number_count = 0;
        features.forEach((feature, idx) => {
          const value = feature?.properties[field.key];
          if (!isNaN(value)) is_number_count = is_number_count + 1;
          let type_of = typeof value;
          function is_color(e) {
            const temporary_div = document.createElement("div");
            temporary_div.style.backgroundColor = e;
            return temporary_div.style.backgroundColor !== "";
          }
          const is_color_value = is_color(value);
          if (is_color_value) type_of = "color";
          let number = Number(value);
          number = isNaN(number) ? 0 : number;
          if (idx === 0) {
            min = number;
            max = number;
          } else {
            if (number < min) min = number;
            if (number > max) max = number;
          }
          if (!value_array?.includes(value)) value_array.push(value);
          let type_of_number = type_of_object?.[type_of] || 0;
          type_of_number = type_of_number + 1;
          type_of_object[type_of] = type_of_number;
        });
        for (const key in type_of_object) {
          type_of_array.push({ key, value: type_of_object[key] });
        }
        //generate logic
        let case_recomendation = "";
        let color_logic = "";
        let color_mode = "";
        if (
          type_of_array?.length === 1 &&
          type_of_array?.[0]?.key === "string" &&
          is_number_count === 0
        ) {
          //kasus text
          case_recomendation = "text";
          color_logic = "by_text";
          color_mode = "random";
        } else if (
          type_of_array?.length === 1 &&
          type_of_array?.[0]?.key === "color" &&
          is_number_count === 0
        ) {
          //kasus color
          case_recomendation = "color";
          color_logic = "use_existing";
          color_mode = "random";
        } else if (
          type_of_array?.length === 1 &&
          type_of_array?.[0]?.key === "number"
        ) {
          //kasus number
          case_recomendation = "number";
          color_logic = "by_number_step";
          color_mode = "light_red_to_dark_red";
        } else if (
          type_of_array?.length === 1 &&
          type_of_array?.[0]?.key === "string" &&
          is_number_count > 0
        ) {
          //kasus angka tapi di dalam format teks
          case_recomendation = "number_in_text";
          color_logic = "by_number_step";
          color_mode = "light_red_to_dark_red";
        } else if (type_of_array?.length > 1) {
          //kasus angka dan teks tercampur
          case_recomendation = "number_and_text";
          color_logic = "by_text";
          color_mode = "random";
        } else {
          //kasus lain yang belum terhandle
          case_recomendation = "unknown";
          color_logic = "by_text";
          color_mode = "random";
        }
        if (min === 0 && max === 0) {
          min = 0;
          max = 100;
        }
        return {
          ...field,
          field_key: field?.key,
          case_recomendation,
          color_logic,
          color_mode,
          value_array,
          type_of_object,
          type_of_array,
          min,
          max,
          steps: 5,
          is_number_count,
          constant_color: "#a0d062",
          style_type,
          is_cluster: false,
        };
      });
    }
    const fields_style = fields.filter((item) => !!item.isStyle);
    fields = fields.filter((item) => !item.isStyle);
    const features_length = features?.length || 0;
    this.props.set_value_properties({
      key: "style_array",
      value: style_array,
    });
    this.props.set_value_properties({
      key: "fields_edit_style",
      value: fields,
    });
    this.props.set_value_properties({
      key: "fields_style_true",
      value: fields_style,
    });
    this.props.set_value_properties({
      key: "features_length",
      value: features_length,
    });
    this.props.set_value_properties({
      key: "layer_type",
      value: layer_type,
    });
    this.props.set_value_properties({
      key: "default_style_key",
      value: default_style_key,
    });
    this.props.set_value_properties({
      key: "is_cluster",
      value: is_cluster,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_menu, modal_delete, anchor } = this.state;

    //global props
    const { layer_id } = this.props.layer;
    const {
      layer_style_step,
      layer_type,
      style_array,
      fields_edit_style,
      default_style_key,
      is_preview_on,
      is_cluster,
    } = this.props.properties;

    //content
    let content;
    if (!layer_id) {
      content = (
        <main>
          <p className="text_medium margin_bottom">
            {dict?.["Have not selected a layer yet"]?.[language]}
          </p>
        </main>
      );
    } else if (style_array.length === 0) {
      content = (
        <main>
          <p className="text_medium margin_bottom">
            {dict?.["No styles saved yet"]?.[language]}
          </p>
        </main>
      );
    } else {
      content = (
        <main className="container_light">
          <h1 className="text_bold margin_bottom">
            {dict?.["Saved styles:"]?.[language]}
          </h1>
          <table className="table full_width margin_bottom">
            <tbody>
              <tr>
                <td className="margin_bottom">
                  {dict?.["Classification column name"]?.[language]}
                </td>
                <td className="margin_bottom">
                  {
                    dict?.["Thematic classification logic & color scheme"]?.[
                      language
                    ]
                  }
                </td>
                <td className="margin_bottom">
                  {dict?.["Action"]?.[language]}
                </td>
              </tr>
            </tbody>
            <tbody className="margin_top">
              {style_array.map((item, idx) => {
                const { key, color_logic, color_mode, field_key } = item;
                const field = fields_edit_style.find(
                  (element) => element.key === field_key
                );
                return (
                  <tr key={idx}>
                    <td>
                      <p className="text_bold">{field?.name}</p>
                      {key === default_style_key && (
                        <>
                          <div className="badge_small background_green">
                            Default
                          </div>
                        </>
                      )}
                    </td>
                    <td>
                      {color_logic}
                      <br />
                      {color_mode}
                    </td>
                    <td>
                      <button
                        className="button background_grey"
                        style={{ width: "30px" }}
                        onClick={this.toggle_menu}
                        name={key}
                      >
                        ⫶
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      );
    }
    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "0px 10px 0px 10px",
          }}
        >
          <button className="button button_white" onClick={this.on_edit}>
            {dict?.["Edit"]?.[language]}
          </button>
          <br />
          <button className="button button_white" onClick={this.toggle_delete}>
            {dict?.["Delete"]?.[language]}
          </button>
          <br />
          <button
            className="button button_white"
            onClick={this.on_set_default_style_key}
          >
            {dict?.["Set as default"]?.[language]}
          </button>
        </div>
      </Menu>
    );
    const modal_delete_content = modal_delete && (
      <Modal
        modalSize="small"
        isOpen={modal_delete}
        onClose={this.toggle_delete}
      >
        <div className="box-body" id="box-body">
          <h1 className="text_bold margin_bottom">
            {dict?.["Confirm delete style"]?.[language]}
          </h1>
          <br />
          <button
            className="button background_red"
            onClick={this.on_pull_style_array}
          >
            {dict?.["Delete"]?.[language]}
          </button>
        </div>
      </Modal>
    );
    return (
      <main style={{ maxHeight: "calc( 100vh - 100px )", overflowY: "auto" }}>
        {modal_menu_content}
        {modal_delete_content}
        <section className="text_center">
          <button
            className="badge_small background_black margin_bottom margin_top margin_right"
            onClick={this.on_back}
          >
            {dict?.["Back"]?.[language]}
          </button>
          <p className="badge_small background_black margin_bottom margin_top margin_right">
            Tipe: {layer_type}
          </p>
          <button
            className={`badge_small margin_bottom margin_top margin_right ${
              is_preview_on ? "background_green" : "background_grey"
            }`}
            onClick={this.toggle_is_preview_on}
          >
            Preview: {is_preview_on ? "On" : "Off"}
          </button>
        </section>
        <section className="container_light background_white outline_grey margin_bottom">
          {layer_style_step === 0 && (
            <>
              <div className="text_center">
                <button
                  onClick={this.on_next_step}
                  className="button_pill background_green"
                >
                  <span className="badge_circle background_white">+</span>{" "}
                  {dict?.["Create a new style"]?.[language]}
                </button>
              </div>
              {["Point", "MultiPoint"].includes(layer_type) && (
                <div className="margin_top">
                  <br />
                  <p className="text_medium margin_bottom">
                    {dict?.["Select cluster mode:"]?.[language]}
                  </p>
                  <section
                    className="two_container_equal"
                    style={{ height: "100px" }}
                  >
                    <div
                      className={`text_center ${
                        !is_cluster ? "outline_black" : "button_white"
                      }`}
                      onClick={this.on_set_is_cluster.bind(this, false)}
                    >
                      <p className="margin_bottom">
                        {dict?.["Turn off cluster"]?.[language]}
                      </p>
                      <img src={cluster_off} alt="Cluster off" width="75px" />
                    </div>
                    <div
                      className={`text_center ${
                        is_cluster ? "outline_black" : "button_white"
                      }`}
                      onClick={this.on_set_is_cluster.bind(this, true)}
                    >
                      <p className="margin_bottom">
                        {dict?.["Turn on cluster"]?.[language]}
                      </p>
                      <img src={cluster_on} alt="Cluster on" width="75px" />
                    </div>
                  </section>
                </div>
              )}
            </>
          )}
          {/* {layer_style_step >= 1 && <EDIT_LAYER_STYLE_1 />}
          {layer_style_step >= 2 && <EDIT_LAYER_STYLE_2 />}
          {layer_style_step >= 3 && <EDIT_LAYER_STYLE_3 />} */}
        </section>
        {layer_style_step === 0 && content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  auth: state.auth,
  layer: state.layer,
  loading_reducer: state.loading_reducer,
  project: state.project,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  getDetailLayerById,
  set_value_properties,
  pull_style_array,
  status_action,
  set_is_cluster,
  set_default_style_key,
})(EDIT_SYMBOL_ICON);
