/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_properties,
  pull_value_properties_string,
  push_value_properties,
} from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import checklist from "../../Assets/svg/checklist.svg";
import checklist_grey from "../../Assets/svg/checklist_grey.svg";

/*GENERAL FUNCTION & DATA*/
import demographic_theme from "../../Data/demographic_theme.json";

/*NON IMPORT*/

class InputDemographic extends Component {
  state = {};

  on_change = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.props.set_value_properties({
      key: name,
      value: value,
    });

    if (name === "demographic_parent") {
      if (value === "UMUM") {
        this.props.set_value_properties({
          key: "demographic_child_idx_list",
          value: [0],
        });
      } else {
        this.props.set_value_properties({
          key: "demographic_child_idx_list",
          value: [],
        });
      }
    }
  };

  toggle_child = (idx) => {
    const { demographic_child_idx_list } = this.props.properties;
    if (demographic_child_idx_list.includes(idx)) {
      this.props.pull_value_properties_string({
        key: "demographic_child_idx_list",
        value: idx,
      });
    } else {
      this.props.push_value_properties({
        key: "demographic_child_idx_list",
        value: idx,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { demographic_parent, demographic_child_idx_list } =
      this.props.properties;

    //content
    let demographic_theme_child = [];
    if (demographic_parent) {
      let string_key = "";
      if (language === "ina") {
        string_key = "list";
      } else {
        string_key = "list_eng";
      }
      demographic_theme_child = demographic_theme.find(
        (item) => item.key === demographic_parent
      )[string_key];
    }

    return (
      <main>
        <p className="text_bold">Pilih tema demografi</p>
        <p className="text_small">Pilih tema</p>
        <select
          name="demographic_parent"
          value={demographic_parent}
          onChange={this.on_change}
          data-mapid="selectParameter"
        >
          {demographic_theme.map((item, idx) => {
            return (
              <option key={idx} value={item.key}>
                {item[language]}
              </option>
            );
          })}
        </select>
        {demographic_parent && (
          <>
            <p className="text_small">Pilih parameter</p>
            <table>
              <tbody>
                {demographic_theme_child.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td
                        onClick={this.toggle_child.bind(this, idx)}
                        className="inline"
                      >
                        <img
                          className="cursor_pointer margin_right"
                          width="17px"
                          alt={item}
                          src={
                            demographic_child_idx_list.includes(idx)
                              ? checklist
                              : checklist_grey
                          }
                          data-mapid={`selectKelamin-${idx}`}
                        />
                      </td>
                      <td>{item}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  pull_value_properties_string,
  push_value_properties,
})(InputDemographic);
