/*eslint-disable*/
import React, { Component } from "react";
import SetupTableC3RingkasanHasilPenilaian from "./SetupTableC3RingkasanHasilPenilaian";
import DropdownOrData from "../DropdownOrData";

export default class C3_RingkasanHasilPenilaian extends Component {
  render() {
    const { mode } = this.props;

    return (
      <>
        {mode === "setup" && (
          <SetupTableC3RingkasanHasilPenilaian
            id="c3_setup_ringkasan_hasil_penilaian_pdf"
            {...this.props}
          />
        )}
        <table
          id="c3_table_ringkasan_hasil_penilaian_pdf"
          className="table_pdf side_border"
        >
          <thead>
            <tr className="">
              <td colSpan={7}>
                <p className="subtitle_pdf">II. RINGKASAN HASIL PENILAIAN</p>
              </td>
            </tr>
            <tr className="">
              <td colSpan={7}>
                <p className="subtitle_pdf">Nilai Pasar Sesuai IMB</p>
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>
                <p>No.</p>
              </td>
              <td rowSpan={2}>
                <p>Object</p>
              </td>
              <td rowSpan={2}>
                <p>Luas (m²)</p>
              </td>
              <td colSpan={2}>
                <p>Nilai Pasar</p>
              </td>
              <td colSpan={2}>
                <p>Indikasi Nilai Likuidasi</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Nilai/m² (Rp.)</p>
              </td>
              <td>
                <p>Total (Rp.)</p>
              </td>
              <td>
                <p>Nilai/m² (Rp.)</p>
              </td>
              <td>
                <p>Total (Rp.)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>-</p>
              </td>
              <td>
                <p className="text_left">Tanah</p>
                {/* <DropdownOrData
                  id="object_tanah_imb_pdf"
                  className="highlight"
                  {...this.props}
                /> */}
              </td>
              <td>
                <DropdownOrData
                  id="c3_luas_tanah_imb_pdf"
                  className=" text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c3_nilai_m2_rp_tanah_imb_pdf"
                  className=" text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c3_total_rp_tanah_imb_pdf"
                  className=" text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c3_nilai_m2_rp_tanah_likuidasi_imb_pdf"
                  className=" text_right"
                  {...this.props}
                />
              </td>
              <td>
                <DropdownOrData
                  id="c3_total_rp_tanah_likuidasi_imb_pdf"
                  className=" text_right"
                  {...this.props}
                />
              </td>
            </tr>
          </thead>
        </table>
      </>
    );
  }
}
