import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*NON API*/

/*GET METHODE*/

/**
 * @name : verify_license_user
 * @endpoint : /license/verify_license_user
 * @methode : get
 */
export const verify_license_user = () => async (dispatch) => {
  try {
    if (localStorage.token_mapid) {
      dispatch({
        type: "set_loading_action",
        payload: "verify_license_user",
      });
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL + `/license/verify_license_user`,
        config
      );
      dispatch({
        type: "set_value_license",
        payload: {
          key: "license_user_status",
          value: res.data,
        },
      });
      dispatch({
        type: "clear_loading_action",
        payload: "verify_license_user",
      });
    }
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "verify_license_user",
    });
  }
};

/**
 * @name : verify_license_group
 * @endpoint : /license/verify_license_group
 * @methode : get
 */
export const verify_license_group = () => async (dispatch) => {
  try {
    if (localStorage.token_mapid) {
      dispatch({
        type: "set_loading_action",
        payload: "verify_license_group",
      });
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL + `/license/verify_license_group`,
        config
      );
      dispatch({
        type: "set_value_license",
        payload: {
          key: "license_group_status",
          value: res.data,
        },
      });
      dispatch({
        type: "clear_loading_action",
        payload: "verify_license_group",
      });
    }
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "verify_license_group",
    });
  }
};

/*POST METHODE*/

/**
 * @name : create_group_family
 * @endpoint : /groups_new/create_group_family
 * @methode : post
 */
export const create_group_family = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "create_group_family",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + `/groups_new/create_group_family`,
      body,
      config
    );
    dispatch({
      type: "set_value_license",
      payload: {
        key: "group_family",
        value: res.data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "create_group_family",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "create_group_family",
    });
  }
};
