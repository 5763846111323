/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SINI_SURVEY_1_TABLE extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const poi_survey_1_features = sini_object?.poi_survey_1_features || [];
    const { manifest_survey_1 } = sini_object;
    let fields = manifest_survey_1?.fields || [];
    fields = fields.filter((field) => !field.isStyle && !field.isHide);
    return (
      <main>
        <h1 className="text_bold text_center">Detail Hasil Survei</h1>
        <br />
        <section
          style={{ overflowX: "auto", maxHeight: "calc( 80vh - 115px )" }}
        >
          <table className="table_lite">
            <thead>
              <tr>
                <th>No.</th>
                {fields.map((field, idx) => {
                  return (
                    <th key={idx} className="text_left">
                      <div className="badge_small background_orange">
                        {field?.type}
                      </div>
                      <br />
                      <p>{field?.name}</p>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {poi_survey_1_features.map((feature, idx_feature) => {
                return (
                  <tr key={idx_feature}>
                    <th>{idx_feature + 1}</th>
                    {fields.map((field, idx_field) => {
                      const value = feature?.properties?.[field?.key];
                      let content;
                      switch (field?.type) {
                        case "text":
                          content = value;
                          break;
                        case "number":
                          content = new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(value);
                          break;
                        default:
                          content = value;
                          break;
                      }
                      return <td key={idx_field}>{content}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
});

export default connect(mapStateToProps, {})(SINI_SURVEY_1_TABLE);
