import React, { Component } from "react";
import DataTypesSelectionSub from "./DataTypesSelectionSub";
import dict from "../../Data/dict.json";

export default class DataTypesSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      //   height: 0,
      grand_parent: "",
      parent: "",
      child: "",
      grand_child: "",
      standard: "",
      location: "",
      formula: "",
      file: "",
      time: "",
      other: "",
    };
    this.ref = React.createRef(null);
  }

  componentDidMount = () => {
    this.test_dimensions();
  };

  test_dimensions = () => {
    if (this.ref.current) {
      this.setState({
        width: this.ref.current.offsetWidth,
        // height: this.ref.current.offsetHeight,
      });
    }
  };

  setClassName = (key, className) => {
    switch (key) {
      case "grand_parent":
        this.setState({ grand_parent: className });
        break;
      case "parent":
        this.setState({ parent: className });
        break;
      case "child":
        this.setState({ child: className });
        break;
      case "grand_child":
        this.setState({ grand_child: className });
        break;
      case "standard":
        this.setState({ standard: className });
        break;
      case "location":
        this.setState({ location: className });
        break;
      case "formula":
        this.setState({ formula: className });
        break;
      case "file":
        this.setState({ file: className });
        break;
      case "time":
        this.setState({ time: className });
        break;
      case "other":
        this.setState({ other: className });
        break;
      default:
        break;
    }
  };

  componentDidUpdate = (_, prevState) => {
    const { width } = this.state;
    if (prevState.width !== width) {
      if (width < 400) {
        this.setState({
          // grand_parent: "flex gap_5 flex_col h_300 scroll_y_auto",
          grand_parent: "flex gap_5 flex_col scroll_y_auto",
          parent: "flex_col",
          child: "flex_col",
          standard: "w_full",
          location: "w_full",
          formula: "w_full",
          file: "w_full",
          time: "w_full",
          other: "w_full",
        });
      } else if (width >= 400 && width <= 1500) {
        this.setState({
          // grand_parent: "grid gap_5 h_300 scroll_y_auto",
          grand_parent: "grid gap_5 scroll_y_auto",
          parent: "flex_row",
          child: "flex_row",
          standard: "inline",
          location: "inline",
          formula: "inline",
          file: "inline",
          time: "inline",
          other: "inline",
        });
      } else if (width > 1500) {
        this.setState({
          grand_parent: "flex gap_5 flex_row",
          parent: "flex_row",
          child: "flex_col",
          standard: "max_w_470",
          location: "max_w_240",
          formula: "max_w_240",
          file: "max_w_80",
          time: "max_w_240",
          other: "w_full",
        });
      }
    }
  };

  render() {
    let movement_timer = null;
    const RESET_TIMEOUT = 100;
    window.addEventListener("resize", () => {
      clearInterval(movement_timer);
      movement_timer = setTimeout(this.test_dimensions, RESET_TIMEOUT);
    });

    const {
      data_type,
      handleDataTypeOnClick,
      field,
      isDataTypeCalculateDimensionAndLayerTypeIsPoint,
      geo_layer_type,
      language,
    } = this.props;

    const classified_data_types = {
      standard: [],
      time: [],
      file: [],
      location: [],
      formula: [],
      other: [],
    };

    data_type?.forEach((item) => {
      if (item?.type === "standard") {
        classified_data_types[item?.type].push(item);
      } else if (item?.type === "time") {
        classified_data_types[item?.type].push(item);
      } else if (item?.type === "file") {
        classified_data_types[item?.type].push(item);
      } else if (item?.type === "location") {
        classified_data_types[item?.type].push(item);
      } else if (item?.type === "formula") {
        classified_data_types[item?.type].push(item);
      } else {
        classified_data_types["other"].push(item);
      }
    });

    const {
      grand_parent,
      parent,
      child,
      //   grand_child,
      standard,
      location,
      formula,
      file,
      time,
      other,
    } = this.state;

    return (
      <main ref={this.ref} className={`${grand_parent}`}>
        <DataTypesSelectionSub
          sub_title={dict["Standard"][language]}
          className={standard}
          field={field}
          geo_layer_type={geo_layer_type}
          data_type={classified_data_types?.["standard"]}
          handleDataTypeOnClick={handleDataTypeOnClick}
          isDataTypeCalculateDimensionAndLayerTypeIsPoint={
            isDataTypeCalculateDimensionAndLayerTypeIsPoint
          }
        />

        <DataTypesSelectionSub
          sub_title={dict["Location"][language]}
          className={location}
          field={field}
          geo_layer_type={geo_layer_type}
          data_type={classified_data_types?.["location"]}
          handleDataTypeOnClick={handleDataTypeOnClick}
          isDataTypeCalculateDimensionAndLayerTypeIsPoint={
            isDataTypeCalculateDimensionAndLayerTypeIsPoint
          }
        />
        <DataTypesSelectionSub
          sub_title={dict["Formula"][language]}
          className={formula}
          field={field}
          geo_layer_type={geo_layer_type}
          data_type={classified_data_types?.["formula"]}
          handleDataTypeOnClick={handleDataTypeOnClick}
          isDataTypeCalculateDimensionAndLayerTypeIsPoint={
            isDataTypeCalculateDimensionAndLayerTypeIsPoint
          }
        />

        <div className={`flex gap_5 ${parent}`}>
          <DataTypesSelectionSub
            sub_title={dict["File"][language]}
            className={file}
            field={field}
            geo_layer_type={geo_layer_type}
            data_type={classified_data_types?.["file"]}
            handleDataTypeOnClick={handleDataTypeOnClick}
            isDataTypeCalculateDimensionAndLayerTypeIsPoint={
              isDataTypeCalculateDimensionAndLayerTypeIsPoint
            }
          />
          <div className={`flex gap_5 ${child}`}>
            <DataTypesSelectionSub
              sub_title={dict["Time"][language]}
              className={time}
              field={field}
              geo_layer_type={geo_layer_type}
              data_type={classified_data_types?.["time"]}
              handleDataTypeOnClick={handleDataTypeOnClick}
              isDataTypeCalculateDimensionAndLayerTypeIsPoint={
                isDataTypeCalculateDimensionAndLayerTypeIsPoint
              }
            />
            <DataTypesSelectionSub
              sub_title={dict["Other"][language]}
              className={other}
              field={field}
              geo_layer_type={geo_layer_type}
              data_type={classified_data_types?.["other"]}
              handleDataTypeOnClick={handleDataTypeOnClick}
              isDataTypeCalculateDimensionAndLayerTypeIsPoint={
                isDataTypeCalculateDimensionAndLayerTypeIsPoint
              }
            />
          </div>
        </div>
      </main>
    );
  }
}
