import sort_array from "./sort_array";
import st_colors from "../../Data/st_colors.json";

const get_aggregate_train = ({ geo_layer, start, end, field_key }) => {
  let object_data = {};
  const timeseries_object = geo_layer?.timeseries_object || {};
  const { field_name_jam_berangkat, field_name_jam_datang } = timeseries_object;
  const fields = geo_layer?.fields || [];
  let features_original = geo_layer?.geojson?.features || [];

  const field_key_jam_berangkat = fields.find(
    (item) => item.name === field_name_jam_berangkat
  )?.key;
  const field_key_jam_datang = fields.find(
    (item) => item.name === field_name_jam_datang
  )?.key;

  features_original = features_original.filter((feature) => {
    let logic = false;
    let jam_string =
      feature?.properties?.[field_key_jam_datang] ||
      feature?.properties?.[field_key_jam_berangkat];
    if (jam_string) {
      let time_array = jam_string?.split(":");
      /*
        konversi menit & detik ke jam
        1 jam = 1/1 jam
        1 menit = 1/60 jam
        1 detik = 1/3600 jam
        */
      const hour_number = Number(time_array[0]);
      const minute_number = Number(time_array[1]);
      const second_number = Number(time_array[2]);
      const hour_total = hour_number + minute_number / 60 + second_number / 60;
      logic = hour_total >= start && hour_total <= end;
    }
    return logic;
  });

  features_original.forEach((feature) => {
    const nama_kereta = feature?.properties?.[field_key];
    let value = object_data[nama_kereta] || 0;
    object_data[nama_kereta] = value + 1;
  });

  let array = [];
  for (const [key, value] of Object.entries(object_data)) {
    array.push({ key, value });
  }
  array = sort_array(array, "value", false);

  const labels = array.map((item) => item.key);
  const data = array.map((item) => item.value);
  let colors = [];

  for (let i = 0; i < array.length; i++) {
    let index = i;
    if (i > st_colors.length - 1) {
      index = i % st_colors.length;
    }
    colors.push(st_colors[index]);
  }

  const chart_js_aggregate_train = {
    labels: labels,
    datasets: [
      {
        label: "Jumlah kereta pada rentang waktu",
        labels: labels,
        data: data,
        backgroundColor: colors,
      },
    ],
  };
  return chart_js_aggregate_train;
};

export default get_aggregate_train;
