const initial_state = {
  genangan_update: 0,
  array_genangan_success: [],
  array_genangan_failed: [],
  array_genangan_message_failed: [],
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_genangan":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "push_value_genangan":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };
    case "pull_value_genangan_string":
      return {
        ...state,
        ...pull_value_genangan_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };
    case "pull_value_genangan_object":
      return {
        ...state,
        ...pull_value_genangan_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.key_child
        ),
      };
    case "genangan_update":
      return {
        ...state,
        genangan_update: state.genangan_update + 1,
      };
    default:
      return state;
  }
}

const pull_value_genangan_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};

const pull_value_genangan_object = (key, list, value, key_child) => {
  list = list.filter((item) => item[key_child] !== value);
  return { [key]: list };
};
