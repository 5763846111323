/*LIBRARY MODULE*/
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LAYER_FEATURE_SELECTED extends Component {
  componentDidUpdate(prevProps) {
    const key_after = this.props.layer.feature_object_selected?.properties?.key;
    const key_before = prevProps.layer.feature_object_selected?.properties?.key;
    const id_after = this.props.layer.feature_object_selected?.id;
    const id_before = prevProps.layer.feature_object_selected?.id;
    const key_original_after = this.props.layer.feature_object_selected?.key;
    const key_original_before = prevProps.layer.feature_object_selected?.key;
    if (
      key_after !== key_before ||
      id_after !== id_before ||
      key_original_after !== key_original_before
    ) {
      this.on_render_content();
      this.on_fly();
    }
  }

  on_render_content = () => {
    const layer_id_const = "feature_object_selected";
    const { feature_object_selected, map_object } = this.props.layer;

    if (feature_object_selected === null) {
      if (map_object.getLayer(layer_id_const)) {
        map_object.removeLayer(layer_id_const);
      }
      if (map_object.getSource(layer_id_const)) {
        map_object.removeSource(layer_id_const);
      }
    } else {
      const type = feature_object_selected?.geometry?.type;
      const geojson = {
        type: "FeatureCollection",
        features: [feature_object_selected],
      };

      let type_shape, paint_object, layout_object;
      switch (type) {
        case "Point":
        case "MultiPoint":
          type_shape = "circle";
          paint_object = {
            "circle-radius": 5,
            "circle-color": "#ff0000",
            "circle-stroke-width": 2,
            "circle-stroke-color": "#000000",
          };
          layout_object = { visibility: "visible" };
          break;
        case "LineString":
        case "MultiLineString":
        case "Polygon":
        case "MultiPolygon":
          type_shape = "line";
          paint_object = {
            "line-color": "#ff0000",
            "line-width": 5,
          };
          layout_object = {
            visibility: "visible",
            "line-cap": "round",
            "line-join": "round",
          };
          break;
        default:
          console.error("Unsupported GeoJSON type");
          break;
      }

      if (!map_object.getSource(layer_id_const)) {
        map_object.addSource(layer_id_const, {
          type: "geojson",
          data: geojson,
        });
      } else {
        map_object.getSource(layer_id_const).setData(geojson);
      }
      if (!map_object.getLayer(layer_id_const)) {
        map_object.addLayer({
          id: layer_id_const,
          source: layer_id_const,
          type: type_shape,
          paint: paint_object,
          layout: layout_object,
        });
      } else {
        map_object.removeLayer(layer_id_const);
        map_object.addLayer({
          id: layer_id_const,
          source: layer_id_const,
          type: type_shape,
          paint: paint_object,
          layout: layout_object,
        });
      }
    }
  };

  on_fly = () => {
    const { feature_object_selected, map_object, is_pause_zoom_map } =
      this.props.layer;
    if (map_object && feature_object_selected && !is_pause_zoom_map) {
      const geojson = {
        type: "FeatureCollection",
        features: [feature_object_selected],
      };

      const { sidebar_right_status, sidebar_left_status } =
        this.props.properties;

      let top = 50;
      let bottom = 400;
      let left = 10;
      let right = 10;

      if (window.innerWidth < 1172) {
        //MOBILE
        top = 50;
        bottom = 400;
        left = 10;
        right = 10;
      } else {
        //DESKTOP
        top = 150;
        bottom = 300;
        left = 420;
        right = 500;
        if (sidebar_right_status === true) {
          right = 500;
        } else {
          right = 50;
        }
        if (sidebar_left_status === true) {
          left = 500;
        } else {
          left = 50;
        }
      }
      const padding = { top, bottom, left, right };

      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson);
      map_object.fitBounds(
        [
          [min_longitude, min_latitude],
          [max_longitude, max_latitude],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
          duration: 1250,
        }
      );
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_FEATURE_SELECTED);
