import React, { Component } from "react";
class IconCheck extends Component {
  render() {
    return (
      <svg
        id="svg8"
        version="1.1"
        viewBox="0 0 118.61263 118.61263"
        height={this.props.height}
      >
        <defs id="defs2" />
        <g id="layer1" transform="translate(1.8539402,-51.818682)">
          <circle
            style={{
              fill: "none",
              stroke: "#cecece",
              strokeWidth: 4,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            id="path833"
            cx="57.452377"
            cy="111.125"
            r="56.660484"
          />
          <g transform="rotate(-45,137.48192,320.61852)" id="g1423">
            <path
              style={{
                fill: "none",
                stroke: "#cecece",
                strokeWidth: 4,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeOpacity: 1,
              }}
              d="m 197.40211,115.8948 h 63.24933"
              id="path1417"
            />
            <path
              id="path1419"
              d="M 229.02678,84.270135 V 147.51946"
              style={{
                fill: "none",
                stroke: "#cecece",
                strokeWidth: 4,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeOpacity: 1,
              }}
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconCheck;
