/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import TimeseriesMonth from "./TimeseriesMonth";
import TimeseriesHour from "./TimeseriesHour";
import TimeseriesResume from "./TimeseriesResume";

/*REDUX FUNCTION*/
import {
  set_value_properties,
  get_detail_many_layer,
} from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

const mode_view_list = [
  {
    name: "Bulanan",
    value: "month",
  },
  {
    name: "Interval Per Jam",
    value: "hour",
  },
  {
    name: "Proyeksi",
    value: "resume",
  },
];

class TimeseriesKAI extends Component {
  state = {
    mode_view_state: "month", //month || hour || resume
    relasi_list: [],
  };

  on_set_mode_view_state = (mode_view_state) => {
    this.setState({ mode_view_state }, () => {
      if (mode_view_state === "resume") {
        const { layer_id, geo_layer_list } = this.props.layer;
        const { geo_project } = this.props.project;

        const geo_layer = geo_layer_list.find(
          (item) => item?.geo_layer?._id === layer_id
        );
        const folder = geo_project.folders.find(
          (item) => item._id === geo_layer?.folder
        );
        if (folder) {
          this.props.set_value_properties({
            key: "sidebar_right_status",
            value: true,
          });

          this.props.set_value_properties({
            key: "folder_kai_timeseries",
            value: folder,
          });

          const layer_list = geo_layer_list
            .filter(
              (element) =>
                element?.folder === folder?._id &&
                !element?.geo_layer?.geojson?.features?.length
            )
            .map((item) => item?.geo_layer?._id);

          if (layer_list.length > 0) {
            const params = {
              layer_list,
              geo_project_id: geo_project._id,
              folder_id: folder._id,
            };

            this.props.get_detail_many_layer(params);
          }
        }
      }
    });
  };

  render() {
    //local storage

    //local state
    const { mode_view_state } = this.state;

    //global props

    //content

    return (
      <main style={{ marginBottom: "50px" }}>
        <h1 className="text_bold margin_bottom">Analisis Penumpang</h1>
        <p className="text_small">Pilih menu</p>
        {mode_view_list.map((item, idx) => {
          return (
            <button
              key={idx}
              onClick={this.on_set_mode_view_state.bind(this, item.value)}
              className="button margin_right margin_bottom"
              id={item.value === mode_view_state ? "orange" : "dark_blue"}
            >
              {item.name}
            </button>
          );
        })}

        {mode_view_state === "month" ? (
          <TimeseriesMonth />
        ) : mode_view_state === "hour" ? (
          <TimeseriesHour />
        ) : (
          <TimeseriesResume />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_properties,
  get_detail_many_layer,
})(TimeseriesKAI);
