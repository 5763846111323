import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogContent } from "@mui/material";

export default function ModalNoClose(props) {
  const {
    modalSize,
    id = "modal",
    isOpen,
    onClose,
    children,
    fullScreen,
    content,
    footer,
  } = props;
  const [size, setSize] = useState("sm");

  useEffect(() => {
    if (modalSize === "extra_large") {
      setSize("xl");
    } else if (modalSize === "large") {
      setSize("lg");
    } else if (modalSize === "normal" || modalSize === "medium") {
      setSize("md");
    } else if (modalSize === "small") {
      setSize("sm");
    }
  }, [modalSize]);

  return (
    <Dialog
      id={id}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={size}
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      PaperProps={{ sx: { borderRadius: "15px" } }}
      disableEscapeKeyDown
    >
      <main className="margin_30">
        {children && (
          <main className="marginBottom_20 marginLeft_20 marginRight_20">
            <section>{children}</section>
          </main>
        )}
        {content && <DialogContent>{content}</DialogContent>}
        {footer && <DialogActions>{footer}</DialogActions>}
      </main>
    </Dialog>
  );
}
