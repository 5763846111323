/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
const id_content = "layer_genangan";

class LAYER_GENANGAN extends Component {
  componentDidUpdate(prevProps) {
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    if (
      this.props.sini.state_update !== prevProps.sini.state_update ||
      basemap_used_after !== basemap_used_before
    ) {
      this.on_render_content();
    }
  }

  on_render_content = () => {
    const { features_genangan } = this.props.sini;
    const { genangan_view_status } = this.props.map;
    const { map_object } = this.props.layer;
    const visibility = genangan_view_status ? "visible" : "none";
    if (features_genangan.length > 0 && map_object) {
      const geojson = {
        type: "FeatureCollection",
        features: features_genangan,
      };
      if (!map_object.getSource(id_content)) {
        map_object.addSource(id_content, {
          type: "geojson",
          data: geojson,
        });
      } else {
        map_object.getSource(id_content).setData(geojson);
      }
      if (!map_object.getLayer(id_content)) {
        map_object.addLayer(
          {
            id: id_content,
            source: id_content,
            type: "fill",
            paint: {
              "fill-color": "rgb(255, 13, 13)",
              "fill-opacity": 0.5,
            },
            layout: {
              visibility: visibility,
            },
          },
          "building"
        );
      } else {
        map_object.setLayoutProperty(id_content, "visibility", visibility);
      }
    }
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_GENANGAN);
