/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";
import ReactDOMServer from "react-dom/server";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

import { generatePopupContentDOM } from "../libre_popup/popup_geo";
import { set_value_layer } from "../../App/actions/layerActions";
class SYMBOL_GEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup_handlers: {}, //store the references to the event handlers
    };
  }

  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;

    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    if (
      status_action_after !== status_action_before ||
      basemap_used_after !== basemap_used_before
    ) {
      this.on_render();
    }
  }

  on_render = () => {
    let { geo_layer_list, layer_id_active, geometryStatus, layer_id } =
      this.props.layer;
    const { map_object } = this.props.map;

    // Loop through geo layers and create markers
    geo_layer_list.forEach(async (data) => {
      const is_use_symbol = data?.geo_layer?.is_use_symbol;
      const type = data?.geo_layer?.type;
      const is_use_rotate_symbol = data?.geo_layer?.is_use_rotate_symbol;
      const geo_layer = data.geo_layer;
      const { _id } = data.geo_layer;

      let visibility = layer_id_active.includes(data.geo_layer?._id)
        ? "visible"
        : "none";

      if (geometryStatus && layer_id === _id) visibility = "none";

      if (is_use_symbol) {
        const active_symbol = geo_layer?.default_symbol_key;
        const selected_symbol = geo_layer?.symbol_array.find(
          (symbol) => symbol?.key === active_symbol
        );

        for (const item of selected_symbol?.value_link_array) {
          const id_point_image = item.value;
          if (!map_object.hasImage(id_point_image)) {
            const image = await map_object.loadImage(item.image);
            map_object.addImage(id_point_image, image.data);
          }
        }

        const id_map_points_source = geo_layer?._id;
        if (!map_object.getSource(id_map_points_source)) {
          map_object.addSource(id_map_points_source, {
            type: "geojson",
            data: geo_layer?.geojson,
            cluster: false,
            clusterRadius: 0,
          });
        }

        const id_map_points_symbol = geo_layer?._id + "_symbol";
        if (!map_object.getLayer(id_map_points_symbol)) {
          let body = {
            id: id_map_points_symbol,
            source: id_map_points_source,
            type: "symbol",
            layout: {
              ...selected_symbol?.layout_object,
              visibility,
            },
          };

          if (!is_use_rotate_symbol) {
            delete body.layout["icon-rotate"];
          }

          map_object.addLayer(body);
        } else {
          map_object.setLayoutProperty(
            id_map_points_symbol,
            "visibility",
            visibility
          );
        }

        if (type === "Point" || type === "IoT") {
          //POP UP
          this.generate_pop_up({
            _id: geo_layer?._id + "_symbol",
            fields: geo_layer?.fields,
            type_2: geo_layer?.type_2,
            geo_layer: geo_layer,
          });
        }
      }
    });
  };

  generate_pop_up = ({ _id, fields, type_2, geo_layer }) => {
    const { map_object, geometryStatus, layer_id } = this.props.layer;

    if (map_object) {
      //check if the handler is already stored, remove it
      if (this?.state?.popup_handlers?.[_id]) {
        map_object.off("click", _id, this.state.popup_handlers[_id]);
        map_object.off("touchstart", _id, this.state.popup_handlers[_id]); //remove touchstart handler too
      }
      if (!geometryStatus || !layer_id) {
        //create a new handler
        const click_handler = (event) => {
          // const feature = event?.features?.[0];
          if (event?.features?.[0]) {
            const feature_key =
              event?.features?.[0]?.properties?.key || event?.features?.[0]?.id;
            const feature_object_selected = geo_layer?.geojson?.features?.find(
              (item) => item?.key === feature_key
            );
            // const feature_object_selected = feature;
            this.props.set_value_layer({
              key: "feature_object_selected",
              value: feature_object_selected,
            });
            const properties = feature_object_selected?.properties || {};
            const geometry = feature_object_selected?.geometry;
            const type = geometry?.type;
            let longitude, latitude;
            if (type === "Point") {
              longitude = geometry?.coordinates?.[0];
              latitude = geometry?.coordinates?.[1];
            } else if (type === "LineString") {
              const length_km = calculate_length(feature_object_selected, {
                units: "kilometers",
              }).toFixed(2);
              const center_length = length_km / 2;
              let feature_center = along(
                feature_object_selected,
                center_length,
                {
                  units: "kilometers",
                }
              );
              longitude = feature_center?.geometry?.coordinates?.[0];
              latitude = feature_center?.geometry?.coordinates?.[1];
            } else {
              const geojson = {
                type: "FeatureCollection",
                features: [feature_object_selected],
              };
              const feature_center = centroid(geojson);
              longitude = feature_center?.geometry?.coordinates?.[0];
              latitude = feature_center?.geometry?.coordinates?.[1];
            }
            const { container, close_button, full_button, banjir_button } =
              generatePopupContentDOM(properties, fields, type_2);
            const popup = new maplibregl.Popup({
              //disable maplibre's default close button
              closeButton: false,
            })
              .setLngLat([longitude, latitude])
              .setDOMContent(container)
              .addTo(map_object);
            this.props.set_value_layer({
              key: "popup_dom",
              value: popup,
            });
            //add a listener for the custom close button
            close_button.addEventListener("click", () => {
              popup.remove(); //manually close the popup when the button is clicked
              this.props.set_value_layer({
                key: "popup_dom",
                value: null,
              });
              this.props.set_value_layer({
                key: "feature_object_selected",
                value: null,
              });
            });
            full_button.addEventListener("click", () => {
              this.props.set_value_layer({
                key: "modal_edit_feature_properties",
                value: true,
              });
              this.props.set_value_layer({
                key: "latitude_selected",
                value: latitude,
              });
              this.props.set_value_layer({
                key: "longitude_selected",
                value: longitude,
              });
              this.props.set_value_layer({
                key: "fields_selected",
                value: fields,
              });
              this.props.set_value_layer({
                key: "properties_selected",
                value: properties,
              });
              this.props.set_value_layer({
                key: "type_2_selected",
                value: type_2,
              });
              this.props.set_value_layer({
                key: "feature_key_selected",
                value: feature_key,
              });
              this.props.set_value_layer({
                key: "geo_layer_selected",
                value: geo_layer,
              });
            });
            if (banjir_button) {
              banjir_button.addEventListener("click", () => {
                const genangan_cm_selected = parseInt(
                  feature_object_selected.properties.Ketinggian_Genangan_cm
                );
                this.props.set_value_properties({
                  key: "modal_pop_up_genangan",
                  value: true,
                });
                this.props.set_value_properties({
                  key: "feature_key_params_genangan",
                  value: feature_key,
                });
                this.props.set_value_properties({
                  key: "latitude_params_genangan",
                  value: latitude,
                });
                this.props.set_value_properties({
                  key: "longitude_params_genangan",
                  value: longitude,
                });

                this.props.set_value_properties({
                  key: "genangan_cm_params_genangan",
                  value: genangan_cm_selected,
                });
                this.props.set_value_properties({
                  key: "success_status",
                  value: false,
                });
              });
            }

            const popup_content = container.parentElement; //access the popup content div
            popup_content.style.borderRadius = "15px";
            popup_content.style.margin = "0";
            popup_content.style.padding = "10px";
            popup_content.style.overflow = "hidden";
          }
        };

        //save the handler in state
        this.setState((prevState) => ({
          popup_handlers: { ...prevState.popup_handlers, [_id]: click_handler },
        }));

        //attach click and touchstart events
        map_object.on("click", _id, click_handler); //for desktop
        map_object.on("touchstart", _id, click_handler); //for touchscreen devices
      }
    }
  };

  calculate_marker_centers = (type, features_filtered) => {
    let features_center_marker = [];
    // Calculate marker centers based on geometry type
    if (
      ["Polygon", "MultiPolygon", "MultiLineString", "MultiPoint"].includes(
        type
      )
    ) {
      features_center_marker = features_filtered.map((feature) => {
        let feature_final = {};
        const geojson = {
          type: "FeatureCollection",
          features: [feature],
        };
        feature_final = centroid(geojson, { properties: feature.properties });
        return feature_final;
      });
    } else if (type === "Point") {
      features_center_marker = features_filtered;
    } else if (type === "LineString") {
      features_center_marker = features_filtered.map((feature) => {
        const length_km = calculate_length(feature, {
          units: "kilometers",
        }).toFixed(2);
        const center_length = length_km / 2;
        let point = along(feature, center_length, { units: "kilometers" });
        point.properties = feature.properties;
        return point;
      });
    }
    return features_center_marker;
  };

  render_label_markers = ({
    features_center_marker,
    label_config,
    fields,
    map_object,
    type,
    visibility,
  }) => {
    features_center_marker.forEach((feature) => {
      let label_final = this.create_label_content(
        feature,
        label_config,
        fields,
        type
      );
      if (visibility === "visible") {
        const markerEl = document.createElement("div");
        markerEl.innerHTML = ReactDOMServer.renderToString(label_final);
        const marker = new maplibregl.Marker({ element: markerEl })
          .setLngLat([
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ])
          .addTo(map_object);

        this.setState((prevState) => ({
          markers: [...prevState.markers, marker],
        }));
      } else {
        // Remove markers when not visible
        if (this.state && this.state.markers) {
          this.state.markers.forEach((marker) => marker.remove());
          this.setState({ markers: [] });
        }
      }
    });
  };

  create_label_content = (feature, label_config, fields, type) => {
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const style_mode = label_config?.style_mode || "style_1";
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const background_color_secondary =
      label_config?.background_color_secondary || "#ee6b1e";
    const outline_color_secondary =
      label_config?.outline_color_secondary || "#ffffff";
    const text_color_secondary =
      label_config?.text_color_secondary || "#ffffff";

    let label_image;
    let label_text;
    if (label_config?.is_use_label_image) {
      label_image = (
        <div>
          {label_image_field_key_array.map((field_key, idx) => {
            let content;
            if (feature?.properties?.[field_key]) {
              content = (
                <div
                  key={idx}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "5px",
                    backgroundImage: `url(${feature?.properties?.[field_key]})`,
                    backgroundSize: "cover",
                  }}
                  className="margin_bottom_minor margin_right_minor"
                />
              );
            } else {
              content = <div key={idx} />;
            }
            return content;
          })}
        </div>
      );
    }

    if (label_config?.is_use_label_text) {
      label_text = (
        <div>
          {label_text_field_key_array.map((field_key, idx) => {
            const field = fields.find((feature) => feature.key === field_key);
            const field_type = field?.type;
            let text = feature?.properties?.[field_key];
            if (["number"].includes(field_type)) {
              text = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(text);
            } else if (field_type === "currency") {
              text =
                "Rp." +
                new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  text
                );
            }
            let content;
            if (feature?.properties?.[field_key]) {
              content = (
                <div key={idx}>
                  <p
                    className={`margin_bottom_minor badge_pill_small text_small`}
                    style={{
                      color:
                        idx === 0 ? text_color_primary : text_color_secondary,
                      backgroundColor:
                        idx === 0
                          ? background_color_primary
                          : background_color_secondary,
                      borderColor:
                        idx === 0
                          ? outline_color_primary
                          : outline_color_secondary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    {text}
                  </p>
                </div>
              );
            } else {
              content = <div key={idx}></div>;
            }
            return content;
          })}
        </div>
      );
    }

    let label_final;
    if (type === "Point" || type === "MultiPoint") {
      label_final = (
        <div
          style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
        >
          <main
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {label_image}
            {label_text}
            <div
              style={{ width: "2px", height: "25px", backgroundColor: "black" }}
            />
          </main>
        </div>
      );
    } else if (style_mode === "style_1") {
      label_final = (
        <>
          {label_image}
          {label_text}
        </>
      );
    } else {
      label_final = (
        <div>
          <div
            className="button_pill margin_bottom_minor"
            style={{
              color: text_color_primary,
              backgroundColor: background_color_primary,
              borderColor: outline_color_primary,
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            {label_image}
            {label_text}
          </div>
        </div>
      );
    }
    return label_final;
  };

  // cleanup_markers = () => {
  //   this.state.markers.forEach((marker) => marker.remove());
  //   this.setState({ markers: [] });
  // };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
})(SYMBOL_GEO);
