const initial_state = {};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_insight":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "push_value_insight":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };

    default:
      return state;
  }
}
