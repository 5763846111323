/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import icon_gift from "../../Assets/svg/gift.svg";
import Pricing from "./Pricing";
import { Modal } from "../reuseable";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json"

/*NON IMPORT*/
class RedeemPopup extends Component {

  toggle_modal_redeem = () => {
    this.props.set_value_properties({
      key: "modal_redeem",
      value: !this.props.properties.modal_redeem,
    });
  };

  render() {
    const { language } = this.props

    const modal_redeem = this.props.properties.modal_redeem;

    const modal_redeem_content = modal_redeem && (
      <Modal
        title={""}
        modalSize="large"
        id="modal_pricing"
        isOpen={modal_redeem}
        onClose={this.toggle_modal_redeem}
      >
        <main
          className="box-body flex flex_col gap_10 h_full">
          <Pricing />
        </main>
      </Modal>
    );

    //content
    return (
      <div className="sidebar_button vertical_center w_200 hover_lightGrey">
        <div
          onClick={this.toggle_modal_redeem}
          className="vertical_center_child"
        >
          <div style={{ marginLeft: "20px" }}>
            <img src={icon_gift} alt={"Redeem"} width="35px" />
          </div>
          <div
            className="text_medium"
            style={{ marginLeft: "20px", width: "250px", color: "black" }}
          >
            {dict["Offer"][language]}
          </div>
        </div>
        {modal_redeem_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(RedeemPopup);
