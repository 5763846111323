/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
} from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*CONSTANT*/

class GET_CAPEX extends Component {
  state = {};

  on_resume_kai = () => {
    const { geo_layer_list } = this.props.layer;
    const { geo_project } = this.props.project;

    const folders = geo_project.folders || [];
    const folder = folders.find(
      (item) => item.folder_type === "kai_timeseries"
    );

    // this.props.clear_layer_id();
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });

    if (folder) {
      this.props.set_value_properties({
        key: "sidebar_right_status",
        value: true,
      });
      this.props.set_value_properties({
        key: "folder_kai_timeseries",
        value: folder,
      });
      const layer_list = geo_layer_list
        .filter(
          (element) =>
            element?.folder === folder?._id &&
            !element?.geo_layer?.geojson?.features?.length
        )
        .map((item) => item?.geo_layer?._id);
      if (layer_list.length > 0) {
        const params = {
          layer_list,
          geo_project_id: geo_project._id,
          folder_id: folder._id,
        };
        this.props.get_detail_many_layer(params);
      }
    }
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <main>
        <button
          className="button_small background_black"
          onClick={this.on_resume_kai}
        >
          Memuat nilai CAPEX
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  project: state.project,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
})(GET_CAPEX);
