function checkContactType(input) {

  if (!input) {
    return "empty";
  }

  // Regex untuk email
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  // Regex untuk nomor WhatsApp (format internasional atau lokal dengan 10-15 digit)
  const whatsappRegex = /^\+?\d{10,15}$/;


  if (emailRegex.test(input)) {
    return "email";
  } else if (whatsappRegex.test(input)) {
    return "whatsapp";
  } else {
    return "unknown format";
  }
}

export default checkContactType;