import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";

export default class C2_LaporanHasilPenilaianAgunan extends Component {
  render() {
    const { mode } = this.props;
    return (
      <table
        id="c2-laporan-hasil-penilaian-agunan"
        className="table_pdf"
        cellSpacing={0}
      >
        <tbody>
          {/* no. laporan */}
          <tr className="tr_border_bottom_pdf">
            <td colSpan={4} bgcolor="#CECECE">
              <p className="center_perfect title_pdf ">
                LAPORAN HASIL PENILAIAN AGUNAN
              </p>
            </td>
          </tr>
          <tr className="tr_border_bottom_pdf">
            <td>
              <p>No. Laporan</p>
            </td>
            <td>
              <DropdownOrData id="no_laporan_pdf" {...this.props} />
            </td>
          </tr>
          {/* Pelaksanaan pekerjaan */}
          <tr className="tr_border_bottom_pdf">
            <td colSpan={4}>
              <p className="subtitle_pdf">I. PELAKSANAAN PEKERJAAN</p>
            </td>
          </tr>
          <tr className="tr_border_bottom_pdf">
            <td className="quarter">
              <p>Penilai</p>
            </td>
            <td className="quarter">
              <DropdownOrData id="penilai_pdf" {...this.props} />
            </td>
            <td className="quarter">
              <p>Email</p>
            </td>
            <td className="quarter">
              <DropdownOrData id="email_pdf" {...this.props} type="email" />
            </td>
          </tr>
          <tr className="tr_border_bottom_pdf">
            <td
              style={{
                width: "525pt",
              }}
              colSpan={4}
            >
              <p>
                Dengan ini disampaikan laporan penilaian atas aset sebagai
                berikut :
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Debitur</p>
            </td>
            <td>
              <DropdownOrData id="debitur_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Jenis Objek</p>
            </td>
            <td>
              <DropdownOrData id="jenis_objek_pdf" {...this.props} />
            </td>
          </tr>
          <tr className="tr_border_bottom_pdf">
            <td>
              <p>Alamat Object Sesuai Fisik</p>
            </td>
            <td colSpan={mode === "preview" ? 3 : 1}>
              <DropdownOrData
                id="alamat_object_sesuai_fisik_pdf"
                {...this.props}
              />
            </td>
            {mode === "setup" && (
              <td>
                <DropdownOrData
                  placeholder="Pilih Kelurahan"
                  id="c2_kelurahan_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
                <DropdownOrData
                  placeholder="Pilih Kecamatan"
                  id="c2_kecamatan_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
                <DropdownOrData
                  placeholder="Pilih Kabupaten"
                  id="c2_kabupaten_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
                <DropdownOrData
                  placeholder="Pilih Provinsi"
                  id="c2_provinsi_object_sesuai_fisik_pdf"
                  {...this.props}
                  if_string_is_empty=""
                />
              </td>
            )}
          </tr>
          <tr>
            <td className="quarter">
              <p>Tanggal Order</p>
            </td>
            <td className="quarter">
              <DropdownOrData id="tanggal_order_pdf" {...this.props} />
            </td>
            <td className="quarter">
              <p>Tujuan Penilaian</p>
            </td>
            <td className="quarter">
              <DropdownOrData id="tujuan_penilaian_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Tanggal Penilaian</p>
            </td>
            <td>
              <DropdownOrData id="tanggal_penilaian_pdf" {...this.props} />
            </td>
            <td>
              <p>Pendekatan Penilaian</p>
            </td>
            <td>
              <DropdownOrData id="pendekatan_penilaian_pdf" {...this.props} />
            </td>
          </tr>
          <tr>
            <td>
              <p>Tanggal Laporan</p>
            </td>
            <td colSpan={3}>
              <DropdownOrData id="tanggal_laporan_pdf" {...this.props} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
