//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  create_payment,
  checkSpecialist,
  getSpecialistCode,
  clearPayment,
  resetSpecialistCode,
} from "../../App/actions/paymentActions";
// import Modal from "../common_modal/Modal";
// import Modal from "../reuseable/Modal";
// import PaymentInstruction from "../../Pages/dashboard/PaymentInstruction";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import ICON_CHECKLIST from "../../Assets/jsx/ICON_CHECKLIST";

class BuyNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: 1,
      modal_confirmation: false,
      payment_id: "",
    };
  }

  on_set_month = (month) => {
    this.setState({ month });
  };

  toggle_modal_confirmation = () => {
    const { modal_confirmation } = this.state;
    this.setState({
      modal_confirmation: !modal_confirmation,
    });
  };

  // on_create_payment = async () => {
  //   const { month } = this.state;
  //   const license_object = this.props.license_object;
  //   const body = {
  //     payment_type: license_object?.key,
  //     month,
  //     payment_methode: "midtrans",
  //   };

  //   let payment_id = "";
  //   const current_url = window.location.href;
  //   if (current_url.includes("user_profile")) {
  //     payment_id = await this.props.create_payment(body, "user_profile");
  //   } else {
  //     payment_id = await this.props.create_payment(body);
  //   }

  //   if (payment_id) {
  //     this.setState(
  //       {
  //         payment_id,
  //       },
  //       () => {
  //         this.toggle_modal_payment();
  //       }
  //     );
  //   }
  // };


  on_create_payment = async () => {
    const { month } = this.state;
    const { set_page, set_payment_id, set_error } = this.props;
    set_page({ new_page: "loading" })
    const license_object = this.props.license_object;
    const body = {
      payment_type: license_object?.key,
      month,
      payment_methode: "midtrans",
    };

    let response = "";
    const current_url = window.location.href;
    if (current_url.includes("user_profile")) {
      response = await this.props.create_payment(body, "user_profile");
    } else {
      response = await this.props.create_payment(body);
    }

    if (
      response?.status === 200 &&
      response?.id
    ) {
      set_payment_id(response?.id);
      set_page({ new_page: "payment" })
      // this.toggle_modal_payment();
    } else {
      set_page({ new_page: "error" })
      set_error(response)
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { month } = this.state;

    //global props
    const license_object = this.props.license_object;
    // const current_url = window.location.href;

    //content

    // let modal_confirmation_content = // !current_url.includes("user_profile") &&
    //   modal_confirmation && (
    //     <Modal
    //       modalSize="small"
    //       id="modal"
    //       isOpen={modal_confirmation}
    //       onClose={this.toggle_modal_confirmation}
    //     >
    //       <main className="box-body flex flex_col gap_10 align_center">
    //         <label className="bold font_24">{dict["Confirmation"][language]}</label>
    //         <p>{dict["Are you sure you want to buy now?"][language]}</p>
    //         <div className="center_perfect gap_10">
    //           <button
    //             onClick={() => {
    //               this.on_create_payment();
    //             }}
    //             className="button_inactive bg_darkBlue rounded_50 text_white padding_5 w_130 h_35">{dict["Yes"][language]}</button>
    //           <button
    //             onClick={this.toggle_modal_confirmation}
    //             className="button_inactive border rounded_50 border_darkBlue text_darkBlue padding_5 w_130 h_35">{dict["No"][language]}</button>
    //         </div>
    //       </main>
    //     </Modal>
    //   );

    let content;

    if (license_object?._id) {
      const prices = license_object?.prices || [];
      const price_object = prices.find((item) => item.month === month);

      let price = Number(price_object?.price);
      const discount = (Number(price_object?.discount_percent) / 100) * price;
      price = price - discount;


      content = (
        <main>
          {/* <header className="marginBottom_20">
            <h1 className="text_header">{license_object?.name?.[language]}</h1>
            <p>{dict["Exclude VAT"][language]}</p>
          </header> */}
          <section className="flex justify_center gap_10 wrap">
            {prices.map((item, idx) => {
              const is_active = month === item?.month;
              return (
                <main
                  key={idx}
                  className={`card_license noselect w_130 gap_10 ${is_active ? "active_card" : ""}`}
                  onClick={this.on_set_month.bind(this, item.month)}
                >
                  <h1
                    style={{ color: is_active ? '#006ABA' : '#ABABAB' }}
                    className="font_16">Bundle {idx + 1}</h1>

                  <section>
                    <span className="flex align-end">
                      <p
                        style={{ color: is_active ? '#006ABA' : '#ABABAB' }}
                        className="font_20 inline bold">
                        Rp
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(item?.price)}
                      </p>
                    </span>
                  </section>

                  <section
                    style={{ height: "60px" }}
                    className="flex flex_col justify_start">
                    <div>
                      <div
                        className="two_container_list"
                        style={{ marginBottom: "10px" }}
                        key={idx}
                      >
                        <ICON_CHECKLIST
                          color={is_active ? '#006ABA' : '#ABABAB'}
                          size={20}
                        />
                        <div className="text_feature_pricing">
                          {item?.month}{" "}
                          {dict[item?.month === 1 ? "month" : "months"][language]}</div>
                      </div>
                    </div>

                    {!!item.discount_percent && (
                      <div
                        className="two_container_list"
                        style={{ marginBottom: "10px" }}
                        key={idx}
                      >
                        <ICON_CHECKLIST
                          color={is_active ? '#006ABA' : '#ABABAB'}
                          size={20}
                        />
                        <div className="text_feature_pricing">
                          Diskon <b>{item?.discount_percent}%</b>
                        </div>
                      </div>
                    )}
                  </section>

                  <button
                    onClick={() => {
                      if (is_active) {
                        // set_page({ new_page: "payment" })
                        this.on_create_payment()
                        // this.toggleBuyNow(e)
                      }
                    }}
                  >
                    {dict["Choose"][language]}
                  </button>
                </main>
              );
            })}
          </section>
          {/* <br />
          <br />
          <hr />
          <br />
          <p className="text_header">
            Rp
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(price)}
          </p>
          <p className="text_inferior">{dict["Exclude VAT"][language]} 11%</p>
          <br />
          <button
            className="button_big"
            id="blue"
            onClick={this.on_create_payment}
          >
            {dict["Pay"][language]}
          </button> */}
        </main>
      );
    }

    return (
      <main>
        {content}
        {/* {modal_confirmation_content} */}
        {/* {modal_payment_content} */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  create_payment,
  checkSpecialist,
  getSpecialistCode,
  clearPayment,
  resetSpecialistCode,
})(BuyNow);
