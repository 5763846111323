/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import getStatusMember from "../../App/validation/get-status-member";

/*NON IMPORT*/

class VIEW_MENU extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { geo_project } = this.props.project;
    const { user } = this.props.auth;

    //content
    const link = geo_project.link;
    const groups = geo_project?.groups || [];
    let status_user = getStatusMember(groups, user?._id);
    if (!status_user) {
      status_user = "owner";
    }
    let content;
    if (["owner", "creator", "admin", "contributor"].includes(status_user)) {
      content = (
        <main>
          <Link className="button_small margin_bottom" to={`/editor/${link}`}>
            Edit
          </Link>
          <br />
          <Link className="button_small" to={`/view/${link}`}>
            View
          </Link>
        </main>
      );
    } else {
      content = (
        <main>
          <Link className="button_small" to={`/view/${link}`}>
            View
          </Link>
        </main>
      );
    }
    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, {})(VIEW_MENU);
