import React, { Component } from "react";
import UploadFile from "./UploadFile";
import dict from "../../Data/dict.json";

import icon_download from "../../Assets/svg/icon_import.svg";

const language = localStorage?.language ? localStorage?.language : "ina";

export default class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: "",
    };
  }

  componentDidMount = () => {
    const document = this.props.params?.value
    if (typeof document === "string" && isNaN(document)) {
      this.setState({
        document: this.props.params?.value,
      });
    }
  };

  set_document = (value) => {
    this.setState({
      document: value,
    });
  };
  render() {
    const { document } = this.state;

    const fileNameOnServer = document?.split("/")?.at(-1);
    const arrayName = fileNameOnServer?.split("_%25");
    const extension = fileNameOnServer?.split(".")?.at(-1);
    const originalName =
      arrayName?.at(0)?.split("_")?.at(1) === "doc"
        ? arrayName?.at(1)?.replaceAll("%20", " ") +
        (extension ? `.${extension}` : "")
        : "";
    return (
      <section
        style={{
          height: "100%",
          width: "100%",
          padding: 5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        title="Only for jpg, png, pdf, zip, and rar extensions"
      >
        <UploadFile
          documentUrl={document}
          geo_layer_id={this.props.layer_id}
          feature_key={this.props.params.row.key}
          properties_key={this.props.params.field}
          set_document={this.set_document}
          params={
            this.props.params?.row?.nested_attributes
              ? this.props.params
              : undefined
          }
        />
        {document ? (
          <div className="flex gap_5 h_2">
            <div title={originalName} className="w_150 center_perfect">
              <p className="truncate">{originalName}</p>
            </div>
            <a
              className="hover_darkGreen hover_bigger pointer"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "10px",
                width: 15,
                height: 15,
                backgroundColor: "#e6eef3",
                padding: "5px",
                border: "1px solid #9ca8bb",
                borderRadius: "99px",
              }}
              target="_blank"
              rel="noreferrer noopener"
              href={document}
              id={`${this.props.feature_key}-${this.props.properties_key}-download`}
            >
              <img
                alt="download"
                src={icon_download}
                className="h_15 w_15 rounded"
              />
            </a>
          </div>
        ) : (
          <div
            style={{
              fontSize: "10px",
              width: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            {dict["No document"][language]}
          </div>
        )}
      </section>
    );
  }
}
