/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import CHART_TEMPLATE from "../viewer_chart/CHART_TEMPLATE";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class EXAMPLE_USE_Y extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content
    // Normal Waspada Siaga Darurat
    const array = [
      {
        pos: "POS 1",
        status: "2019",
        pendapatan: 2_500_000_000,
      },
      {
        pos: "POS 1",
        status: "2020",
        pendapatan: 1_000_000_000,
      },
      {
        pos: "POS 2",
        status: "2021",
        pendapatan: 1_500_000_000,
      },
      {
        pos: "POS 3",
        status: "2022",
        pendapatan: 1_750_000_000,
      },
      {
        pos: "POS 4",
        status: "2023",
        pendapatan: 1_800_000_000,
      },
      {
        pos: "POS 5",
        status: "2024",
        pendapatan: 2_000_000_000,
      },
    ];
    const lables = ["2019", "2020", "2021", "2022", "2023", "2024"];
    const data = [
      2_500_000_000, 1_000_000_000, 1_500_000_000, 1_750_000_000, 1_800_000_000,
      2_000_000_000,
    ];

    return (
      <main>
        <table className="table full_width" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th>Contoh data</th>
              <th>Output grafik</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="container_light outline_green">
                  Nilai pada grafik akan dihitung dengan mengelompokkan nilai
                  yang memiliki label yang sama, lalu dihitung jumlahnya.
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>
                        Tahun
                        <br />
                        (sebagai sumbu x atau label)
                      </th>
                      <th>
                        Pendapatan dari tiket
                        <br />
                        (sebagai sumbu y)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {array.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td> {idx + 1}</td>
                          <td>{item.status}</td>
                          <td>{item.pendapatan}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
              <td>
                <h1 className="text_medium">PIE CHART</h1>
                <CHART_TEMPLATE
                  type="pie"
                  width="100"
                  height="100"
                  use_legend={true}
                  use_generate_array_color={true}
                  options={{
                    hoverBackgroundColor: "#f38026",
                    animation: false,
                    maintainAspectRatio: false,
                    responsive: false,
                    legend: {
                      display: false,
                    },
                    indexAxis: "x",
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        label: "Contoh",
                        labels: lables,
                        data: data,
                        backgroundColor: "#373784",
                      },
                    ],
                  }}
                />
                <br />
                <br />
                <h1 className="text_medium">BAR CHART</h1>
                <CHART_TEMPLATE
                  type="bar"
                  width="300"
                  height="200"
                  use_legend={false}
                  use_generate_array_color={true}
                  options={{
                    hoverBackgroundColor: "#f38026",
                    animation: false,
                    maintainAspectRatio: false,
                    responsive: false,
                    legend: {
                      display: false,
                    },
                    indexAxis: "x",
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        label: "Contoh",
                        labels: lables,
                        data: data,
                        backgroundColor: "#373784",
                      },
                    ],
                  }}
                />
                <br />
                <br />
                <h1 className="text_medium">LINE CHART</h1>
                <CHART_TEMPLATE
                  type="line"
                  width="300"
                  height="200"
                  use_legend={false}
                  use_generate_array_color={true}
                  options={{
                    hoverBackgroundColor: "#f38026",
                    animation: false,
                    maintainAspectRatio: false,
                    responsive: false,
                    legend: {
                      display: false,
                    },
                    indexAxis: "x",
                    title: {
                      display: false,
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: lables,
                    datasets: [
                      {
                        label: "Contoh",
                        labels: lables,
                        data: data,
                        backgroundColor: "#373784",
                      },
                    ],
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(EXAMPLE_USE_Y);
