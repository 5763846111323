/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-luxon";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { filter_isi_layer } from "../../App/actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import generate_array_color from "../../App/validation/generate_array_color";

/*NON IMPORT*/
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  SubTitle
);

class CHART_TEMPLATE extends Component {
  state = {};

  on_filter_isi_layer = (value) => {
    const { layer_id } = this.props.layer;
    const field_key = this.props.field_key;
    if (layer_id && field_key) {
      const body = {
        geo_layer_id: layer_id,
        field_key,
        value,
      };
      this.props.filter_isi_layer(body);
    }
  };

  render() {
    //local storage

    //local state

    //global props
    const { type, key, width, height, use_legend, use_generate_array_color } =
      this.props;

    let options = this.props.options;
    let data = this.props.data;
    let style = this.props?.style || {};

    //content
    let legend_content;
    const label_array = data.datasets[0].labels;

    if (use_legend) {
      const value_array = data.datasets[0].data;
      let color_array = data?.datasets?.[0]?.backgroundColor;
      if (use_generate_array_color) {
        color_array = generate_array_color(label_array);
      }
      data.datasets[0].backgroundColor = color_array;

      legend_content = (
        <>
          <p className="text_small_margin_top">Legenda pie chart:</p>
          <table className="table margin_top">
            <tbody>
              <tr>
                <td>No</td>
                <td>W</td>
                <td></td>
                <td></td>
              </tr>
              {label_array.map((label, idx) => {
                return (
                  <tr
                    key={idx}
                    onClick={this.on_filter_isi_layer.bind(this, label)}
                  >
                    <td>{idx + 1}</td>
                    <td>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: color_array[idx],
                        }}
                      />
                    </td>
                    <td>{idx === 0 ? <b>{label}</b> : <p>{label}</p>}</td>
                    <td>
                      {idx === 0 ? (
                        <b>{value_array[idx]}</b>
                      ) : (
                        <p>{value_array[idx]}</p>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }

    options = {
      ...options,
      onClick: (event, element) => {
        try {
          const index = element?.[0]?.index;
          const value = label_array?.[index];
          if (value !== undefined) {
            this.on_filter_isi_layer(value);
          }
        } catch (e) {
          console.log(e.message);
        }
      },
    };

    return (
      <>
        <Chart
          type={type}
          key={key}
          data={data}
          options={options}
          width={width}
          height={height}
          style={style}
        />
        {use_legend && legend_content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, { filter_isi_layer })(CHART_TEMPLATE);
