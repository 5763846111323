import React, { Component } from 'react'
import { SelectField } from '../common_input/TextFieldGroup'

//General Function
import dict from "../../Data/dict.json";
import get_industries from '../../App/validation/industry_list';
import get_objectives from '../../App/validation/objectives';


export default class RegisterStep2 extends Component {
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { industry, objective, onChange, errors } = this.props;
    return (
      <>
        <SelectField
          list_array={get_industries(language)}
          Title={dict["What industry you work in?"][language]}
          name="industry"
          id="industry"
          value={industry}
          placeholder={`${dict["Choose an industry"][language]}`}
          onChange={onChange}
          error={errors?.industry}
        />


        <SelectField
          Title={dict["What objectives does your organization want to achieve?"][language]}
          list_array={get_objectives(language)}
          placeholder={`${dict["Choose an objective"][language]}`}
          name="objective"
          value={objective}
          onChange={onChange}
          error={errors?.objective}
        />
      </>
    )
  }
}
