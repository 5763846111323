import React, { Component } from "react";
import { comma_every_3_digits } from "../../App/validation/format_number";
import {
  polygon_units,
  line_units,
} from "../../App/validation/geometry_to_area_or_length";

export default class CalculateDimension extends Component {
  render() {
    const unit = polygon_units
      ?.concat(line_units)
      ?.find((row) => row?.key === this.props.column?.unit)?.symbol;
    const { value } = this.props.params;

    return (
      <span style={{ height: "100%", overflowY: "auto" }}>
        {`${comma_every_3_digits(value || 0)} ${unit}`}
      </span>
    );
  }
}
