/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import FOLDER_CHILD from "./FOLDER_CHILD";
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import {
  get_all_folder_project_list_by_user_id,
  push_project,
  pull_project,
} from "../../App/actions/folderProjectAction";
import { set_value_project } from "../../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class MOVE_PROJECT_TO_FOLDER extends Component {
  state = {
    modal_warn: false,
  };

  componentDidMount() {
    this.props.get_all_folder_project_list_by_user_id();
  }

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  on_click_folder = (folder_id) => {
    this.props.set_value_project({
      key: "folder_id_selected_move",
      value: folder_id,
    });
  };

  on_push_project = () => {
    const {
      folder_id_selected_move,
      project_id_selected_move,
      folder_object_selected_list,
      folder_id_selected,
    } = this.props.project;

    if (
      folder_id_selected === folder_id_selected_move ||
      !folder_id_selected_move
    ) {
      this.toggle_warn();
    } else {
      const key =
        folder_object_selected_list.length === 0
          ? "geo_project_list"
          : "geo_project_list_child";
      const body = {
        key,
        folder_id: folder_id_selected_move,
        project_id: project_id_selected_move,
      };
      this.props.push_project(body);
      this.props.toggle_move_folder();

      if (folder_id_selected) {
        //jika posisinya berada di dalam folder maka lakukan penghapusan project_id di folder selected, supaya satu project hanya bisa berada di satu folder
        const {
          folder_id_selected,
          folder_object_selected_list,
          project_id_selected_move,
          geo_project,
        } = this.props.project;
        const key =
          folder_object_selected_list.length === 0
            ? "geo_project_list"
            : "geo_project_list_child";
        const body = {
          is_not_outside: true,
          key,
          folder_id: folder_id_selected,
          project_id: project_id_selected_move,
          geo_project: geo_project,
        };
        this.props.pull_project(body);
      }
    }
  };

  on_pull_project = () => {
    const {
      folder_id_selected,
      folder_object_selected_list,
      project_id_selected_move,
      geo_project,
    } = this.props.project;
    const key =
      folder_object_selected_list.length === 0
        ? "geo_project_list"
        : "geo_project_list_child";
    const body = {
      key,
      folder_id: folder_id_selected,
      project_id: project_id_selected_move,
      geo_project: geo_project,
    };
    this.props.pull_project(body);
    this.props.toggle_move_folder();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_warn } = this.state;

    //global props
    const {
      folder_project_list_all,
      folder_id_selected_move,
      folder_object_selected_list,
      folder_id_selected,
    } = this.props.project;

    //content
    const folder_parent_list = folder_project_list_all.filter(
      (item) => !item?.folder_parent_id
    );

    const modal_warn_content = modal_warn && (
      <Modal modalSize="small" isOpen={modal_warn} onClose={this.toggle_warn}>
        <div className="box-body" id="box-body">
          {dict?.["Please select a folder first"]?.[language]}
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_warn_content}
        <h3 className="text_header margin_bottom">
          {dict?.["Move to folder"]?.[language]}
        </h3>

        <section
          className="margin_bottom"
          style={{
            maxHeight: "calc(( 70vh - 200px ))",
            height: "calc(( 70vh - 200px ))",
            overflowY: "scroll",
          }}
        >
          {folder_parent_list.map((item, idx) => {
            const folder_list_child = folder_project_list_all.filter(
              (child) => child?.folder_parent_id === item?._id
            );
            return (
              <div key={idx} className="margin_bottom">
                <div
                  className={`container_folder cursor_pointer ${
                    item._id === folder_id_selected_move
                      ? "outline_blue"
                      : "outline_grey"
                  }`}
                  onClick={this.on_click_folder.bind(this, item?._id)}
                  data-mapid={`moveTo-${idx}`}
                >
                  <table className="full_width">
                    <tbody>
                      <tr>
                        <td>
                          <p
                            className="max_one_lines"
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            {item?.name}
                          </p>
                        </td>
                        {folder_id_selected_move === item?._id && (
                          <td className="text_right">
                            <span className="badge_circle_small background_green" />
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>

                {folder_id_selected === item?._id && (
                  <div className="badge">Current</div>
                )}

                {folder_list_child?.length > 0 && (
                  <FOLDER_CHILD
                    folder_id_selected={folder_id_selected}
                    folder_parent_id={item?._id}
                    folder_list_child={folder_list_child}
                    folder_project_list_all={folder_project_list_all}
                    on_click_folder={this.on_click_folder}
                    folder_id_selected_move={folder_id_selected_move}
                  />
                )}
              </div>
            );
          })}
        </section>

        <section className="margin_top">
          <button
            className="button margin_right margin_bottom"
            onClick={this.on_push_project}
            data-mapid="moveProject"
          >
            {dict?.["Move"]?.[language]}
          </button>
          {folder_object_selected_list.length > 0 && (
            <button
              className="button background_black"
              onClick={this.on_pull_project}
            >
              {dict?.["Remove from folder"]?.[language]}
            </button>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
});

export default connect(mapStateToProps, {
  get_all_folder_project_list_by_user_id,
  set_value_project,
  push_project,
  pull_project,
})(MOVE_PROJECT_TO_FOLDER);
