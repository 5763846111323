import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #9a9a9a",
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    borderRight: `1px solid #ffffff`,
    borderBottom: `1px solid #9a9a9a`,
    background: `#3aabe6`,
    color: "#ffffff",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid #9a9a9a`,
    borderRight: `1px solid #9a9a9a`,
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },

  "& .row-theme": {
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#bee2fc",
    },
    "&.Mui-selected": {
      backgroundColor: "#abd9aa",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#7cd07a",
      },
    },
  },
}));
