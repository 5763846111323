/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import STATUS_GET_GENANGAN_CONTENT from "./STATUS_GET_GENANGAN_CONTENT";
import { Draggable } from "../reuseable";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class STATUS_GET_GENANGAN extends Component {
  state = {};

  on_close_modal = () => {
    this.props.set_value_properties({
      key: "modal_pop_up_genangan",
      value: false,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { modal_pop_up_genangan } = this.props.properties;

    //content

    return (
      <main>
        {modal_pop_up_genangan && (
          <Draggable
            title="Setup algoritma genangan"
            toggle_close={this.on_close_modal}
            style={{
              background: "white",
              width: "310px",
              zIndex: 5,
            }}
            initialPosition={{
              x: 50,
              y: 50,
            }}
          >
            <STATUS_GET_GENANGAN_CONTENT />
          </Draggable>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(
  STATUS_GET_GENANGAN
);
