import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_ARROW_RIGHT extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={10}
        viewBox="0 0 3.969 2.646"
      >
        <path
          d="M73.038 146.025v.989H70.73a.333.333 0 1 0 0 .667h2.308v.99l1.326-1.325z"
          style={{
            fill: this.props.fill || "#000",
            fillRule: "evenodd",
            stroke: "none",
            strokeWidth: 0.264583,
            strokeDasharray: "none",
            paintOrder: "markers fill stroke",
            stopColor: "#000",
          }}
          transform="translate(-70.395 -146.025)"
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_ARROW_RIGHT);
