import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_BOX extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 5.292 5.292"
      >
        <g
          transform="translate(-426.14 -81.632)"
          className={this.props.className}
        >
          <rect
            width={2.075}
            height={2.075}
            x={429.09}
            y={81.897}
            rx={0.3}
            ry={0.661}
            style={{
              fill: "none",
              paintOrder: "stroke markers fill",
              strokeDashoffset: 11.411,
              strokeWidth: 0.52917,
            }}
          />
          <rect
            width={2.075}
            height={2.075}
            x={429.09}
            y={84.584}
            rx={0.3}
            ry={0.661}
            style={{
              fill: "none",
              paintOrder: "stroke markers fill",
              strokeDashoffset: 11.411,
              strokeWidth: 0.52917,
            }}
          />
          <rect
            width={2.075}
            height={2.075}
            x={426.4}
            y={84.584}
            rx={0.3}
            ry={0.661}
            style={{
              fill: "none",
              paintOrder: "stroke markers fill",
              strokeDashoffset: 11.411,
              strokeWidth: 0.52917,
            }}
          />
          <rect
            width={2.075}
            height={2.075}
            x={426.4}
            y={81.897}
            rx={0.3}
            ry={0.661}
            style={{
              fill: "none",
              paintOrder: "stroke markers fill",
              strokeDashoffset: 11.411,
              strokeWidth: 0.52917,
            }}
          />
        </g>
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_BOX);
