import logo_mapid from "../Assets/svg/logo_mapid.svg";
import logo_alpha from "../Assets/svg/logo_geo_alpha.svg";
import logo_cilicis from "../Assets/svg/logo_outline_cilicis.svg";
import logo_brantas from "../Assets/svg/logo_outline_brantas.svg";
import logo_kemenperin from "../Assets/svg/logo_outline_kemenperin.svg";
import logo_mrt from "../Assets/png_jpg/logo_outline_mrt.png";
import logo_hud from "../Assets/png_jpg/logo_hud.png";
import logo_danamas from "../Assets/png_jpg/logo_danamas.png";
import logo_befa from "../Assets/png_jpg/logo_befa.png";
import logo_kai from "../Assets/png_jpg/logo_kai.png";
import logo_atr from "../Assets/png_jpg/logo_atr.png";
import logo_mapid_triangle from "../Assets/svg/logo_mapid_triangle.svg";
import background_mapid from "../Assets/png_jpg/background_mapid.png";
import background_kai from "../Assets/png_jpg/background_kai.png";
import background_cilicis from "../Assets/png_jpg/background_cilicis.png";
import connected_future from "../Assets/svg/connected_future.svg";
import bapperidamadiun from "../Assets/svg/bapperidamadiun.svg";
import bapperidamadiunmobile from "../Assets/svg/bapperidamadiunmobile.svg";
import dsda from "../Assets/svg/dsda.svg";
import dsda_mobile from "../Assets/svg/dsdamobile.svg";

const domain_list = {
  "geo.mapid.io": {
    mobile_logo: logo_mapid_triangle,
    logo: logo_mapid,
    title: "GEO MAPID",
    short_title: "GEO MAPID",
    sub_title: "PT. Multi Areal Planing Indonesia",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "basic",
    show_rdtr: false,
    show_survey_1: false,
    tag_line_image: connected_future,
  },
  "geo-alpha.mapid.io": {
    mobile_logo: logo_mapid_triangle,
    logo: logo_alpha,
    title: "GEO ALPHA",
    short_title: "GEO MAPID",
    sub_title: "Hanya untuk alpha tester",
    background: background_mapid,
    form_link: "https://form-alpha.mapid.io/",
    status: "basic",
    show_rdtr: false,
    show_survey_1: false,
    tag_line_image: connected_future,
  },
  "geo-beta.mapid.io": {
    mobile_logo: logo_mapid_triangle,
    logo: logo_mapid,
    title: "GEO MAPID",
    short_title: "GEO MAPID",
    sub_title: "Multi Areal Planing Indonesia",
    background: background_mapid,
    form_link: "https://form-beta.mapid.io/",
    status: "basic",
    show_rdtr: false,
    show_survey_1: false,
    tag_line_image: connected_future,
  },
  localhost: {
    mobile_logo: logo_mapid_triangle,
    logo: logo_mapid,
    title: "GEO MAPID",
    short_title: "GEO MAPID",
    sub_title: "PT. Multi Areal Planing Indonesia",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "basic",
    show_rdtr: false,
    show_survey_1: false,
    tag_line_image: connected_future,
  },
  "kai.mapid.io": {
    mobile_logo: logo_kai,
    logo: logo_kai,
    title: "KAI Assets Development Intelligent Platform",
    short_title: "KAI Platform",
    sub_title: "Powered by MAPID",
    background: background_kai,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: true,
    show_survey_1: true,
    organization: "PT. Kereta Api Indonesia",
    continent: "Asia",
    industry: "Transportation and Mobility",
    objective: "Develop Spatial Applications",
  },
  "kai-alpha.mapid.io": {
    mobile_logo: logo_kai,
    logo: logo_kai,
    title: "KAI Assets Development Intelligent Platform",
    short_title: "KAI Platform",
    sub_title: "Powered by MAPID",
    background: background_kai,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: true,
    show_survey_1: true,
    organization: "PT. Kereta Api Indonesia",
    continent: "Asia",
    industry: "Transportation and Mobility",
    objective: "Develop Spatial Applications",
  },
  "kai-beta.mapid.io": {
    mobile_logo: logo_kai,
    logo: logo_kai,
    title: "KAI Assets Development Intelligent Platform",
    short_title: "KAI Platform",
    sub_title: "Powered by MAPID",
    background: background_kai,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: true,
    show_survey_1: true,
    organization: "PT. Kereta Api Indonesia",
    continent: "Asia",
    industry: "Transportation and Mobility",
    objective: "Develop Spatial Applications",
  },
  "ptpi.mapid.io": {
    mobile_logo: logo_mapid,
    logo: logo_mapid,
    title: "PTPI",
    short_title: "PTPI",
    sub_title: "PTPI LOCATION PLATFORM",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "danamas.mapid.io": {
    mobile_logo: logo_danamas,
    logo: logo_danamas,
    title: "DANAMAS",
    short_title: "DANAMAS",
    sub_title: "Danamas Location Intelligent Platform",
    background: background_mapid,
    form_link: "https://danamas-survey.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "befa.mapid.io": {
    mobile_logo: logo_befa,
    logo: logo_befa,
    title: "BEFA",
    short_title: "BEFA",
    sub_title: "BEFA PLATFORM",
    background: background_mapid,
    form_link: "https://form-befa.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "befa-alpha.mapid.io": {
    mobile_logo: logo_befa,
    logo: logo_befa,
    title: "BEFA",
    short_title: "BEFA",
    sub_title: "BEFA PLATFORM",
    background: background_mapid,
    form_link: "https://form-befa.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "hud.mapid.io": {
    mobile_logo: logo_hud,
    logo: logo_hud,
    title: "HUD",
    short_title: "HUD",
    sub_title: "GIS PLATFORM ONLINE",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "mrt.mapid.io": {
    mobile_logo: logo_mrt,
    logo: logo_mrt,
    title: "MRT Jakarta",
    short_title: "MRTJ",
    sub_title: "Transit Oriented Development",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "ditjenikma.mapid.io": {
    mobile_logo: logo_kemenperin,
    logo: logo_kemenperin,
    title: "Kementerian Perindustrian",
    short_title: "Ditjenikma",
    sub_title: "DITJEN IKM Smart Data Center",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "ditjenikma-kemenperin.mapid.io": {
    mobile_logo: logo_kemenperin,
    logo: logo_kemenperin,
    title: "Kementerian Perindustrian",
    short_title: "Ditjenikma",
    sub_title: "DITJEN IKM Smart Data Center",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "desaku.mapid.io": {
    mobile_logo: logo_mapid,
    logo: logo_mapid,
    title: "BATAS DESA",
    short_title: "DESAKU",
    sub_title: "BATAS DESA",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "bwsmalukuutara.mapid.io": {
    mobile_logo: logo_brantas,
    logo: logo_brantas,
    title: "BWS MALUKU UTARA",
    short_title: "BWS MALUKU UTARA",
    sub_title: "BWS MALUKU UTARA",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "bbwsbrantas.mapid.io": {
    mobile_logo: logo_brantas,
    logo: logo_brantas,
    title: "Smart Water Center",
    short_title: "SWC Brantas",
    sub_title: "BBWS Brantas",
    background: background_mapid,
    form_link: "https://brantas.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
    organization: "BBWS BRANTAS",
    continent: "Asia",
    industry: "Government",
    objective: "Create and Run Data Processing and Analytical Workflows",
  },
  "kabalai.mapid.io": {
    mobile_logo: logo_cilicis,
    logo: logo_cilicis,
    title: "Smart Water Center",
    short_title: "SWC Cilicis",
    background: background_mapid,
    sub_title: "BBWS Ciliwung Cisadane",
    form_link: "https://cilicis.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "bbwsciliwungcisadane.mapid.io": {
    mobile_logo: logo_cilicis,
    logo: logo_cilicis,
    title: "Smart Water Center",
    short_title: "SWC Cilicis",
    sub_title: "BBWS Ciliwung Cisadane",
    background: background_cilicis,
    form_link: "https://cilicis.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
    organization: "BBWS CILIWUNG CISADANE",
    continent: "Asia",
    industry: "Government",
    objective: "Create and Run Data Processing and Analytical Workflows",
  },
  "cilicis-alpha.mapid.io": {
    mobile_logo: logo_cilicis,
    logo: logo_cilicis,
    title: "Smart Water Center",
    short_title: "SWC Cilicis",
    sub_title: "BBWS Ciliwung Cisadane",
    background: background_cilicis,
    form_link: "https://cilicis.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
    organization: "BBWS CILIWUNG CISADANE",
    continent: "Asia",
    industry: "Government",
    objective: "Create and Run Data Processing and Analytical Workflows",
  },
  "cilicis-beta.mapid.io": {
    mobile_logo: logo_cilicis,
    logo: logo_cilicis,
    title: "Smart Water Center",
    short_title: "SWC Cilicis",
    sub_title: "BBWS Ciliwung Cisadane",
    background: background_cilicis,
    form_link: "https://cilicis.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
    organization: "BBWS CILIWUNG CISADANE",
    continent: "Asia",
    industry: "Government",
    objective: "Create and Run Data Processing and Analytical Workflows",
  },
  "sppr.mapid.io": {
    mobile_logo: logo_atr,
    logo: logo_atr,
    title: "KEMENTERIAN AGRARIA DAN TATA RUANG BADAN PERTANAHAN NASIONAL",
    short_title: "SPPR | ATR BPN",
    sub_title: "ATR BPN",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "basic",
    show_rdtr: false,
    show_survey_1: false,
  },
  "bmnsunateraiv.mapid.io": {
    mobile_logo: logo_cilicis,
    logo: logo_cilicis,
    title: "BWS Padang",
    short_title: "BWS Padang",
    sub_title: "BWS Padang",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "bwssumatera5padang.mapid.io": {
    mobile_logo: logo_cilicis,
    logo: logo_cilicis,
    title: "BWS Padang",
    short_title: "BWS Padang",
    sub_title: "BWS Padang",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "bapperidamadiun.mapid.io": {
    mobile_logo: bapperidamadiunmobile,
    logo: bapperidamadiun,
    title: "BAPPERIDA MADIUN",
    short_title: "BAPPERIDA MADIUN",
    sub_title: "BAPPERIDA MADIUN",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
  "dsdajakarta.mapid.io": {
    mobile_logo: dsda_mobile,
    logo: dsda,
    title: "DINAS SUMBER DAYA AIR JAKARTA",
    short_title: "DSDA JAKARTA",
    sub_title: "DSDA JAKARTA",
    background: background_mapid,
    form_link: "https://form.mapid.io/",
    status: "premium",
    show_rdtr: false,
    show_survey_1: false,
  },
};

export default domain_list;
