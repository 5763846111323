import React, { Component } from "react";
import { connect } from "react-redux";
import { getGeoLayer } from "../../App/reduxHelper/layer";

class RIVER_SCHEMA extends Component {
  render() {
    const layer = this.props.layer;
    const geo_layer = getGeoLayer(layer);
    const layer_id = geo_layer?._id;
    // const project_id = geo_layer?.geo_project?._id;
    const access_token = localStorage.token_mapid;

    return (
      <main>
        {/* <section>
          <label>id project:</label>
          <label>{project_id}</label>
        </section>
        <section>
          <label>id layer:</label>
          <label>{layer_id}</label>
        </section>
        <section>
          <label>token user:</label>
          <label>{access_token}</label>
        </section> */}
        <section>
          <iframe
            title="RIVER_SCHEMA"
            src={`https://mapid.co.id/maluku?layer_id=${layer_id}&token=${access_token}`}
            style={{ width: "100%", height: "100vh", border: 'none' }}
          />
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {})(RIVER_SCHEMA);
