/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import BI_FOLDER_CHILD from "./BI_FOLDER_CHILD";
import BI_PROJECT_LIST from "./BI_PROJECT_LIST";
import BI_DELETE_FOLDER from "./BI_DELETE_FOLDER";

/*REDUX FUNCTION*/
import {
  set_value_bi,
  push_value_bi,
  pull_value_bi_string,
  delete_folder,
} from "../../App/actions/biActions";

/*PICTURE ASSET*/
import ICON_EXPAND from "../../Assets/jsx/ICON_EXPAND";
import ICON_COLLAPSE from "../../Assets/jsx/ICON_COLLAPSE";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import get_url_query from "../../App/validation/get_url_query";

/*NON IMPORT*/

class BI_FOLDER_LIST extends Component {
  state = {
    modal_delete_folder: false,
  };

  toggle_delete_folder = (folder_object_selected) => {
    this.setState({ modal_delete_folder: !this.state.modal_delete_folder });
    if (folder_object_selected) {
      this.props.set_value_bi({
        key: "folder_object_selected",
        value: folder_object_selected,
      });
    }
  };

  on_push_folder_id_open_list = (folder_id) => {
    const { folder_id_open_list } = this.props.bi;
    if (folder_id_open_list.includes(folder_id)) {
      this.props.pull_value_bi_string({
        key: "folder_id_open_list",
        value: folder_id,
      });
    } else {
      this.props.push_value_bi({
        key: "folder_id_open_list",
        value: folder_id,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode = get_url_query("mode");

    //local state
    const { modal_delete_folder } = this.state;

    //global props
    const {
      bi_object,
      folder_list,
      project_list,
      loading_status,
      loading_item,
      folder_id_open_list,
    } = this.props.bi;

    //content
    let folder_list_original = bi_object?.folder_list || [];
    folder_list_original = folder_list_original.map((item) => item._id);

    let list_content;

    if (loading_status && loading_item === "get_bi_object_by_link") {
      list_content = (
        <section className="container_light">
          <p className="text_bold">{dict?.["Loading"]?.[language]}...</p>
        </section>
      );
    } else if (folder_list.length > 0) {
      const folder_parent_list = folder_list.filter(
        (item) =>
          !item?.folder_parent_id || folder_list_original.includes(item._id)
      );
      list_content = (
        <main>
          <section>
            {folder_parent_list.map((item, idx) => {
              const project_id_list = item?.project_id_list || [];
              const folder_list_child = folder_list.filter(
                (child) => child?.folder_parent_id === item?._id
              );
              const open_status = folder_id_open_list.includes(item?._id);
              return (
                <section key={idx}>
                  <div
                    className="two_container_button_right_small cursor_pointer margin_bottom outline_blue"
                    style={{
                      borderRadius: "5px",
                      padding: "5px",
                      backgroundColor: "#fff",
                    }}
                    onClick={this.on_push_folder_id_open_list.bind(
                      this,
                      item?._id
                    )}
                  >
                    <p className="text_bold max_one_lines">{item?.name}</p>
                    {!open_status ? (
                      <ICON_EXPAND width={15} height={15} />
                    ) : (
                      <ICON_COLLAPSE width={15} height={15} />
                    )}
                  </div>
                  {open_status && (
                    <>
                      {mode === "edit" && (
                        <button
                          className="button_small background_red margin_bottom"
                          onClick={this.toggle_delete_folder.bind(this, item)}
                        >
                          {dict?.["Delete folder"]?.[language]}
                        </button>
                      )}
                      <BI_PROJECT_LIST
                        project_id_list={project_id_list}
                        folder_list_child={folder_list_child}
                      />
                      <BI_FOLDER_CHILD
                        folder_parent_id={item?._id}
                        folder_list_child={folder_list_child}
                        folder_list_all={folder_list}
                        folder_id_open_list={folder_id_open_list}
                        on_push_folder_id_open_list={
                          this.on_push_folder_id_open_list
                        }
                      />
                    </>
                  )}
                </section>
              );
            })}
          </section>
        </main>
      );
    } else if (project_list.length > 0) {
      list_content = (
        <main>
          {project_list.map((item, idx) => {
            return <section key={idx}>{item.name}</section>;
          })}
        </main>
      );
    } else {
      list_content = (
        <section className="container_light">
          <p className="text_bold">
            {dict?.["There are no folders and projects yet"]?.[language]}
          </p>
        </section>
      );
    }
    const modal_delete_folder_content = modal_delete_folder && (
      <Modal
        modalSize="small"
        isOpen={modal_delete_folder}
        onClose={this.toggle_delete_folder}
      >
        <div className="box-body" id="box-body">
          <BI_DELETE_FOLDER toggle_delete_folder={this.toggle_delete_folder} />
        </div>
      </Modal>
    );
    return (
      <main className="bi_sidebar_left_scroll">
        {modal_delete_folder_content}
        {list_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  set_value_bi,
  push_value_bi,
  pull_value_bi_string,
  delete_folder,
})(BI_FOLDER_LIST);
