/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import LayerListRender from "./LayerListRender";
import Modal from "../common_modal/Modal";
import CreateFolder from "./CreateFolder";
import DeleteFolder from "./DeleteFolder";
import EditFolder from "./EditFolder";

/*REDUX FUNCTION*/
import {
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
} from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

class LayerListModeButton extends Component {
  state = {
    checkedLayers: [],
    is_mode_checklist_layer: false,
    anchor: null,
    folder_id: "",

    modal_create_sub_folder: false,
    modal_menu: false,
    modal_delete_folder: false,
    modal_edit_folder: false,
  };

  on_resume_kai = (folder) => {
    const { geo_layer_list } = this.props.layer;
    const { geo_project } = this.props.project;
    this.props.clear_layer_id();
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
    this.props.set_value_properties({
      key: "folder_kai_timeseries",
      value: folder,
    });
    if (folder) {
      this.props.set_value_properties({
        key: "sidebar_right_status",
        value: true,
      });
      this.props.set_value_properties({
        key: "folder_kai_timeseries",
        value: folder,
      });
      const layer_list = geo_layer_list
        .filter(
          (element) =>
            element?.folder === folder?._id &&
            !element?.geo_layer?.geojson?.features?.length &&
            element?.geo_layer?.timeseries_object?.timeseries_mode === "kai"
        )
        .map((item) => item?.geo_layer?._id);
      if (layer_list.length > 0) {
        const params = {
          layer_list,
          geo_project_id: geo_project._id,
          folder_id: folder._id,
        };

        this.props.get_detail_many_layer(params);
      }
    }
  };

  toggle_delete_folder = () => {
    this.setState({
      modal_delete_folder: !this.state.modal_delete_folder,
      modal_menu: false,
    });
  };

  toggle_edit_folder = () => {
    this.setState({
      modal_edit_folder: !this.state.modal_edit_folder,
      modal_menu: false,
    });
  };

  toggle_menu = (e) => {
    this.setState({ modal_menu: !this.state.modal_menu });
    if (e) {
      this.setState({ anchor: e.currentTarget, folder_id: e.target.id });
    }
  };

  toggle_create_sub_folder = () => {
    this.setState({
      modal_create_sub_folder: !this.state.modal_create_sub_folder,
    });
  };

  clear_folder = () => {
    this.props.set_value_properties({
      key: "folder_list_active",
      value: [],
    });
  };

  back_one_folder = () => {
    let { folder_list_active } = this.props.properties;
    folder_list_active.pop();
    this.props.set_value_properties({
      key: "folder_list_active",
      value: folder_list_active,
    });
  };

  click_folder = (folder_id) => {
    let { folder_list_active } = this.props.properties;
    folder_list_active.push(folder_id);
    this.props.set_value_properties({
      key: "folder_list_active",
      value: folder_list_active,
    });
  };

  setCheckedLayers = (value) => {
    this.setState(
      {
        checkedLayers: value,
      },
      () => {
        if (value.length === 0) {
          this.toggleIsModeChecklistLayer(false);
        } else {
          this.toggleIsModeChecklistLayer(true);
        }
      }
    );
  };

  toggleIsModeChecklistLayer = (value) => {
    const { is_mode_checklist_layer } = this.state;
    this.setState({
      is_mode_checklist_layer: value || !is_mode_checklist_layer,
    });
  };

  render() {
    //local storage

    //local state
    const {
      checkedLayers,
      is_mode_checklist_layer,
      anchor,
      folder_id,

      modal_menu,
      modal_create_sub_folder,
      modal_delete_folder,
      modal_edit_folder,
    } = this.state;

    //global props
    const { geo_project } = this.props.project;
    const { geo_layer_list } = this.props.layer;
    const { folder_list_active } = this.props.properties;

    //content
    const folders = geo_project.folders || [];
    const last_folder =
      folder_list_active?.[folder_list_active.length - 1] || "";
    const folder_object_state = folders.find((item) => item._id === folder_id);

    let folder_list = [];
    let layer_list = [];
    let content;

    if (folder_list_active.length > 0) {
      const last_folder = folder_list_active[folder_list_active?.length - 1];
      folder_list = folders.filter(
        (folder) => folder.parent_folder === last_folder
      );
      layer_list = geo_layer_list.filter(
        (layer) => layer.folder === last_folder
      );
    } else {
      folder_list = folders.filter((item) => !item.parent_folder);
      const folders_id = folders.map((folder) => folder._id);
      layer_list = geo_layer_list.filter(
        (item) => !folders_id.includes(item.folder)
      );
    }

    content = (
      <main>
        <br />
        <section className="margin_bottom">
          <p className="inline cursor_pointer" onClick={this.clear_folder}>
            {"Home > "}
          </p>
          {folder_list_active.map((folder_id, idx) => {
            const folder = folders.find((item) => item._id === folder_id);
            let folder_name = folder?.name || "";
            folder_name = folder_name.slice(0, 30);
            const ending = idx === folder_list_active.length - 1 ? "" : ">";
            return (
              <p
                key={idx}
                className="inline margin_bottom"
              >{`${folder_name} ${ending} `}</p>
            );
          })}
        </section>
        <br />
        <button
          className="button_circle margin_bottom"
          id="blue"
          onClick={this.back_one_folder}
        >
          {"<"}
        </button>
        <br />
        <button
          className="button margin_bottom"
          id="outline_white"
          onClick={this.toggle_create_sub_folder}
        >
          <div className="button_icon inline margin_right" id="blue">
            +
          </div>
          New folder
        </button>
        {folder_list.length > 0 && (
          <section>
            <hr />
            {folder_list.map((folder, idx) => {
              return (
                <div
                  className={`button margin_bottom margin_right background_white ${
                    folder_id !== folder._id ? "outline_grey" : "outline_blue"
                  } `}
                  key={idx}
                >
                  <main className="two_container_auto">
                    <div
                      className="inline_center text_left"
                      onClick={this.click_folder.bind(this, folder._id)}
                      style={{ marginRight: "20px" }}
                    >
                      <p className="inline margin_right">{folder?.name}</p>
                      {folder?.folder_type === "kai_timeseries" && (
                        <>
                          <br />
                          <button
                            onClick={this.on_resume_kai.bind(this, folder)}
                            style={{ display: "inline-block" }}
                            title="Resume timeseries"
                            className="button_small background_orange inline margin_top"
                          >
                            Resume
                          </button>
                        </>
                      )}
                    </div>
                    <div
                      onClick={this.toggle_menu}
                      id={folder._id}
                      className="button almost_white"
                    >
                      ⫶
                    </div>
                  </main>
                </div>
              );
            })}
          </section>
        )}
        {layer_list.length > 0 ? (
          <section>
            <hr />
            <LayerListRender
              checkedLayers={checkedLayers}
              setCheckedLayers={this.setCheckedLayers}
              is_mode_checklist_layer={is_mode_checklist_layer}
              toggleIsModeChecklistLayer={this.toggleIsModeChecklistLayer}
              geo_layer_list={layer_list}
            />
          </section>
        ) : (
          <main>
            <hr />
            <section className="container_light">
              <p className="text_small">Tidak ada layer.</p>
            </section>
          </main>
        )}
      </main>
    );

    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px" }}>
          <button
            className="button_small margin_bottom blue"
            onClick={this.toggle_edit_folder}
          >
            Edit
          </button>
          <br />
          <button
            className="button_small margin_bottom red"
            onClick={this.toggle_delete_folder}
          >
            Delete
          </button>
        </div>
      </Menu>
    );

    const modal_create_sub_folder_content = modal_create_sub_folder && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_create_sub_folder}
        onClose={this.toggle_create_sub_folder}
      >
        <div className="box-body">
          <CreateFolder
            parent_folder={last_folder}
            toggle_create_sub_folder={this.toggle_create_sub_folder}
            from_modal={true}
          />
        </div>
      </Modal>
    );

    const modal_delete_folder_content = modal_delete_folder && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_delete_folder}
        onClose={this.toggle_delete_folder}
      >
        <div className="box-body">
          <DeleteFolder
            folder={folder_object_state}
            toggle_delete_folder={this.toggle_delete_folder}
            from_modal={true}
          />
        </div>
      </Modal>
    );

    const modal_edit_folder_content = modal_edit_folder && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_edit_folder}
        onClose={this.toggle_edit_folder}
      >
        <div className="box-body">
          <EditFolder
            folder={folder_object_state}
            toggle_edit_folder={this.toggle_edit_folder}
            from_modal={true}
          />
        </div>
      </Modal>
    );

    return (
      <main style={{ marginBottom: "100px" }}>
        {modal_menu_content}
        {modal_create_sub_folder_content}
        {modal_delete_folder_content}
        {modal_edit_folder_content}
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
})(LayerListModeButton);
