const initial_state = {
  api_keys: [],
  basemap_styles: [],
  monthly_report: null,
  report_by_date_range: null,
  daily_report_detail: null,
  users_who_can_access_map_service: []
};

export default function mapServiceReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_map_service":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "push_value_map_service":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };
    case "pull_value_map_service_string":
      return {
        ...state,
        ...pull_value_map_service_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };
    case "pull_value_map_service_object":
      return {
        ...state,
        ...pull_value_map_service_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id
        ),
      };
    default:
      return state;
  }
}

const pull_value_map_service_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};

const pull_value_map_service_object = (key, list, value, id) => {
  list = list.filter((item) => item[id] !== value);
  return { [key]: list };
};
