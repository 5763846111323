/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import BI_CARD_LIST from "./BI_CARD_LIST";

/*REDUX FUNCTION*/
import { set_value_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_CARD_LIST_MODE_EXPAND extends Component {
  state = {};

  on_select_group = (group_id) => {
    this.props.set_value_bi({
      key: "group_card_id_selected",
      value: group_id,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    let { bi_object, project_object_selected } = this.props.bi;

    //content
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );

    const group_card_order = setting_object?.group_card_order || [];
    const group_card_show_other =
      setting_object?.group_card_show_other || "show";
    let group_card_list = setting_object?.group_card_list || [];
    if (group_card_list.length > 0) {
      group_card_list = group_card_order.map((group_id) => {
        return group_card_list.find((element) => element._id === group_id);
      });
    }

    let card_list = bi_object?.card_list || [];
    card_list = card_list.filter(
      (item) => item.project_id === project_object_selected?._id
    );

    let card_id_with_group = [];
    group_card_list.forEach((item) => {
      if (item?.card_id_list?.length > 0)
        card_id_with_group = [...card_id_with_group, ...item.card_id_list];
    });

    const card_list_without_group = card_list.filter(
      (item) => !card_id_with_group.includes(item._id)
    );

    const layer_load_list = setting_object?.layer_load_list || [];
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let layer_final_list = [...layer_load_list];
    layer_view_map_list.forEach((item) => {
      if (!layer_load_list.includes(item)) layer_final_list.push(item);
    });

    return (
      <main>
        <section>
          {group_card_list.length > 0 && (
            <div>
              {group_card_list.map((item, idx) => {
                const card_id_list = item?.card_id_list || [];
                const card_list_item = card_list.filter((item) =>
                  card_id_list.includes(item?._id)
                );
                return (
                  <section key={idx}>
                    <p className="text_medium">{item?.name}</p>
                    <BI_CARD_LIST
                      card_list={card_list_item}
                      mode={this.props.mode}
                    />
                  </section>
                );
              })}
            </div>
          )}

          {group_card_show_other === "show" && (
            <>
              <p className="text_medium">{dict?.["Others"]?.[language]}</p>
              <BI_CARD_LIST
                card_list={card_list_without_group}
                mode={this.props.mode}
              />
            </>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_bi })(
  BI_CARD_LIST_MODE_EXPAND
);
