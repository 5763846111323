/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { edit_css_bi, set_value_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import css_string_init from "./css_string_init";
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_EDIT_CSS extends Component {
  state = {
    css_string: css_string_init(),
  };

  componentDidMount() {
    const { bi_object } = this.props.bi;
    if (bi_object?.css_string) {
      this.setState({ css_string: bi_object.css_string });
    }
  }

  on_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  on_edit_css_bi = () => {
    const { css_string } = this.state;
    const { bi_object } = this.props.bi;
    const body = {
      _id: bi_object._id,
      css_string,
    };
    this.props.edit_css_bi(body);
  };

  on_close_css = () => {
    this.props.set_value_bi({
      key: "modal_edit_css",
      value: false,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { css_string } = this.state;

    //global props

    //content

    return (
      <main>
        <section>
          <h1 className="margin_bottom">CSS for style editor</h1>
          <button
            className="button button_white margin_bottom margin_right"
            onClick={this.on_edit_css_bi}
          >
            {dict?.["Save"]?.[language]}
          </button>
          <button
            className="button button_white margin_bottom margin_right"
            onClick={this.on_close_css}
          >
            {dict?.["Close"]?.[language]}
          </button>
        </section>
        <textarea
          className="bi_input_css"
          onChange={this.on_change}
          value={css_string}
          name="css_string"
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { edit_css_bi, set_value_bi })(
  BI_EDIT_CSS
);
