/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { create_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class CREATE_BI extends Component {
  state = {
    name: "",
  };

  on_change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  on_submit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const body = {
      name,
      folder_list: [],
      project_list: [],
      setting_list: [],
      card_list: [],
    };
    this.props.create_bi(body);
    this.props.toggle_create_bi();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { name } = this.state;

    //global props

    //content
    return (
      <main>
        <form onSubmit={this.on_submit}>
          <h2 className="margin_bottom">
            {dict?.["Create New Business Intelligence Dashboard"]?.[language]}
          </h2>
          <p className="margin_top">{dict?.["Enter BI name"]?.[language]}</p>
          <input
            name="name"
            value={name}
            onChange={this.on_change}
            type="text"
            className="margin_bottom"
            placeholder={dict?.["BI name"]?.[language]}
          />
          <br />
          <br />
          <button type="submit" className="button background_blue">
            Submit
          </button>
        </form>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
});

export default connect(mapStateToProps, { create_bi })(CREATE_BI);
