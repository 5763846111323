// import Modal from "../common_modal/Modal";
import Modal from "../reuseable/Modal";
import React, { Component } from 'react'
import { connect } from "react-redux";

import dict from "../../Data/dict.json";
import { redeem_code } from "../../App/actions/redeemActions";
import { snackbar } from "../../App/actions/snackbarActions";


class RedeemButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      modal_confirmation: false,
      // notification: {
      //   is_open: false,
      //   status: "error",
      //   message: ""
      // }
    }
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   const notification_old = prevProps?.notification;
  //   const notification_new = this.state.notification;
  //   if (JSON.stringify(notification_old) !== JSON.stringify(notification_new)) {
  //     this.props.snackbar({
  //       is_open: notification_new?.is_open,
  //       status: notification_new?.status,
  //       message: notification_new?.message
  //     });
  //   }
  // }

  handle_change = (e) => {
    const value = e.target.value
    this.setState({
      code: value
    })
  }

  handle_redeem = async () => {
    const { code } = this.state;
    const body = {
      code
    }
    const result = await this.props.redeem_code(body);
    const { status, data, message } = result;
    if (
      status === 200
    ) {

      this.toggle_modal_confirmation();
      if (!data?.response_payment?.midtrans) {
        this.props.snackbar({
          is_open: true,
          status: "success",
          message: "Transaction Successful"
        });
      } else {
        this.toggleMidtrans(data, snackbar)
      }

    } else {
      this.toggle_modal_confirmation();
      this.props.snackbar({
        is_open: true,
        status: "error",
        message: message || "Failed to create transaction"
      });
    }
  }

  toggleMidtrans = (data) => {
    const show_snackbar = this.props.snackbar;
    if (data?.response_payment?.midtrans?.token) {
      window.snap.pay(data?.response_payment?.midtrans?.token, {
        onSuccess: function (result) {
          /* You may add your own implementation here */
          show_snackbar({
            is_open: true,
            status: "success",
            message: "Payment Success"
          })
        },
        onPending: function (result) {
          /* You may add your own implementation here */
          // alert("wating your payment!");
        },
        onError: async function (result) {
          /* You may add your own implementation here */
          show_snackbar({
            is_open: true,
            status: "error",
            message: "Payment Failed"
          });
        },
        onClose: function () {
          /* You may add your own implementation here */
          show_snackbar({
            is_open: false,
            status: "info",
            message: ""
          });
          // history.push("/purchase_list");
        },
      });
    }
  };

  toggle_modal_confirmation = () => {
    const { modal_confirmation } = this.state;
    this.setState({
      modal_confirmation: !modal_confirmation
    })
  }

  is_code_format_valid = () => {
    const { code } = this.state;
    const pattern = /^(?=.*[A-Z\d]).{6}$/;
    return pattern.test(code);
  }

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    // props
    const { license_key } = this.props;

    // state
    const { code, modal_confirmation } = this.state
    const is_valid_code = this.is_code_format_valid(code)


    const modal_confirmation_content =
      modal_confirmation &&
      <Modal
        modalSize="small"
        isOpen={modal_confirmation}
        onClose={this.toggle_modal_confirmation}
      >
        <main className="box-body flex flex_col gap_10 align_center">
          <label className="bold font_24">{dict["Confirmation"][language]}</label>
          <p>{dict["Are you sure you want to redeem the code?"][language]}</p>
          <div className="center_perfect gap_10">
            <button
              onClick={this.handle_redeem}
              className="button_inactive bg_darkBlue rounded_50 text_white padding_5 w_130 h_35">{dict["Yes"][language]}</button>
            <button
              onClick={this.toggle_modal_confirmation}
              className="button_inactive border rounded_50 border_darkBlue text_darkBlue padding_5 w_130 h_35">{dict["No"][language]}</button>
          </div>
        </main>
      </Modal>

    return (
      <main className="center_perfect marginTop_50 h_full">
        {license_key !== "license_3" &&
          <div className="redeem_field center_perfect">
            <input
              className="border_none font_16 bold text_black"
              type="text"
              placeholder="Input Code..."
              value={code}
              onChange={this.handle_change}
            />
            {
              is_valid_code &&
              <button
                style={{
                  background: "#006ABA",
                }}
                className="button_inactive rounded_5 text_white margin_y_5 w_100 padding_y_15"
                onClick={this.toggle_modal_confirmation}
              >
                Redeem
              </button>
            }
          </div>}
        {modal_confirmation_content}
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  snackbar_props: state.snackbar
});

export default connect(mapStateToProps, {
  redeem_code,
  snackbar
})(RedeemButton);