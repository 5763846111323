/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class LEGEND_NUMBER_STEP extends Component {
  state = {};

  on_filter_by_number = ({ field_key, idx, value_min, value_max }) => {
    console.table({ field_key, value_min, value_max });
  };

  render() {
    //local storage
    // const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { geo_layer_list, layer_id } = this.props.layer;

    //content
    const layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const style_array = layer?.style_array || [];
    const default_style_key = layer?.default_style_key || "";
    const style_object = style_array.find(
      (item) => item.key === default_style_key
    );

    const {
      style_type,
      // min, max, steps,
      paint_object,
      field_key,
    } = style_object;
    let color_key;
    if (style_type === "circle") {
      color_key = "circle-color";
    } else if (style_type === "line") {
      color_key = "line-color";
    } else if (style_type === "fill") {
      color_key = "fill-color";
    }

    let rows = [];
    const array_number_step = paint_object?.[color_key] || [];
    for (let idx = 3; idx < array_number_step.length; idx += 2) {
      const is_last_value = idx + 1 === array_number_step.length - 1;
      const is_first_value = idx === 3; // Check if it's the first numeric threshold
      const number_1 = new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(array_number_step[idx]);
      const number_2 = new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(array_number_step[idx + 2]);
      let value_min = 0;
      let value_max = 0;
      if (is_first_value) {
        value_min = "min";
      } else {
        value_min = array_number_step[idx];
      }
      if (is_last_value) {
        value_max = "max";
      } else {
        value_max = array_number_step[idx + 2];
      }
      let range_text;
      if (is_first_value) {
        range_text = `< ${number_1}`; // Open-ended range at the bottom
      } else if (is_last_value) {
        range_text = `> ${number_1}`; // Open-ended range at the top
      } else {
        range_text = `${number_1} - ${number_2}`;
      }
      rows.push(
        <tr
          key={idx}
          className="cursor_pointer"
          onClick={this.on_filter_by_number.bind(
            this,
            field_key,
            value_min,
            value_max
          )}
        >
          <td className="text_right">{range_text}</td>
          <td style={{ width: "20px", verticalAlign: "middle" }}>
            <div
              style={{
                backgroundColor: array_number_step[idx + 1], // Color is at the next index
                width: "20px",
                height: "20px",
                border: "1px solid black",
                margin: "auto",
              }}
            />
          </td>
        </tr>
      );
    }
    return (
      <main style={{ maxHeight: "250px", overflowY: "scroll" }}>
        <table className="table full_width">
          <thead>
            <tr>
              <th>Range</th>
              <th>Color</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
})(LEGEND_NUMBER_STEP);
