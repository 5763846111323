const default_prevented_string = [
  "undefined",
  "NaN",
  "null",
  "NaN-NaN-NaN",
  "62undefined",
];

/**
 * Function is_not_empty ini untuk mengecek apakah data kosong atau tidak.
 * 
 * Yang dianggap kosong: 
 * 
 * - undefined
 * - NaN, 
 * - null
 * - NaN-NaN-NaN
 * - 62undefined
 * 
 * Yang dianggap berisi:
 * 
 * - 0
 * - ""
 */
export default function is_not_empty(value, not_allowed = []) {
  if (typeof value === "string") {
    if (default_prevented_string.includes(value)) return false;
  }

  if (not_allowed.includes(value)) return false;

  // 0
  // string
  // number
  // empty string
  // filled array or empty array
  // filled object or empty object
  if (value || value === 0 || value === "") {
    return true;
  }
  // Undefined
  // Null
  // NaN
  else {
    return false;
  }
}
