import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";

export default class C9_Lingkungan extends Component {
  render() {
    return (
      <table className="table_pdf C9">
        <thead>
          <tr>
            <td colSpan={2} className="tr_border_bottom_pdf">
              <p className="subtitle_pdf center_perfect">LINGKUNGAN</p>
            </td>
          </tr>
        </thead>
        <tbody>
          {/* 1 */}
          <tr>
            <td>
              <p>{`Aset yang tidak memiliki akses jalan umum`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset_yang_tidak_memiliki_akses_jalan_umum_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 2 */}
          <tr>
            <td>
              <p>
                {`Aset berada dalam radius ≤ 50 m dari rumah ibadah atau sekolah umum (yang ramai dan rawan macet)`}
              </p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵dalam⎵radius⎵≤⎵50⎵m⎵dari⎵rumah⎵ibadah⎵atau⎵sekolah⎵umum_(yang⎵ramai⎵dan⎵rawan⎵mace_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* <tr>
            <td>
              <p>{`Aset Berada Pada Lingkungan Kumuh`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵pada⎵lingkungan⎵kumuh_pdf"
                {...this.props}
              />
            </td>
          </tr> */}

          {/* 3 */}
          <tr>
            <td>
              <p>{`Aset memiliki akses jalan ≤ 3 m dan / atau jalan buntu`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵memiliki⎵akses⎵jalan⎵<=⎵3⎵m⎵dan⎵/⎵atau⎵jalan⎵buntu_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 4 */}
          <tr>
            <td>
              <p>{`Aset berada di bawah permukaan jalan umum ≥ 50 cm`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵dibawah⎵permukaan⎵jalan⎵umum⎵>=⎵50⎵cm_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 5 */}
          <tr>
            <td>
              <p>{`Aset berada dekat dengan rel kereta api, rel LRT, rel MRT berjarak ≤ 25m`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵dekat⎵dengan⎵rel⎵kereta⎵api,⎵rel⎵lrt,⎵rel⎵mrt⎵berjarak<=⎵25m_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 6 */}
          <tr>
            <td>
              <p>{`Aset tidak sesuai dengan rencana tata kota atau berada di zona jalur hijau, fasilitas umum, hutan lindung 
dan perkantoran pemerintah`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵tidak⎵sesuai⎵dengan⎵rencana⎵tata⎵kota⎵atau⎵berada⎵di⎵zona⎵jalur⎵hijau,⎵fasilitas⎵umum,⎵hutan⎵lindung⎵dan⎵perkantoran⎵pemerintah_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 7 */}
          <tr>
            <td>
              <p>{`Aset berada di daerah rawan longsor, pesisir pantai, konservasi dan pertambangan berjarak d 100 m`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵di⎵daerah⎵rawan⎵longsor,⎵pesisir⎵pantai,⎵konservasi⎵dan⎵pertambangan⎵berjarak⎵d⎵100⎵m_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 8 */}
          <tr>
            <td>
              <p>{`Aset berada dekat dengan rawa / di hutan radius 100 m`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵dekat⎵dengan⎵rawa⎵/⎵di⎵hutan⎵radius⎵100⎵m_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 9 */}
          <tr>
            <td>
              <p>{`Aset berupa tanah dan / atau bangunan yang berdiri dalam radius 30 meter dari jalur distribusi pipa gas bumi / gas alam`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berupa⎵tanah⎵dan⎵/⎵atau⎵bangunan⎵yang⎵berdiri⎵dalam⎵radius⎵30⎵meter⎵dari⎵jalur⎵distribusi⎵pipa⎵gas⎵bumi⎵/⎵gas⎵alam_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 10 */}
          <tr>
            <td>
              <p>{`Aset berdekatan dengan menara / tower BTS yang tertanam dengan jarak ≤ 5m`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berdekatan⎵dengan⎵menara⎵/⎵tower⎵bts⎵yang⎵tertanam⎵dengan⎵jarak⎵<=⎵5m_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 11 */}
          <tr>
            <td>
              <p>{`Aset bermasalah secara hukum / dalam sengketa`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵bermasalah⎵secara⎵hukum⎵/⎵dalam⎵sengketa_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 12 */}
          <tr>
            <td>
              <p>{`Aset berada di daerah rawan banjir`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵di⎵daerah⎵rawan⎵banjir_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 13 */}
          <tr>
            <td>
              <p>{`Aset berada ≤ 30m dari kabel terluar SUTET / SUTT / SUTTAS`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵<=⎵30m⎵dari⎵kabel⎵terluar⎵sutet⎵/⎵sutt⎵/⎵suttas_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 14 */}
          <tr>
            <td>
              <p>{`Aset berada dalam radius ≤ 50m dari pemakaman keluarga / umum (TPU)`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵dalam⎵radius⎵<=⎵50m⎵dari⎵pemakaman⎵keluarga⎵/⎵umum⎵(tpu)_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 15 */}
          <tr>
            <td>
              <p>{`Aset berada dekat sungai (Tepi sungai berturap beton ≤ 10m, Tepi sungai tidak berturap beton ≤ 30m)`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵dekat⎵sungai⎵(tepi⎵sungai⎵berturap⎵beton⎵<=⎵10m,⎵tepi⎵sungai⎵tidak⎵berturap⎵beton⎵<=⎵30m)_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 16 */}
          <tr>
            <td>
              <p>{`Aset berada pada posisi tusuk sate`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵pada⎵posisi⎵tusuk⎵sate_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 17 */}
          <tr>
            <td>
              <p>{`Aset berdekatan dengan SPBU radius ± 50 m`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berdekatan⎵dengan⎵spbu⎵radius⎵±⎵50⎵m_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 18 */}
          <tr>
            <td>
              <p>{`Aset yang dipergunakan untuk : Rumah sakit, klinik pengobatan, Rumah Ibadah, Kantor / markas Partai 
Politik, Organisasi Masa, Organisasi Keagamaan, Sekolah Umum`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵yang⎵dipergunakan⎵untuk⎵:⎵rumah⎵sakit,⎵klinik⎵pengobatan,⎵rumah⎵ibadah,⎵kantor⎵/⎵markas⎵partai⎵politik,⎵organisasi⎵masa,⎵organisasi⎵keagamaan,⎵sekolah⎵umum_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 19 */}
          <tr>
            <td>
              <p>{`Aset berupa rumah panggung / dinding kayu / bilik`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berupa⎵rumah⎵panggung⎵/⎵dinding⎵kayu⎵/⎵bilik_pdf"
                {...this.props}
              />
            </td>
          </tr>
          {/* 20 */}
          <tr>
            <td>
              <p>{`Aset berada dekat dengan Tempat Pembuangan Sampah Sementara (TPS) berjarak ≥ 100m dan Tempat 
Pembuangan Akhir (TPA) berjarak ≤ 500m`}</p>
            </td>
            <td>
              <DropdownOrData
                id="aset⎵berada⎵dekat⎵dengan⎵tempat⎵pembuangan⎵sampah⎵sementara⎵(tps)⎵berjarak⎵>=⎵100m⎵dan⎵tempat⎵pembuangan⎵akhir⎵(tpa)⎵berjarak⎵<=⎵500m_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 21 */}
          <tr>
            <td>
              <p>{`Akses jalan masih berupa tanah atau batu`}</p>
            </td>
            <td>
              <DropdownOrData
                id="akses⎵jalan⎵masih⎵berupa⎵tanah⎵atau⎵batu_pdf"
                {...this.props}
              />
            </td>
          </tr>

          {/* 22 */}
          <tr>
            <td>
              <p>{`Titik lokasi dekat dengan krematorium/rumah abu/ rumah duka dengan radius ≤ 50 m`}</p>
            </td>
            <td>
              <DropdownOrData
                id="titik⎵lokasi⎵dekat⎵dengan⎵krematorium/rumah⎵abu/⎵rumah⎵duka⎵dengan⎵radius⎵<50⎵m_pdf"
                {...this.props}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
