// function gometry_to_area_or_length(geometry, unit = "meter") {
//   const { coordinates, type } = geometry;

//   let result = {};
//   if (type === "Polygon") {
//     const pol = polygon(coordinates);
//     result["value"] = area(pol).toFixed(2);
//     result["unit"] = "m\u00B2";
//   } else if (type === "MultiPolygon") {
//     const pol = multiPolygon(coordinates);
//     result["value"] = area(pol).toFixed(2);
//     result["unit"] = "m\u00B2";
//   } else if (type === "MultiLineString") {
//     const pol = multiLineString(coordinates);
//     result["value"] = length(pol, { units: "meters" }).toFixed(2);
//     result["unit"] = "m";
//   } else if (type === "LineString") {
//     const pol = lineString(coordinates);
//     result["value"] = length(pol, { units: "meters" }).toFixed(2);
//     result["unit"] = "m";
//   }

//   if (type === "Polygon" || type === "MultiPolygon") {
//     switch (unit) {
//       case "square_meter":
//         // do nothing
//         break;
//       case "hectare":
//         result["value"] = (+result["value"] / 10000).toFixed(2);
//         result["unit"] = "ha";
//         break;
//       case "square_kilometer":
//         result["value"] = (+result["value"] / 1000000).toFixed(2);
//         result["unit"] = "km\u00B2";
//         break;
//     }
//   } else if (type === "MultiLineString" || type === "LineString") {
//     switch (unit) {
//       case "meter":
//         // do nothing
//         break;
//       case "kilometer":
//         result["value"] = (+result["value"] / 1000).toFixed(2);
//         result["unit"] = "km";
//         break;
//       case "mile":
//         result["value"] = (+result["value"] / 1609.344).toFixed(2);
//         result["unit"] = "mi";
//         break;
//     }
//   }

//   return result;
// }

// const polygon_units = [
//   { key: "square_kilometer", name: "Square Kilometer", symbol: "km\u00B2" },
//   { key: "hectare", name: "Hectare", symbol: "ha" },
//   { key: "square_meter", name: "Square Meter", symbol: "m\u00B2" },
// ];
// const line_units = [
//   { key: "kilometer", name: "Kilometer", symbol: "km" },
//   { key: "meter", name: "Meter", symbol: "m" },
//   { key: "mile", name: "Mile", symbol: "mi" },
// ];

// export { gometry_to_area_or_length, polygon_units, line_units };

import calculate_length from "@turf/length";
import calculate_area from "@turf/area";
import polygon from "turf-polygon";
import lineString from "turf-linestring";
import multiPolygon from "turf-multipolygon";
import multiLineString from "turf-multilinestring";

function gometry_to_area_or_length(geometry, unit = "meter") {
  const { coordinates, type } = geometry;

  let result = {};
  if (type === "Polygon") {
    const pol = polygon(coordinates);
    result["value"] = calculate_area(pol).toFixed(2);
    result["unit"] = "m\u00B2";
  } else if (type === "MultiPolygon") {
    const pol = multiPolygon(coordinates);
    result["value"] = calculate_area(pol).toFixed(2);
    result["unit"] = "m\u00B2";
  } else if (type === "MultiLineString") {
    const pol = multiLineString(coordinates);
    result["value"] = calculate_length(pol, { units: "meters" }).toFixed(2);
    result["unit"] = "m";
  } else if (type === "LineString") {
    const pol = lineString(coordinates);
    result["value"] = calculate_length(pol, { units: "meters" }).toFixed(2);
    result["unit"] = "m";
  }
  result = convertUnit(result["value"], unit);

  return result;
}

// from meter to another unit
function convertUnit(value, to_unit) {
  let result = {};
  if (line_units.map((unit) => unit.key).includes(to_unit)) {
    switch (to_unit) {
      case "meter":
        result["value"] = (+value).toFixed(2);
        result["unit"] = "m";
        break;
      case "kilometer":
        result["value"] = (+value / 1000).toFixed(2);
        result["unit"] = "km";
        break;
      case "mile":
        result["value"] = (+value / 1609.344).toFixed(2);
        result["unit"] = "mi";
        break;
      default:
        result["value"] = (+value).toFixed(2);
        result["unit"] = "m";
    }
  } else if (polygon_units.map((unit) => unit.key).includes(to_unit)) {
    switch (to_unit) {
      case "square_meter":
        result["value"] = (+value).toFixed(2);
        result["unit"] = "m\u00B2";
        break;
      case "hectare":
        result["value"] = (+value / 10000).toFixed(2);
        result["unit"] = "ha";
        break;
      case "square_kilometer":
        result["value"] = (+value / 1000000).toFixed(2);
        result["unit"] = "km\u00B2";
        break;
      default:
        result["value"] = (+value).toFixed(2);
        result["unit"] = "m\u00B2";
    }
  }
  return result;
}

const polygon_units = [
  { key: "square_kilometer", name: "Square Kilometer", symbol: "km\u00B2" },
  { key: "hectare", name: "Hectare", symbol: "ha" },
  { key: "square_meter", name: "Square Meter", symbol: "m\u00B2" },
];
const line_units = [
  { key: "kilometer", name: "Kilometer", symbol: "km" },
  { key: "meter", name: "Meter", symbol: "m" },
  { key: "mile", name: "Mile", symbol: "mi" },
];

export { gometry_to_area_or_length, polygon_units, line_units, convertUnit };
