/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { create_folder_project } from "../../App/actions/folderProjectAction";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class CREATE_FOLDER_PROJECT extends Component {
  state = {
    name: "",
  };

  on_change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  on_submit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const body = {
      name,
      folder_parent_id: this.props.project.folder_id_selected,
      project_id_list: [],
    };
    this.props.create_folder_project(body);
    this.props.toggle_create_folder();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { name } = this.state;

    //global props
    const { folder_object_selected } = this.props.project;

    //content
    return (
      <main>
        <form onSubmit={this.on_submit}>
          <h2 className="margin_bottom">
            {dict?.["Create a new folder"]?.[language]}
          </h2>
          {folder_object_selected?._id && (
            <section className="container_light margin_bottom">
              <p>
                {dict?.["In a folder"]?.[language]}: <br /> <br />
                <b className="text_medium">{folder_object_selected?.name}</b>
              </p>
            </section>
          )}
          <p className="margin_top">
            {dict?.["Enter a folder name"]?.[language]}
          </p>
          <input
            name="name"
            value={name}
            onChange={this.on_change}
            type="text"
            className="margin_bottom"
            placeholder={dict?.["Folder name"]?.[language]}
            data-mapid="insertFolderName"
          />
          <br />
          <br />
          <button
            type="submit"
            className="button background_blue"
            data-mapid="clickSubmit"
          >
            Submit
          </button>
        </form>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, { create_folder_project })(
  CREATE_FOLDER_PROJECT
);
