export const c13_setup_object_penilaian_dan_data_pembanding = {
  label: [
    "Jenis Properti",
    "Alamat",
    "Jarak dengan Aktivasi",
    "Sumber Data",
    "Telepon",
    "Keterangan",
    "Data Harga",
    "Penawaran Transaksi",
    "Waktu Penawaran Transaksi",
    "Legalitas Kepemilikan",
    "Luas Tanah",
    "Bentuk Tanah",
    "Lebar Depan",
    "Lebar Jalan",
    "Letak Tanah",
    "Kondisi Tanah",
    "Peruntukan",
    "Kontur Tanah Topografi",
    "Luas Bangunan",
    "Indikasi Nilai Properti",
    "Perkiraan RCN Bangunan",
    "Perkiraan Nilai Pasar Bangunan",
    "Indikasi Nilai Pasar Bangunan",
    "Indikasi Nilai Tanah",
    "Indikasi Nilai Tanah m²",
  ],
  data_pembanding: [
    "c13_jenis_properti_data_pembanding_pdf",
    "c13_alamat_data_pembanding_pdf",
    "c13_jarak_dengan_aktivasi_data_pembanding_pdf",
    "c13_sumber_data_data_pembanding_pdf",
    "c13_telepon_data_pembanding_pdf",
    "c13_keterangan_data_pembanding_pdf",
    "c13_data_harga_data_pembanding_pdf",
    "c13_penawaran_transaksi_data_pembanding_pdf",
    "c13_waktu_penawaran_transaksi_data_pembanding_pdf",
    "c13_legalitas_kepemilikan_data_pembanding_pdf",
    "c13_luas_tanah_data_pembanding_pdf",
    "c13_bentuk_tanah_data_pembanding_pdf",
    "c13_lebar_depan_data_pembanding_pdf",
    "c13_lebar_jalan_data_pembanding_pdf",
    "c13_letak_tanah_data_pembanding_pdf",
    "c13_kondisi_tanah_data_pembanding_pdf",
    "c13_peruntukan_data_pembanding_pdf",
    "c13_kontur_tanah_topografi_data_pembanding_pdf",
    "c13_luas_bangunan_data_pembanding_pdf",
    "c13_indikasi_nilai_properti_data_pembanding_pdf",
    "c13_perkiraan_rcn_bangunan_data_pembanding_pdf",
    "c13_perkiraan_nilai_pasar_bangunan_data_pembanding_pdf",
    "c13_indikasi_nilai_pasar_bangunan_data_pembanding_pdf",
    "c13_indikasi_nilai_tanah_data_pembanding_pdf",
    "c13_indikasi_nilai_tanah_m2_data_pembanding_pdf",
    "c13_foto_data_pembanding_pdf",
  ],
  object_penilaian: [
    "c13_jenis_properti_object_penilaian_pdf",
    "c13_alamat_object_penilaian_pdf",
    "c13_jarak_dengan_aktivasi_object_penilaian_pdf",
    "c13_sumber_data_object_penilaian_pdf",
    "c13_telepon_object_penilaian_pdf",
    "c13_keterangan_object_penilaian_pdf",
    "c13_data_harga_object_penilaian_pdf",
    "c13_penawaran_transaksi_object_penilaian_pdf",
    "c13_waktu_penawaran_transaksi_object_penilaian_pdf",
    "c13_legalitas_kepemilikan_object_penilaian_pdf",
    "c13_luas_tanah_object_penilaian_pdf",
    "c13_bentuk_tanah_object_penilaian_pdf",
    "c13_lebar_depan_object_penilaian_pdf",
    "c13_lebar_jalan_object_penilaian_pdf",
    "c13_letak_tanah_object_penilaian_pdf",
    "c13_kondisi_tanah_object_penilaian_pdf",
    "c13_peruntukan_object_penilaian_pdf",
    "c13_kontur_tanah_topografi_object_penilaian_pdf",
    "c13_luas_bangunan_object_penilaian_pdf",
    "c13_indikasi_nilai_properti_object_penilaian_pdf",
    "c13_perkiraan_rcn_bangunan_object_penilaian_pdf",
    "c13_perkiraan_nilai_pasar_bangunan_object_penilaian_pdf",
    "c13_indikasi_nilai_pasar_bangunan_object_penilaian_pdf",
    "c13_indikasi_nilai_tanah_object_penilaian_pdf",
    "c13_indikasi_nilai_tanah_m2_object_penilaian_pdf",
  ],
};
