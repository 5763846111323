import React, { Component } from "react";
import { comma_every_3_digits } from "../../App/validation/format_number";

export default class Slider extends Component {
  state = {
    value: this.props.params.value || 0,
  };

  onMouseUp = () => {
    let newParams = { ...this.props.params };
    newParams["value"] = Number(this.state.value);
    newParams.row.value = Number(this.state.value);
    this.props.handleEditCellChange(
      this.props.editProperties,
      this.props.layer_id,
      newParams
    );
  };

  render() {
    const { min, max, step } = this.props.params.colDef;
    const value = +this.state.value || 0;
    return (
      <section
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <input
            type="range"
            style={{ width: "120px", padding: "0px" }}
            min={min}
            max={max}
            step={step}
            value={value}
            onMouseUp={() => {
              this.onMouseUp();
            }}
            onChange={(event) => {
              this.setState({ value: event.target.value });
            }}
          />
          <div
            style={{
              paddingLeft: 15,
            }}
          >
            {comma_every_3_digits(this.state.value)}
          </div>
        </div>
      </section>
    );
  }
}
