/**
 * Keterangan:
 * 
 * Environment yang dapat menggunakan fitur laporan pdf:
 * localhost, geo-alpha.mapid.io, geo-beta.mapid.io, danamas.mapid.io
 */
export const clients_that_use_laporan_pdf = [
  // "bbwsciliwungcisadane.mapid.io",
  "danamas.mapid.io",
  "localhost",
  "geo-alpha.mapid.io",
  "geo-beta.mapid.io",
];

export const ip_dev = "192.168.1"

/**
 * Keterangan:
 * 
 * Functional component ini adalah component yang dapat disebut sebagai saklar, tombol download dengan template manakah yang akan di tampilkan pada fitur laporan pdf. Apakah template danamas atau template bbws cilicis?
 */
export const which_laporan_cell_download_will_be_active = ({
  // SetupDownloadLaporanPdfCilicis,
  SetupDownloadLaporanPdfDanamas,
  domain,
  setRef,
  row,
}) => {
  let setupDownloadLaporanPdf = <></>;
  // if (domain === "bbwsciliwungcisadane.mapid.io") {
  //   setupDownloadLaporanPdf = (
  //     <SetupDownloadLaporanPdfCilicis
  //       ref={(response) => {
  //         setRef(response);
  //       }}
  //       mode="preview"
  //       row={row}
  //     />
  //   );
  // } else 
  if (domain === "danamas.mapid.io") {
    setupDownloadLaporanPdf = (
      <SetupDownloadLaporanPdfDanamas
        ref={(response) => {
          setRef(response);
        }}
        mode="preview"
        row={row}
      />
    );
  } else if (
    domain === "geo-alpha.mapid.io" ||
    domain === "geo-beta.mapid.io"
  ) {
    setupDownloadLaporanPdf = (
      <SetupDownloadLaporanPdfDanamas
        ref={(response) => {
          setRef(response);
        }}
        mode="preview"
        row={row}
      />
    );
  } else if (domain === "localhost" || domain?.includes(ip_dev)) {
    setupDownloadLaporanPdf = (
      // cilicis
      // <SetupDownloadLaporanPdfCilicis
      //   ref={(response) => {
      //     setRef(response);
      //   }}
      //   mode="preview"
      //   row={row}
      // />

      //   danamas
      <SetupDownloadLaporanPdfDanamas
        ref={(response) => {
          setRef(response);
        }}
        mode="preview"
        row={row}
      />
    );
  }
  return setupDownloadLaporanPdf;
};


/**
 * Keterangan:
 * 
 * Functional component ini adalah component yang dapat disebut sebagai saklar, template manakah yang akan di tampilkan pada fitur laporan pdf. Apakah template danamas atau template bbws cilicis?
 */
export const which_laporan_setup_will_be_active = ({
  // SetupDownloadLaporanPdfCilicis,
  SetupDownloadLaporanPdfDanamas,
  domain,
  fields,
  layer_id,
}) => {
  let setupDownloadLaporanPdf = <></>;
  // if (domain === "bbwsciliwungcisadane.mapid.io") {
  //   setupDownloadLaporanPdf = (
  //     <SetupDownloadLaporanPdfCilicis
  //       mode="setup"
  //       fields={fields}
  //       layer_id={layer_id}
  //     />
  //   );
  // } else 
  if (domain === "danamas.mapid.io") {
    setupDownloadLaporanPdf = (
      <SetupDownloadLaporanPdfDanamas
        mode="setup"
        fields={fields}
        layer_id={layer_id}
      />
    );
  } else if (
    domain === "geo-alpha.mapid.io" ||
    domain === "geo-beta.mapid.io"
  ) {
    setupDownloadLaporanPdf = (
      <SetupDownloadLaporanPdfDanamas
        mode="setup"
        fields={fields}
        layer_id={layer_id}
      />
    );
  } else if (domain === "localhost" || domain?.includes(ip_dev)) {
    setupDownloadLaporanPdf = (
      // cilicis
      // <SetupDownloadLaporanPdfCilicis
      //   mode="setup"
      //   fields={fields}
      //   layer_id={layer_id}
      // />

      // danamas
      <SetupDownloadLaporanPdfDanamas
        mode="setup"
        fields={fields}
        layer_id={layer_id}
      />
    );
  }
  return setupDownloadLaporanPdf;
};
