import React, { Component } from "react";

export default class ShortText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.params.value || "",
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = () => {
    let newParams = { ...this.props.params };
    newParams["value"] = this.state.value;
    newParams.row.value = this.state.value;
    this.props.handleEditCellChange(
      this.props.editProperties,
      this.props.layer_id,
      newParams
    );
  };

  render() {
    return (
      <>
        {/* {this.state.value} */}
        <input
          value={this.state.value}
          type="text"
          // style={{
          //   overflowY: "auto",
          // }}
          onChange={(event) => this.handleChange(event)}
          onBlur={(event) => this.handleSubmit(event)}
        />
      </>
    );
  }
}
