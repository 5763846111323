import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import { calculateWithoutConvert } from "../validation/convert_data";

//KAI FUNCTION

const SERVER_URL = geoServerBaseUrl;

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/*NON API*/

export const set_loading_bi = (loading_item) => {
  return {
    type: "set_loading_bi",
    payload: loading_item,
  };
};

export const clear_loading_bi = (loading_item) => {
  return {
    type: "clear_loading_bi",
    payload: loading_item,
  };
};

/*GET METHODE*/

/**
 * @route :
 * @endpoint :
 * @methode : NON API
 * @query : query_array
 * @query_bi :
 */

export const get_layer_detail_bi_many = (query) => async (dispatch) => {
  const { query_array, project_id } = query;
  const total = query_array.length;

  try {
    dispatch(set_loading_bi("get_layer_detail_bi_many"));

    dispatch({
      type: "set_value_bi",
      payload: {
        key: "total_progress_layers",
        value: total,
      },
    });
    const delay_constant = 0;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_constant));
    const parent_function = () => {
      return query_array.reduce(
        (last_promise, object, index) =>
          last_promise.then((result_sum) =>
            child_function(object, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (query_child, index) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            query_child.is_ignore_progress = true;
            query_child.index = index;
            await dispatch(get_layer_detail_bi(query_child));
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      dispatch(clear_loading_bi("get_layer_detail_bi_many"));
      dispatch({
        type: "edit_inside_array",
        payload: {
          key: "project_list",
          _id: project_id,
          id_key: "is_data_loaded",
          value: true,
        },
      });
      dispatch({
        type: "edit_inside_object",
        payload: {
          key: "project_object_selected",
          id_key: "is_data_loaded",
          value: true,
        },
      });
      dispatch({
        type: "trigger_rerender",
      });
      dispatch({
        type: "trigger_fly_map",
      });
    });
  } catch (error) {}
};

/**
 * @route : get_layer_detail_bi
 * @endpoint :
 * @methode : GET
 * @query : geo_project_id, geo_layer_id, is_ignore_set_layer_id, folder_id, capex_value, geo_layer_item, skipLoading, tableStatus, geometryStatus, flyStatus,
 * @query_bi :
 */
export const get_layer_detail_bi = (query) => async (dispatch) => {
  const {
    geo_project_id,
    geo_layer_id,
    // folder_id,
    capex_value,
    geo_layer_item,
    is_ignore_progress,
    index,
  } = query;
  try {
    const { is_custom_endpoint } = geo_layer_item || {};
    if (geo_layer_id) {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      if (!is_ignore_progress) {
        dispatch(set_loading_bi("get_layer_detail_bi"));
      }
      const res = await axios.get(
        bun_server +
          `/moneys_bun/get_layer_detail_by_id/${geo_layer_id}/${geo_project_id}`,
        config
      );
      let geo_layer = res.data || {};
      let geojson = geo_layer?.geojson || {};
      // PROSES READ FEATURE UNTUK ARCHITECTURE BARU V2
      if (res.data?.architecture === "v2") {
        geojson.features = [];
        // CUSTOM API
        if (is_custom_endpoint) {
          const config = {
            headers: {
              accesstoken: localStorage.token_mapid,
            },
          };
          const res = await axios.get(
            SERVER_URL +
              "/open_api/get_detail_layer_custom_endpoint/" +
              geo_layer_id,
            config
          );
          geojson.features.push(...res.data?.geojson?.features);
        }
        // DEFAULT V2
        else {
          let condition = true;
          let skip = 0,
            limit = 100;
          const stop = 200_000;
          while (condition) {
            const res = await axios.get(
              bun_server +
                `/moneys_bun/v2/get_layer_detail_by_id/${geo_layer_id}/${geo_project_id}?skip=${skip}&limit=${limit}`,
              config
            );
            if (res.data.length === 0 || skip >= stop) {
              condition = false;
              if (!is_ignore_progress) {
                dispatch(clear_loading_bi("get_layer_detail_bi"));
              }
            } else {
              geojson.features.push(...res.data);
              if (res.data.length !== limit) {
                condition = false;
              }
            }
            skip += limit;
            await delay(150);
          }
        }
      }
      if (geo_layer?._id) {
        const features_before = geojson?.features || [];
        const fields = geo_layer?.fields || [];
        const source = "fetch_data_first";
        let features_new = features_before;

        features_new = features_new.map((feature_parent) => {
          let child_array = feature_parent?.child_array || [];
          if (child_array.length > 0) {
            child_array = child_array.map((item) => {
              const fields_child = fields.filter(
                (f) => f.parent_uuid === item.parent_uuid
              );
              let child_array = [item];
              child_array = calculateWithoutConvert({
                features: child_array,
                fields: fields_child,
                feature_parent,
                source: "child new layer",
              });
              return child_array[0];
            });
          }
          feature_parent.child_array = child_array;
          return {
            ...feature_parent,
          };
        });

        features_new = calculateWithoutConvert({
          features: features_new,
          fields,
          source,
          capex_value,
        });

        geojson.features = features_new.map((feature, idx) => {
          let properties = feature?.properties || {};
          properties = { ...properties, key: feature.key };
          feature.properties = properties;
          feature.idx = idx;
          return feature;
        });
        //generate chart untuk beberapa type data timeseries dari KAI

        //khusus untuk layer banjir, hanya tampilkan 7 data terbaru
        if (geo_layer.type_2 === "banjir") {
          const geojson = geo_layer.geojson;
          dispatch({
            type: "get_layer_detail_bi",
            payload: {
              project_id: geo_project_id,
              layer_id: geo_layer_id,
              geojson,
            },
          });
        } else {
          dispatch({
            type: "get_layer_detail_bi",
            payload: {
              project_id: geo_project_id,
              layer_id: geo_layer_id,
              geojson,
            },
          });
        }
        if (!is_ignore_progress) {
          dispatch(clear_loading_bi("get_layer_detail_bi"));
        }
        dispatch({
          type: "fly_action",
        });
      }
      dispatch({
        type: "status_action",
      });
      dispatch({
        type: "set_value_bi",
        payload: {
          key: "current_progress_layers",
          value: index + 1,
        },
      });
    }
  } catch (error) {
    if (!is_ignore_progress) {
      dispatch(clear_loading_bi("get_layer_detail_bi"));
    }
  }
};

/*POST METHODE*/

/*PUT METHODE*/

/*DELETE METHODE*/
