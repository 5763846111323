import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_ARROW_UP extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={7.5}
        height={9.499}
        viewBox="0 0 1.984 2.514"
      >
        <path
          d="M.992.198v2.117m.794-1.33L.992.198.198.985"
          style={{
            fill: "none",
            paintOrder: "stroke markers fill",
            strokeDashoffset: 11.411,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 0.39688,
            stroke: "#000",
          }}
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_ARROW_UP);
