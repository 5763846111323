import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*GET METHODE*/

//get_list_all_provinsi
export const get_list_all_provinsi = () => async (dispatch) => {
  try {
    const res = await axios.get(SERVER_URL + `/sini/get_list_all_provinsi`);
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "list_all_provinsi",
        value: res.data,
      },
    });
  } catch (error) {}
};

//get_list_all_kota_by_provinsi
export const get_list_all_kota_by_provinsi = (params) => async (dispatch) => {
  try {
    const { provinsi } = params;
    const res = await axios.get(
      SERVER_URL + `/sini/get_list_all_kota_by_provinsi?provinsi=${provinsi}`
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "list_all_kota",
        value: res.data,
      },
    });
  } catch (error) {}
};

//get_list_all_kecamatan_by_kota
export const get_list_all_kecamatan_by_kota = (params) => async (dispatch) => {
  try {
    const { provinsi, kota } = params;
    const res = await axios.get(
      SERVER_URL +
        `/sini/get_list_all_kecamatan_by_kota?provinsi=${provinsi}&kota=${kota}`
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "list_all_kecamatan",
        value: res.data,
      },
    });
  } catch (error) {}
};

//get_list_all_kelurahan_by_kecamatan
export const get_list_all_kelurahan_by_kecamatan =
  (params) => async (dispatch) => {
    try {
      const { provinsi, kota, kecamatan } = params;
      const res = await axios.get(
        SERVER_URL +
          `/sini/get_list_all_kelurahan_by_kecamatan?provinsi=${provinsi}&kota=${kota}&kecamatan=${kecamatan}`
      );
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "list_all_kelurahan",
          value: res.data,
        },
      });
    } catch (error) {}
  };

//get_list_kota_by_search
export const get_list_kota_by_search = (params) => async (dispatch) => {
  try {
    const { search } = params;
    const res = await axios.get(
      SERVER_URL + `/sini/get_list_kota_by_search?search=${search}`
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "list_kota_by_search",
        value: res.data,
      },
    });
  } catch (error) {}
};

//get_list_kecamatan_by_search
export const get_list_kecamatan_by_search = (params) => async (dispatch) => {
  try {
    const { search } = params;
    const res = await axios.get(
      SERVER_URL + `/sini/get_list_kecamatan_by_search?search=${search}`
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "list_kecamatan_by_search",
        value: res.data,
      },
    });
  } catch (error) {}
};

//get_list_kelurahan_by_search
export const get_list_kelurahan_by_search = (params) => async (dispatch) => {
  try {
    const { search } = params;
    const res = await axios.get(
      SERVER_URL + `/sini/get_list_kelurahan_by_search?search=${search}`
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "list_kelurahan_by_search",
        value: res.data,
      },
    });
  } catch (error) {}
};

/*POST METHODE*/

/*API */
export const ai_prompt = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    dispatch(setLoadingProcess("Loading..."));

    // get_body_from_prompt
    const res = await axios.post(
      SERVER_URL + `/sini/get_body_from_prompt`,
      body,
      config
    );

    dispatch(clearLoading());
    return res;
  } catch (error) {
    dispatch(clearLoading());
  }
};

/*NON API*/

export const setLoadingProcess = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};
