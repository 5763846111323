import React from "react";
// import { connect } from "react-redux";
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridPagination,
} from "@mui/x-data-grid";
import CreateRowNested from "./CreateRowNested";

// solusi sementara pakai functional component
function CustomToolbarContainerNested(props) {
  return (
    <GridToolbarContainer>
      <div className="row_table">
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <GridToolbarQuickFilter />
          <CreateRowNested {...props} />
        </div>
        <div style={{ marginRight: "80px" }}>
          <GridPagination />
        </div>
      </div>
    </GridToolbarContainer>
  );
}

export default CustomToolbarContainerNested;
