//Picture Asset
import icon_type_text from "../../Assets/svg/icon_type_text.svg";
import icon_type_long from "../../Assets/svg/icon_type_long.svg";
import icon_type_number from "../../Assets/svg/icon_type_number.svg";
import icon_type_range from "../../Assets/svg/icon_type_range.svg";
import icon_type_phone from "../../Assets/svg/icon_type_phone.svg";
import icon_type_currency from "../../Assets/svg/icon_type_currency.svg";
import icon_type_year from "../../Assets/svg/icon_type_year.svg";
import icon_type_date from "../../Assets/svg/icon_type_date.svg";
import icon_type_time from "../../Assets/svg/icon_type_time.svg";
import icon_type_selection from "../../Assets/svg/icon_type_selection.svg";
import icon_type_radio from "../../Assets/svg/icon_type_radio.svg";
import icon_type_checklist from "../../Assets/svg/icon_type_checklist.svg";
import icon_type_image from "../../Assets/svg/icon_type_image.svg";
import icon_type_document from "../../Assets/svg/icon_type_document.svg";
import icon_type_link from "../../Assets/svg/icon_type_link.svg";
import icon_type_address from "../../Assets/svg/icon_type_address.svg";
import icon_type_address_poi from "../../Assets/svg/icon_type_address_poi.svg";
import icon_type_table from "../../Assets/svg/icon_type_table.svg";
import icon_type_counting from "../../Assets/svg/icon_type_counting.svg";
import icon_type_section from "../../Assets/svg/icon_type_section.svg";
import icon_type_calc from "../../Assets/svg/icon_type_calc.svg";
import icon_type_ref from "../../Assets/svg/icon_type_ref.svg";
import icon_type_ref_2d from "../../Assets/svg/icon_type_ref_2d.svg";
import icon_type_conditional from "../../Assets/svg/icon_type_conditional.svg";
import icon_type_provinsi from "../../Assets/svg/icon_type_provinsi.svg";
import icon_type_kota from "../../Assets/svg/icon_type_kota.svg";
import icon_type_kec from "../../Assets/svg/icon_type_kec.svg";
import icon_type_kel from "../../Assets/svg/icon_type_kel.svg";

import { gometry_to_area_or_length } from "./geometry_to_area_or_length";
import { comma_every_3_digits } from "./format_number";
import { calculate_formula } from "./formula_validation";
import { calculate_conditional_statement } from "./conditional_statement_validation";

import dict from "../../Data/dict.json";
import calc_distance from "./calc_distance";
import { getChildrenFeaturesByColumnParentUuidAndRowParentUuid } from "../reduxHelper/layer";

/**
 * Keterangan:
 *
 * Function dengan return array dari berbagai macam tipe data seperti text, number, selection, year, date, checklist, dropdown, dll.
 * Kenapa nggak dibuat array langsung aja? kenapa harus berupa function? Ya, biar bisa pake bahasa dong...
 */
function data_types(language) {
  let array = [
    {
      name: "Section",
      value: "section",
      icon: icon_type_section,
      type: "standard",
    },
    {
      name: dict["Text"][language],
      value: "text",
      icon: icon_type_text,
      type: "standard",
    },
    {
      name: dict["Long description"][language],
      value: "textarea",
      icon: icon_type_long,
      type: "standard",
    },
    {
      name: dict["Number"][language],
      value: "number",
      icon: icon_type_number,
      type: "standard",
    },
    {
      name: dict["Number with range"][language],
      value: "range",
      icon: icon_type_range,
      type: "standard",
    },
    {
      name: dict["Phone Number"][language],
      value: "phone_number",
      icon: icon_type_phone,
      type: "standard",
    },
    {
      name: dict["Currency"][language],
      value: "currency",
      icon: icon_type_currency,
      type: "standard",
    },
    {
      name: dict["Year"][language],
      value: "year",
      icon: icon_type_year,
      type: "time",
    },
    {
      name: dict["Date"][language],
      value: "date",
      icon: icon_type_date,
      type: "time",
    },
    {
      name: dict["Time"][language],
      value: "time",
      icon: icon_type_time,
      type: "time",
    },
    {
      name: dict["Selection"][language],
      value: "selection",
      icon: icon_type_selection,
      type: "standard",
    },
    {
      name: dict["Single Answer Button"][language],
      value: "radio_button",
      icon: icon_type_radio,
      type: "standard",
    },
    {
      name: dict["Multi-Answer Checklist"][language],
      value: "checklist",
      icon: icon_type_checklist,
      type: "standard",
    },
    {
      name: dict["Image"][language],
      value: "image",
      icon: icon_type_image,
      type: "file",
    },
    {
      name: dict["Document File"][language],
      value: "document",
      icon: icon_type_document,
      type: "file",
    },
    {
      name: dict["Link"][language],
      value: "link",
      icon: icon_type_link,
      type: "standard",
    },
    {
      name: dict["Urban Villages"][language],
      value: "kelurahan",
      icon: icon_type_kel,
      type: "location",
    },
    {
      name: dict["Regencies"][language],
      value: "kecamatan",
      icon: icon_type_kec,
      type: "location",
    },
    {
      name: dict["Cities"][language],
      value: "kota",
      icon: icon_type_kota,
      type: "location",
    },
    {
      name: dict["Provinces"][language],
      value: "provinsi",
      icon: icon_type_provinsi,
      type: "location",
    },
    {
      name: dict["Auto Generate Street"][language],
      value: "auto_address",
      icon: icon_type_address,
      type: "location",
    },
    {
      name: dict["Auto Generate POI"][language],
      value: "auto_address_poi",
      icon: icon_type_address_poi,
      type: "location",
    },
    {
      name: dict["Table"][language],
      value: "table",
      icon: icon_type_table,
      type: "standard",
    },
    {
      name: "Counting POI",
      value: "counting_poi",
      icon: icon_type_counting,
      type: "location",
    },
    {
      name: "Custom Counting",
      value: "counting_custom",
      icon: icon_type_number,
      type: "location",
    },
    {
      name: dict["Calculate Dimension"][language],
      value: "calculate_dimension",
      icon: icon_type_calc,
      type: "formula",
    },
    {
      name: dict["Simple Referring"][language],
      value: "simple_referring",
      icon: icon_type_ref,
      type: "formula",
    },
    {
      name: dict["2D Referring"][language],
      value: "2d_referring",
      icon: icon_type_ref_2d,
      type: "formula",
    },
    {
      name: dict["Math Operators"][language],
      value: "math_operators",
      icon: icon_type_number,
      type: "formula",
    },
    {
      name: dict["Conditional Statement"][language],
      value: "conditional_statement",
      icon: icon_type_conditional,
      type: "formula",
    },

    // {
    //   name: "Nilai Pembanding",
    //   value: "nilai_pembanding",
    //   icon: icon_type_number,
    // },
    // {
    //   name: "Jarak Apraisal",
    //   value: "jarak_apraisal",
    //   icon: icon_type_number,
    // },

    {
      name: dict["Table Formula"][language],
      value: "table_formula",
      icon: icon_type_number,
      type: "formula",
    },
    {
      name: dict["User name"][language],
      value: "auto_generate_user_name",
      icon: icon_type_number,
      type: "other",
    },
  ];

  const domain = window.location.hostname;

  if (
    [
      "kai.mapid.io",
      "localhost",
      "geo-alpha.mapid.io",
      "geo-beta.mapid.io",
    ].includes(domain)
  ) {
    array.push({
      name: "CAPEX",
      value: "capex_value",
      icon: icon_type_number,
      type: "other",
    });
  }

  return array;
}

/**
 * Keterangan:
 *
 * list tipe data yang harus dikalkulasikan dulu sebelum ditampilkan
 */
const data_types_that_must_be_calculate_before_render = [
  "simple_referring",
  "calculate_dimension",
  "math_operators",
  "2d_referring",
  "conditional_statement",
  "nilai_pembanding",
  "jarak_apraisal",
  "summary_of_nested_table",
  "table_formula",
  "auto_generate_user_name",
  "capex_value",
];

/**
 * Keterangan:
 *
 * Function ini digunakan untuk menghubungkan function (rumus/algoritma) dengan tipe data formula yang sesuai. Angka hasil kalkulasi akan masuk dalam feature.
 */
function calculate_by_data_type({
  feature,
  fields,
  layer_pembanding,
  feature_parent,
  feature_appraisal,
  source,
  capex_value,
}) {
  try {
    let properties = feature?.properties || {};

    feature.properties = properties;

    fields.forEach((field) => {
      if (field.type === "simple_referring") {
        const { reference_list, reference_key } = field;
        const properties = feature?.properties || {};
        const value = reference_list.find(
          (ref) =>
            String(ref?.name)?.toLowerCase()?.trim() ===
            String(properties?.[reference_key])?.toLowerCase()?.trim()
        )?.["value"];
        if (typeof value === "number") {
          feature.properties[field.key] = +value;
        } else {
          feature.properties[field.key] = value;
        }
      } else if (field.type === "capex_value") {
        feature.properties[field.key] = capex_value;
      } else if (field.type === "calculate_dimension") {
        const { geometry } = feature;
        const { value } = gometry_to_area_or_length(geometry, field.unit);
        const final_value = comma_every_3_digits(value);
        feature.properties[field.key] = final_value;
      } else if (field.type === "math_operators") {
        let value;
        const value_db = feature?.properties?.[field?.key];

        if (value_db === undefined || value_db === "") {
          value = calculate_formula(field.formula, feature.properties);
        } else {
          value = value_db;
        }

        if (field.decimal_digit !== undefined) {
          if (isNaN(value) || value === "" || value === undefined) {
            feature.properties[field.key] = 0;
          } else {
            const decimal_digits = Number(field?.decimal_digits) || 0;
            let value_decimal = 0;
            const value_number = Number(value);
            if (!isNaN(value_number)) {
              value_decimal = Number(value_number.toFixed(decimal_digits));
            }
            feature.properties[field.key] = value_decimal;
          }
        } else {
          feature.properties[field.key] = value;
        }
      } else if (field.type === "2d_referring") {
        const index_row_name = field.reference_list_2d?.row_name;
        const index_column_name = field.reference_list_2d?.column_name;
        let value = "";
        if (feature?.properties?.[index_column_name]) {
          const selected_row = field.reference_list_2d?.features?.find(
            (row) =>
              String(row?.["index_name"])?.toLowerCase()?.trim() ===
              String(feature?.properties?.[index_row_name])
                ?.toLowerCase()
                ?.trim()
          );
          value = selected_row?.[feature.properties?.[index_column_name]];
          feature.properties[field.key] = value;
        }
      } else if (field.type === "conditional_statement") {
        const value = calculate_conditional_statement(
          field.conditional_statement_list,
          feature.properties
        );
        feature.properties[field.key] = value;
      } else if (field.type === "nilai_pembanding") {
        const feature_parent_final = feature_parent || feature;
        const data_pembanding_list =
          feature_parent_final?.data_pembanding_list || [];
        let fields = layer_pembanding?.fields || [];
        fields = fields.filter((item) => !item?.isStyle);
        let features = layer_pembanding?.geojson?.features || [];
        features = features.filter((item) =>
          data_pembanding_list.includes(item?.key)
        );
        //kolom nilai rev_calculation_field_key
        const rev_calculation_field_key =
          layer_pembanding?.rev_calculation_field_key;
        //kolom nilai percent_value_field_key
        const percent_value_field_key =
          layer_pembanding?.percent_value_field_key;
        const field_percent = fields.find(
          (item) => item.key === percent_value_field_key
        );
        let sum;
        if (field_percent?.key) {
          const array_of_number = features.map((item) =>
            Number(item.properties[percent_value_field_key])
          );
          const init = 0;
          sum = array_of_number.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            init
          );
        }
        features = features.map((item) => {
          const nilai_pembobotan =
            item?.properties?.[percent_value_field_key] / sum;
          const nilai_indikasi =
            item?.properties?.[rev_calculation_field_key] * nilai_pembobotan;
          item.properties.nilai_pembobotan = nilai_pembobotan;
          item.properties.nilai_indikasi = nilai_indikasi;
          return item;
        });
        let total_indikasi = 0;
        if (field_percent?.key) {
          const array_of_number = features.map((item) =>
            Number(item?.properties?.nilai_indikasi)
          );
          const init = 0;
          total_indikasi = array_of_number.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            init
          );
        }
        feature.properties[field.key] = parseInt(total_indikasi);
      } else if (field.type === "jarak_apraisal" && feature_appraisal) {
        let distance_in_meter = "?";
        const long_1 = feature?.geometry?.coordinates[0];
        const lat_1 = feature?.geometry?.coordinates[1];
        const long_2 = feature_appraisal?.geometry?.coordinates[0];
        const lat_2 = feature_appraisal?.geometry?.coordinates[1];
        if (long_1 && lat_1 && long_2 && lat_2) {
          distance_in_meter = (
            calc_distance(lat_1, long_1, lat_2, long_2) * 1000
          ).toFixed(0); //dikalikan 1000 untuk konversi kilometer menjadi meter
        }
        feature.properties[field.key] = distance_in_meter;
      } else if (field.type === "table_formula") {
        if (field?.table_formula) {
          const { field_parent_uuid, field_child_uuid, formula } =
            field?.table_formula;
          if (field_parent_uuid && field_child_uuid && formula) {
            const params = {
              feature: feature_parent || feature,
              column_parent_uuid: field_parent_uuid,
              row_parent_uuid: feature?.child_uuid,
            };
            const children =
              getChildrenFeaturesByColumnParentUuidAndRowParentUuid(params);

            if (children?.length > 0) {
              let summary = 0;
              if (formula === "sum") {
                children?.forEach((child) => {
                  let value_number = Number(
                    child?.properties?.[field_child_uuid]
                  );
                  if (isNaN(value_number)) value_number = 0;
                  summary += value_number;
                });
              } else if (formula === "avg") {
                children?.forEach((child) => {
                  let value_number = Number(
                    child?.properties?.[field_child_uuid]
                  );
                  if (isNaN(value_number)) value_number = 0;
                  summary += value_number;
                });
                summary /= children?.length;
              } else if (formula === "min") {
                const array_of_number = children.map((child) => {
                  let value_number = Number(
                    child?.properties?.[field_child_uuid]
                  );
                  if (isNaN(value_number)) value_number = 0;
                  return value_number;
                });
                summary = Math.min(...array_of_number);
              } else if (formula === "max") {
                const array_of_number = children.map((child) => {
                  let value_number = Number(
                    child?.properties?.[field_child_uuid]
                  );
                  if (isNaN(value_number)) value_number = 0;
                  return value_number;
                });
                summary = Math.max(...array_of_number);
              } else if (formula === "count") {
                summary = children?.length || 0;
              }
              feature.properties[field.key] = summary;
            }
          }
        }
      } else if (field.type === "auto_generate_user_name") {
        feature.properties[field.key] = feature?.user?.full_name || "";
      }
    });

    return feature;
  } catch (error) {
    console.log("error=", error);
  }
}

export {
  data_types,
  data_types_that_must_be_calculate_before_render,
  calculate_by_data_type,
};
