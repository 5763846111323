/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { requestFreeImport } from "../../App/actions/blogAction";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class RequestFree extends Component {
  state = {
    free_message: "",
  };
  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { free_message } = this.state;
    const { user } = this.props.auth;
    const { blog } = this.props.blog;
    const body = {
      blog_id: blog._id,
      user_id: user._id, //Dari server ga butuh user_id, tapi di FE butuh
      free_message,
    };
    this.props.requestFreeImport(body);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { free_message } = this.state;
    const { loadingProcess, itemLoading } = this.props.blog;
    let buttonContent;
    if (loadingProcess && itemLoading === "request_free_import") {
      buttonContent = (
        <button className="button_block">{dict["Sending"][language]}...</button>
      );
    } else {
      buttonContent = (
        <button type="submit" className="button_block">
          {dict["Send request"][language]}
        </button>
      );
    }
    return (
      <main>
        <h1>{dict["Your message request"][language]}</h1>
        <form onSubmit={this.handleSubmit}>
          <textarea
            rows="7"
            type="text"
            name="free_message"
            id="free_message"
            value={free_message}
            onChange={this.handleChange}
          />
          {buttonContent}
        </form>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  blog: state.blog,
});
export default connect(mapStateToProps, { requestFreeImport })(RequestFree);
