import React, { Component } from "react";

export default class Text extends Component {
  getTextWidth(text, font) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font || getComputedStyle(document.body).font;
    return context.measureText(text).width;
  }

  render() {
    const { value } = this.props;
    let centerStyle = "";
    if (this.getTextWidth(value) < 170) {
      centerStyle = "center_perfect";
    }
    return (
      <span
        className={`hide-scrollbar ${centerStyle}`}
        style={{ height: "100%", overflowY: "auto" }}
      >
        {value}
      </span>
    );
  }
}
