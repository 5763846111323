const initial_state = {
  loading_item_array: [],
};

export default function loading_reducer(state = initial_state, action) {
  switch (action.type) {
    case "set_loading_action":
      return {
        ...state,
        loading_item_array: [...state.loading_item_array, action.payload],
      };

    case "clear_loading_action":
      return {
        ...state,
        loading_item_array: state.loading_item_array.filter(
          (item) => item !== action.payload
        ),
      };

    default:
      return state;
  }
}
