import React, { Component } from "react";
import { connect } from "react-redux";

class SpinnerSimpleGreen extends Component {
  render() {
    return (
      <div className={`loader${this.props.unik}`}>
        <style>
          {`
  .loader${this.props.unik} {
    border: ${this.props.width / 10}px solid #2ca1fdff;
    border-radius: 50%;
    border-top: ${this.props.width / 10}px solid #7eb532ff;
    width: ${this.props.width}px;
    height: ${this.props.width}px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top:${this.props.marginTop ? this.props.marginTop : "0px"};
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `}
        </style>
      </div>
    );
  }
}
SpinnerSimpleGreen.propTypes = {};
const mapStateToProps = state => ({});
export default connect(mapStateToProps, {})(SpinnerSimpleGreen);
