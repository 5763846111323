import axios from "axios";
// import { geoServerBaseUrl } from "./baseUrl";

// const SERVER_URL = geoServerBaseUrl;

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/*NON-API*/

export const set_loading = (loading_item) => {
  return {
    type: "set_loading_layer",
    payload: loading_item,
  };
};

export const clear_loading = () => {
  return {
    type: "clear_loading_layer",
  };
};

/*POST METHODE*/

export const set_data_premium_bulk = (body) => async (dispatch) => {
  //set_data_premium_bulk
  /*
const body = {
  geo_project_id,
  layer_id_array,
  price
}
*/
  try {
    dispatch({
      type: "set_loading_action",
      payload: "set_data_premium_bulk",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    await axios.post(
      bun_server + "/moneys_bun/set_data_premium_bulk",
      body,
      config
    );
    dispatch({
      type: "set_data_premium_bulk",
      payload: body,
    });
    dispatch({
      type: "set_project_premium",
      payload: body,
    });
    dispatch({
      type: "clear_loading_action",
      payload: "set_data_premium_bulk",
    });
    dispatch({
      type: "status_action",
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: "clear_loading_action",
      payload: "set_data_premium_bulk",
    });
  }
};

export const undo_data_premium_bulk = (body) => async (dispatch) => {
  //undo_data_premium_bulk
  /*
const body = {
  geo_project_id,
  layer_id_array
}
*/
  try {
    dispatch({
      type: "set_loading_action",
      payload: "undo_data_premium_bulk",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      bun_server + "/moneys_bun/undo_data_premium_bulk",
      body,
      config
    );
    dispatch({
      type: "undo_data_premium_bulk",
      payload: body,
    });
    dispatch({
      type: "undo_project_premium",
      payload: body,
    });
    dispatch({
      type: "clear_loading_action",
      payload: "undo_data_premium_bulk",
    });
    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_action",
      payload: "undo_data_premium_bulk",
    });
  }
};

/*GET METHODE*/

export const get_data_premium_count = () => async (dispatch) => {
  //get_data_premium_count
  try {
    dispatch(set_loading("get_data_premium_count"));

    const res = await axios.get(
      bun_server + "/moneys_bun/get_data_premium_count"
    );

    dispatch({
      type: "get_data_premium_count",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//search_data_premium
export const search_data_premium = (search_params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    dispatch(set_loading("search_layer"));
    const res = await axios.get(
      bun_server + `/moneys_bun/search_data_premium/${search_params}`,
      config
    );

    dispatch(clear_loading());
    dispatch({
      type: "SEARCH_LAYER",
      payload: res.data?.layers,
    });
  } catch (error) {
    dispatch(clear_loading());
  }
};

//get_data_premium_by_category_id
export const get_data_premium_by_category_id =
  (category_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      dispatch(set_loading("search_layer"));
      const res = await axios.get(
        bun_server +
          `/moneys_bun/get_data_premium_by_category_id/${category_id}`,
        config
      );

      dispatch(clear_loading());
      dispatch({
        type: "SEARCH_LAYER",
        payload: res.data,
      });
    } catch (error) {
      dispatch(clear_loading());
    }
  };
