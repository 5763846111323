import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_SETTING extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10.001}
        height={10.434}
        viewBox="0 0 2.646 2.761"
      >
        <path
          d="M-739.8-241.6a1.548 1.548 0 0 1-.146-.017c-.064-.011-.07-.013-.083-.02a.083.083 0 0 1-.025-.025c-.01-.015-.01-.017-.03-.124a10.11 10.11 0 0 0-.02-.111l-.007-.04c-.019-.104-.022-.114-.04-.143a.165.165 0 0 0-.149-.07.2.2 0 0 0-.06.014l-.039.014-.037.014c-.014.005-.032.01-.04.014l-.04.014-.04.014-.08.029c-.026.009-.027.01-.042.01-.018 0-.026 0-.04-.008-.015-.007-.026-.018-.062-.06a1.396 1.396 0 0 1-.247-.44c-.008-.023-.009-.027-.009-.04 0-.015.001-.025.008-.04.006-.012.014-.02.051-.052l.074-.062a44.73 44.73 0 0 0 .163-.14.163.163 0 0 0-.001-.22 5.712 5.712 0 0 0-.123-.106l-.04-.033a2.288 2.288 0 0 1-.112-.096.076.076 0 0 1-.02-.058c0-.012 0-.016.008-.037.062-.179.15-.33.272-.469a.12.12 0 0 1 .038-.033.075.075 0 0 1 .04-.008c.015 0 .016 0 .043.01l.04.014a3.341 3.341 0 0 0 .08.028l.039.015.038.013.04.014c.073.027.083.03.11.03a.16.16 0 0 0 .138-.072c.018-.03.02-.04.039-.142l.007-.04.02-.111c.02-.107.02-.109.03-.125a.089.089 0 0 1 .025-.024c.014-.008.019-.009.083-.02a1.363 1.363 0 0 1 .487.003c.048.01.057.013.073.027.015.014.022.029.028.059l.008.04.009.051.012.07.013.066a.809.809 0 0 0 .022.11.164.164 0 0 0 .19.103.311.311 0 0 0 .036-.01l.042-.015.037-.014.038-.013.04-.014.04-.015.099-.035a.116.116 0 0 1 .046-.002c.01.002.026.01.035.018a1.33 1.33 0 0 1 .294.495l.006.02v.017c0 .016 0 .018-.004.029-.008.019-.011.023-.067.07l-.062.052-.038.032c-.123.104-.126.107-.14.126a.163.163 0 0 0 .016.2c.006.007.064.057.118.102l.132.112c.028.023.035.032.041.048.004.01.004.012.004.029v.017l-.008.024a1.364 1.364 0 0 1-.262.458c-.025.029-.034.038-.047.044a.075.075 0 0 1-.04.008c-.016 0-.017-.001-.043-.01a7.552 7.552 0 0 0-.08-.029l-.04-.014-.04-.014-.039-.015a3.922 3.922 0 0 0-.038-.013c-.007-.002-.024-.01-.039-.014a.201.201 0 0 0-.06-.015.162.162 0 0 0-.158.088.438.438 0 0 0-.03.13l-.013.065-.013.07-.009.051-.008.041c-.005.03-.012.045-.027.058-.016.015-.026.019-.074.027a1.318 1.318 0 0 1-.34.021zm.129-.977a.404.404 0 0 0 .043-.794.404.404 0 0 0-.37.678.4.4 0 0 0 .327.115z"
          style={{
            fill: "#fff",
          }}
          transform="translate(741.04 244.36)"
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_SETTING);
