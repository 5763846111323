/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import { searchUser } from "../../App/actions/groupActions";
import {
  pushMemberThen,
  pushGroup,
  deleteMember,
  editMember,
  deleteGroup,
} from "../../App/actions/projectActions";
import { resetSearchUser } from "../../App/actions/authActions";
import { set_value_user } from "../../App/actions/authActions";

/*PICTURE ASSET*/
import pic_static from "../../Assets/svg/profile_pic_comment.svg";
import checklist from "../../Assets/svg/checklist.svg";
import icon_search from "../../Assets/svg/icon_search.svg";
import icon_delete_2 from "../../Assets/svg/icon_delete_2.svg";

/*GENERAL FUNCTION & DATA*/
import isEqual from "../../App/validation/is-equal";
import isEmpty from "../../App/validation/is-empty";
import uuid from "../../App/validation/uuid";
import formatDate from "../../App/validation/format-date";
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class PushMember extends Component {
  state = {
    geo_project: {},
    user_id: this.props.auth.user._id,
    search_user: "",
    member_id: "",
    modal_add_group: false,
    group: {},
    modalWarningNotAdmin: false,
    modalDeleteGroup: false,
    member: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.project.geo_project, prevState.geo_project)) {
      return {
        geo_project: nextProps.project.geo_project,
        group: !isEmpty(nextProps.project.geo_project.groups)
          ? nextProps.project.geo_project.groups[0]
          : {},
      };
    } else return null;
  }

  componentDidMount() {
    this.timer = null;
  }

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  toggle_add_group = () => {
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      this.setState({
        modal_add_group: !this.state.modal_add_group,
      });
    }
  };

  toggleDeleteGroup = (group) => {
    this.setState({ modalDeleteGroup: !this.state.modalDeleteGroup }, () => {
      if (!isEmpty(group)) {
        this.setState({ group });
      }
    });
  };

  toggleWarningNotAdmin = () => {
    this.setState({ modalWarningNotAdmin: !this.state.modalWarningNotAdmin });
  };

  setGroup = (group) => {
    this.setState({ group });
  };

  handleSubmitAddGroup = async (group_id) => {
    const { geo_project } = this.state;

    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }

    const body = {
      key,
      geo_project_id: geo_project._id,
      group_id,
    };

    await this.props.pushGroup(body);
    this.setState({ modal_add_group: false });
  };

  handleSubmitDeleteGroup = () => {
    const { geo_project, group } = this.state;

    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }

    const body = {
      key,
      geo_project_id: geo_project._id,
      group_id: group._id,
    };
    this.props.deleteGroup(body);
    this.setState({ modalDeleteGroup: false });
  };

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  triggerChange() {
    const { search_user } = this.state;
    this.props.searchUser(search_user);
  }

  handleSubmit = (member_id) => {
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      this.setState({ member_id }, async () => {
        const { geo_project, member_id, group } = this.state;

        const { folder_id_selected } = this.props.project;
        let key;
        if (this.props.title === "Pinned") {
          key = "geo_project_pinned_list";
        } else if (!!folder_id_selected) {
          key = "geo_project_list_child";
        } else {
          key = "geo_project_list";
        }

        const body = {
          key,
          geo_project_id: geo_project._id,
          group_id: group._id,
          member_id: member_id,
        };

        await this.props.pushMemberThen(body);
      });
    }
  };

  handleResetSearchUser = () => {
    this.setState({ search_user: "" }, () => {
      this.props.resetSearchUser();
    });
  };

  toggleDeleteMember = (member) => {
    this.setState({ member }, () => {
      this.setState({
        modalDeleteMember: !this.state.modalDeleteMember,
      });
    });
  };

  handleDeleteMember = async () => {
    const { geo_project, group, member } = this.state;
    const { _id } = geo_project;

    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }

    const body = {
      key,
      geo_project_id: _id,
      group_id: group?._id,
      user_id: member?._id,
      user_id_real: member?.user?._id,
      member_name: member?.user?.full_name
        ? member?.user?.full_name
        : member?.user?.name,
    };
    await this.props.deleteMember(body);

    this.toggleDeleteMember(this.state.member);
  };

  handleEditMember = async (e) => {
    e.preventDefault();

    const { geo_project, group } = this.state;

    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }

    const status = e.target.value;
    const user_id = e.target.name;
    const user_id_real = e.target.id;
    const body = {
      key,
      user_id,
      status,
      geo_project_id: geo_project._id,
      group_id: group._id,
      user_id_real,
    };
    await this.props.editMember(body);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      geo_project,
      search_user,
      modal_add_group,
      modalWarningNotAdmin,
      modalDeleteGroup,
      group,
    } = this.state;
    const { loadingProcess } = this.props.project;
    const { user_list, user, groups } = this.props.auth;
    let memberStatus = "viewer";
    let group_members = [];
    let groupsId = [];
    if (geo_project.groups.length > 0) {
      const groups = geo_project.groups;
      groupsId = groups.map(({ _id }) => {
        return _id;
      });
    }
    if (!isEmpty(group)) {
      group_members = group.members;
      const array_member_filtered = group.members.filter(
        (member) => member?.user?._id === user?._id
      )[0];
      if (!isEmpty(array_member_filtered)) {
        memberStatus = array_member_filtered?.status;
      } else {
        memberStatus = "viewer";
      }
    }
    const group_list_content = geo_project.groups.map((group_element, idx) => {
      const { name, _id, members } = group_element;
      let userCurrentStatus = "viewer";
      const array_member_filtered = members.filter(
        (member) => member?.user?._id === user?._id
      )[0];
      userCurrentStatus = array_member_filtered?.status;
      let item;
      let deleteItem;
      if (userCurrentStatus === "creator" || userCurrentStatus === "admin") {
        deleteItem = (
          <div
            onClick={this.toggleDeleteGroup.bind(this, group_element)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={icon_delete_2}
              alt="delete group"
              width="15px"
              height="auto"
            />
          </div>
        );
      }
      if (group?._id === _id) {
        item = (
          <div
            className="container_basic"
            style={{
              display: "inline-block",
              margin: "10px",
              width: "150px",
              minHeight: "120px",
              fontSize: "12px",
              cursor: "pointer",
              overflow: "hidden",
              backgroundColor: "#0ca5ebff",
              color: "#fff",
              borderRadius: "10px",
              position: "relative",
              verticalAlign: "top",
            }}
            onClick={this.setGroup.bind(this, group_element)}
            key={idx}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "60px",
                width: "120px",
                overflow: "hidden",
                fontWeight: "bold",
              }}
            >
              <div style={{ overflow: "hidden", maxHeight: "30px" }}>
                {name}
              </div>
              {deleteItem}
            </div>
          </div>
        );
      } else {
        item = (
          <div
            className="container_basic"
            style={{
              display: "inline-block",
              margin: "10px",
              width: "150px",
              minHeight: "120px",
              fontSize: "12px",
              cursor: "pointer",
              overflow: "hidden",
              backgroundColor: "#d3d3d3",
              color: "#fff",
              borderRadius: "10px",
              position: "relative",
              verticalAlign: "top",
            }}
            onClick={this.setGroup.bind(this, group_element)}
            key={idx}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "60px",
                width: "120px",
                overflow: "hidden",
                fontWeight: "bold",
              }}
            >
              <div style={{ overflow: "hidden", maxHeight: "30px" }}>
                {name}
              </div>
              {deleteItem}
            </div>
          </div>
        );
      }
      return item;
    });
    const add_group_button = (
      <div
        className="container_basic"
        style={{
          width: "100px",
          height: "70px",
          fontSize: "55px",
          cursor: "pointer",
        }}
        onClick={this.toggle_add_group}
        data-mapid="addGroup"
      >
        +
      </div>
    );
    const carousel_group = (
      <div className="carousell">
        {group_list_content}
        {(memberStatus === "creator" ||
          memberStatus === "admin" ||
          user?._id === geo_project?.user?._id) &&
          add_group_button}
      </div>
    );
    const modalAddGroup = modal_add_group && (
      <Modal
        modalSize="medium"
        id="add group modal"
        isOpen={this.state.modal_add_group}
        onClose={this.toggle_add_group}
      >
        <div
          className="box-body"
          style={{ textAlign: "center", minHeight: "80vh" }}
        >
          <div style={{ marginBottom: "30px" }}>
            {dict["Add Group into Project"][language]}
          </div>
          <div>
            {groups.map(({ name, _id, members, payment }) => {
              let organizationContent = null;
              if (!isEmpty(payment)) {
                const { date_in } = payment;
                const multiple_month = payment.multiple_month
                  ? payment.multiple_month
                  : 1;
                //86400 unix = 1 day
                //1 month = 30.5 day
                const month_unix = 86400 * 30.5;
                const day_unix = 86400;
                const date_finish_unix =
                  parseInt((new Date(date_in).getTime() / 1000).toFixed(0)) +
                  month_unix * multiple_month;
                const date_now_unix = parseInt(
                  (new Date(Date.now()).getTime() / 1000).toFixed(0)
                );
                const delta_unix =
                  (date_finish_unix - date_now_unix) * multiple_month;
                const delta_day = parseInt(delta_unix / day_unix);
                const date_finish = new Date(date_finish_unix * 1000);
                if (delta_day > 0) {
                  if (delta_day > 30) {
                    organizationContent = (
                      <div className="badge_normal" id="grey_light">
                        <b>
                          {dict["Team license active until"][language]}{" "}
                          {formatDate(date_finish)}
                        </b>
                      </div>
                    );
                  } else {
                    organizationContent = (
                      <div className="badge_normal" id="grey_light">
                        <b>
                          {dict["Team license active for"][language]}{" "}
                          {delta_day} {dict["days"][language]}
                        </b>
                      </div>
                    );
                  }
                } else {
                  organizationContent = (
                    <div className="badge_normal" id="grey_light">
                      <b>{dict["Team license exp."][language]}</b>
                    </div>
                  );
                }
              }
              let memberStatus = "viewer";
              const array_member_filtered = members.filter(
                (member) => member?.user?._id === user?._id
              )[0];
              if (array_member_filtered) {
                memberStatus = array_member_filtered?.status;
              }
              let groupCard;
              if (groupsId.includes(_id)) {
                groupCard = (
                  <div
                    style={{
                      display: "inline-block",
                      margin: "10px",
                      width: "150px",
                      minHeight: "120px",
                      fontSize: "12px",
                      cursor: "default",
                      overflow: "hidden",
                      backgroundColor: "#d3d3d3",
                      color: "#fff",
                      borderRadius: "10px",
                      position: "relative",
                      verticalAlign: "top",
                    }}
                    key={_id}
                  >
                    {organizationContent}
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "60px",
                        width: "120px",
                        overflow: "hidden",
                        fontWeight: "bold",
                      }}
                    >
                      {name}{" "}
                      <img alt="checklist" src={checklist} width="11px" />
                    </div>
                  </div>
                );
              } else {
                if (memberStatus === "creator" || memberStatus === "admin") {
                  groupCard = (
                    <div
                      style={{
                        display: "inline-block",
                        margin: "10px",
                        width: "150px",
                        minHeight: "120px",
                        fontSize: "12px",
                        cursor: "pointer",
                        overflow: "hidden",
                        backgroundColor: "#0ca5ebff",
                        color: "#fff",
                        borderRadius: "10px",
                        position: "relative",
                        verticalAlign: "top",
                      }}
                      onClick={this.handleSubmitAddGroup.bind(this, _id)}
                      key={_id}
                      data-mapid="addGroupAdmin"
                    >
                      {organizationContent}
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          maxHeight: "60px",
                          width: "120px",
                          overflow: "hidden",
                          fontWeight: "bold",
                        }}
                      >
                        {name}
                      </div>
                    </div>
                  );
                } else {
                  groupCard = (
                    <div
                      style={{
                        display: "inline-block",
                        margin: "10px",
                        width: "150px",
                        minHeight: "120px",
                        fontSize: "12px",
                        cursor: "pointer",
                        overflow: "hidden",
                        backgroundColor: "#d3d3d3",
                        color: "#fff",
                        borderRadius: "10px",
                        position: "relative",
                        verticalAlign: "top",
                      }}
                      onClick={this.toggleWarningNotAdmin}
                      key={_id}
                    >
                      {organizationContent}
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          maxHeight: "60px",
                          width: "120px",
                          overflow: "hidden",
                          fontWeight: "bold",
                        }}
                      >
                        {name}
                      </div>
                    </div>
                  );
                }
              }
              return groupCard;
            })}
          </div>
        </div>
      </Modal>
    );

    let loadingContent;
    if (loadingProcess) {
      loadingContent = (
        <div className="button" style={{ marginTop: "1rem" }}>
          {dict["Adding member"][language]}...
        </div>
      );
    } else {
      let kata_kata;
      if (group?.name) {
        kata_kata = `${dict["Add member to"][language]} ${group?.name} ${dict["group"][language]}`;
      } else {
        kata_kata =
          dict["Please select a group you want to share this project."][
            language
          ];
      }
      loadingContent = (
        <div className="button" style={{ marginTop: "1rem" }}>
          {kata_kata}
        </div>
      );
    }
    let members_group_id = [];
    if (geo_project.groups.length > 0) {
      const members_group = group.members;
      members_group_id = members_group.map((member) => member?.user?._id);
    }
    let userListContent;
    if (user_list.length > 0) {
      userListContent = (
        <main>
          <hr />
          <main
            style={{ maxHeight: "300px", overflowY: "auto" }}
            data-mapid="clickUser"
          >
            {user_list.map(({ _id, name, full_name, profile_picture }, idx) => {
              let user_unit;
              if (members_group_id.includes(_id)) {
                user_unit = (
                  <main
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                      cursor: "default",
                    }}
                  >
                    <section
                      alt={_id}
                      className={`photo_${_id}`}
                      samesite="None"
                      secure="true"
                    />
                    <section
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {full_name && full_name !== "" && full_name !== "-"
                        ? full_name
                        : name}
                    </section>
                    <style>
                      {`.photo_${_id}{
                        background: url(${
                          profile_picture && profile_picture.url_compressed
                            ? profile_picture.url_compressed
                            : profile_picture &&
                              profile_picture.url &&
                              profile_picture.url !==
                                "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                              profile_picture.url !== "-" &&
                              profile_picture.url !== "default"
                            ? profile_picture.url
                            : pic_static
                        }) no-repeat center center/ cover;
                        background-color: #c1c1c1;
                        height:40px;
                        width:40px;
                        border-radius: 50%;
                        border: 2px solid #2196f3;
                        display: inline-block;
                        align: center;
                        vertical-align: middle;
          }`}
                    </style>
                    <section
                      alt={_id}
                      className="badgeMember"
                      style={{ marginLeft: "5px" }}
                    >
                      <style>
                        {`.badgeMember{
                            background: url(${checklist}) no-repeat center center/ cover;
                            background-color: #c1c1c1;
                            height:15px;
                            width:15px;
                            border-radius: 50%;
                            display: inline-block;
                            align: center;
                            vertical-align: middle;
                            }`}
                      </style>
                    </section>
                  </main>
                );
              } else {
                user_unit = (
                  <main
                    style={{
                      textAlign: "left",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onClick={this.handleSubmit.bind(this, _id)}
                  >
                    <section
                      alt={_id}
                      className={`photo_${_id}`}
                      samesite="None"
                      secure="true"
                    />
                    <section
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {full_name && full_name !== "" && full_name !== "-"
                        ? full_name
                        : name}
                    </section>
                    <style>
                      {`.photo_${_id}{
                        background: url(${
                          profile_picture && profile_picture.url_compressed
                            ? profile_picture.url_compressed
                            : profile_picture &&
                              profile_picture.url &&
                              profile_picture.url !==
                                "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                              profile_picture.url !== "-" &&
                              profile_picture.url !== "default"
                            ? profile_picture.url
                            : pic_static
                        }) no-repeat center center/ cover;
                        background-color: #c1c1c1;
                        height:40px;
                        width:40px;
                        border-radius: 50%;
                        border: 2px solid #2196f3;
                        display: inline-block;
                        align: center;
                        vertical-align: middle;
                        }`}
                    </style>
                  </main>
                );
              }
              return <main key={idx}>{user_unit}</main>;
            })}
          </main>
        </main>
      );
    } else {
      userListContent = null;
    }

    const searchForm = !isEmpty(group) && (
      <main className="input_rounded" style={{ width: "80%", margin: "auto" }}>
        <main className="icon-text">
          <section>
            <img
              src={icon_search}
              alt="search"
              height="16px"
              style={{ marginBottom: "5px" }}
            />
          </section>
          <section>
            <input
              className="form-rounded"
              type="text"
              placeholder={`${dict["Search user to add as member"][language]}...`}
              autoComplete="off"
              name="search_user"
              value={search_user}
              onChange={this.handleChange.bind(this)}
              onKeyDown={this.handleKeyDown.bind(this)}
              style={{
                overflow: "hidden",
                boxSizing: "border-box",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "15px",
                color: "#1e5e96ff",
                textAlign: "left",
              }}
              data-mapid="inputUser"
            />
          </section>
          <section>
            <div
              onClick={this.handleResetSearchUser}
              style={{ marginBottom: "5px", cursor: "pointer" }}
            >
              X
            </div>
          </section>
        </main>
        {userListContent}
      </main>
    );

    const membersContent = (
      <main style={{ marginTop: "20px" }}>
        <p>{dict["Members in this project group"][language]}:</p>
        <table>
          <tbody>
            {group_members.map((member, idx) => {
              const user = member?.user;
              const status = member?.status;
              let _id = uuid();
              let member_id = uuid();
              let full_name, profile_picture, name;
              if (user) {
                member_id = member._id;
                _id = user?._id;
                full_name = user?.full_name;
                profile_picture = user?.profile_picture;
                name = user?.name;
              } else {
                full_name = "Deleted user";
              }
              let deleteButton;
              const statusArray = ["admin", "contributor", "viewer"];
              let statusButton;

              if (status === "creator") {
                statusButton = <p>{member?.status}</p>;
              } else if (
                memberStatus === "creator" ||
                (memberStatus === "admin" &&
                  member?.user?.name !== this.props.auth.user?.name)
              ) {
                statusButton = (
                  <select
                    id={_id}
                    name={member_id}
                    value={status}
                    onChange={this.handleEditMember}
                    data-mapid={`selectStatus-${idx}`}
                  >
                    {statusArray.map((status, idx) => {
                      return (
                        <option key={idx} value={status}>
                          {status}
                        </option>
                      );
                    })}
                  </select>
                );
              } else {
                statusButton = <div>{status}</div>;
              }

              if (
                (memberStatus === "creator" || memberStatus === "admin") &&
                status !== "creator" &&
                member?.user?.name !== this.props.auth.user?.name
              ) {
                deleteButton = (
                  <div
                    onClick={this.toggleDeleteMember.bind(this, member)}
                    className="button"
                    id="red"
                    data-mapid="deleteUser"
                  >
                    {dict["Delete"][language]}
                  </div>
                );
              } else {
                deleteButton = null;
              }

              return (
                user && (
                  <tr key={idx} data-mapid={`tableTr-${idx}`}>
                    <td
                      style={{
                        textAlign: "left",
                        marginBottom: "10px",
                        width: "40px",
                      }}
                    >
                      <div
                        alt={_id}
                        className={`photo_member_${_id}`}
                        samesite="None"
                        secure="true"
                      />
                      <style>
                        {`.photo_member_${_id}{
                            background: url(${
                              profile_picture && profile_picture.url_compressed
                                ? profile_picture.url_compressed
                                : profile_picture &&
                                  profile_picture.url &&
                                  profile_picture.url !==
                                    "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                                  profile_picture.url !== "-" &&
                                  profile_picture.url !== "default"
                                ? profile_picture.url
                                : pic_static
                            }) no-repeat center center/ cover;
                            background-color: white;
                            height:40px;
                            width:40px;
                            border-radius: 50%;
                            border: 2px solid white;
                            display: inline-block;
                            align: center;
                            vertical-align: middle;
              }`}
                      </style>
                    </td>
                    <td
                      style={{
                        width: "250px",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        {full_name && full_name !== "" && full_name !== "-"
                          ? full_name
                          : name}
                      </div>
                    </td>
                    <td
                      style={{
                        width: "150px",
                      }}
                    >
                      <div style={{ textAlign: "left" }}>
                        {memberStatus !== "creator" &&
                        memberStatus !== "admin" ? (
                          <p>{member?.status}</p>
                        ) : (
                          statusButton
                        )}
                      </div>
                    </td>
                    <td
                      style={{
                        width: "100px",
                      }}
                    >
                      {deleteButton}
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      </main>
    );

    const modalDeleteMemberContent = this.state.modalDeleteMember && (
      <Modal
        modalSize="small"
        id="deleteModal"
        isOpen={this.state.modalDeleteMember}
        onClose={this.toggleDeleteMember.bind(this, this.state.member)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          Are you sure to delete
          <div className="welcome" style={{ textAlign: "center" }}>
            {this.state.member?.user?.full_name
              ? this.state.member?.user?.full_name
              : this.state.member?.user?.name}
          </div>
          <br />
          <div
            className="button"
            id="deleteBright"
            style={{ marginTop: "1rem" }}
            onClick={this.handleDeleteMember}
            data-mapid="clickDelete"
          >
            {dict["Process deletion"][language]}
          </div>
        </div>
      </Modal>
    );

    const modalWarningNotAdminContent = modalWarningNotAdmin && (
      <Modal
        modalSize="small"
        id="warning modal"
        isOpen={modalWarningNotAdmin}
        onClose={this.toggleWarningNotAdmin}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="text_bold">
            {
              dict[
                "You are not an admin in this group, only admins can add groups to the project."
              ][language]
            }
          </div>
        </div>
      </Modal>
    );

    const modalDeleteGroupContent = modalDeleteGroup && (
      <Modal
        modalSize="small"
        id="deleteModal"
        isOpen={modalDeleteGroup}
        onClose={this.toggleDeleteGroup}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="text_medium">
            {dict["Are you sure to delete"][language]} <b>{group.name}</b>{" "}
            {dict["from"][language]} <b>{geo_project.name}</b>?
          </div>
          <br />
          <div className="text_inferior">
            {
              dict[
                "This action will not delete the group, just remove the group association with this project, the members of this group will no longer have access to this project."
              ][language]
            }
          </div>
          <br />
          <div
            className="button"
            id="deleteBright"
            style={{ marginTop: "1rem" }}
            onClick={this.handleSubmitDeleteGroup}
          >
            {dict["Process deletion"][language]}
          </div>
        </div>
      </Modal>
    );

    const itemContent = (
      <div style={{ textAlign: "center" }}>
        {carousel_group}
        <div style={{ marginBottom: "10px" }}>{loadingContent}</div>
        {(memberStatus === "creator" || memberStatus === "admin") && searchForm}
        {membersContent}
        {modalDeleteMemberContent}
        {modalAddGroup}
        {modalWarningNotAdminContent}
        {modalDeleteGroupContent}
      </div>
    );
    return <div style={{ minHeight: "80vh" }}>{itemContent}</div>;
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  pushMemberThen,
  searchUser,
  editMember,
  deleteMember,
  resetSearchUser,
  pushGroup,
  deleteGroup,
  set_value_user,
})(PushMember);
