import React, { useEffect, useState } from "react";
import RecoveryCard from "./RecoveryCard";
import RecoveryList from "./RecoveryList";

function ListInRecovery(props) {
  const {
    mode_view,
    data = [],
    toggle_is_multiple,
    is_multiple,
    push_selected,
    selected,
    actived_tab,
    set_recovery_of_projects,
    set_recovery_of_layers,
    delete_permanent,
    toggle_modal_restore_confirmation,
  } = props;

  const [items, set_items] = useState(data);

  useEffect(() => {
    set_items(data);
  }, [data]);

  let children = <></>;
  if (mode_view === "card") {
    children = items.map((item) => (
      <RecoveryCard
        key={item?._id}
        content={item}
        toggle_is_multiple={toggle_is_multiple}
        is_multiple={is_multiple}
        push_selected={push_selected}
        is_checked={selected?.some((s) => s?._id === item?._id)}
        actived_tab={actived_tab}
        set_recovery_of_projects={set_recovery_of_projects}
        set_recovery_of_layers={set_recovery_of_layers}
        delete_permanent={delete_permanent}
        // handle_restore_project={handle_restore_project}
        toggle_modal_restore_confirmation={toggle_modal_restore_confirmation}
      />
    ));
  } else {
    children = items.map((item) => (
      <RecoveryList
        key={item?._id}
        content={item}
        toggle_is_multiple={toggle_is_multiple}
        is_multiple={is_multiple}
        push_selected={push_selected}
        is_checked={selected?.some((s) => s?._id === item?._id)}
        actived_tab={actived_tab}
        set_recovery_of_projects={set_recovery_of_projects}
        set_recovery_of_layers={set_recovery_of_layers}
        delete_permanent={delete_permanent}
        toggle_modal_restore_confirmation={toggle_modal_restore_confirmation}
      />
    ));
  }

  return <>{data?.length > 0 && children}</>;
}

export default ListInRecovery;
