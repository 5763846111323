/*eslint-disable*/

// personal components
import {
  Header,
  Slider,
  Textarea,
  Checklist,
  Selection,
  Image,
  PhoneNumber,
  Time,
  Document,
  CalculateDimension,
  SimpleReferring,
  MathOperators,
  ConditionalStatement,
  Text,
  TABLE_NESTED_CELL,
  DateCell,
  CountingCustomEditor,
  HeaderUneditable,
} from "../../Components/custom_cell";
import { clients_that_use_laporan_pdf, ip_dev } from "./all_about_client";
import {
  data_types_that_must_be_calculate_before_render,
  calculate_by_data_type,
} from "./data_types";
import { is_valid_url } from "./is_valid_url";
import uuid from "./uuid";
const language = localStorage?.language ? localStorage?.language : "ina";
import moment from "moment/moment";

/**
 * Function ini dipake buat Tabel utama yang menggunakan library MUI Datagrid. Tujuannya buat menyesuaikan template custom cell dengan tipe data kolomnya.
 */
export const convertFieldDataTypesToHead = function ({
  column,
  layer_id,
  handleEditCellChange,
  editProperties,
  sortedFeatures,
  is_from_first_gen_table,
}) {
  switch (column.type) {
    case "counting_custom":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <CountingCustomEditor
              params={params}
              row={params.row}
              column={column}
            />
          );
        },
      };
    case "calculate_dimension":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <CalculateDimension
              // language={language}
              // geometry={params.row.geometry}
              column={column}
              params={params}
            />
          );
        },
      };
    case "table":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (e) => {
          const feature =
            sortedFeatures?.find(
              (feature) => feature?.properties?.key === e?.row?.key
            ) || e.row;

          return (
            <TABLE_NESTED_CELL
              layer_id={layer_id}
              row={feature}
              column={column}
              is_from_first_gen_table={is_from_first_gen_table}
            />
            // <button
            //   className="button"
            //   onClick={toggle_table.bind(this, column, feature, child)}
            // >
            //   Buka tabel
            // </button>
          );
        },
      };
    case "text":
      return {
        type: "string",
        width: 200,
        editable: true,
        renderCell: ({ value }) => (
          <Text value={value} />
          // <span
          //   className="hide-scrollbar"
          //   style={{ height: "100%", overflowY: "auto" }}
          // >
          //   {value}
          // </span>
        ),
      };
    case "number":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          const hasError = !Number.isInteger(parseInt(params.props.value));
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return (
            <div className="text_right w_full">
              {!isNaN(params?.value)
                ? new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(Number(params?.value))
                : ""}
            </div>
          );
        },
      };
    case "textarea":
      return {
        type: "string",
        width: 500,
        editable: false,
        renderCell: (params) => {
          return (
            <Textarea
              params={params}
              editProperties={editProperties}
              layer_id={layer_id}
              handleEditCellChange={handleEditCellChange}
            />
          );
        },
      };
    case "range":
      return {
        type: "number",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <Slider
              params={params}
              editProperties={editProperties}
              layer_id={layer_id}
              handleEditCellChange={handleEditCellChange}
            />
          );
        },
      };
    case "phone_number":
      return {
        type: "string",
        width: 250,
        editable: false,
        renderCell: (params) => {
          return (
            <PhoneNumber
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
              // toggleModalNotification={toggleModalNotification}
            />
          );
        },
      };
    case "currency":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          const hasError = !Number.isInteger(parseInt(params.props.value));
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return (
            <div className="text_right w_full">
              {!isNaN(params?.value)
                ? new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(Number(params?.value))
                : ""}
            </div>
          );
        },
      };
    case "table_formula":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          const hasError = !Number.isInteger(parseInt(params.props.value));
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return (
            <div className="text_right w_full">
              {!isNaN(params?.value)
                ? new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(Number(params?.value))
                : ""}
            </div>
          );
        },
      };
    case "arithmetic":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          const hasError = !Number.isInteger(parseInt(params.props.value));
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return (
            <div className="text_right w_full">
              {!isNaN(params?.value)
                ? new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(Number(params?.value))
                : ""}
            </div>
          );
        },
      };
    case "year":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          let hasError = !Number.isInteger(parseInt(params.props.value));
          if (params.props.value < 0) {
            hasError = true;
          }
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return <section>{params.value}</section>;
        },
      };
    case "date":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <DateCell
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "time":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <Time
              column={column}
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "selection":
      return {
        type: "singleSelect",
        width: 200,
        valueOptions: column.array_templates.map((option) => option.name),
        editable: false,
        renderCell: (params) => {
          return (
            <Selection
              column={column}
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "radio_button":
      return {
        type: "string",
        width: 200,
        valueOptions: column.array_templates.map((option) => option.name),
        editable: false,
        renderCell: (params) => {
          return (
            <Selection
              column={column}
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "checklist":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <Checklist
              column={column}
              params={params}
              packThePropertiesDataToUseApiEdit={
                packThePropertiesDataToUseApiEdit
              }
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "image":
      return {
        type: "string",
        isImage: true,
        width: 250,
        editable: false,
        renderCell: (params) => {
          return (
            <Image
              id={"ciaat"}
              params={params}
              language={language}
              layer_id={layer_id}
            />
          );
        },
      };
    case "document":
      return {
        type: "string",
        isDoc: true,
        width: 250,
        editable: false,
        renderCell: (params) => {
          return (
            <Document params={params} language={language} layer_id={layer_id} />
          );
        },
      };
    case "link":
      return {
        type: "string",
        width: 200,
        editable: true,
        renderCell: (params) => {
          let value_url = params.value;
          let href = params.value;
          // let style = "text_black";
          if (is_valid_url(params.value)) {
            if (params.value?.includes("https://")) {
              // style = "text_blue underline";
            } else if (params.value?.includes("http://")) {
              // style = "text_orange underline";
            } else {
              href = `https://${params.value}`;
              // style = "text_blue underline";
            }
          }

          return (
            params.value && (
              <a
                className="url"
                rel="noopener noreferrer"
                target="_blank"
                href={href}
              >
                {value_url}
              </a>
            )
          );
        },
      };
    case "auto_address":
      return {
        type: "string",
        width: 300,
        renderCell: ({ value }) => (
          <span style={{ height: "100%", overflowY: "auto" }}>{value}</span>
        ),
      };
    case "auto_address_poi":
      return {
        type: "string",
        width: 300,
        renderCell: ({ value }) => (
          <span style={{ height: "100%", overflowY: "auto" }}>{value}</span>
        ),
      };
    case "section":
      return {
        type: "string",
        isSection: true,
      };
    case "simple_referring":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <SimpleReferring
              // language={language}
              // geometry={params.row.geometry}
              // column={column}
              params={params}
            />
          );
        },
      };
    case "2d_referring":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <SimpleReferring params={params} />;
        },
      };
    case "math_operators":
      return {
        type: "number",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <MathOperators params={params} />;
        },
      };
    case "conditional_statement":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <ConditionalStatement params={params} />;
        },
      };
    case "nilai_pembanding":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return new Intl.NumberFormat("id-ID", {
            style: "decimal",
          })?.format(params?.value);
        },
      };
    case "jarak_apraisal":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return new Intl.NumberFormat("id-ID", {
            style: "decimal",
          })?.format(params?.value);
        },
      };
    default:
      return {
        type: "string",
        width: 200,
      };
  }
};

const push = (headers, new_header) => {
  if (new_header) {
    headers.push(new_header);
  }
};

const unshift = (headers, new_header) => {
  if (new_header) {
    headers.unshift(new_header);
  }
};

/**
 * Keterangan:
 *
 * Function ini digunakan untuk mengubah fields menjadi headers. Kenapa perlu diubah ke header? Jawabannya adalah supaya dapat dibaca oleh Mui Datagrid (tabel dengan library MUI)
 *
 */
export const convertFieldsToHeaders = function (variables, methods, columns) {
  const {
    fields,
    layer_id,
    sortedFeatures,
    geo_layer,
    is_from_first_gen_table,
    is_editable_field,
  } = variables;
  const {
    handleEditCellChange,
    toggleEditField,
    toggleDeleteField,
    // toggleModalNotification,
    editProperties,
  } = methods;
  const {
    deleteColumn,
    newColumn,
    column_surveyor,
    column_status,
    column_pembanding,
    column_value_pembanding,
    column_download_laporan_pdf,
  } = columns;

  let headers = fields.map((column) => {
    if (column.name) {
      let result = {
        ...column,
        field: column.key,
        headerName: column.name,
        editable: true,
        sortable: true,
        description: column.name,
        renderHeader: (params) => {
          if (is_editable_field) {
            return (
              <Header
                params={params}
                column={column}
                toggleEditField={toggleEditField}
                toggleDeleteField={toggleDeleteField}
              />
            );
          } else {
            return <HeaderUneditable column={column} />;
          }
        },
      };

      const newAttributes = convertFieldDataTypesToHead({
        column,
        layer_id,
        handleEditCellChange,
        editProperties,
        // toggleModalNotification,
        sortedFeatures,
        is_from_first_gen_table,
        // column_surveyor
      });

      result = {
        ...result,
        ...newAttributes,
      };

      delete result.key;
      delete result.name;

      return result;
    } else {
      return column;
    }
  });

  if (geo_layer?.is_survey) {
    if (geo_layer?.layer_data_list?.[0]?.name !== undefined) {
      unshift(headers, column_value_pembanding);
      unshift(headers, column_pembanding);
    }

    /* fitur client: kolom khusus untuk download laporan pdf
     yang seleksi berdasarkan domain */
    const domain = window.location.hostname;
    if (
      clients_that_use_laporan_pdf.includes(domain) ||
      domain?.includes(ip_dev)
    ) {
      unshift(headers, column_download_laporan_pdf);
    }

    unshift(headers, column_surveyor);
    unshift(headers, column_status);
  }

  unshift(headers, deleteColumn);
  push(headers, newColumn);
  headers = headers.filter((head) => !head?.isSection);

  return headers;
};

/**
 * Keterangan:
 *
 * Function ini kurang lebih sama dengan convertFieldsToHeaders yaitu untuk mengubah fields menjadi headers, tapi yang ini KHUSUS untuk NESTED TABLE. Kenapa perlu diubah ke header? Jawabannya adalah supaya dapat dibaca oleh Mui Datagrid (tabel dengan library MUI)
 *
 */
export const convertFieldsToHeadersForSecondGenOrMore = function (
  variables,
  methods
  // columns
) {
  const { fields, layer_id, child, sortedFeatures } = variables;
  const {
    // handlePreviewImage,
    // toggleImagePreview,
    handleEditCellChange,
    toggleEditField,
    toggleDeleteField,
    // toggleModalNotification,
    // toggle_table,
    editProperties,
  } = methods;
  // const {
  //   deleteColumn,
  //   newColumn,
  //   column_surveyor,
  //   column_status,
  //   column_pembanding,
  //   column_value_pembanding,
  //   column_download_laporan_pdf,
  // } = columns;

  let headers = fields?.map((column) => {
    if (column.name) {
      let result = {
        ...column,
        field: column.key,
        headerName: column.name,
        editable: true,
        sortable: false,
        description: column.name,
        renderHeader: (params) => {
          return (
            <Header
              params={params}
              column={column}
              toggleEditField={toggleEditField}
              toggleDeleteField={toggleDeleteField}
            />
          );
        },
      };

      const newAttributes = convertFieldDataTypesToHead({
        column,
        layer_id,
        handleEditCellChange,
        editProperties,
        sortedFeatures,
      });

      result = {
        ...result,
        ...newAttributes,
      };

      delete result.key;
      delete result.name;

      return result;
    } else {
      return column;
    }
  });

  // if (geo_layer?.geo_layer?.is_survey) {
  //   if (geo_layer?.geo_layer?.layer_data_list?.[0]?.name !== undefined) {
  //     headers.unshift(column_download_laporan_pdf);
  //     headers.unshift(column_value_pembanding);
  //     headers.unshift(column_pembanding);
  //   }
  //   // headers.unshift(column_download_laporan_pdf);
  //   headers.unshift(column_surveyor);
  //   headers.unshift(column_status);
  // }

  // headers.unshift(deleteColumn);
  // headers.push(newColumn);
  headers = headers?.filter((head) => !head?.isSection);

  return headers;
};

/**
 * Keterangan:
 *
 * Function ini untuk mengubah attribute properties menjadi data row yang dapat dibaca oleh Mui Datagrid (tabel dengan library MUI). Di dalam function ini sudah termasuk dengan kalkulasi data dengan formula.
 *
 */

export const convertPropertiesToDataList = function ({
  features,
  fields,
  layer_pembanding,
  feature_parent,
  feature_appraisal,
  source,
  capex_value,
  nested_attributes,
}) {
  const fieldKeysThatNeedToCalculateBeforeRender = fields.filter((field) =>
    data_types_that_must_be_calculate_before_render.includes(field.type)
  );
  const data_list = features.map((feature, index) => {
    const calculated_feature = calculate_by_data_type({
      feature,
      fields: fieldKeysThatNeedToCalculateBeforeRender,
      layer_pembanding,
      feature_parent,
      feature_appraisal,
      source,
      capex_value,
    });
    const properties = calculated_feature?.properties || {};
    let body = {
      ...properties,
      id: index,
      key: feature.key || feature.child_uuid,
      formStatus: feature.formStatus,
      user: feature.user,
      geometry: feature.geometry,
      data_pembanding_list: feature?.data_pembanding_list || [],
      counting_custom_array: feature?.counting_custom_array || [],
    };
    if (nested_attributes) {
      body["nested_attributes"] = nested_attributes;
    }
    return body;
  });
  return data_list;
};

/**
 * Keterangan:
 *
 * Function ini hanya untuk kalkulasi data features dengan tipe data formula (math operator, conditional statement, dll), ingat TANPA KONVERSI ke MUI Datagrid.
 *
 * Kemudian ketika function ini dipanggil, FEATURES YANG DIINPUTKAN KE DALAM PARAMETER SUDAH IKUT BERUBAH TANPA HARUS MERETURN,
 *
 * tapi function ini tetap melakaukan return. Hanya untuk berjaga-jaga untuk kasus tertentu.
 *
 */
export const calculateWithoutConvert = function (
  {
    features,
    fields,
    source,
    layer_pembanding,
    feature_parent,
    feature_appraisal,
    capex_value,
  } // features, // fields, // source, // layer_pembanding, // feature_parent, // feature_appraisal
) {
  const fieldKeysThatNeedToCalculateBeforeRender = fields.filter((field) =>
    data_types_that_must_be_calculate_before_render.includes(field.type)
  );
  const data_list = features.map((feature, index) => {
    const calculated_feature = calculate_by_data_type({
      feature,
      fields: fieldKeysThatNeedToCalculateBeforeRender,
      source,
      layer_pembanding,
      feature_parent,
      feature_appraisal,
      capex_value,
    });
    return calculated_feature;
  });
  return data_list;
};

/**
 * Fitur ini sama halnya dengan convertFieldsToHeaders yaitu ubah fields ke headers biar bisa dipake di libary Mui Datagrid.
 * Bedanya fitur ini cuma nambahin atribut field (key) dan headerName (column name) aja.
 * Tujuan dibuatnya fitur ini adalah buat di form editor, karena beberapa komponen di form editor sama seperti di map editor, jadi beberapa butuh headers dan bukan fields.
 *
 * Semoga kedepannya function ini bisa dihapus aja karena nggak terlalu dibutuhin.
 */
export const simpleConvertFieldsToHeaders = function (fields) {
  let headers = fields.map((column) => {
    if (column.name) {
      let result = {
        ...column,
        field: column.key,
        headerName: column.name,
      };
      return result;
    } else {
      return column;
    }
  });
  return headers;
};

/**
 * Fitur ini cuma buat ngepacking data feature supaya langsung bisa dimasukin ke body API editProperties & editPropertiesV2
 */
export const packThePropertiesDataToUseApiEdit = function (layer_id, feature) {
  return {
    geo_layer_id: layer_id,
    feature_key: feature.row.key,
    properties_key: feature.field,
    properties_value: feature.value,
  };
};

/**
 * Fitur ini cuma buat ngepacking data feature supaya langsung bisa dimasukin ke body API edit_child_array
 */
export const packThePropertiesDataToUseApiEditNested = function (
  layer_id,
  params
) {
  const value = !isNaN(params?.value) ? +params?.value : params?.value;

  return {
    geo_layer_id: layer_id,
    feature_key: params?.row?.nested_attributes?.feature_key,
    child_uuid: params?.row?.key,
    properties_key: params?.field,
    properties_value: value,
  };
};
