import React, { Component } from "react";
import { connect } from "react-redux";
class ICON_PLUS extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={9.5}
        height={9.5}
        viewBox="0 0 2.514 2.514"
        className="center_child"
      >
        <path
          d="M1.257 2.315V.198M.198 1.257h2.117"
          style={{
            fill: "none",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 0.39688,
            stroke: "#fff",
          }}
        />
      </svg>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(ICON_PLUS);
