import React, { Component } from "react";
import SetupTableC13PembandingDataPasar from "./SetupTableC13PembandingDataPasar";

export default class C13_PembandingDataPasar extends Component {
  render() {
    const { mode } = this.props;
    return mode === "setup" ? (
      <SetupTableC13PembandingDataPasar
        id="c13_setup_data_pembanding_pasar_pdf"
        {...this.props}
      />
    ) : (
      <table
        id="c13_table_data_pembanding_pasar_pdf"
        className="table_pdf side_border c13"
      >
        {/* <thead>
          <tr>
            <td colSpan={6}>
              <p className="subtitle_pdf">PEMBANDING DATA PASAR</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">NO.</p>
            </td>
            <td>
              <p className="s2">URAIAN</p>
            </td>
            <td>
              <p className="s2">OBJEK PENILAIAN</p>
            </td>
            <td>
              <p className="s2">DATA 1</p>
            </td>
            <td>
              <p className="s2">DATA 2</p>
            </td>
            <td>
              <p className="s2">DATA 3</p>
            </td>
          </tr>
        </thead> */}
        {/* <tbody>
          <tr>
            <td>
              <p className="s2">1</p>
            </td>
            <td>
              <p className="s2">Jenis Properti</p>
            </td>
            <td>
              <p className="s2">Rumah Tinggal</p>
            </td>
            <td>
              <p className="s2">Rumah Tinggal</p>
            </td>
            <td>
              <p className="s2">Rumah Tinggal</p>
            </td>
            <td>
              <p className="s2">Rumah Tinggal</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">2</p>
            </td>
            <td>
              <p className="s2">Alamat</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">3</p>
            </td>
            <td>
              <p className="s2">Jarak dengan Aktivasi</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">4</p>
            </td>
            <td>
              <p className="s2">Sumber Data</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">5</p>
            </td>
            <td>
              <p className="s2">Telepon</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">6</p>
            </td>
            <td>
              <p className="s2">Keterangan</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">Broker / Perantara</p>
            </td>
            <td>
              <p className="s2">Broker / Perantara</p>
            </td>
            <td>
              <p className="s2">Broker / Perantara</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">7</p>
            </td>
            <td>
              <p className="s2">Data Harga (Rp)</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">2.000.000.000</p>
            </td>
            <td>
              <p className="s2">2.000.000.000</p>
            </td>
            <td>
              <p className="s2">2.000.000.000</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">8</p>
            </td>
            <td>
              <p className="s2">Penawaran / Transaksi</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">Penawaran</p>
            </td>
            <td>
              <p className="s2">Penawaran</p>
            </td>
            <td>
              <p className="s2">Penawaran</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">9</p>
            </td>
            <td>
              <p className="s2">Waktu Penawaran / Transaksi</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
            <td>
              <p className="s2">xxxxxx</p>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <p className="subtitle_pdf">SPESIFIKASI DATA</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">1</p>
            </td>
            <td>
              <p className="s2">Legalitas Kepemilikan</p>
            </td>
            <td>
              <p className="s2">SHM</p>
            </td>
            <td>
              <p className="s2">SHM</p>
            </td>
            <td>
              <p className="s2">SHM</p>
            </td>
            <td>
              <p className="s2">SHM</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">2</p>
            </td>
            <td>
              <p className="s2">Luas Tanah (m²)</p>
            </td>
            <td>
              <p className="s2">200</p>
            </td>
            <td>
              <p className="s2">100</p>
            </td>
            <td>
              <p className="s2">100</p>
            </td>
            <td>
              <p className="s2">100</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">3</p>
            </td>
            <td>
              <p className="s2">Bentuk Tanah</p>
            </td>
            <td>
              <p className="s2">Menyerupai Persegi Panjang</p>
            </td>
            <td>
              <p className="s2">Menyerupai Persegi Panjang</p>
            </td>
            <td>
              <p className="s2">Menyerupai Persegi Panjang</p>
            </td>
            <td>
              <p className="s2">Menyerupai Persegi Panjang</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">4</p>
            </td>
            <td>
              <p className="s2">Lebar Depan, Frontage (m)</p>
            </td>
            <td>
              <p className="s2">12</p>
            </td>
            <td>
              <p className="s2">12</p>
            </td>
            <td>
              <p className="s2">1</p>
            </td>
            <td>
              <p className="s2">1</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">5</p>
            </td>
            <td>
              <p className="s2">Lebar Jalan (m)</p>
            </td>
            <td>
              <p className="s2">7</p>
            </td>
            <td>
              <p className="s2">7</p>
            </td>
            <td>
              <p className="s2">1</p>
            </td>
            <td>
              <p className="s2">1</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">6</p>
            </td>
            <td>
              <p className="s2">Letak Tanah</p>
            </td>
            <td>
              <p className="s2">Depan</p>
            </td>
            <td>
              <p className="s2">Depan</p>
            </td>
            <td>
              <p className="s2">Depan</p>
            </td>
            <td>
              <p className="s2">Depan</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">7</p>
            </td>
            <td>
              <p className="s2">Kondisi Tanah</p>
            </td>
            <td>
              <p className="s2">Tanah Matang</p>
            </td>
            <td>
              <p className="s2">Tanah Matang</p>
            </td>
            <td>
              <p className="s2">Tanah Matang</p>
            </td>
            <td>
              <p className="s2">Tanah Matang</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">8</p>
            </td>
            <td>
              <p className="s2">Peruntukan</p>
            </td>
            <td>
              <p className="s2">Pemukiman / Rumah Tinggal</p>
              <p className="s2">/ Hunian</p>
            </td>
            <td>
              <p className="s2">Pemukiman / Rumah Tinggal</p>
              <p className="s2">/ Hunian</p>
            </td>
            <td>
              <p className="s2">Pemukiman / Rumah Tinggal</p>
              <p className="s2">/ Hunian</p>
            </td>
            <td>
              <p className="s2">Pemukiman / Rumah Tinggal</p>
              <p className="s2">/ Hunian</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">9</p>
            </td>
            <td>
              <p className="s2">Kontur Tanah / Topografi</p>
            </td>
            <td>
              <p className="s2">Datar</p>
            </td>
            <td>
              <p className="s2">Datar</p>
            </td>
            <td>
              <p className="s2">Datar</p>
            </td>
            <td>
              <p className="s2">Datar</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">10</p>
            </td>
            <td>
              <p className="s2">Luas Bangunan (m²)</p>
            </td>
            <td>
              <p className="s2">100</p>
            </td>
            <td>
              <p className="s2">100</p>
            </td>
            <td>
              <p className="s2">200</p>
            </td>
            <td>
              <p className="s2">200</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">11</p>
            </td>
            <td>
              <p className="s2">Indikasi Nilai Properti</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">1.800.000.000</p>
            </td>
            <td>
              <p className="s2">1.800.000.000</p>
            </td>
            <td>
              <p className="s2">1.710.000.000</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">12</p>
            </td>
            <td>
              <p className="s2">Perkiraan RCN Bangunan (Rp. per m²)</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">4.270.947</p>
            </td>
            <td>
              <p className="s2">4.270.947</p>
            </td>
            <td>
              <p className="s2">4.270.947</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">13</p>
            </td>
            <td>
              <p className="s2">Perkiraan Nilai Pasar Bangunan (Rp. per m²)</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">3.203.210</p>
            </td>
            <td>
              <p className="s2">3.203.210</p>
            </td>
            <td>
              <p className="s2">3.203.210</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">14</p>
            </td>
            <td>
              <p className="s2">Indikasi Nilai Pasar Bangunan</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">640.642.002</p>
            </td>
            <td>
              <p className="s2">640.642.002</p>
            </td>
            <td>
              <p className="s2">640.642.002</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="s2">15</p>
            </td>
            <td>
              <p className="s2">Indikasi Nilai Tanah</p>
            </td>
            <td>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">1.159.357.998</p>
            </td>
            <td>
              <p className="s2">1.159.357.998</p>
            </td>
            <td>
              <p className="s2">1.069.357.998</p>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p className="s2">Indikasi Nilai Tanah / m²</p>
            </td>
            <td>
              <p className="s2">9.600.000</p>
            </td>
            <td>
              <p className="s2">11.593.580</p>
            </td>
            <td>
              <p className="s2">11.593.580</p>
            </td>
            <td>
              <p className="s2">10.693.580</p>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              <p>
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <p>
                <br />
              </p>
            </td>
            <td>
              <p className="s2">Foto Pembanding 1</p>
            </td>
            <td>
              <p className="s2">Foto Pembanding 2</p>
            </td>
            <td>
              <p className="s2">Foto Pembanding 3</p>
            </td>
          </tr>
        </tbody> */}
      </table>
    );
  }
}
