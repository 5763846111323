/*LIBRARY MODULE*/
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LAYER_INSIGHT extends Component {
  state = {
    render_content: null,
  };

  componentDidUpdate(prevProps) {
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    if (
      this.props.sini.state_update !== prevProps.sini.state_update ||
      basemap_used_after !== basemap_used_before
    ) {
      this.on_render_content();
    }
    if (this.props.sini.fly_update !== prevProps.sini.fly_update) {
      this.on_fly_content();
    }
  }

  on_render_content = () => {
    const { features_poi, features_demografi } = this.props.sini;
    const { map_object } = this.props.layer;

    if (!map_object || features_demografi.length === 0) return;

    const geojson_demografi = {
      type: "FeatureCollection",
      features: features_demografi,
    };
    const geojson_poi = {
      type: "FeatureCollection",
      features: features_poi,
    };

    const layer_demografi_id = "DEMOGRAFI_INSIGHT";
    const layer_poi_id = "POI_INSIGHT";

    // Add or update source and layer for demographic data
    if (!map_object.getSource(layer_demografi_id)) {
      map_object.addSource(layer_demografi_id, {
        type: "geojson",
        data: geojson_demografi,
      });
    } else {
      map_object.getSource(layer_demografi_id).setData(geojson_demografi);
    }

    if (!map_object.getLayer(layer_demografi_id)) {
      map_object.addLayer({
        id: layer_demografi_id,
        source: layer_demografi_id,
        type: "line",
        paint: {
          "line-color": "#db1f2e",
          "line-width": 1,
        },
        layout: {
          visibility: "visible",
        },
      });
    }

    // Add or update source and layer for POI data
    if (!map_object.getSource(layer_poi_id)) {
      map_object.addSource(layer_poi_id, {
        type: "geojson",
        data: geojson_poi,
      });
    } else {
      map_object.getSource(layer_poi_id).setData(geojson_poi);
    }

    if (!map_object.getLayer(layer_poi_id)) {
      map_object.addLayer({
        id: layer_poi_id,
        source: layer_poi_id,
        type: "circle",
        paint: {
          "circle-color": "#000000",
          "circle-radius": 3,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        },
        layout: {
          visibility: "visible",
        },
      });
    }

    // Set click event listener for both layers
    map_object.on("click", layer_demografi_id, this.props.layerOnClick);
    map_object.on("click", layer_poi_id, this.props.layerOnClick);
  };

  on_fly_content = () => {
    const { map_object, is_pause_zoom_map } = this.props.layer;
    if (!is_pause_zoom_map) {
      const { features_demografi } = this.props.sini;

      if (!map_object || features_demografi.length === 0) return;

      const geojson_demografi = {
        type: "FeatureCollection",
        features: features_demografi,
      };

      const [minLng, minLat, maxLng, maxLat] = bbox(geojson_demografi);
      let padding = { top: 100, bottom: 100, left: 500, right: 500 };

      if (window.innerWidth < 1172) {
        padding = {
          top: 50,
          bottom: 400,
          left: 10,
          right: 10,
        };
      }

      map_object.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
        }
      );
    }
  };

  render() {
    return <div>{this.state.render_content}</div>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  sini: state.sini,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_INSIGHT);
