import axios from "axios";
import uuid from "../validation/uuid";
import along from "@turf/along";

export const status_toolbox = () => (dispatch) => {
  dispatch({
    type: "status_toolbox",
  });
};

export const set_value_toolbox = (body) => (dispatch) => {
  dispatch({
    type: "set_value_toolbox",
    payload: body,
  });
  dispatch({
    type: "status_toolbox",
  });
};

export const push_value_toolbox = (body) => (dispatch) => {
  dispatch({
    type: "push_value_toolbox",
    payload: body,
  });
  dispatch({
    type: "status_toolbox",
  });
};

export const set_loading_toolbox = (loading_item) => (dispatch) => {
  dispatch({
    type: "set_loading_toolbox",
    payload: loading_item,
  });
};

export const clear_loading_toolbox = () => (dispatch) => {
  dispatch({
    type: "clear_loading_toolbox",
  });
};

export const change_radius = (body) => (dispatch) => {
  dispatch({
    type: "change_radius",
    payload: body,
  });
  dispatch({
    type: "status_toolbox",
  });
};

/**
 *
 * @param {*} body = {
 * geo_layer_id,
 * features_filtered
 * }
 * @returns
 */
export const set_geojson_filtered = (body) => (dispatch) => {
  dispatch({
    type: "set_geojson_filtered",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const generate_elevation = (feature) => async (dispatch) => {
  try {
    dispatch(set_loading_toolbox("generate_elevation"));
    /*
    `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${ft.geometry.coordinates[0]},${ft.geometry.coordinates[1]}.json?layers=contour&limit=1&access_token=pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`

    */
    const length_km = feature?.properties?.length_km;

    const unit = 10;
    const delta = length_km / unit;
    //generate array along
    let array_along = [];
    let features_elevation_point = [];

    //chart
    let data = [];
    let labels = [];
    for (let index = 0; index <= unit; index++) {
      const value = parseFloat((delta * index).toFixed(3));
      array_along.push(value);
    }

    const delay_constant = 0;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_constant));
    const parent_function = () => {
      return array_along.reduce(
        (last_promise, item, index) =>
          last_promise.then((result_sum) =>
            child_function(item, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (item, index) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const options = { units: "kilometers" };
            let point = along(feature, item, options);
            const res = await axios.get(
              `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${point.geometry.coordinates[0]},${point.geometry.coordinates[1]}.json?layers=contour&limit=1&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
            );
            const elevation = res?.data?.features?.[0]?.properties?.ele;
            data.push(elevation);
            labels.push(index);
            point.properties.elevation = elevation;
            point.properties.uuid = uuid();
            point.properties.parent_uuid = feature?.properties?.uuid;
            features_elevation_point.push(point);

            // console.log(features_elevation_point);
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "push_value_toolbox",
        payload: {
          key: "features_elevation_point",
          value: features_elevation_point,
        },
      });
      dispatch({
        type: "push_value_toolbox",
        payload: {
          key: "elevation_chart_array",
          value: [
            {
              parent_uuid: feature?.properties?.uuid,
              data,
              labels,
            },
          ],
        },
      });
      dispatch({
        type: "status_toolbox",
      });
      dispatch(clear_loading_toolbox());
    });
  } catch (error) {}
};

export const generate_isokron = (feature) => async (dispatch) => {
  try {
    const feature_new = { ...feature };

    dispatch(set_loading_toolbox("generate_isokron"));

    const [long, lat] = feature_new.geometry.coordinates;
    let { routing_profile, contour_type, contours_minutes, contours_meters } =
      feature_new.properties;

    routing_profile = routing_profile || "driving";
    contour_type = contour_type || "minutes";
    contours_minutes = contours_minutes || 5;
    contours_meters = contours_meters || 500;

    let url;
    if (contour_type === "minutes") {
      url = `https://api.mapbox.com/isochrone/v1/mapbox/${routing_profile}/${long},${lat}?contours_minutes=${contours_minutes}&polygons=true&denoise=1&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
    } else {
      url = `https://api.mapbox.com/isochrone/v1/mapbox/${routing_profile}/${long},${lat}?contours_meters=${contours_meters}&polygons=true&denoise=1&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
    }
    const response = await axios.get(url);
    const geometry = response.data.features[0].geometry;
    feature_new.geometry = geometry;

    const get_most_west_coordinate = (feature_polygon) => {
      if (
        !feature_polygon ||
        !feature_polygon.geometry ||
        !feature_polygon.geometry.coordinates
      ) {
        throw new Error("Invalid polygon feature.");
      }
      const coordinates = feature_polygon.geometry.coordinates[0]; // Assuming it's a polygon, coordinates will be an array of arrays (Linear Rings)
      let most_west_coordinate = coordinates.reduce((prev, curr) => {
        return curr[0] < prev[0] ? curr : prev;
      }); // Find the coordinate with the smallest longitude (coordinates[0] is longitude, coordinates[1] is latitude)
      return most_west_coordinate;
    };
    const most_west_coordinate = get_most_west_coordinate(feature_new);
    feature_new.properties.most_west_coordinate = most_west_coordinate;
    dispatch({
      type: "push_value_toolbox",
      payload: {
        key: "features_isokron",
        value: [feature_new],
      },
    });

    dispatch({
      type: "status_toolbox",
    });
    dispatch(clear_loading_toolbox());
  } catch (error) {
    console.error("Error fetching isokron:", error);
  }
};
