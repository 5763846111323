import { getChildByUuid, getChildrenFeaturesByColumnParentUuidAndRowParentUuid } from "../../../../App/reduxHelper/layer";
import { createTd } from "../CreateTd";

export default function createC7SpecialTable({
  feature,
  parent_table_id,
  new_body_table_id,
  setup_table_id,
  setup_child_table_id,
  body,
  row
}) {
  let tr = null;

  const tbody = document.createElement("tbody");
  const table = document.getElementById(parent_table_id);

  table.appendChild(tbody);
  tr = tbody.insertRow();
  createTd({
    tr,
    text: "LEGALITAS BANGUNAN",
    tdClassName: "tr_border_bottom_pdf",
    colspan: 8,
  });

  tr = tbody.insertRow();
  createTd({
    tr,
    text: "",
    tdClassName: "border_right",
    colspan: 4,
  });
  createTd({
    tr,
    text: "TOTAL LUAS BANGUNAN",
    tdClassName: "border_right",
    pClassName: "subtitle_pdf",
    colspan: 4,
  });

  const gen_2_rows = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
    feature,
    column_parent_uuid: body[setup_child_table_id],
  });

  gen_2_rows?.forEach((gen_2, index) => {
    const parent_props = getChildByUuid({feature,uuid:gen_2?.parent_uuid_temp})

    const properties = gen_2?.properties;

    let tr = tbody.insertRow();
    createTd({
      tr,
      text: "Tipe Bangunan",
      colspan: 2,
    });
    createTd({
      tr,
      text: properties?.[body?.["c7_tipe_bangunan_pdf"]],
      colspan: 2,
      tdClassName: "border_right",
    });

    createTd({
      tr,
      text: "- Penyesuaian",
    });

    createTd({
      tr,
      text: parent_props?.properties?.[body?.["c7_penyesuaian_pdf"]],
    });

    createTd({
      tr,
      text: "- Lantai",
    });

    createTd({
      tr,
      text: parent_props?.properties?.[body?.["c7_lantai_pdf"]],
    });

    tr = tbody.insertRow();
    createTd({
      tr,
      colspan: 2,
      text: "No. IMB",
    });

    createTd({
      tr,
      colspan: 2,
      tdClassName: "border_right",
      text: properties?.[body?.["c7_no_imb_pdf"]],
    });

    createTd({
      tr,
      text: "- IMB",
    });

    createTd({
      tr,
      text: parent_props?.properties?.[body?.["c7_imb_pdf"]],
    });

    createTd({
      tr,
      text: "- Lantai",
    });

    createTd({
      tr,
      text: parent_props?.properties?.[body?.["c7_lantai_2_pdf"]],
    });

    tr = tbody.insertRow();
    createTd({
      tr,
      colspan: 2,
      text: "Tgl. Penerbitan IMB",
    });

    createTd({
      tr,
      colspan: 2,
      text: properties?.[body?.["c7_tanggal_penerbitan_imb_pdf"]],
      tdClassName: "border_right",
    });

    createTd({
      tr,
      colspan: 4,
      text: "",
    });

    tr = tbody.insertRow();
    tr.setAttribute("class", "tr_border_bottom_pdf");
    createTd({
      tr,
      text: "Tahun Bangun",
    });

    createTd({
      tr,
      text: properties?.[body?.["c7_tahun_bangun_pdf"]],
    });

    createTd({
      tr,
      text: "Tahun Renovasi",
    });

    createTd({
      tr,
      text: properties?.[body?.["c7_tahun_renovasi_pdf"]],
      tdClassName: "border_right",
    });
  });
}
