/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import format_date_formal from "../../App/validation/format_date_formal";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_fields: "",
      filter_value: "",
      field_obj: {},
      start_date: `${this.formatDate(new Date(Date.now()))}`,
      end_date: `${this.formatDate(new Date(Date.now()))}`,
      render_content: null,
    };
  }

  componentDidMount() {
    this.on_render();
  }

  componentDidUpdate(prevProps) {
    //AFTER
    const status_action_after = this.props.map.status_action;
    //BEFORE
    const status_action_before = prevProps.map.status_action;
    if (status_action_after !== status_action_before) {
      this.on_render();
    }
  }

  on_reset_filter = () => {
    const { layer_id } = this.props.layer;
    const body = { geo_layer_id: layer_id };
    this.props.reset_filter(body);
  };

  on_filter_isi_layer = (field_key, value) => {
    const { field_obj, start_date, end_date } = this.state;
    const { layer_id } = this.props.layer;
    let body = {};
    if (field_obj.type === "date") {
      body = {
        geo_layer_id: layer_id,
        start_date,
        end_date,
        field_key,
      };
    } else {
      body = {
        geo_layer_id: layer_id,
        field_key,
        value,
      };
    }

    this.props.filter_isi_layer(body);
    this.setState({ filter_fields: "", filter_value: "", field_obj: {} });
  };

  on_undo_filter_isi_layer = (field_key, value) => {
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      field_key,
      value,
    };
    this.props.undo_filter_isi_layer(body);
    this.setState({ filter_fields: "", filter_value: "", field_obj: {} });
  };

  on_render = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    let { layer_id, geo_layer_list } = this.props.layer;
    let geo_layer = geo_layer_list.find((e) => e?.geo_layer?._id === layer_id);
    geo_layer = geo_layer?.geo_layer ? geo_layer?.geo_layer : {};
    let fields = geo_layer?.fields ? geo_layer?.fields : [];
    fields = fields.map((f) => {
      let array_value_filter = f?.array_value_filter
        ? f?.array_value_filter
        : [];
      f.array_value_filter = array_value_filter;
      return f;
    });

    fields = fields.filter((f) => {
      let logic = f?.array_value_filter?.length > 0;
      if (f.type === "date") {
        logic = f.start_date;
      } else {
        logic = f?.array_value_filter?.length > 0;
      }
      return logic;
    });

    const render_content = (
      <section>
        <p className="text_bold">
          {dict?.["Filtered parameters:"]?.[language]}
        </p>
        <button
          className="button margin_bottom"
          id="red"
          onClick={this.on_reset_filter}
        >
          X Reset filter & tampilkan semua data
        </button>
        <table className="table_lite">
          <tbody>
            {fields.map((f, idx) => {
              let content;
              if (f.type === "date") {
                content = (
                  <>
                    <p>Start: {format_date_formal(f.start_date)}</p>
                    <p>End: {format_date_formal(f.end_date)}</p>
                  </>
                );
              } else {
                content = f.array_value_filter.map((value, idx) => {
                  return (
                    <span
                      className="button_pill margin_right margin_bottom"
                      id="green"
                      key={idx}
                      onClick={this.on_undo_filter_isi_layer.bind(
                        this,
                        f.key,
                        value
                      )}
                    >
                      {value}{" "}
                      <button className="badge_circle" id="red">
                        X
                      </button>
                    </span>
                  );
                });
              }
              return (
                <tr key={idx}>
                  <td>{f.name}</td>
                  <td>{content}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    );
    this.setState({ render_content });
  };

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  filter_fields(e) {
    let { layer_id, geo_layer_list } = this.props.layer;
    let geo_layer = geo_layer_list.find((e) => e?.geo_layer?._id === layer_id);
    geo_layer = geo_layer?.geo_layer ? geo_layer?.geo_layer : {};
    let fields = geo_layer?.fields ? geo_layer?.fields : [];
    const fields_obj = fields.find((f) => f.key === e.target.value);

    this.setState({ filter_fields: e.target.value, field_obj: fields_obj });
  }

  on_change(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { field_obj } = this.state;

    //global props
    let { layer_id, geo_layer_list } = this.props.layer;

    //content
    let geo_layer = geo_layer_list.find((e) => e?.geo_layer?._id === layer_id);
    geo_layer = geo_layer?.geo_layer ? geo_layer?.geo_layer : {};
    let fields = geo_layer?.fields ? geo_layer?.fields : [];
    fields = fields.filter((f) => !f.isStyle);

    function sort_value(name) {
      let filter = geo_layer?.geojson?.features
        .sort((a, b) =>
          a?.properties?.[name] > b?.properties?.[name] ? 1 : -1
        )
        .map((d) => d?.properties?.[name]);

      return filter;
    }
    const sort_data = [...new Set(sort_value(this?.state?.filter_fields))];

    const render_fields = (
      <main>
        <p className="text_bold">
          {dict["Select a column to filter"][language]}
        </p>
        <select
          onChange={(e) => this.filter_fields(e)}
          value={this.state.filter_fields}
          className="input_new"
        >
          <option hidden>Pilih Kolom....</option>
          {fields.map((d, index) => {
            return (
              <option key={index} value={d.key}>
                {d.name}
              </option>
            );
          })}
        </select>
      </main>
    );

    const render_value = (
      <div>
        <p className="text_bold">
          {dict["Choose"][language]} {field_obj?.name}
        </p>
        {this.state.field_obj.type === "date" ? (
          <div className="row">
            <input
              type="date"
              onChange={(e) => this.on_change(e)}
              id="start_date"
              name="start_date"
              value={this.state.start_date}
              className="input_new"
            />
            <input
              type="date"
              onChange={(e) => this.on_change(e)}
              id="end_date"
              name="end_date"
              value={this.state.end_date}
              className="input_new"
            />
          </div>
        ) : (
          <main>
            <input
              list="filter_persungai"
              id="filter_value"
              name="filter_value"
              onChange={(e) => this.on_change(e)}
              value={this.state.filter_value}
              placeholder={`Pilih ${this?.state?.filter_fields}...`}
              className="input_new"
            />
            <datalist id="filter_persungai">
              {sort_data.map((d, index) => {
                return (
                  <option value={d} key={index}>
                    {d}
                  </option>
                );
              })}
            </datalist>
          </main>
        )}
      </div>
    );

    return (
      <div className="container_standard margin_bottom" id="grey_light">
        {render_fields}
        {this?.state?.filter_fields ? <>{render_value}</> : null}
        {(this?.state?.filter_fields && this?.state?.filter_value) ||
        (this?.state?.filter_fields && this.state.end_date) ||
        (this?.state?.filter_fields && this.state.start_date) ? (
          <button
            className="button margin_bottom"
            onClick={this.on_filter_isi_layer.bind(
              this,
              this.state.filter_fields,
              this.state.filter_value
            )}
          >
            {dict["Filter"][language]}
          </button>
        ) : null}
        <br />
        <br />
        {this.state.render_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  map: state.map,
});

export default connect(mapStateToProps, {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
})(Filter);
