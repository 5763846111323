import { getChildrenFeaturesByColumnParentUuidAndRowParentUuid } from "../../../../App/reduxHelper/layer";

import { createTd } from "../CreateTd";
export default function createSpecialC11TableSaranaPelengkap({
  feature,
  body_table_id,
  body,
}) {
  const tbody = document.getElementById(body_table_id);

  const children = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
    feature,
    column_parent_uuid: body?.["c11_sarana_pelengkap_pdf"],
  });

  children?.forEach((child) => {
    const tr = tbody.insertRow();
    createTd({
      tr,
      text: child?.properties?.[body?.["c3_nama_sarana_pelengkap_pdf"]],
      tdClassName: "w_150",
      pClassName: "subtitle_pdf",
    });

    createTd({
      tr,
      text: `: ${
        child?.properties?.[body?.["c3_keterangan_sarana_pelengkap_pdf"]]
      }`,
    });
  });
}
