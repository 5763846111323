const get_url_query = (query_variable) => {
  const search = window?.location?.search?.substring(1);
  let object_param = {};
  let output;
  if (search) {
    object_param = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    output = object_param[query_variable];
  }
  return output;
};
export default get_url_query;
