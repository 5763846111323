import React, { Component } from 'react'

//General Function
import dict from "../../Data/dict.json";
import { Link } from 'react-router-dom';

export default class ErrorPayment extends Component {
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { error } = this.props
    return (
      <div className='center_perfect h_250 '>
        <div className='flex gap_20 flex_col max_w_500 min_w_300'>
          <label className='font_20 bold'>Error</label>
          <div>
            <label className='font_20 bold'>{dict["Information"][language]}</label>
            <div>
              <p className='paddingLeft_20 flex align_center'>{`Status: ${error?.status || 400}`}</p>
              <p className='paddingLeft_20 flex align_center'>{`Description: ${error?.message}`}</p>
            </div>

          </div>
          <Link
            className="bg_darkBlue text_white rounded_5 padding_y_2 padding_x_5 center_perfect h_40 "
            to={{
              pathname: "https://wa.me/6281216450675"
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact us
          </Link>
        </div>
      </div>
    )
  }
}
