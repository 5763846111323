import uuid from "../../App/validation/uuid";

const on_draw_sini = ({
  event,
  sini,
  get_sini_data,
  set_value_sini,
  set_value_layer,
  show_survey_1,
}) => {
  const { sini_data_mode } = sini;
  const geojson = event;
  let feature = geojson?.features?.[0];
  const params = {
    request_id: uuid(),
    long: feature.geometry.coordinates[0],
    lat: feature.geometry.coordinates[1],
    km_rad: 1,
    sini_data_mode,
    show_survey_1,
  };
  get_sini_data(params);
  set_value_sini({
    key: "sini_menu_active",
    value: "",
  });
  set_value_layer({
    key: "draw_state_platform",
    value: "",
  });
  set_value_sini({
    key: "is_sini_visible",
    value: true,
  });
};

export default on_draw_sini;
