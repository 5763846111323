function dot_every_3_digits(x) {
  if (x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  } else {
    return x;
  }
}

function comma_every_3_digits(n) {
  if (!isNaN(n)) {
    const parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  } else {
    return n;
  }
}

export { dot_every_3_digits, comma_every_3_digits };
