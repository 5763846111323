const css_string_init = () => {
  return `.bi_parent {
    overflow-y: hidden;
    background-color: #fff;
  }
  
  .bi_sidebar_left {
    background-color: rgb(235, 235, 235);
    display: inline-block;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 10px;
    padding: 10px;
    height: calc(100vh - 20px);
    width: 300px;
    box-sizing: border-box;
    overflow-y: auto;
    vertical-align: top;
  }
  
  .bi_list {
    overflow-y: scroll;
    max-height: calc(100vh - 320px);
    padding-right: 10px;
  }
  
  .bi_sidebar_left_parent {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  .bi_sidebar_left_scroll {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 10px;
  }
  
  .bi_content {
    display: inline-block;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    vertical-align: top;
    width: calc(100vw - 310px);
    box-sizing: border-box;
  }
  
  .bi_map {
    background-color: rgb(218, 213, 213);
    margin-bottom: 10px;
    box-sizing: border-box;
    height: calc(35vh - 15px);
    border-radius: 10px;
  }
  
  .bi_card_container {
    background-color: rgb(235, 235, 235);
    margin-bottom: 0px;
    box-sizing: border-box;
    height: calc(65vh - 15px);
    border-radius: 10px;
    padding: 10px;
    overflow-y: auto;
  }
  
  .bi_card_item {
    position: relative;
    border-radius: 10px;
    display: inline-block;
    min-width: 210px;
    min-height: 180px;
    background-color: #fff;
  }
  
  .bi_card_title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
  }
  
  .bi_card_value {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
  }
  
  `;
};
export default css_string_init;
