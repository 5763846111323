import React, { Component } from "react";
import { connect } from "react-redux";
import { FileUploader } from "react-drag-drop-files";

import { StyledDataGrid } from "../../Style/StyledDataGrid";
import dict from "../../Data/dict.json";
import { define_extension, get_file } from "../../App/validation/handle_file";
import {
  match_the_fields_and_features,
  standardize_fields_key_as_name,
  standardize_fields_key_as_uuid,
  standardize_geojson_features,
} from "../../App/validation/generateGeoJson";
import { is_lng_lat_valid } from "../../App/validation/geojson_validation";

//Redux function
import { pushField } from "../../App/actions/layerActions";
import { push_features } from "../../App/actions/layerNewActions";
import { set_value_layer } from "../../App/actions/layerActions";

import CheckBox from "../common_input/CheckBox";
import Modal from "../common_modal/Modal";
// import AddRowsBulkMultipleFiles from "./AddRowsBulkMultipleFiles";
// import AddRowBulkSingleFile from "./AddRowBulkSingleFile";

const single_upload_ext = {
  point: ["XLSX", "CSV", "GEOJSON", "KML", "ZIP"],
  rest_geometry: ["GEOJSON", "KML", "ZIP"],
};

class AddRowBulkSingleFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_add_data: false,
      modal_select_lat_long: false,
      // modal_upload_multiple_file: false,

      current_fields: [],
      uploaded_file: {},
      geojson_fields: [],
      upload_setting: {
        latitude: "",
        longitude: "",
        selected_color: "#1a8bc0",
        mode_color: "picker",
      },
      match_key: [],
      doesnt_match_key: [],
      warning: {
        is_open: false,
        content_name: "",
      },
      is_add_unselected_columns: false,
    };
  }

  toggle_upload_multiple_file = () => {
    const { modal_upload_multiple_file } = this.state;
    this.setState({
      modal_upload_multiple_file: !modal_upload_multiple_file,
    });
  };

  toggle_add_unselected_columns = () => {
    const { is_add_unselected_columns } = this.state;
    this.setState({
      is_add_unselected_columns: !is_add_unselected_columns,
    });
  };

  toggleModalWarning = (content_name, value, message) => {
    const { warning } = this.state;
    this.setState({
      warning: {
        is_open: value || !warning.is_open,
        content_name: content_name || "",
        message: message || "",
      },
    });
  };

  set_latitude_column = (e) => {
    const { longitude, selected_color, mode_color } = this.state.upload_setting;
    const latitude = e.target.value;
    this.setState({
      upload_setting: {
        longitude,
        selected_color,
        mode_color,
        latitude,
      },
    });
  };

  set_longitude_column = (e) => {
    const { latitude, selected_color, mode_color } = this.state.upload_setting;
    const longitude = e.target.value;
    this.setState({
      upload_setting: {
        longitude,
        selected_color,
        mode_color,
        latitude,
      },
    });
  };

  set_selected_color = (e) => {
    const { latitude, longitude, mode_color } = this.state.upload_setting;
    const selected_color = e.target.value;
    this.setState({
      upload_setting: {
        longitude,
        selected_color,
        mode_color,
        latitude,
      },
    });
  };

  set_mode_color = (e) => {
    const { latitude, longitude, selected_color } = this.state.upload_setting;
    const mode_color = e.target.value;
    this.setState({
      upload_setting: {
        longitude,
        selected_color,
        mode_color,
        latitude,
      },
    });
  };

  set_failed_upload_features = (layer_id, value) => {
    this.props.set_value_layer({
      key: "failed_upload_features",
      value: {
        layer_id,
        failed_upload_features: value,
      },
    });
  };

  get_failed_upload_features = () => {
    return this.props.layer.failed_upload_features;
  };

  componentDidMount = () => {
    let { match_key } = this.state;
    const { geo_layer } = this.props;
    const current_fields = this.convertFieldsToDataGrid(geo_layer?.fields);
    if (match_key?.length === 0) {
      match_key = current_fields?.map((item) => {
        return {
          current_field: item?.name,
          current_field_key: item?.key,
          upload_field_name: "",
        };
      });
    }
    // kalo kosong toggle aktif
    if (current_fields?.length === 0) {
      this.toggle_add_unselected_columns();
    }

    this.setState({
      current_fields,
      match_key,
    });
  };

  //   componentDidUpdate = (prevProps, prevState) => {
  //     const { modal_add_data } = this.state;
  //     let { match_key } = this.state;
  //     // pertama kali dibuka
  //     if (prevState?.modal_add_data !== modal_add_data && modal_add_data) {
  //       const { geo_layer } = this.props;
  //       const current_fields = this.convertFieldsToDataGrid(geo_layer?.fields);
  //       if (match_key?.length === 0) {
  //         match_key = current_fields?.map((item) => {
  //           return {
  //             current_field: item?.name,
  //             current_field_key: item?.key,
  //             upload_field_name: "",
  //           };
  //         });
  //       }
  //       this.setState({
  //         current_fields,
  //         match_key,
  //       });
  //     }
  //   };

  set_selected_field = (key, upload_field_name) => {
    let { match_key } = this.state;
    const index = match_key?.findIndex(
      (field) => field.current_field_key === key
    );
    if (index > -1) {
      if (upload_field_name) {
        this.set_doesnt_match_key("", index);
      }

      match_key[index]["upload_field_name"] = upload_field_name || "";
      this.setState(
        {
          match_key,
        },
        () => {
          this.set_doesnt_match_key();
        }
      );
    }
  };

  set_doesnt_match_key = () => {
    const { uploaded_file, match_key } = this.state;
    const filled = match_key
      ?.filter((item) => item?.upload_field_name)
      .map((item) => item?.upload_field_name);
    const doesnt_match_key = uploaded_file?.headers?.filter(
      (header) => !filled?.includes(header)
    );
    this.setState({
      doesnt_match_key,
    });
  };

  get_field_that_similar_name = (row, uploaded_file, callback) => {
    // const { uploaded_file } = this.state;
    const result = uploaded_file?.headers?.find(
      (field) =>
        field?.trim().toLowerCase() === row?.current_field?.trim().toLowerCase()
    );
    if (callback) {
      callback(row?.current_field_key, result);
    }
  };

  get_columns = (uploaded_file, match_key, set_selected_field_callback) => {
    const language = localStorage?.language ? localStorage?.language : "ina";

    return [
      {
        field: "no",
        headerName: "No.",
        editable: false,
        flex: 1,
        type: "number",
      },
      {
        field: "currentFields",
        headerName: "Current Fields",
        editable: false,
        flex: 6,
      },
      {
        field: "newFields",
        headerName: "New Fields",
        editable: false,
        flex: 6,
        renderCell: (params) => {
          const value = match_key?.find(
            (f) => f?.current_field_key === params?.row?.key
          )?.["upload_field_name"];

          const selection = uploaded_file?.headers?.length > 0 && (
            <select
              className="dropdown_in_table"
              value={value}
              onChange={(e) => {
                set_selected_field_callback(params?.row?.key, e?.target?.value);
              }}
            >
              <option value="">{dict["Select"][language]}</option>
              {uploaded_file?.headers?.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          );
          return selection;
        },
      },
    ];
  };

  convertFieldsToDataGrid(fields) {
    const data_list = fields?.map((feature, index) => {
      return {
        ...feature,
        id: index,
        no: `${index + 1}.`,
        currentFields: feature?.["name"],
      };
    });
    return data_list;
  }

  toggle_modal_select_lat_long = () => {
    const { modal_select_lat_long } = this.state;
    this.setState({
      modal_select_lat_long: !modal_select_lat_long,
    });
  };

  toggle_modal_add_data = () => {
    const { modal_add_data } = this.state;
    this.setState({
      modal_add_data: !modal_add_data,
    });
  };

  set_data_inside_uploaded_file = (new_data) => {
    const { data, ...rest } = this.state.uploaded_file;
    this.setState({
      uploaded_file: {
        ...rest,
        data: new_data,
      },
    });
  };

  handle_next_button = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { data } = this.state.uploaded_file;
    const { latitude, longitude, selected_color } = this.state.upload_setting;
    const { _id } = this.props.auth?.user;

    this.toggle_modal_select_lat_long();

    let result;

    if (!latitude || !longitude) {
      this.toggleModalWarning(
        "no_lat_long_header",
        true,
        dict["Warning latitude longitude are not selected"][language]
      );
    } else {
      for (let row of data) {
        let lat = row[latitude];
        let long = row[longitude];

        result = is_lng_lat_valid(long, lat);
        if (!result) break;
      }

      if (result) {
        const features = standardize_geojson_features({
          file: data,
          colorStatus: selected_color,
          user_id: _id,
          latitude: latitude,
          longitude: longitude,
          status: "raw",
        });

        if (features) {
          this.set_data_inside_uploaded_file(features);
        }
      } else {
        this.toggleModalWarning(
          "invalid_warning",
          true,
          `${dict["The accepted coordinate type is latitude longitude of type WGS84 / CGS in decimal degrees."][language]} ${dict["Decimal must be separated by dot. Ex: 106.8858778"][language]}`
        );
      }
    }
  };

  define_need_to_show = async (e) => {
    const file = get_file(e);
    const ext = file.name.split(".").pop();
    const data = await define_extension(e);
    const doesnt_match_key = data?.headers;
    const { match_key } = this.state;

    this.setState(
      {
        uploaded_file: data,
        doesnt_match_key,
      },
      () => {
        if (match_key?.length > 0) {
          match_key.forEach((field) =>
            this.get_field_that_similar_name(
              field,
              data,
              this.set_selected_field
            )
          );
        }

        if (["xlsx", "csv"].includes(ext)) {
          this.toggle_modal_select_lat_long();
        }
      }
    );
  };

  handle_add = async () => {
    const { latitude, longitude, selected_color } = this.state.upload_setting;
    const { is_add_unselected_columns, doesnt_match_key, uploaded_file } =
      this.state;
    const geo_layer_id = this.props.geo_layer._id;
    const { _id } = this.props.auth?.user;
    const { is_key_uuid } = this.props;

    if (is_add_unselected_columns) {
      const { match_key } = this.state;
      let other_fields = [];
      let features = [];

      if (is_key_uuid) {
        other_fields = standardize_fields_key_as_uuid(doesnt_match_key);
        features = match_the_fields_and_features(
          [...match_key, ...other_fields],
          uploaded_file?.data?.features
        );
      } else {
        other_fields = standardize_fields_key_as_name(doesnt_match_key);
        features = uploaded_file?.data?.features;
      }

      features = standardize_geojson_features({
        file: features,
        colorStatus: selected_color,
        user_id: _id,
        latitude,
        longitude,
      });

      for (let field of other_fields) {
        const body = {
          geo_layer_id: geo_layer_id,
          field: field,
        };
        await this.props.pushField(body);
      }

      const body = {
        geo_layer_id: geo_layer_id,
        features: features,
      };

      await this.props.push_features(body);
    } else {
      const { match_key } = this.state;

      const result = match_the_fields_and_features(
        match_key,
        uploaded_file?.data?.features
      );

      const features = standardize_geojson_features({
        file: result,
        colorStatus: selected_color,
        user_id: _id,
        latitude,
        longitude,
      });

      const { geo_layer } = this.props;
      const body = {
        geo_layer_id: geo_layer?._id,
        features: features,
      };

      await this.props.push_features(body);
    }
  };

  get_extension = (type) => {
    if (type === "Point") {
      return single_upload_ext?.point;
    }
    return single_upload_ext?.rest_geometry;
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      // modal_add_data,
      modal_select_lat_long,
      // modal_upload_multiple_file,
      current_fields,
      uploaded_file,
      warning,
      is_add_unselected_columns,
      match_key,
    } = this.state;
    const { geo_layer } = this.props;
    const { latitude, longitude, selected_color, mode_color } =
      this.state.upload_setting;
    const headers = uploaded_file?.headers;

    // const modal_upload_multiple_file_content = modal_upload_multiple_file && (
    //   <Modal
    //     modalSize="medium"
    //     id="modal_data_pembanding"
    //     isOpen={modal_upload_multiple_file}
    //     onClose={this.toggle_upload_multiple_file}
    //   >
    //     <AddRowsBulkMultipleFiles
    //       current_fields={current_fields}
    //       match_key={match_key}
    //       get_columns={this.get_columns}
    //       get_field_that_similar_name={this.get_field_that_similar_name}
    //       fields_default={this.props.layer?.fields_default}
    //       user_id={this.props.auth?.user?._id}
    //       layer_id={geo_layer?._id}
    //       set_failed_upload_features={this.set_failed_upload_features}
    //       get_failed_upload_features={this.get_failed_upload_features}
    //     />
    //   </Modal>
    // );

    const warning_no_long_lat_header_content = warning?.is_open && (
      <Modal
        modalSize="medium"
        id="modal_data_pembanding"
        isOpen={warning.is_open}
        onClose={this.toggleModalWarning}
      >
        <main className="box-body">
          <p style={{ textAlign: "center" }}>{warning?.message}</p>
          <div className="center_perfect">
            <button
              onClick={async () => {
                this.toggleModalWarning();
              }}
              className="button"
            >
              Ok
            </button>
          </div>
        </main>
      </Modal>
    );

    let latlong_setup_ui = (
      <section style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
        <div
          style={{
            marginRight: "20px",
          }}
        >
          <h2
            style={{
              fontSize: "20px",
            }}
          >
            Latitude:
          </h2>
          <span>
            <select onChange={this.set_latitude_column}>
              <option value="">Select</option>
              {headers?.map((header, idx) => (
                <option key={idx} value={header}>
                  {header}
                </option>
              ))}
            </select>
          </span>
        </div>

        <div>
          <h2
            style={{
              fontSize: "20px",
            }}
          >
            Longitude:
          </h2>
          <span>
            <select onChange={this.set_longitude_column}>
              <option value="">Select</option>
              {headers?.map((header, idx) => (
                <option key={idx} value={header}>
                  {header}
                </option>
              ))}
            </select>
          </span>
        </div>
      </section>
    );

    let color_setup_ui = (
      <div className="w_full">
        <section>
          <h1>Color:</h1>
        </section>

        <section className="flex marginBottom_20 w_full">
          <div className="three-container w_full">
            <div className="grid_child w_full marginLeft_10">
              <div className="flex align_center font_20 ">
                <p className="w_full">
                  <span>{"Selected Color: "}</span>
                  <span
                    className="bold"
                    style={{
                      color:
                        selected_color[0] === "#" &&
                        (selected_color.length === 7 ||
                          selected_color.length === 4)
                          ? selected_color
                          : "#000000",
                    }}
                  >
                    {selected_color}
                  </span>
                </p>
              </div>
            </div>

            <div className="grid_child">
              <div className="font_20">Select color from</div>
              <select value={mode_color} onChange={this.set_mode_color}>
                <option value="picker">Picker</option>
                <option value="random">Random</option>
                <option value="column">Column</option>
              </select>
            </div>

            {mode_color === "picker" && (
              <div className="grid_child w_full">
                <label
                  className="marginRight_20 font_20 w_full"
                  htmlFor="colorpicker"
                >
                  Color Picker:
                </label>
                <input
                  type="color"
                  id="colorpicker"
                  value={
                    selected_color[0] === "#" &&
                    (selected_color?.length === 7 ||
                      selected_color?.length === 4)
                      ? selected_color
                      : "#000000"
                  }
                  onChange={this.set_selected_color}
                />
              </div>
            )}

            {mode_color === "column" && (
              <div className="grid_child w_full">
                <div>
                  <span className="font_20">Select column:</span>
                  <span>
                    <select onChange={this.toggleColor}>
                      <option value="">Select</option>
                      {headers?.map((header, idx) => (
                        <option key={idx} value={header}>
                          {header}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    );

    const modal_select_lat_long_content = modal_select_lat_long && (
      <Modal
        modalSize="medium"
        id="modal_data_pembanding"
        isOpen={modal_select_lat_long}
        onClose={this.toggle_modal_select_lat_long}
      >
        <main>
          <div>
            {latlong_setup_ui}
            {color_setup_ui}
            <section className="marginTop_10 flex justify_between align_center">
              <button
                onClick={this.toggle_modal_select_lat_long}
                className="button"
              >
                Back
              </button>

              <button
                style={{
                  backgroundColor:
                    !latitude || !longitude ? "#777777" : "#0ca5eb",
                }}
                onClick={() => {
                  this.handle_next_button();
                }}
                className="button"
              >
                Next
              </button>
            </section>
          </div>
        </main>
      </Modal>
    );

    const extension = this.get_extension(geo_layer?.type);
    const str_of_ext = extension?.join("/")?.toLowerCase();
    const content = (
      <section className="paddingBottom_20 paddingRight_20 paddingLeft_20">
        <section className="center_perfect marginBottom_10">
          <FileUploader
            classes="container_upload absolute border_dash"
            handleChange={(e) => {
              this.define_need_to_show(e);
            }}
            name="file"
            types={extension}
            children={
              <div className="container_upload center_perfect">
                <div>
                  {uploaded_file?.fileName ||
                    `${dict["Drop a file here"][language]} (${str_of_ext})`}
                </div>
              </div>
            }
          />
        </section>
        <section className="h_400">
          <StyledDataGrid
            rows={current_fields}
            columns={this.get_columns(
              uploaded_file,
              match_key,
              this.set_selected_field
            )}
            getRowHeight={() => 35}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            disableColumnMenu
          />
        </section>
        <section className="w_full center_perfect marginTop_10">
          <div className="flex justify_between w_full">
            <CheckBox
              text="isIncludeAnotherColumns"
              title={dict["Also add unselected columns"][language]}
              value={is_add_unselected_columns}
              handle={this.toggle_add_unselected_columns}
            />
            <button onClick={this.handle_add} className="button">
              {dict["Add"][language]}
            </button>
          </div>
        </section>
      </section>
    );

    return (
      <main>
        {content}
        {modal_select_lat_long_content}
        {warning_no_long_lat_header_content}
        {/* {modal_upload_multiple_file_content} */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  pushField,
  push_features,
  set_value_layer,
})(AddRowBulkSingleFile);
