import { useEffect, useState } from "react";
import dict from "../../Data/dict.json";
import { useSelector } from "react-redux";

const default_language = "ina";
const available_languages = ["ina", "eng"];

function useLanguage() {
  const [language, set_language_state] = useState(
    localStorage.language || default_language
  );

  const { auth } = useSelector((state) => {
    return {
      auth: state.auth,
    };
  });

  useEffect(() => {
    set_language(auth.language || default_language);
  }, [auth.language]);

  const set_language = (value) => {
    if (available_languages.includes(value)) {
      set_language_state(value);
      localStorage.setItem("language", value);
    }
  };

  return {
    dict,
    language,
    set_language,
  };
}

export default useLanguage;
