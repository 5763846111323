/* eslint-disable */
import React, { Component } from "react";

export default class TextfieldOrData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
  }

  componentDidMount = () => {
    const { body, id } = this.props;
    this.setState({
      text: body?.[id] || "",
    });
  };

  onChangeText = (e) => {
    this.setState({
      text: e?.target?.value || "",
    });
  };

  componentDidUpdate = (prevProps) => {
    const { body, id } = this.props;
    if (prevProps?.body?.[id] !== body?.[id]) {
      this.setState({
        text: body?.[id],
      });
    }
  };

  render() {
    const { mode, id, className } = this.props;
    const { body, text } = this.state;
    if (mode === "setup") {
      return (
        <input
          type="text"
          onChange={(e) => this.onChangeText(e)}
          value={text || ""}
          onBlur={() => {
            if (this.props.onChange) {
              this.props.onChange(id, text);
            }
          }}
        />
      );
    } else {
      return <p className={className}>{text}</p>;
    }
  }
}
