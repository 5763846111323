import React, { Component } from "react";
import Modal from "../../../common_modal/Modal";
import { Dropdown } from "../../../reuseable";
import ColumnsSelectionChildTable from "../ColumnsSelectionChildTable";

export default class SetupTableC7LegalitasBangunan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_columns_selection: false,
      modal_columns_child_table_selection: false,
    };
  }

  toggleModalColumnsSelection = () => {
    const { modal_columns_selection } = this.state;
    this.setState({
      modal_columns_selection: !modal_columns_selection,
    });
  };

  toggleModalColumnsChildTableSelection = () => {
    const { modal_columns_child_table_selection } = this.state;
    this.setState({
      modal_columns_child_table_selection: !modal_columns_child_table_selection,
    });
  };

  render() {
    const { fields, placeholder = "Pilih", body } = this.props;

    // console.log(fields.filter((field) => field?.name === "Tipe Bangunan"));

    const { id, ...rest_props } = this.props;

    const { modal_columns_selection } = this.state;

    const setup_child_id = "c7_setup_legalitas_bangunan_pdf";

    const modal_columns_selection_content = modal_columns_selection && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_columns_selection}
        onClose={this.toggleModalColumnsSelection}
      >
        <table className="table_pdf">
          <thead>
            <tr>
              <td colSpan={8} className="tr_border_bottom_pdf">
                <p className="subtitle_pdf">LEGALITAS BANGUNAN</p>
                <ColumnsSelectionChildTable
                  id="c7_setup_child_legalitas_bangunan_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* {body?.["c7_setup_child_legalitas_bangunan_pdf"] && (
                  <button
                    onClick={() => {
                      this.toggleModalColumnsChildTableSelection();
                    }}
                    className="button"
                  >
                    Fill the Table
                  </button>
                )} */}
              </td>
            </tr>
          </thead>
          {body?.["c7_setup_child_legalitas_bangunan_pdf"] && (
            <tbody>
              <tr>
                <td colSpan={4} className="border_right">
                  {" "}
                </td>
                <td colSpan={4}>
                  <p className="subtitle_pdf">TOTAL LUAS BANGUNAN</p>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <p>Tipe Bangunan</p>
                </td>
                <td colSpan={2} className="border_right">
                  <ColumnsSelectionChildTable
                    id="c7_tipe_bangunan_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_tipe_bangunan_pdf" {...rest_props} /> */}
                </td>
                <td>
                  <p>- Penyesuaian</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c7_penyesuaian_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_penyesuaian_pdf" {...rest_props} /> */}
                </td>
                <td>
                  <p>-Lantai</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c7_lantai_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_lantai_pdf" {...rest_props} /> */}
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <p>No. IMB</p>
                </td>
                <td colSpan={2} className="border_right">
                  <ColumnsSelectionChildTable
                    id="c7_no_imb_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_no_imb_pdf" {...rest_props} /> */}
                </td>
                <td>
                  <p>- IMB</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c7_imb_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_imb_pdf" {...rest_props} /> */}
                </td>
                <td>
                  <p>-Lantai</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c7_lantai_2_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_lantai_2_pdf" {...rest_props} /> */}
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <p>Tgl. Penerbitan IMB</p>
                </td>
                <td colSpan={2} className="border_right">
                  <ColumnsSelectionChildTable
                    id="c7_tanggal_penerbitan_imb_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData
                  id="c7_tanggal_penerbitan_imb_pdf"
                  {...rest_props}
                /> */}
                </td>
                <td colSpan={4} />
              </tr>

              <tr>
                <td>
                  <p>Tahun Bangun</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c7_tahun_bangun_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_tahun_bangun_pdf" {...rest_props} /> */}
                </td>
                <td>
                  <p>Tahun Renovasi</p>
                </td>
                <td className="border_right">
                  <ColumnsSelectionChildTable
                    id="c7_tahun_renovasi_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                  {/* <DropdownOrData id="c7_tahun_renovasi_pdf" {...rest_props} /> */}
                </td>
                <td colSpan={4} />
              </tr>
            </tbody>
          )}
        </table>
      </Modal>
    );
    return (
      <div>
        <table className="table_pdf side_border">
          <thead>
            <tr style={{ height: "16pt" }}>
              <td colSpan={8}>
                <div className="flex">
                  <p className="subtitle_pdf">Pilih Tabel</p>
                  <Dropdown
                    className="w_100"
                    placeholder={placeholder}
                    filter_on
                    is_enable_empty
                    value={body?.[id] || ""}
                    onChange={(value) => {
                      if (this.props.onChange) {
                        this.props.onChange(id, value);
                      }
                    }}
                  >
                    {fields &&
                      fields.map((field) => (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      ))}
                  </Dropdown>
                  {body?.["c7_setup_legalitas_bangunan_pdf"] ? (
                    <button
                      onClick={() => {
                        this.toggleModalColumnsSelection();
                      }}
                      className="button"
                    >
                      Fill the Table
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </td>
            </tr>
          </thead>
        </table>
        {modal_columns_selection_content}
      </div>
    );
  }
}
