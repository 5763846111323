//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common_modal/Modal";

//Redux function
import { closeModal } from "../../App/actions/authActions";

//Picture Asset
import icon_404 from "../../Assets/svg/icon_404.svg";

//General Function
import dict from "../../Data/dict.json";
const youtube_link =
  "https://www.youtube.com/playlist?list=PLExQBc2gAI8uopeN3Xaj9V9b1QLPp8wPj";

class ModalTutorial extends Component {
  onCloseModal = () => {
    this.props.closeModal();
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    let iframe_content;
    const { modal_tutorial, tutorial_active, tutorial_list } = this.props.auth;
    if (modal_tutorial) {
      const tutorial = tutorial_list.filter(
        (e) => e.title === tutorial_active
      )[0];
      const title = tutorial?.title;
      const src = tutorial?.src;
      let src_raw;
      if (src) {
        src_raw = src.split("/").pop();
      }
      iframe_content = (
        <iframe
          style={{
            height: "100%",
            width: "100%",
            border: "none",
          }}
          title={title}
          src={`https://www.youtube.com/embed/${src_raw}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
      if (!src_raw) {
        iframe_content = (
          <main style={{ textAlign: "center" }}>
            <section>
              <img
                src={icon_404}
                alt="Viewer"
                style={{
                  width: "300px",
                  maxWidth: "70%",
                  marginBottom: "20px",
                }}
              />
            </section>
            <section style={{ fontSize: "15px" }}>
              {
                dict?.[
                  "Sorry, the tutorial for this topic has not been made."
                ]?.[language]
              }
            </section>
            <section>
              {
                dict?.[
                  "In the mean time, let's see the existing tutorial playlist:"
                ]?.[language]
              }
            </section>
            <a
              style={{ marginTop: "20px" }}
              href={youtube_link}
              target="_blank"
              rel="noopener noreferrer"
              className="button_standard"
            >
              MAPID YouTube
            </a>
          </main>
        );
      }
    }
    const modal_content = modal_tutorial && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_tutorial}
        onClose={this.onCloseModal}
      >
        <div
          className="box-body"
          style={{ height: "80vh", overflowY: "hidden" }}
        >
          {iframe_content}
        </div>
      </Modal>
    );
    return modal_content;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { closeModal })(ModalTutorial);
