//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//Personal Component

//Redux function
import { getUserPaymentList } from "../../App/actions/paymentActions";

//Picture Asset

//General Function
import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";
import formatDate from "../../App/validation/format-date";

class PaymentList extends Component {
  state = {};

  componentDidMount() {
    this.props.getUserPaymentList();
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      payment_list,
      loadingList,
      // license_list
    } = this.props.payment;
    const payment_list_filtered = payment_list.filter(
      (e) =>
        [
          "license_0",
          "license_1",
          "license_2",
          "license_3",
          "form",
          "data_exchange",
        ].includes(e.payment_type) &&
        ![
          "specialist_wait_transfer",
          "specialist_wait_transfer",
          "specialist_paid_off",
          "data_exchange",
          "admin_wait_transfer",
          "admin_paid_off",
          "admin_income",
          "ppn",
        ].includes(e.status)
    );
    let content;
    if (loadingList && isEmpty(payment_list_filtered)) {
      content = <main>Memuat data</main>;
    } else {
      content = (
        <main>
          {payment_list.map(
            ({
              _id,
              payment_type,
              price,
              status,
              date_in,
              date_user_action,
              payment_methode,
              multiple_month,
            }) => {
              const date = date_user_action ? date_user_action : date_in;
              let status_text;
              let action_text;
              let backgroundColor;
              switch (status) {
                case "wait_purchase":
                  status_text = dict?.["Waiting for payment"]?.[language];
                  action_text = dict?.["Confirm"]?.[language];
                  backgroundColor = "#8fcc1b";
                  break;
                case "user_cancel":
                  status_text = dict?.["Payment canceled by you"]?.[language];
                  action_text = dict?.["View details"]?.[language];
                  backgroundColor = "#cecece";
                  break;
                case "user_purchase":
                  status_text = (
                    <div>
                      {dict?.["Waiting for admin confirmation."]?.[language]}
                      <br />
                      {
                        dict?.[
                          "While our team checks the payment, you can already use the GEO MAPID license."
                        ]?.[language]
                      }
                    </div>
                  );
                  action_text = dict?.["View details"]?.[language];
                  backgroundColor = "#cecece";
                  break;
                case "success":
                  status_text = dict?.["Payment successful"]?.[language];
                  action_text = dict?.["View details"]?.[language];
                  backgroundColor = "#cecece";
                  break;
                case "rejected":
                  status_text = dict?.["Payment not found"]?.[language];
                  action_text = dict?.["View details"]?.[language];
                  backgroundColor = "#cecece";
                  break;
                default:
                  status_text = dict?.["Waiting for payment"]?.[language];
                  action_text = dict?.["View details"]?.[language];
                  backgroundColor = "#cecece";
                  break;
              }
              let payment_type_text;
              switch (payment_type) {
                case "license_0":
                  payment_type_text = "FREE";
                  break;
                case "license_1":
                  payment_type_text = "PERSONAL";
                  break;
                case "license_2":
                  payment_type_text = "BUSINESS";
                  break;
                case "license_3":
                  payment_type_text = "ENTERPRISE";
                  break;
                case "license_academy":
                  payment_type_text = "ACADEMY";
                  break;
                case "data_exchange":
                  payment_type_text =
                    dict?.["Payment data exchange"]?.[language];
                  break;
                case "form":
                  payment_type_text = dict?.["Survey fee"]?.[language];
                  break;
                default:
                  payment_type_text = dict?.["Other payments"]?.[language];
                  break;
              }
              const rupiah_price = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(price);
              return (
                <main
                  key={_id}
                  className="container_standard"
                  id="white"
                  style={{
                    position: "relative",
                    padding: "15px",
                    minHeight: "150px",
                    maxWidth: "800px",
                    marginBottom: "30px",
                  }}
                >
                  <section
                    className="two_container_equal"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    <div>
                      {formatDate(date)}
                      <p className="text_bold">{payment_type_text}</p>
                      <p className="text_small">
                        Masa aktif {multiple_month} bulan
                      </p>
                      <p className="text_medium" style={{ color: "#0ca5ebff" }}>
                        Rp.{rupiah_price}
                      </p>
                      <p>{payment_methode}</p>
                    </div>
                    <div>
                      <p className="text_inferior">Status</p>
                      <p className="text_inferior">{status_text}</p>
                    </div>
                  </section>
                  <section
                    style={{
                      position: "absolute",
                      bottom: "0px",
                    }}
                  >
                    <Link
                      to={`/payment/${_id}`}
                      className="button_pill_normal"
                      style={{
                        color: "#ffffff",
                        backgroundColor: backgroundColor,
                        marginBottom: "10px",
                        fontSize: "11px",
                      }}
                    >
                      {action_text}
                    </Link>
                  </section>
                </main>
              );
            }
          )}
        </main>
      );
    }
    return (
      <main className="main_container">
        <section className="header_medium" style={{ marginBottom: "20px" }}>
          {dict?.["License purchase history"]?.[language]}
        </section>
        {content}
        <Helmet>
          <title>{dict?.["Payment History"]?.[language]}</title>
          <meta
            name="description"
            content={dict?.["Payment History"]?.[language]}
          />
        </Helmet>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});
export default connect(mapStateToProps, { getUserPaymentList })(PaymentList);
