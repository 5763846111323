//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { edit_properties_upload } from "../../App/actions/layerActions";

//Picture Asset
// import icon_download from "../../Assets/svg/icon_import.svg";
import icon_upload from "../../Assets/svg/icon_upload.svg";

//General Function
import uuid from "../../App/validation/uuid";
import { getGeoLayer } from "../../App/reduxHelper/layer";
// import dict from "../../Data/dict.json";

// const language = localStorage?.language ? localStorage?.language : "ina";

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.handle_submit = this.handle_submit.bind(this);
    this.fileInput = React.createRef();
  }

  async handle_submit(e) {
    e.preventDefault();
    const file = this.fileInput.current.files[0];
    const body_file = {
      file: file,
      file_name: `doc_%${file?.name}_%${uuid()}`,
      dirname: "properties",
    };

    const body_database = {
      geo_layer_id: this.props.geo_layer_id,
      feature_key: this.props.feature_key,
      properties_key: this.props.properties_key,
      properties_value: "", //boleh dikosongkan karena akan ditimpa dengan url dengan methode edit_properties_upload
    };

    let body = {
      body_file,
      body_database,
      mode: "file",
    };

    const result = await this.props.edit_properties_upload(
      body,
      getGeoLayer(this.props.layer)?.architecture,
      this.props.geo_layer_id,
      this.props.params
    );

    if (this.props.set_textfield) {
      this.props.set_textfield(
        result?.properties_value,
        this.props.properties_key
      );
    } else if (this.props.set_document) {
      this.props.set_document(result?.properties_value);
    }
  }

  render() {
    return (
      <label className="fileContainer">
        <div
          className="button_small hover_bigger"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "10px",
            width: 15,
            height: 15,
            backgroundColor: "#e6eef3",
            padding: "5px",
            border: "1px solid #9ca8bb",
            borderRadius: "99px",
          }}
        >
          <img src={icon_upload} className="h_15 w_15 rounded" alt="upload" />
          {/* {dict["Upload file"][language]} */}
        </div>
        <input
          id={`${this.props.feature_key}-${this.props.properties_key}`}
          style={{ display: "none" }}
          type="file"
          accept="file/*"
          ref={this.fileInput}
          onChange={this.handle_submit}
        />
      </label>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  edit_properties_upload,
})(UploadFile);
