/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { delete_card } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class BI_DELETE_CARD extends Component {
  state = {};

  on_delete_card = () => {
    const { bi_object, card_add_object } = this.props.bi;
    const _id = bi_object._id;
    const card_id = card_add_object._id;
    const query = { _id, card_id };
    this.props.delete_card(query);
    this.props.toggle_delete_card();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props

    //content
    return (
      <main>
        <h1 className="text_bold">
          {dict?.["Confirm delete data"]?.[language]}
        </h1>
        <br />
        <br />
        <br />
        <button className="button background_red" onClick={this.on_delete_card}>
          {dict?.["Delete"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { delete_card })(BI_DELETE_CARD);
