const decode_params_to_object = function (params) {
  const object = JSON.parse(
    '{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );
  return object;
};
export default decode_params_to_object;
