import { generate_colors } from "./generate_colors";

export function generate_paint({
  value_array,
  color_mode,
  color_logic,
  field_key,
  min,
  max,
  steps,
  constant_color,
  style_type,
  paint_object_edited,
}) {
  if (min === 0 && max === 0) {
    min = 0;
    max = 100;
  }
  steps = Number(steps);
  let colors = [];
  let paint_config = {};
  // Define color_key based on style_type
  let color_key;
  if (style_type === "circle") {
    color_key = "circle-color";
  } else if (style_type === "line") {
    color_key = "line-color";
  } else if (style_type === "fill") {
    color_key = "fill-color";
  } else {
    return null;
  }
  // Handle different color logic
  if (color_logic === "constant") {
    paint_config[color_key] = constant_color;
  } else if (color_logic === "use_existing") {
    paint_config[color_key] = ["get", field_key];
  } else if (color_logic === "by_text") {
    const steps_text = value_array.length;
    colors = generate_colors(steps_text, color_mode);
    const match_config = ["match", ["get", field_key]];
    value_array.forEach((value, index) => {
      const color_index = index % colors.length;
      match_config.push(value);
      match_config.push(colors[color_index]);
    });
    match_config.push("#FFFFFF"); // Default color for all other categories
    paint_config[color_key] = match_config;
  } else if (color_logic === "by_number_step") {
    colors = generate_colors(steps + 1, color_mode);
    const step_config = [
      "step",
      ["get", field_key],
      colors[0], // Default color for value <= first threshold
    ];
    const raw_interval = (max - min) / steps;
    const interval = get_nice_interval(raw_interval);
    const value_number = [];
    for (let i = 1; i <= steps; i++) {
      value_number.push(min + i * interval);
    }
    value_number.forEach((value, index) => {
      step_config.push(value);
      step_config.push(colors[index + 1]); // colors[0] is for the default color
    });
    paint_config[color_key] = step_config;
  } else if (color_logic === "by_number_gradient") {
    // colors = generate_colors(steps + 1, color_mode);
    colors = generate_colors(steps, color_mode);
    const interpolate_config = ["interpolate", ["linear"], ["get", field_key]];
    const raw_interval = (max - min) / steps;
    const interval = get_nice_interval(raw_interval);
    for (let i = 0; i <= steps - 1; i++) {
      const value = min + i * interval;
      interpolate_config.push(value);
      interpolate_config.push(colors[i]);
    }
    paint_config[color_key] = interpolate_config;
  } else {
    return null;
  }

  // Define default paint properties based on style_type
  let paint_properties = {};
  if (paint_object_edited) {
    paint_properties = paint_object_edited;
  } else {
    if (style_type === "circle") {
      paint_properties = {
        "circle-opacity": 1, // Opacity of the circle (0 to 1)
        "circle-radius": 3, // Radius of the circle (in pixels)
        "circle-stroke-color": "#fff", // The color of the circle's border (default black)
        "circle-stroke-width": 2, // The width of the circle's border (in pixels)
        "circle-stroke-opacity": 1, // Opacity of the circle's border (0 to 1)
        "circle-blur": 0, // Amount of blur applied to the circle (0 for no blur)
        "circle-pitch-alignment": "map", // Defines whether the circle is aligned to the map or the viewport (options: 'map', 'viewport')
        "circle-pitch-scale": "map", // Controls scaling relative to pitch (options: 'map', 'viewport')
        "circle-translate": [0, 0], // The geometry's offset in pixels
        "circle-translate-anchor": "map", // Controls the translation anchor (options: 'map', 'viewport')
      };
    } else if (style_type === "line") {
      paint_properties = {
        "line-width": 2,
        "line-opacity": 1,
        "line-dasharray": [],
        "line-gap-width": 0,
        // "line-blur": 0,
        // "line-translate-anchor": "map",
        // "line-join": "round",
        // "line-cap": "round",
      };
    } else if (style_type === "fill") {
      paint_properties = {
        //asli poligon
        "fill-opacity": 0.7,
        "fill-outline-color": "transparent",
        //line bawaan terpisah
        "line-color": "#000000",
        "line-width": 1,
        "line-opacity": 1,
        "line-dasharray": [],
        "line-gap-width": 0,
        // "line-blur": 0,
        // "line-translate-anchor": "map",
        // "line-join": "round",
        // "line-cap": "round",
      };
    }
  }

  // Merge additional paint properties into the final paint config
  return { ...paint_properties, ...paint_config };
}

function get_nice_interval(raw_interval) {
  const magnitude = Math.pow(10, Math.floor(Math.log10(raw_interval)));
  const normalized_interval = raw_interval / magnitude;
  let nice_interval;
  if (normalized_interval < 1.5) {
    nice_interval = 1;
  } else if (normalized_interval < 3) {
    nice_interval = 2;
  } else if (normalized_interval < 7) {
    nice_interval = 5;
  } else {
    nice_interval = 10;
  }
  return nice_interval * magnitude;
}
