// Buat Ngatur mode "draw" || "toolbox" || "sini"
export const set_mode_fitur = (map) => {
  return {
    type: "set_mode_fitur",
    payload: map,
  };
};

// Buat Ngatur mode lagi di Draw "simple_select" || "draw_point" || ect
export const set_mode_draw = (map) => {
  return {
    type: "set_mode_draw",
    payload: map,
  };
};

export const set_map = (map) => {
  return {
    type: "set_map",
    payload: map,
  };
};

export const status_action = () => {
  return {
    type: "status_action",
  };
};

export const sini_action = () => {
  return {
    type: "sini_action",
  };
};

export const simple_map_action = () => {
  return {
    type: "simple_map_action",
  };
};

export const fly_action = () => {
  return {
    type: "fly_action",
  };
};

export const set_value_map = (body) => (dispatch) => {
  /*
  body: {
  key,
  value
  }
  */
  dispatch({
    type: "status_action",
  });
  dispatch({
    type: "state_update",
  });
  dispatch({
    type: "set_value_map",
    payload: body,
  });
};
