/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";
import { get_genangan } from "../../App/actions/algoritma_genangan";
import { set_value_map } from "../../App/actions/mapActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_layer } from "../../App/actions/layerActions";

/*PICTURE ASSET*/

/*GENERAL*/

class SIDEBAR_BANJIR extends Component {
  state = {};

  toggle_genangan_view_status = () => {
    const { genangan_view_status } = this.props.map;
    this.props.set_value_map({
      key: "genangan_view_status",
      value: !genangan_view_status,
    });
  };

  on_get_genangan = (feature) => {
    this.props.set_value_properties({
      key: "success_status",
      value: false,
    });
    this.props.set_value_properties({
      key: "error_status",
      value: false,
    });
    this.props.set_value_properties({
      key: "error_message",
      value: "",
    });

    this.on_fly(feature);
    const genangan_cm = feature?.properties?.Ketinggian_Genangan_cm
      ? feature?.properties?.Ketinggian_Genangan_cm
      : 0;
    const feature_key = feature?.key;
    const longitude = feature?.geometry?.coordinates?.[0];
    const latitude = feature?.geometry?.coordinates?.[1];
    this.props.set_value_properties({
      key: "modal_pop_up_layer",
      value: false,
    });
    this.props.set_value_properties({
      key: "modal_pop_up_genangan",
      value: true,
    });
    this.props.set_value_properties({
      key: "feature_key_params_genangan",
      value: feature_key,
    });
    this.props.set_value_properties({
      key: "latitude_params_genangan",
      value: latitude,
    });
    this.props.set_value_properties({
      key: "longitude_params_genangan",
      value: longitude,
    });
    this.props.set_value_properties({
      key: "genangan_cm_params_genangan",
      value: genangan_cm,
    });
  };

  on_fly = (feature) => {
    //set feature untuk menandakan sedang load data genangan di feature itu
    this.props.set_value_sini({
      key: "feature_key_fly_genangan",
      value: feature.key,
    });
    this.props.set_value_layer({
      key: "feature_key_selected",
      value: feature.key,
    });
    this.props.set_value_layer({
      key: "feature_object_selected",
      value: feature,
    });

    //bounding box fly to ke feature yang terpilih
    const { map_object } = this.props.layer;
    const geojson = { type: "FeatureCollection", features: [feature] };
    let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
    if (map_object) {
      map_object.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: {
            top: 150,
            bottom: 300,
            left: 420,
            right: 500,
          },
          maxZoom: this.props.layer.max_zoom,
          duration: 750,
        }
      );
    }
  };

  render() {
    //local storage

    //local state

    //global props
    const { geo_layer_list, layer_id } = this.props.layer;
    const {
      loading_item,
      loading_status,
      features_genangan,
      features_genangan_feature_key,
      feature_key_loading_genangan,
      feature_key_fly_genangan,
    } = this.props.sini;
    const { genangan_view_status } = this.props.map;
    const { array_genangan_failed } = this.props.genangan_reducer;

    //content
    let geo_layer = geo_layer_list.find((e) => e?.geo_layer?._id === layer_id);
    geo_layer = geo_layer?.geo_layer ? geo_layer?.geo_layer : {};
    const features_resume = geo_layer?.geojson_filtered?.features || [];

    return (
      <main>
        <br />
        <br />
        <section className="container_standard margin_bottom" id="grey_light">
          <h1 className="text_bold margin_bottom">Detail laporan banjir</h1>
          <p>
            Total laporan ditampilkan: <b>{features_resume?.length}</b> laporan
          </p>
          <button
            className={`button_small ${
              genangan_view_status ? "background_blue" : "background_grey"
            }`}
            onClick={this.toggle_genangan_view_status}
          >
            Hide/show
          </button>
          <table className="table_lite">
            <tbody>
              {features_resume.map((f, idx) => {
                const feature_genangan = features_genangan.find(
                  (item) => item?.properties?.feature_key === f.key
                );
                return (
                  <tr key={idx}>
                    <td>
                      <p className="margin_1">
                        {f?.properties?.Tanggal}{" "}
                        <b className="text_medium">|</b> {f?.properties?.Pukul}
                      </p>
                      <img
                        alt="banjir"
                        src={f?.properties?.Foto_kejadian_1}
                        style={{
                          height: "auto",
                          width: "140px",
                        }}
                      />
                      <br />
                      <section className="margin_bottom">
                        {
                          //1. memuat
                          loading_status &&
                          loading_item === "get_genangan" &&
                          feature_key_loading_genangan === f.key ? (
                            <div className="button_small">Loading...</div>
                          ) : //2. sudah ada data tidak aktif
                          features_genangan_feature_key.includes(f.key) &&
                            feature_key_fly_genangan !== f.key ? (
                            <div
                              className="button_small"
                              onClick={this.on_get_genangan.bind(this, f)}
                            >
                              Luas genangan banjir
                            </div>
                          ) : //3. sudah ada data aktif
                          features_genangan_feature_key.includes(f.key) &&
                            feature_key_fly_genangan === f.key ? (
                            <>
                              <button
                                className="button_small margin_1 vertical_top"
                                onClick={this.on_get_genangan.bind(this, f)}
                              >
                                Luas genangan banjir
                              </button>
                              <div
                                className="badge_circle vertical_top"
                                id="green"
                              />
                            </>
                          ) : //4. gagal
                          array_genangan_failed.includes(f.key) ? (
                            <div
                              className="button_small background_red"
                              onClick={this.on_get_genangan.bind(this, f)}
                            >
                              Tidak ada data DEM
                            </div>
                          ) : (
                            //5. belum ada data
                            <button
                              className="button_small background_grey"
                              onClick={this.on_get_genangan.bind(this, f)}
                            >
                              Luas genangan banjir
                            </button>
                          )
                        }
                      </section>
                    </td>
                    <td>
                      Sungai: <br />
                      <b>{f?.properties?.Sungai_Terdekat}</b>
                      <br />
                      <br />
                      Ketinggian genangan:
                      <br />
                      <b>{f?.properties?.Ketinggian_Genangan_cm} cm</b>
                      <br />
                      <br />
                      Perkiraan luas genangan (meter persegi): <br />
                      <b>
                        {feature_genangan?.properties?.area_meter_square
                          ? feature_genangan?.properties?.area_meter_square
                          : "-"}
                      </b>
                      <br />
                      <br />
                      Perkiraan luas genangan (hektar): <br />
                      <b>
                        {feature_genangan?.properties?.area_hectare
                          ? feature_genangan?.properties?.area_hectare
                          : "-"}
                      </b>
                      <br />
                      <br />
                      Sumber data algoritma: <br />
                      <b>
                        {feature_genangan?.properties?.mode
                          ? feature_genangan?.properties?.mode
                          : "-"}
                      </b>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <section className="text_center margin_top">
            <button className="button">Informasi Lengkap</button>
          </section>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  sini: state.sini,
  genangan_reducer: state.genangan_reducer,
});

export default connect(mapStateToProps, {
  get_genangan,
  set_value_sini,
  set_value_map,
  set_value_properties,
  set_value_layer,
})(SIDEBAR_BANJIR);
