import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import maplibregl from "maplibre-gl";
import centerOfMass from "@turf/center-of-mass";

const properties_key = "Kelas";
const state_name = "tsunami";
const variable_name = "tsunami_one";

class M_S_TSUNAMI_ONE extends Component {
  state = {
    markers_object_list: {}, // Store markers_object_list using their IDs for easy access
  };

  componentWillUnmount() {
    // Ensure all markers_object_list are removed when the component unmounts
    this.clearAllMarkers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.layer?.map_object !== prevProps.layer?.map_object) {
      this.clearAllMarkers(); // Clear markers_object_list when the map object changes
      this.on_render_layer();
    }

    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    if (state_update_after !== state_update_before) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const { map_object } = this.props.layer;
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_show_polygon_label,
    } = this.props.sini;

    const visibility = sini_map_show.includes(state_name) ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];

    if (map_object) {
      if (visibility === "visible" && sini_show_polygon_label) {
        this.addOrUpdateMarkers(merge_array, map_object);
      } else {
        this.clearAllMarkers(); // Remove all markers_object_list if visibility is not 'visible'
      }
    }
  };

  addOrUpdateMarkers = (array, mapObject) => {
    array.forEach((item) => {
      const request_id = item?.request_id;
      const feature = item?.[variable_name];
      const marker_id = `marker_${variable_name}_${request_id}`;
      if (feature?._id && !this.state.markers_object_list[marker_id]) {
        const feature_centroid = centerOfMass(feature);
        const label_final = (
          <div className="badge_small background_black">
            {feature.properties[properties_key]}
          </div>
        );
        const markerEl = document.createElement("div");
        markerEl.innerHTML = ReactDOMServer.renderToString(label_final);
        const marker = new maplibregl.Marker({ element: markerEl })
          .setLngLat(feature_centroid.geometry.coordinates)
          .addTo(mapObject);
        this.setState((prevState) => ({
          markers_object_list: {
            ...prevState.markers_object_list,
            [marker_id]: marker,
          },
        }));
      }
    });
  };

  clearAllMarkers = () => {
    Object.values(this.state.markers_object_list).forEach((marker) =>
      marker.remove()
    );
    this.setState({ markers_object_list: {} });
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  sini: state.sini,
  layer: state.layer,
});

export default connect(mapStateToProps)(M_S_TSUNAMI_ONE);
