import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";

export default class C12_KeteranganTambahan extends Component {
  render() {
    return (
      <table className="table_pdf c12">
        <thead>
          <tr className="tr_border_bottom_pdf">
            <td>
              <p className="center_perfect ">KETERANGAN TAMBAHAN</p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          <tr>
            <td>
              <p>CATATAN PENILAIAN :</p>
            </td>
          </tr>
          <tr>
            <td className="min_h_150 align_start flex">
              <DropdownOrData id="catatan_penilaian_pdf" {...this.props} />
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td>
              <p>Faktor yang Dapat Mengurangi Nilai</p>
            </td>
          </tr>
          <tr>
            <td className="min_h_75 align_start flex">
              <DropdownOrData
                id="faktor_yang_dapat_mengurangi_nilai_pdf"
                {...this.props}
              />
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td>
              <p>Faktor Yang Dapat Menambah Nilai</p>
            </td>
          </tr>
          <tr>
            <td className="min_h_75 align_start flex">
              <DropdownOrData
                id="faktor_yang_dapat_menambah_nilai_pdf"
                {...this.props}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
