/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import {
  create_share_link,
  get_user_by_email,
  get_list_share_link_by_layer_id,
  delete_share_link,
} from "../../App/actions/sharedLinkActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import icon_copy from "../../Assets/svg/icon_copy.svg";

/*GENERAL FUNCTION & DATA*/
import uuid from "../../App/validation/uuid";

/*NON IMPORT*/
const role_array = [
  {
    key: "viewer",
    name: "View",
  },
  {
    key: "viewer_download",
    name: "View & download",
  },
  {
    key: "editor",
    name: "Editor",
  },
];

const auth_mode_array = [
  {
    key: "public",
    name: "Semua orang dengan link bisa melihat",
  },
  {
    key: "user",
    name: "Hanya user yang terpilih",
  },
  {
    key: "group",
    name: "Hanya group yang terpilih",
  },
];

const mode_features_array = [
  {
    key: "selected_features",
    name: "Hanya baris yang dipilih",
  },
  {
    key: "all_features",
    name: "Semua data di layer",
  },
];

class ShareLink extends Component {
  state = {
    role: "viewer", // (viewer || viewer_download || editor)
    auth_mode: "public", // (public || user || group)
    group_id_array: [],
    email: "",
    group_search: "",
    group_list_state: [],
    mode_features: "selected_features", // all_features || selected_features
    share_link_copied: "",
    modal_delete_link: false,
    share_link_delete: "",
  };

  componentDidMount() {
    this.props.set_value_properties({
      key: "shared_link_list",
      value: [],
    });
    const { layer_id } = this.props.layer;
    const feature_key = this.props.properties.feature_will_be_shared_key;
    this.props.get_list_share_link_by_layer_id({
      geo_layer_id: layer_id,
      feature_key,
    });
  }

  toggle_delete_link = (share_link_delete) => {
    this.setState({ modal_delete_link: !this.state.modal_delete_link });
    if (share_link_delete) {
      this.setState({ share_link_delete });
    }
  };

  on_delete_share_link = () => {
    const { share_link_delete } = this.state;
    const params = { link: share_link_delete };
    this.props.delete_share_link(params);
    this.setState({ modal_delete_link: false });
  };

  on_copy_clipboard = (text, share_link_copied) => {
    navigator.clipboard.writeText(text);
    this.setState({
      share_link_copied,
    });
    setTimeout(
      function () {
        this.setState({ share_link_copied: "" });
      }.bind(this),
      1000
    );
  };

  toggle_group_id_array = (group_id) => {
    let { group_id_array } = this.state;
    if (group_id_array.includes(group_id)) {
      group_id_array = group_id_array.filter((item) => item !== group_id);
    } else {
      group_id_array.push(group_id);
    }
    this.setState({ group_id_array });
  };

  on_get_user_by_email = (e) => {
    e.preventDefault();
    const { email } = this.state;
    const params = { email };
    this.props.get_user_by_email(params);
  };

  on_set_role = (role) => {
    this.setState({ role });
  };

  on_change = (e) => {
    //general html
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });

    //custom
    if (name === "email") {
      this.props.set_value_properties({
        key: "user_search_object",
        value: {},
      });
    }
  };

  on_search_group = (e) => {
    e.preventDefault();
    const { group_search } = this.state;
    const { groups } = this.props.auth;
    if (group_search.length > 2) {
      const regex_string = `${group_search
        ?.toUpperCase()
        ?.split(" ")
        ?.map((word) => `(?=.*?${word})`)
        ?.join("")}.*`;
      const regex_pattern = new RegExp(regex_string);
      const group_list_state = groups.filter((item) =>
        regex_pattern.test(item.name.toUpperCase())
      );
      this.setState({ group_list_state });
    }
  };

  on_submit = () => {
    const { role, auth_mode, group_id_array, mode_features } = this.state;
    const { layer_id } = this.props.layer;
    const { user_search_object, feature_will_be_shared_key } =
      this.props.properties;
    let user_id_array = [];
    if (user_search_object?._id !== "not_found" && user_search_object?._id) {
      user_id_array = [user_search_object._id];
    }

    const body = {
      link: uuid(),
      layer_id,
      mode_features,
      feature_key_array: [feature_will_be_shared_key],
      role,
      auth_mode,
      is_use_password: false,
      password: "",
      is_one_time_access_only: false,
      is_accessed: false,
      user_id_array,
      group_id_array,
      user_email_array: [],
    };
    this.props.create_share_link(body);
  };

  render() {
    //local storage

    //local state
    const {
      role,
      auth_mode,
      email,
      group_search,
      group_list_state,
      group_id_array,
      mode_features,
      share_link_copied,
      modal_delete_link,
    } = this.state;

    //global props
    const {
      user_search_object,
      loading_status,
      loading_item,
      shared_link_list,
    } = this.props.properties;
    const { domain } = this.props.auth;
    const domain_final = domain === "localhost" ? `${domain}:3000` : domain;

    //content
    const modal_delete_link_content = modal_delete_link && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_delete_link}
        onClose={this.toggle_delete_link}
      >
        <div className="box-body">
          <h1 className="text_header">Konfirmasi hapus link</h1>
          <p className="text_small">
            Peringatan: data yang dibagikan via link yang sudah dihapus tidak
            akan bisa diakses kembali
          </p>
          <br />
          <br />
          <button
            className="button background_red"
            onClick={this.on_delete_share_link}
          >
            Hapus
          </button>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_delete_link_content}
        <h1 className="text_header margin_bottom">
          Bagikan baris data via link
        </h1>

        <section className="container_light margin_bottom">
          <p className="text_small">Atur data sharing</p>
          <select
            name="mode_features"
            value={mode_features}
            onChange={this.on_change}
          >
            {mode_features_array.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </section>

        <section className="container_light margin_bottom">
          <p className="text_small">Atur pembatasan akses</p>
          {role_array.map((item, idx) => {
            return (
              <button
                key={idx}
                className={`button margin_right margin_bottom ${
                  item.key === role ? "background_green" : "background_white"
                }`}
                onClick={this.on_set_role.bind(this, item.key)}
              >
                {item.name}
              </button>
            );
          })}
        </section>

        <section className="container_light margin_bottom">
          <p className="text_small">Atur autentifikasi</p>
          <select name="auth_mode" value={auth_mode} onChange={this.on_change}>
            {auth_mode_array.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </section>

        <section className="container_light margin_bottom">
          {auth_mode === "public" ? (
            <>Public</>
          ) : auth_mode === "user" ? (
            <>
              <p className="text_small">Bagikan melalui email akun</p>
              <form onSubmit={this.on_get_user_by_email}>
                <input
                  value={email}
                  name="email"
                  onChange={this.on_change}
                  type="text"
                  placeholder="Cari user dengan email"
                  className="margin_bottom"
                />
                <section className="margin_top">
                  {loading_status && loading_item === "get_user_by_email" ? (
                    <div className="button background_grey">
                      Mencari user...
                    </div>
                  ) : (
                    <button
                      className="button"
                      onClick={this.on_get_user_by_email}
                    >
                      Cari
                    </button>
                  )}
                </section>
              </form>
              {user_search_object?._id && (
                <section className="margin_top">
                  {user_search_object?.full_name && (
                    <p className="text_bold">{user_search_object?.full_name}</p>
                  )}
                  <p className="text_small">{user_search_object?.email}</p>
                </section>
              )}
            </>
          ) : (
            <>
              <p className="text_small">Cari grup</p>
              <form onSubmit={this.on_search_group}>
                <input
                  value={group_search}
                  name="group_search"
                  onChange={this.on_change}
                  type="text"
                  placeholder="Cari grup dengan nama"
                  className="margin_bottom"
                />
                <button type="submit" className="button margin_top">
                  Cari grup
                </button>
              </form>
              <section
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <table>
                  <tbody>
                    {group_list_state.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <div
                              className={
                                group_id_array.includes(item._id)
                                  ? "checklist_active"
                                  : "checklist_not"
                              }
                              onClick={this.toggle_group_id_array.bind(
                                this,
                                item._id
                              )}
                            ></div>
                          </td>
                          <td>{item.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            </>
          )}
        </section>

        <section className="margin_bottom">
          {loading_status && loading_item === "create_share_link" ? (
            <div className="button">Memuat...</div>
          ) : (
            <button className="button" onClick={this.on_submit}>
              Buat link sharing
            </button>
          )}
        </section>

        {shared_link_list.length > 0 && (
          <section className="margin_top">
            <br />
            <br />
            <p className="text_bold">Link yang sudah dibagikan:</p>
            <section className="container_light margin_bottom margin_top">
              <table>
                <tbody>
                  {shared_link_list.map((item, idx) => {
                    /*
                  mode_features,
                  role,
                  auth_mode
                  */
                    const { link, mode_features, role, auth_mode } = item;
                    const url = `${domain_final}/view/share?link=${link}`;
                    return (
                      <tr key={idx}>
                        <td style={{ overflowWrap: "break-word" }}>
                          <div className="badge_pill margin_right margin_bottom background_white">
                            {mode_features}
                          </div>
                          <div className="badge_pill margin_right margin_bottom background_white">
                            {role}
                          </div>
                          <div className="badge_pill margin_right margin_bottom background_white">
                            {auth_mode}
                          </div>
                          <br />
                          <p className="text_small">{url}</p>
                          <button
                            className="button_small background_red"
                            onClick={this.toggle_delete_link.bind(this, link)}
                          >
                            Hapus
                          </button>
                          {share_link_copied === link && (
                            <div className="button_small background_white margin_left">
                              Tersalin
                            </div>
                          )}
                          <hr />
                        </td>
                        <td>
                          <img
                            width={17}
                            alt="Copy"
                            src={icon_copy}
                            className="cursor_pointer"
                            onClick={this.on_copy_clipboard.bind(
                              this,
                              url,
                              link
                            )}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  create_share_link,
  get_user_by_email,
  set_value_properties,
  get_list_share_link_by_layer_id,
  delete_share_link,
})(ShareLink);
