/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datasheet/lib/react-datasheet.css";

/*PERSONAL COMPONENT*/
import TABLE_VIEWER from "./TABLE_VIEWER";
import IotTable from "../viewer_chart/IotTable";
import IotTableBar from "../viewer_chart/IotTableBar";
import IotTableSpbu from "../viewer_chart/IotTableSpbu";

/*REDUX FUNCTION*/
import {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
} from "../../App/actions/layerActions";
import { tableFull } from "../../App/actions/layerNewActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import isEmpty from "../../App/validation/is-empty";

/*NON IMPORT*/

class TableFix extends Component {
  state = {};
  componentWillUnmount() {
    this.props.setTabelStatus(false);
  }
  closeFullTable = () => {
    this.props.tableFull(false);
    this.props.setTabelStatus(false);
  };
  render() {
    const { geo_layer, param_filter } = this.props.layer;
    let features = geo_layer?.geojson?.features
      ? geo_layer.geojson.features
      : [];
    if (!isEmpty(param_filter)) {
      features = features.filter(
        (f) =>
          String(f.properties[param_filter.key]).toLowerCase() ===
          String(param_filter.value).toLowerCase()
      );
    }
    let filtered_features = features.slice();
    param_filter.forEach((prm) => {
      filtered_features = filtered_features.filter(
        (f) =>
          String(f.properties[prm.key]).toLowerCase() ===
          String(prm.value).toLowerCase()
      );
    });
    let itemContent;
    if (geo_layer?.type !== "IoT") {
      itemContent = (
        <div
          style={{
            overflow: "scroll",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#e4ecf1",
          }}
        >
          <TABLE_VIEWER />
        </div>
      );
    } else {
      let iotContent;
      const domain = window.location.hostname;
      if (["localhost"].includes(domain)) {
        if (this.props.auth.mode_balai === "cilicis") {
          iotContent =
            geo_layer.name === "Pos Duga Air" ? (
              <IotTable propsFly={(e) => this.flyTo(e)} />
            ) : (
              <IotTableBar propsFly={(e) => this.flyTo(e)} />
            );
        }
        if (this.props.auth.mode_balai === "brantas") {
          iotContent = geo_layer.name.includes("AWLR") ? (
            <IotTable propsFly={(e) => this.flyTo(e)} />
          ) : (
            <IotTableBar propsFly={(e) => this.flyTo(e)} />
          );
        }
      } else {
        if (["bbwsciliwungcisadane.mapid.io"].includes(domain)) {
          iotContent =
            geo_layer.name === "Pos Duga Air" ? (
              <IotTable propsFly={(e) => this.flyTo(e)} />
            ) : (
              <IotTableBar propsFly={(e) => this.flyTo(e)} />
            );
        } else if (["bbwsbrantas.mapid.io"].includes(domain)) {
          iotContent = geo_layer.name.includes("AWLR") ? (
            <IotTable propsFly={(e) => this.flyTo(e)} />
          ) : (
            <IotTableBar propsFly={(e) => this.flyTo(e)} />
          );
        } else {
          iotContent = <IotTableSpbu propsFly={(e) => this.flyTo(e)} />;
        }
      }
      itemContent = (
        <main>
          <main style={{ overflow: "scroll" }}>
            <main
              style={{
                height: "calc(( 100vh ))",
                backgroundColor: "#fff",
              }}
            >
              {iotContent}
            </main>
          </main>
        </main>
      );
    }
    return <div className="absolute_table">{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
  tableFull,
})(TableFix);
