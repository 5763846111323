/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import ADD_PARENT_BI from "./ADD_PARENT_BI";
import BI_FOLDER_LIST from "./BI_FOLDER_LIST";
import BI_EDIT_CSS from "./BI_EDIT_CSS";

/*REDUX FUNCTION*/
import { set_value_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/
import ICON_PLUS from "../../Assets/jsx/ICON_PLUS";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import get_url_query from "../../App/validation/get_url_query";

/*NON IMPORT*/

class BI_SIDEBAR_LIST extends Component {
  state = {
    modal_add_parent: false,
  };

  toggle_edit_css = () => {
    this.props.set_value_bi({
      key: "modal_edit_css",
      value: !this.props.bi.modal_edit_css,
    });
  };

  toggle_add_parent = () => {
    this.props.set_value_bi({
      key: "modal_add_parent",
      value: !this.props.bi.modal_add_parent,
    });
  };

  toggle_edit_project = () => {
    this.props.set_value_bi({
      key: "modal_edit_project",
      value: !this.props.bi.modal_edit_project,
    });
  };

  on_open_all_folder = () => {
    const { folder_id_open_list } = this.props.bi;
    if (folder_id_open_list.length === 0) {
      const folder_list = this.props.bi.folder_list;
      const folder_id_open_list_value = folder_list.map((item) => item._id);
      this.props.set_value_bi({
        key: "folder_id_open_list",
        value: folder_id_open_list_value,
      });
    } else {
      this.props.set_value_bi({
        key: "folder_id_open_list",
        value: [],
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode = get_url_query("mode");

    //local state

    //global props
    const { bi_object, modal_add_parent, modal_edit_css } = this.props.bi;
    const link = this.props.link;

    //content
    const modal_add_parent_content = modal_add_parent && (
      <Modal
        modalSize="large"
        isOpen={modal_add_parent}
        onClose={this.toggle_add_parent}
        backgroundColor="rgb(235, 235, 235)"
      >
        <div className="box-body" id="box-body">
          <main className="max_height">
            <ADD_PARENT_BI />
          </main>
        </div>
      </Modal>
    );

    return (
      <main className="bi_sidebar_left_parent">
        {modal_add_parent_content}
        {modal_edit_css && (
          <section className="bi_sidebar_css">
            <BI_EDIT_CSS />
          </section>
        )}
        <section className="bi_sidebar_left_non_scroll">
          <Link
            className="badge_pill background_orange margin_bottom margin_right"
            to="/dashboard?menu=bi"
          >
            {dict?.["Back"]?.[language]}
          </Link>
          <table className="full_width">
            <tbody>
              <tr>
                <td>
                  {/* <Link
                    className="badge_pill background_orange margin_bottom margin_right"
                    to="/dashboard"
                  >
                    {dict?.["Back"]?.[language]}
                  </Link> */}
                </td>
                <td className="text_right">
                  {mode === "edit" && (
                    <button
                      className={`badge_pill outline_black margin_bottom margin_right ${
                        modal_edit_css ? "background_black" : "background_white"
                      }`}
                      onClick={this.toggle_edit_css}
                    >
                      css
                    </button>
                  )}
                  <Link
                    className={`badge_pill outline_black margin_bottom margin_right ${
                      mode !== "edit" ? "background_black" : "background_white"
                    }`}
                    to={`/bi/${link}`}
                  >
                    View
                  </Link>
                  <Link
                    className={`badge_pill outline_black margin_bottom margin_right  ${
                      mode === "edit" ? "background_black" : "background_white"
                    }`}
                    to={`/bi/${link}?mode=edit`}
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h1 className="text_header margin_bottom max_two_lines_big">
            {bi_object?.name || dict?.["BI OVERVIEW DASBOARD"]?.[language]}
          </h1>
          {mode === "edit" && (
            <>
              <br />
              <button
                onClick={this.toggle_add_parent}
                className="button_pill background_white margin_bottom margin_right"
              >
                <span className="badge_circle background_green margin_right">
                  <ICON_PLUS />
                </span>
                {dict?.["Add project/folder"]?.[language]}
              </button>
            </>
          )}

          <br />
          <button
            className="button_small margin_bottom"
            onClick={this.on_open_all_folder}
          >
            {dict?.["Open/close all folders"]?.[language]}
          </button>
          <br />
        </section>
        <BI_FOLDER_LIST toggle_add_parent={this.toggle_add_parent} />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { set_value_bi })(BI_SIDEBAR_LIST);
