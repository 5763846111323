/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/
import tutorial_map_editor from "../../Assets/gif/tutorial_map_editor.gif";
import tutorial_map_viewer from "../../Assets/gif/tutorial_map_viewer.gif";

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SIDEBAR_LAYER_EMPTY extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { page_layer_mode } = this.props.layer;

    //content
    return (
      <main>
        <h1 className="margin_bottom">Klik ikon mata untuk melihat data</h1>
        {page_layer_mode === "editor" ? (
          <img
            alt="tutorial_map_editor"
            src={tutorial_map_editor}
            style={{ borderRadius: "15px", width: "400px", maxWidth: "100%" }}
          />
        ) : (
          <img
            alt="tutorial_map_viewer"
            src={tutorial_map_viewer}
            style={{ borderRadius: "15px", width: "400px", maxWidth: "100%" }}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {})(SIDEBAR_LAYER_EMPTY);
