import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";
import TextfieldOrData from "../TextfieldOrData";

export default class C5_SesuaiDenganInspeksi extends Component {
  render() {
    return (
      <table className="table_pdf table_box_pdf break_before special">
        <tbody>
          <tr>
            <td colSpan={4}>
              <p>
                Sesuai dengan inspeksi yang telah kami lakukan, mencakup analisa
                situasi (site data), lingkungan dan pengembangan area serta
                pemanfaatan dari properti saat ini, maka kami berpendapat bahwa
                :
              </p>
            </td>
          </tr>

          <tr>
            <td colSpan={4}>
              <p className="header">
                <u>NILAI PASAR :</u>
              </p>
              <p id="nilai_pasar_pdf" className="highlight text_center">
                -
              </p>
              {/* <DropdownOrData
                id="nilai_pasar_pdf"
                className="highlight"
                {...this.props}
              /> */}
              <br />
              <p
                id="nilai_pasar_terbilang_pdf"
                className="highlight text_center"
              >
                -
              </p>
            </td>
          </tr>
          <tr />
          <tr>
            <td colSpan={4}>
              <p className="header">
                <u>INDIKASI NILAI LIKUIDASI :</u>
              </p>
              <p id="indikasi_nilai_likuiditas_pdf" className="highlight"></p>
              {/* <DropdownOrData
                id="indikasi_nilai_likuiditas_pdf"
                className="highlight"
                {...this.props}
              /> */}
              <br />
              <p
                id="indikasi_nilai_likuiditas_terbilang_pdf"
                className="highlight"
              >
                -
              </p>
            </td>
          </tr>

          <tr>
            <td colSpan={4}>
              <p>
                Saya/kami menjamin bahwa penilaian ini telah dilakukan dengan
                penuh kejujuran dan tanggung jawab serta dengan penuh keyakinan
                dan kemampuan ilmu yang saya/kami kuasai sesuai profesi selaku
                penilai properti tanpa adanya pengaruh atau tekanan apapun.
              </p>
            </td>
          </tr>

          <tr />

          <tr className="sebagai">
            <td>
              <p>Hormat Kami,</p>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr className="sebagai">
            <td>
              <p>Appraisal</p>
            </td>

            <td></td>
            <td></td>
            <td>
              <p>Approval</p>
            </td>
          </tr>

          <tr
            style={{
              minHeight: "40px",
              height: "40px",
              maxHeight: "40px",
            }}
          />

          <tr className="nama_terang">
            <td>
              <div>
                <DropdownOrData
                  id="c5_appraisal_pdf"
                  className="underline"
                  {...this.props}
                />
                {/* <TextfieldOrData
                  id="c5_appraisal_pdf"
                  className="underline"
                  {...this.props}
                /> */}
              </div>
            </td>

            <td></td>
            <td></td>
            <td>
              <div>
                <TextfieldOrData
                  id="c5_approval_pdf"
                  className="underline"
                  {...this.props}
                />
              </div>
            </td>
          </tr>
          <tr />
        </tbody>
      </table>
    );
  }
}
