/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const WAIT_INTERVAL = 1_000;
const ENTER_KEY = 13;

class EDIT_LAYER_STYLE_1 extends Component {
  state = {
    search_query: "",
  };

  componentDidMount() {
    this.timer = null;
  }

  on_reset_field = () => {
    this.props.set_value_properties({
      key: "style_parameter",
      value: {
        paint_object: {},
        key: "",
        field_key: "",
        case_recomendation: "",
        color_logic: "",
        color_mode: "",
        value_array: [],
        type_of_object: {},
        type_of_array: [],
        min: 0,
        max: 0,
        steps: 5,
        is_number_count: [],
        constant_color: "#a0d062",
        style_type: "",
        is_cluster: false,
      },
    });
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 1,
    });
  };

  on_reset = () => {
    this.props.set_value_properties({
      key: "style_parameter",
      value: {
        paint_object: {},
        key: "",
        field_key: "",
        case_recomendation: "",
        color_logic: "",
        color_mode: "",
        value_array: [],
        type_of_object: {},
        type_of_array: [],
        min: 0,
        max: 0,
        steps: 5,
        is_number_count: [],
        constant_color: "#a0d062",
        style_type: "",
        is_cluster: false,
      },
    });
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 1,
    });
    this.setState({ search_query: "" });
  };

  on_change = (e) => {
    clearTimeout(this.timer);
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
    if (name === "search_query" && value === "") {
      this.on_reset_field();
    }
    this.timer = setTimeout(this.trigger_action.bind(this), WAIT_INTERVAL);
  };

  handle_enter(e) {
    if (e.keyCode === ENTER_KEY) {
      this.trigger_action();
    }
  }

  trigger_action = () => {
    this.on_reset_field();
    const { search_query } = this.state;
    if (search_query.length >= 2) {
      const { fields_edit_style } = this.props.properties;
      // Create regex to match entire query or any part of the query
      const regexString = search_query
        .split(" ")
        .map((word) => `(?=.*?${word})`)
        .join("|"); // Join with OR condition to match any part of the search_query
      const regex = new RegExp(regexString, "i");
      const list = fields_edit_style.filter((item) => regex.test(item.name));
      this.props.set_value_properties({
        key: "fields_edit_style_filtered",
        value: list,
      });
    }
  };

  on_next_step = () => {
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 2,
    });
  };

  on_click_field = (field) => {
    if (field.key && field.name) {
      const style_parameter = { ...field };
      delete style_parameter.key;
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
      this.setState({
        search_query: field.name,
      });
    }
    this.on_next_step();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { search_query } = this.state;

    //global props
    const {
      fields_edit_style,
      fields_edit_style_filtered,
      layer_style_step,
      style_parameter,
    } = this.props.properties;

    //content
    let list_used = [];
    if (!search_query) {
      list_used = fields_edit_style;
    } else {
      list_used = fields_edit_style_filtered;
    }
    let search_result;
    if (
      search_query &&
      fields_edit_style_filtered?.length === 0 &&
      layer_style_step === 0
    ) {
      search_result = <p>{dict?.["No result"]?.[language]}</p>;
    } else if (!style_parameter.field_key) {
      search_result = (
        <section
          style={{
            maxHeight: "300px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {list_used.map((field, idx) => {
            const { name } = field;
            return (
              <div
                key={idx}
                className="cursor_pointer margin_bottom"
                onClick={this.on_click_field.bind(this, field)}
              >
                <p
                  style={{
                    maxHeight: "2em",
                    overflowY: "hidden",
                    padding: "5px",
                    wordBreak: "break-all",
                  }}
                  className="text_medium full_width button_white"
                >
                  {name}
                </p>
              </div>
            );
          })}
        </section>
      );
    }
    return (
      <main className="container_light margin_bottom">
        <h1 className="text_bold margin_bottom">
          {dict?.["Select column:"]?.[language]}
        </h1>
        {style_parameter.key ? (
          <>
            <p className="badge_small background_black">
              {dict?.["Edit mode"]?.[language]}
            </p>
            <p>
              {
                dict?.[
                  "Cannot select column, go back and create a new style if you want to select another column"
                ]?.[language]
              }
            </p>
          </>
        ) : (
          <>
            <section className="two_container_small_right">
              <input
                className="margin_bottom"
                name="search_query"
                value={search_query}
                onChange={this.on_change}
                onFocus={this.on_reset_field}
              />
              <div>
                <button
                  className="button_small background_black"
                  onClick={this.on_reset}
                >
                  Reset
                </button>
              </div>
            </section>
            {search_result}
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(
  EDIT_LAYER_STYLE_1
);
