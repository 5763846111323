import React, { Component } from "react";
// import { Dropdown } from "../../../reuseable";
import Modal from "../../../common_modal/Modal";
import ColumnsSelectionChildTable from "../ColumnsSelectionChildTable";
import { connect } from "react-redux";
import { getAllDataPembanding } from "../../../../App/reduxHelper/layer";

class SetupTableC13PembandingDataPasar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_columns_selection: false,
      data_pembanding_list: [],
    };
  }

  componentDidMount = () => {
    const { layer } = this.props;
    const data_pembanding_list = getAllDataPembanding(layer)?.map(
      (item) => item?.geo_layer
    );
    this.setState({
      data_pembanding_list,
    });
  };

  toggleModalColumnsSelection = () => {
    const { modal_columns_selection } = this.state;
    this.setState({
      modal_columns_selection: !modal_columns_selection,
    });
  };

  render() {
    const {
      // placeholder = "Pilih",
      body,
    } = this.props;

    const { id, ...rest_props } = this.props;

    const { modal_columns_selection, data_pembanding_list } = this.state;
    rest_props["data_pembanding_list"] = data_pembanding_list;

    // let selected_data_pembanding = undefined;
    // const value = body["c13_selected_data_pembanding_pdf"];
    // const label = data_pembanding_list?.find(
    //   (item) => item?._id === body?.["c13_selected_data_pembanding_pdf"]
    // )?.["name"];
    // if (body["c13_selected_data_pembanding_pdf"] && value) {
    //   selected_data_pembanding = {
    //     value,
    //     label,
    //   };
    // }

    const modal_columns_selection_content = modal_columns_selection && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_columns_selection}
        onClose={this.toggleModalColumnsSelection}
      >
        <div className="box-body flex justify_center">
          <table>
            <tbody>
              <tr>
                <td colSpan={3}>
                  <p className="subtitle_pdf">PEMBANDING DATA PASAR</p>
                </td>
                {/* <td>
                  <p>Pilih Data Pembanding:</p>
                </td>
                <td>
                  <Dropdown
                    className="w_100"
                    placeholder={placeholder}
                    filter_on
                    is_enable_empty
                    value={body?.["c13_selected_data_pembanding_pdf"] || ""}
                    onChange={(value) => {
                      if (this.props.onChange) {
                        this.props.onChange(
                          "c13_selected_data_pembanding_pdf",
                          value
                        );
                      }
                    }}
                  >
                    {data_pembanding_list &&
                      data_pembanding_list?.map((item) => (
                        <option key={item?._id} value={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                  </Dropdown>
                </td> */}
              </tr>
              <tr>
                <td>
                  <p className="s2">NO.</p>
                </td>
                <td>
                  <p className="s2">URAIAN</p>
                </td>
                <td>
                  <p className="s2">OBJEK PENILAIAN</p>
                </td>
                <td>
                  <p className="s2">DATA PEMBANDING</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">1</p>
                </td>
                <td>
                  <p className="s2">Jenis Properti</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_jenis_properti_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_jenis_properti_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">2</p>
                </td>
                <td>
                  <p className="s2">Alamat</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_alamat_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_alamat_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">3</p>
                </td>
                <td>
                  <p className="s2">Jarak dengan Aktivasi</p>
                </td>
                <td>
                  {/* <ColumnsSelectionChildTable
                    id="c13_jarak_dengan_aktivasi_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  /> */}
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_jarak_dengan_aktivasi_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">4</p>
                </td>
                <td>
                  <p className="s2">Sumber Data</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_sumber_data_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_sumber_data_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">5</p>
                </td>
                <td>
                  <p className="s2">Telepon</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_telepon_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_telepon_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">6</p>
                </td>
                <td>
                  <p className="s2">Keterangan</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_keterangan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_keterangan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">7</p>
                </td>
                <td>
                  <p className="s2">Data Harga (Rp)</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_data_harga_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_data_harga_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">8</p>
                </td>
                <td>
                  <p className="s2">Penawaran / Transaksi</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_penawaran_transaksi_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_penawaran_transaksi_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">9</p>
                </td>
                <td>
                  <p className="s2">Waktu Penawaran / Transaksi</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_waktu_penawaran_transaksi_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_waktu_penawaran_transaksi_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <p className="subtitle_pdf">SPESIFIKASI DATA</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">1</p>
                </td>
                <td>
                  <p className="s2">Legalitas Kepemilikan</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_legalitas_kepemilikan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_legalitas_kepemilikan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">2</p>
                </td>
                <td>
                  <p className="s2">Luas Tanah (m²)</p>
                </td>
                <td>
                  {/* <ColumnsSelectionChildTable
                    id="c13_luas_tanah_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  /> */}
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_luas_tanah_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">3</p>
                </td>
                <td>
                  <p className="s2">Bentuk Tanah</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_bentuk_tanah_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_bentuk_tanah_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">4</p>
                </td>
                <td>
                  <p className="s2">Lebar Depan, Frontage (m)</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_lebar_depan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_lebar_depan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">5</p>
                </td>
                <td>
                  <p className="s2">Lebar Jalan (m)</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_lebar_jalan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_lebar_jalan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">6</p>
                </td>
                <td>
                  <p className="s2">Letak Tanah</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_letak_tanah_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_letak_tanah_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">7</p>
                </td>
                <td>
                  <p className="s2">Kondisi Tanah</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_kondisi_tanah_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_kondisi_tanah_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">8</p>
                </td>
                <td>
                  <p className="s2">Peruntukan</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_peruntukan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_peruntukan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">9</p>
                </td>
                <td>
                  <p className="s2">Kontur Tanah / Topografi</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_kontur_tanah_topografi_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_kontur_tanah_topografi_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">10</p>
                </td>
                <td>
                  <p className="s2">Luas Bangunan (m²)</p>
                </td>
                <td>
                  {/* <ColumnsSelectionChildTable
                    id="c13_luas_bangunan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  /> */}
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_luas_bangunan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">11</p>
                </td>
                <td>
                  <p className="s2">Indikasi Nilai Properti</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_properti_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_properti_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">12</p>
                </td>
                <td>
                  <p className="s2">Perkiraan RCN Bangunan (Rp. per m²)</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_perkiraan_rcn_bangunan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_perkiraan_rcn_bangunan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">13</p>
                </td>
                <td>
                  <p className="s2">
                    Perkiraan Nilai Pasar Bangunan (Rp. per m²)
                  </p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_perkiraan_nilai_pasar_bangunan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_perkiraan_nilai_pasar_bangunan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">14</p>
                </td>
                <td>
                  <p className="s2">Indikasi Nilai Pasar Bangunan</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_pasar_bangunan_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_pasar_bangunan_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s2">15</p>
                </td>
                <td>
                  <p className="s2">Indikasi Nilai Tanah</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_tanah_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_tanah_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p className="s2">Indikasi Nilai Tanah / m²</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_tanah_m2_object_penilaian_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_indikasi_nilai_tanah_m2_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p className="s2">Foto Pembanding</p>
                </td>
                <td>
                  {/* <ColumnsSelectionChildTable
                  id="c13_indikasi_nilai_tanah_m2_object_penilaian_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                /> */}
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c13_foto_data_pembanding_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                    is_pembanding
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );

    return (
      <div>
        <table className="table_pdf side_border">
          <thead>
            <tr>
              <td colSpan={6}>
                <p className="subtitle_pdf">PEMBANDING DATA PASAR</p>
              </td>
            </tr>
            <tr style={{ height: "16pt" }}>
              <td colSpan={6}>
                <div className="flex">
                  <p className="subtitle_pdf">Sesuai Fisik Peraturan</p>
                  {/* <Dropdown
                    className="w_100"
                    placeholder={placeholder}
                    filter_on
                    is_enable_empty
                    value={{
                      value: body?.[id],
                      label: fields?.find((field) => field.key === body?.[id])?.[
                        "name"
                      ],
                    }}
                    onChange={({ value }) => {
                      if (this.props.onChange) {
                        this.props.onChange(id, value);
                      }
                    }}
                  >
                    {fields &&
                      fields.map((field) => (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      ))}
                  </Dropdown>
                  {body?.["c13_setup_data_pembanding_pasar_pdf"] && ( */}
                  <button
                    onClick={() => {
                      this.toggleModalColumnsSelection();
                    }}
                    className="button"
                  >
                    Fill the Table
                  </button>
                  {/* )} */}
                </div>
              </td>
            </tr>
          </thead>
        </table>
        {modal_columns_selection_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps)(SetupTableC13PembandingDataPasar);
