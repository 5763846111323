import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import get_url_query from "../../App/validation/get_url_query";

const geojson_fill = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.81758024882822, -6.17697418530642],
            [106.81758024882822, -6.180512056854923],
            [106.8207877803087, -6.180512056854923],
            [106.8207877803087, -6.17697418530642],
            [106.81758024882822, -6.17697418530642],
          ],
        ],
      },
      properties: {
        name: "Java Island",
        tinggi_meter: 100,
      },
    },
  ],
};

const paint_fill = {
  "fill-color": "red",
  "fill-opacity": 0.3,
  "fill-outline-color": "#000",
};

const geojson_ext = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [106.80734572042746, -6.1771545275193915],
            [106.80713980781087, -6.196238549254161],
            [106.82963836570212, -6.196443817278038],
            [106.83005069036551, -6.164431511349392],
            [106.80755117579366, -6.164019108675134],
            [106.80735430203652, -6.177044485546915],
            [106.81387259944188, -6.177207712900852],
            [106.81400717970934, -6.1690905189982885],
            [106.82534916132829, -6.169173499174818],
            [106.82498564989191, -6.192835724023311],
            [106.81322122055661, -6.192475828394123],
            [106.8138735966491, -6.177281945117144],
            [106.80734572042746, -6.1771545275193915],
          ],
        ],
      },
      properties: {
        name: "Java Island",
        color: "blue",
        height: 500,
        opacity: 0.5,
        base: 0,
      },
    },
  ],
};

const paint_fill_ext = {
  "fill-extrusion-color": ["get", "color"],
  "fill-extrusion-height": ["get", "height"],
  "fill-extrusion-base": ["get", "base"],
  "fill-extrusion-opacity": 0.5,
};

class LIBRE_EGI extends Component {
  state = {};

  componentDidMount() {
    const mode = get_url_query("mode");
    if (mode === "egi") {
      this.on_render_content();
      this.on_fly();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.layer.map_object !== prevProps.layer.map_object) {
      const mode = get_url_query("mode");
      if (mode === "egi") {
        this.on_render_content();
        this.on_fly();
      }
    }
  }

  on_render_content = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      if (!map_object.isStyleLoaded()) {
        // If the style is not yet loaded, wait until it is
        map_object.once("styledata", () => {
          this.addMapContent(map_object);
        });
      } else {
        // If the style is already loaded, proceed immediately
        this.addMapContent(map_object);
      }
    }
  };

  // Helper function to handle the logic for adding the map content
  addMapContent = (map_object) => {
    const id_map = "libre_egi_fill";
    if (!map_object.getSource(id_map)) {
      map_object.addSource(id_map, {
        type: "geojson",
        data: geojson_fill,
      });
    }

    if (!map_object.getLayer(id_map)) {
      map_object.addLayer({
        id: id_map,
        source: id_map,
        type: "fill",
        paint: paint_fill,
        layout: { visibility: "visible" },
      });
    } else {
      for (const key in paint_fill) {
        if (Object.prototype.hasOwnProperty.call(paint_fill, key)) {
          const element = paint_fill[key];
          map_object.setPaintProperty(id_map, key, element);
        }
      }
    }

    const id_map_ext = "libre_egi_ext";
    if (!map_object.getSource(id_map_ext)) {
      map_object.addSource(id_map_ext, {
        type: "geojson",
        data: geojson_ext,
      });
    }

    if (!map_object.getLayer(id_map_ext)) {
      map_object.addLayer({
        id: id_map_ext,
        source: id_map_ext,
        type: "fill-extrusion",
        paint: paint_fill_ext,
        layout: { visibility: "visible" },
      });
    } else {
      // map_object.setPaintProperty(id_map_ext, "fill-extrusion-color", "green");
      // map_object.setPaintProperty(id_map_ext, "fill-extrusion-height", 100);
      // map_object.setPaintProperty(id_map_ext, "fill-extrusion-opacity", 0.2);
      // map_object.setPaintProperty(id_map_ext, "fill-extrusion-base", 0);

      for (const key in paint_fill_ext) {
        if (Object.prototype.hasOwnProperty.call(paint_fill_ext, key)) {
          const element = paint_fill_ext[key];
          map_object.setPaintProperty(id_map_ext, key, element);
        }
      }
    }
  };

  on_fly = () => {
    const { map_object } = this.props.layer;

    if (map_object) {
      const { sidebar_right_status, sidebar_left_status } =
        this.props.properties;

      let top = 50;
      let bottom = 400;
      let left = 10;
      let right = 10;

      if (window.innerWidth < 1172) {
        //MOBILE
        top = 50;
        bottom = 400;
        left = 10;
        right = 10;
      } else {
        //DESKTOP
        top = 150;
        bottom = 300;
        left = 420;
        right = 500;
        if (sidebar_right_status === true) {
          right = 500;
        } else {
          right = 50;
        }
        if (sidebar_left_status === true) {
          left = 500;
        } else {
          left = 50;
        }
      }
      const padding = { top, bottom, left, right };
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_ext);
      map_object.fitBounds(
        [
          [min_longitude, min_latitude],
          [max_longitude, max_latitude],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
          duration: 750,
        }
      );
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LIBRE_EGI);
