//library
import axios from "axios";

//function
import { geoServerBaseUrl } from "./baseUrl";
import { getProjectList } from "./projectActions";

//non import
const SERVER_URL = geoServerBaseUrl;

/*NON API*/

export const set_loading_project = (loading_item) => {
  return {
    type: "set_loading_project",
    payload: loading_item,
  };
};

export const clear_loading_project = (loading_item) => {
  return {
    type: "clear_loading_project",
    payload: loading_item,
  };
};

/*GET METHODE*/

/**
 * get_folder_project_list_by_user_id
 * @endpoint : /folder_projects/get_folder_project_list_by_user_id
 * @methode : GET
 * @body :
 * @params :
 */
export const get_folder_project_list_by_user_id =
  (body) => async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL + `/folder_projects/get_folder_project_list_by_user_id`,
        config
      );
      const folder_project_list = res.data;
      dispatch({
        type: "set_value_project",
        payload: {
          key: "folder_project_list",
          value: folder_project_list,
        },
      });
    } catch (error) {}
  };

//get_all_folder_project_list_by_user_id
/**
 * get_all_folder_project_list_by_user_id
 * @endpoint : /folder_projects/get_all_folder_project_list_by_user_id
 * @methode : GET
 * @body :
 * @params :
 */
export const get_all_folder_project_list_by_user_id =
  (body) => async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL + `/folder_projects/get_all_folder_project_list_by_user_id`,
        config
      );
      const list = res.data;
      dispatch({
        type: "set_value_project",
        payload: {
          key: "folder_project_list_all",
          value: list,
        },
      });
    } catch (error) {}
  };

/**
 * get_folder_list_by_parent_folder_id
 * @endpoint : /folder_projects/get_folder_list_by_parent_folder_id
 * @methode : GET
 * @body :
 * @params : folder_parent_id
 */
export const get_folder_list_by_parent_folder_id =
  (params) => async (dispatch) => {
    try {
      dispatch(set_loading_project("get_folder_list_by_parent_folder_id"));
      const { folder_parent_id } = params;
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL +
          `/folder_projects/get_folder_list_by_parent_folder_id?folder_parent_id=${folder_parent_id}`,
        config
      );
      const folder_project_child_list = res.data;
      dispatch({
        type: "set_value_project",
        payload: {
          key: "folder_project_child_list",
          value: folder_project_child_list,
        },
      });
      dispatch(clear_loading_project("get_folder_list_by_parent_folder_id"));
    } catch (error) {
      dispatch(clear_loading_project("get_folder_list_by_parent_folder_id"));
    }
  };

//get_folder_project_list_all_nested

//get_project_list_by_folder_id

/**
 * get_project_list_by_folder_id
 * @endpoint : /folder_projects/get_project_list_by_folder_id
 * @methode : GET
 * @body :
 * @params : folder_id
 */
export const get_project_list_by_folder_id = (params) => async (dispatch) => {
  try {
    dispatch(set_loading_project("get_project_list_by_folder_id"));
    const { folder_id } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL +
        `/folder_projects/get_project_list_by_folder_id?folder_id=${folder_id}`,
      config
    );
    const list = res.data;
    dispatch({
      type: "set_value_project",
      payload: {
        key: "geo_project_list_child",
        value: list,
      },
    });
    dispatch(clear_loading_project("get_project_list_by_folder_id"));
  } catch (error) {
    dispatch(clear_loading_project("get_project_list_by_folder_id"));
  }
};

/*POST METHODE*/

/**
 * create_folder_project
 * @endpoint : /folder_projects/create_folder_project
 * @methode : POST
 * @body :  name, folder_parent_id, project_id_list
 */
export const create_folder_project = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + `/folder_projects/create_folder_project`,
      body,
      config
    );
    const response = res.data;
    const key = body?.folder_parent_id
      ? "folder_project_child_list"
      : "folder_project_list";
    dispatch({
      type: "push_value_project",
      payload: {
        key: key,
        value: response,
      },
    });
  } catch (error) {}
};

/**
 * edit_folder_project
 * @endpoint : /folder_projects/edit_folder_project
 * @methode : POST
 * @body : folder_id, name
 */
export const edit_folder_project = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + `/folder_projects/edit_folder_project`,
      body,
      config
    );
    const key = body.key;
    dispatch({
      type: "edit_value_project",
      payload: {
        key: key,
        value: body,
        id_key: "_id",
        id_value: body.folder_id,
      },
    });
  } catch (error) {}
};

/**
 * move_folder_to_another_folder
 * @endpoint : /folder_projects/move_folder_to_another_folder
 * @methode : POST
 * @body : folder_id, folder_parent_id
 */
export const move_folder_to_another_folder = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + `/folder_projects/move_folder_to_another_folder`,
      body,
      config
    );
    //menghilangkan folder dari folder parent atau folder current
    const key = body.key;
    dispatch({
      type: "pull_value_project_object",
      payload: {
        key: key,
        value: body?.folder_id,
        id: "_id",
      },
    });
  } catch (error) {}
};

/**
 * push_project
 * @endpoint : /folder_projects/push_project
 * @methode : POST
 * @body : folder_id, project_id
 */
export const push_project = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + `/folder_projects/push_project`,
      body,
      config
    );
    //menghilangkan project dari project parent atau project current
    const key = body.key;
    dispatch({
      type: "pull_value_project_object",
      payload: {
        key: key,
        value: body?.project_id,
        id: "_id",
      },
    });
  } catch (error) {}
};

//pull_project
/**
 * pull_project
 * @endpoint : /folder_projects/pull_project
 * @methode : POST
 * @body : folder_id, project_id
 */
export const pull_project = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + `/folder_projects/pull_project`,
      body,
      config
    );
    //menghilangkan project dari project parent
    const key = body.key;
    dispatch({
      type: "pull_value_project_object",
      payload: {
        key: key,
        value: body?.project_id,
        id: "_id",
      },
    });
    if (!body.is_not_outside) {
      dispatch({
        type: "push_value_project",
        payload: {
          key: "geo_project_list",
          value: body.geo_project,
        },
      });
    }
  } catch (error) {
    console.log("error=", error);
  }
};

/*DELETE METHODE*/

/**
 * delete_folder_project
 * @endpoint : /folder_projects/delete_folder_project
 * @methode : DELETE
 * @body : folder_id
 */
export const delete_folder_project = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.delete(
      SERVER_URL +
        `/folder_projects/delete_folder_project?folder_id=${body.folder_id}`,
      config
    );
    const array_id_deleted = res?.data || [];
    dispatch({
      type: "pull_many_item_project_object",
      payload: {
        key: "folder_project_list",
        value_list: array_id_deleted,
        id: "_id",
      },
    });
    dispatch({
      type: "pull_many_item_project_object",
      payload: {
        key: "folder_project_child_list",
        value_list: array_id_deleted,
        id: "_id",
      },
    });
    dispatch({
      type: "pull_many_item_project_object",
      payload: {
        key: "folder_project_list_all",
        value_list: array_id_deleted,
        id: "_id",
      },
    });

    const screenW = window.screen.width;
    let limit = 6;
    if (screenW > 1919) {
      limit = 15;
    } else if (screenW > 1499) {
      limit = 12;
    } else if (screenW > 1149) {
      limit = 9;
    }
    const params = {
      skip: 0,
      limit: limit,
    };
    dispatch(getProjectList(params));
  } catch (error) {}
};
