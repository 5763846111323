import React, { Component } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default class ApiTable extends Component {

  get_columns = () => {
    return [
      {
        field: "key",
        headerName: "Key",
        flex: 1,
        editable: true,
      },
      {
        field: "value",
        headerName: "Value",
        flex: 1,
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        width: 70,
        editable: false,
        renderCell: ({ id }) => {
          return (
            <>
              {id !== this.get_last_id() && (
                <button
                  onClick={(e) => {
                    this.props.delete_row(id, this.props.title);
                  }}
                >
                  Delete
                </button>
              )}
            </>
          );
        },
      },
    ];
  };

  get_last_id = () => this.props.data?.at(-1)?.id;

  render() {
    const { title, data, edit_cell, full_url } = this.props;

    return (
      <main className="w_full">
        <section className="flex justify_between">
          <label>{title}</label>
          {full_url && (
            <label className="w_60_percent text_right" title={full_url}>
              {full_url}
            </label>
          )}
        </section>
        <section className="h_300">
          <DataGrid
            className="bg_white"
            rows={data}
            columns={this.get_columns()}
            getRowHeight={() => 30}
            processRowUpdate={(params) => {
              edit_cell(title, params);
              return params;
            }}
          />
        </section>
      </main>
    );
  }
}
