import React, { Component } from "react";
import Modal from "../common_modal/Modal";
import dict from "../../Data/dict.json";

export default class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.params.value,
      oldTime: this.props.params.value,
      modalNotification: false,
    };
  }

  validateHhMm(inputField) {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
      inputField
    );
    return isValid;
  }

  handleSubmit = (params) => {
    if (this.validateHhMm(this.state.time)) {
      const result = this.state.time;
      let newParams = this.props.params;
      newParams.value = result;
      newParams.row.value = result;
      this.props.handleEditCellChange(
        this.props.editProperties,
        this.props.layer_id,
        newParams
      );
    } else {
      this.toggleModalNotification();
    }
  };

  toggleModalNotification = () => {
    this.setState({ modalNotification: !this.state.modalNotification });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const modalNotification = this.state.modalNotification && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modalNotification}
        onClose={(params) => this.toggleModalNotification(params)}
      >
        <main style={{ margin: 20 }}>
          {dict["Invalid time input, please try again!"][language]}
          <button
            className="button"
            id="deleteBright"
            style={{
              marginTop: "1rem",
              display: "block",
              width: "100%",
              justifyContent: "center",
            }}
            onClick={this.toggleModalNotification}
          >
            OK
          </button>
        </main>
      </Modal>
    );

    return (
      <section>
        <input
          id="time_input"
          type="time"
          className="time_in_table"
          value={this.state.time || ""}
          onChange={(params) => {
            this.setState({ time: params.target.value });
          }}
          onKeyDown={(params) => {
            if (params.key === "Enter") {
              this.handleSubmit();
              params.target.blur();
            }
          }}
          onBlur={(params) => {
            this.handleSubmit();
          }}
        />
        {modalNotification}
      </section>
    );
  }
}
