/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import { generate_paint } from "./functions/generate_paint";
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class RANGE_NUMBER_STEP extends Component {
  state = {};

  on_change = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const type = e.target.type;
    if (type === "number" || type === "range") {
      value = Number(value);
    }
    if (name === "color_logic_state") {
      this.setState({ [name]: value });
    } else {
      const { style_parameter } = this.props.properties;
      style_parameter[name] = value;
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
    }
  };

  on_regenerate_paint = () => {
    const { style_parameter, paint_object_edited } = this.props.properties;
    const style_parameter_new = {
      ...style_parameter,
      paint_object_edited,
    };
    const paint_object_new = generate_paint(style_parameter_new);
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: paint_object_new,
    });
    this.props.status_action();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { style_parameter, paint_object_edited } = this.props.properties;

    //content

    const { style_type, min, max, steps } = style_parameter;
    let color_key;
    if (style_type === "circle") {
      color_key = "circle-color";
    } else if (style_type === "line") {
      color_key = "line-color";
    } else if (style_type === "fill") {
      color_key = "fill-color";
    }

    let rows = [];
    const array_number_step = paint_object_edited?.[color_key] || [];
    for (let idx = 3; idx < array_number_step.length; idx += 2) {
      const is_last_value = idx + 1 === array_number_step.length - 1;
      const is_first_value = idx === 3; // Check if it's the first numeric threshold
      const number_1 = new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(array_number_step[idx]);
      const number_2 = new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(array_number_step[idx + 2]);

      let range_text;
      if (is_first_value) {
        range_text = `< ${number_1}`; // Open-ended range at the bottom
      } else if (is_last_value) {
        range_text = `> ${number_1}`; // Open-ended range at the top
      } else {
        range_text = `${number_1} - ${number_2}`;
      }
      rows.push(
        <tr key={idx}>
          <td className="text_right">{range_text}</td>
          <td style={{ width: "20px", verticalAlign: "middle" }}>
            <div
              style={{
                backgroundColor: array_number_step[idx + 1], // Color is at the next index
                width: "20px",
                height: "20px",
                border: "1px solid black",
                margin: "auto",
              }}
            />
          </td>
        </tr>
      );
    }
    return (
      <main>
        <section className="margin_bottom">
          <p className="text_medium">
            {dict?.["Ganti jumlah range"]?.[language]}
          </p>
          <input
            className="margin_bottom"
            type="number"
            name="steps"
            value={steps}
            onChange={this.on_change}
          />
          <p className="text_medium">{dict?.["Change min"]?.[language]}</p>
          <input
            className="margin_bottom"
            type="number"
            name="min"
            value={min}
            onChange={this.on_change}
          />
          <p className="text_medium">{dict?.["Change max"]?.[language]}</p>
          <input
            className="margin_bottom"
            type="number"
            name="max"
            value={max}
            onChange={this.on_change}
          />
          <button className="button_small" onClick={this.on_regenerate_paint}>
            {dict?.["Parameter replacement process"]?.[language]}
          </button>
        </section>
        <section style={{ maxHeight: "250px", overflowY: "scroll" }}>
          <table className="table full_width">
            <thead>
              <tr>
                <th>Range</th>
                <th>{dict?.["Color"]?.[language]}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(RANGE_NUMBER_STEP);
