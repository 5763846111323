import axios from "axios";

import { geoServerBaseUrl } from "./baseUrl";
import { get_quota_access } from "./authActions";

const SERVER_URL = geoServerBaseUrl;

const limit_load_poi_sini = 500;

/*GET METHODE*/

//generate_city_object
export const generate_city_object = (params) => async (dispatch) => {
  try {
    const { lat, long } = params;
    const res = await axios.get(
      SERVER_URL + `/sini/generate_city_object?long=${long}&lat=${lat}`
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "city_object",
        value: res.data,
      },
    });
  } catch (error) {}
};

//get_insight
export const get_insight = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    //deklarasi parameter & header untuk ke backend
    const {
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      poi_tipe_2_selected_string,
      request_id,
    } = params;

    dispatch({
      type: "set_loading_sini",
      payload: "get_insight",
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "progress_poi_total",
        value: 1,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "progress_poi_current",
        value: 0,
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "features_poi",
        value: [],
      },
    });

    dispatch({
      type: "set_value_properties",
      payload: {
        key: "save_layer_progress",
        value: 0,
      },
    });

    //mengambil data poligon demografi
    const res = await axios.get(
      SERVER_URL +
        `/sini/get_demografi?provinsi=${provinsi}&kota=${kota}&kecamatan=${kecamatan}&kelurahan=${kelurahan}&request_id=${request_id}`,
      config
    );
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "features_demografi",
        value: res.data,
      },
    });
    dispatch({
      type: "state_update",
    });

    //mengambil data titik POI
    const res_count = await axios.get(
      SERVER_URL +
        `/sini/get_count_poi?provinsi=${provinsi}&kota=${kota}&kecamatan=${kecamatan}&kelurahan=${kelurahan}&poi_tipe_2_selected_string=${poi_tipe_2_selected_string}&request_id=${request_id}`,
      config
    );
    const count_poi = res_count.data;

    if (count_poi === 0) {
      dispatch({
        type: "clear_loading_sini",
      });
      dispatch({
        type: "fly_update",
      });
    }

    const total = count_poi;
    const iteration = Math.ceil(total / limit_load_poi_sini);
    let array_loop = [];
    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit_load_poi_sini,
        end: i * limit_load_poi_sini + limit_load_poi_sini,
      });
    }
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "progress_poi_total",
        value: array_loop[array_loop.length - 1].end,
      },
    });

    const delay_c = 0; //delay sinkronus o detik
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(async () => {
        try {
          const { start, end } = object;
          const res = await axios.get(
            SERVER_URL +
              `/sini/get_poi?provinsi=${provinsi}&kota=${kota}&kecamatan=${kecamatan}&kelurahan=${kelurahan}&poi_tipe_2_selected_string=${poi_tipe_2_selected_string}&start=${start}&end=${end}&request_id=${request_id}`,
            config
          );
          const features = res.data || [];
          dispatch({
            type: "push_value_sini",
            payload: {
              key: "features_poi",
              value: features,
            },
          });
          dispatch({
            type: "state_update",
          });
          dispatch({
            type: "set_value_sini",
            payload: {
              key: "progress_poi_current",
              value: end,
            },
          });
        } catch (error) {}
      });
    };
    parent_function().then(() => {
      dispatch(get_quota_access());
      dispatch({
        type: "clear_loading_sini",
      });
      dispatch({
        type: "state_update",
      });
      dispatch({
        type: "fly_update",
      });
    });

    return res_count;
  } catch (error) {
    dispatch({
      type: "clear_loading_sini",
    });
  }
};

/*POST METHODE*/

/*NON API*/

//push_field_bulk
/*
body={
  geo_layer_id,
  fields: []
}
*/
export const push_field_bulk = (body) => (dispatch) => {
  dispatch({
    type: "push_field_bulk",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

//replace_features
/*
body={
  geo_layer_id,
  features: []
}
*/
export const replace_features = (body) => (dispatch) => {
  dispatch({
    type: "replace_features",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

//clearLoading
export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};
