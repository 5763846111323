import toGeoJSON from "@mapbox/togeojson";
import Papa from "papaparse";
import readXlsxFile, { readSheetNames } from "read-excel-file";
import * as shpUtil from "shp-geojson";
import { geojson_validation } from "./geojson_validation";
import rewind from "@mapbox/geojson-rewind";

/**
 * Keterangan:
 * 
 * Function define_extension digunakan untuk menghubungkan antara extensi file dengan function yang akan digunakan untuk mengolah data tersebut.
 * 
 * File yang didukung adalah:
 * - xlsx
 * - csv
 * - geojson
 * - zip
 * - kml
 */
async function define_extension(e) {
  try {
    const file = get_file(e);
    const ext = file.name.split(".").pop();
    if (file) {
      let result = null;
      if (ext === "xlsx") {
        result = await handle_upload_excel(file);
        result["status"] = "raw";
      } else if (ext === "csv") {
        result = await handle_upload_csv(file);
        result["status"] = "raw";
      } else if (["geojson", "GeoJSON", "json", "JSON"].includes(ext)) {
        result = await handle_upload_geojson(file);
        result["status"] = "geojson";
      } else if (ext === "zip") {
        result = await handle_upload_zip(file);
        result["status"] = "geojson";
      } else if (ext === "kml") {
        result = await handle_upload_kml(file);
        result["status"] = "geojson";
      }
      return result;
    }
  } catch (error) { }
}

async function handle_upload_csv(e) {
  try {
    const file = get_file(e);
    if (file) {
      return await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: (result) => {
            let data = result.data.slice();
            let index = data.length - 1;
            while (Object.values(data?.[index]).every((x) => !x)) {
              data.splice(-1);
              index -= 1;
            }
            resolve({
              data: data,
              fileName: file.name.split(".")[0],
              headers: result.meta?.fields,
            });
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    }
    return undefined;
  } catch (error) { }
}

function get_file(e) {
  if (e.constructor.name === "SyntheticBaseEvent") {
    return e.target.files[0];
  } else if (e.constructor.name === "File") {
    return e;
  } else {
    return e.target.files[0];
  }
}

async function handle_upload_excel(e) {
  try {
    const file = get_file(e);
    if (file) {
      const sheetNames = await readSheetNames(file);
      const rows = await readXlsxFile(file, { sheet: sheetNames[0] });
      const headers = rows.shift();
      const data = rows.map((element) => {
        let obj = {};
        headers.forEach((header, idx) => {
          const key = String(header).replace(/\s*$/, "");
          const value = element[idx] || "";
          obj[key] = value;
        });
        return obj;
      });
      return {
        data: data,
        fileName: file.name,
        headers: headers,
      };
    }
  } catch (error) { }
}

async function handle_upload_zip(e) {
  try {
    const file = get_file(e);
    if (file) {
      let geojson_ori = [];
      const name = file.name.split(".")[0];
      const layers = await file.arrayBuffer();
      const geojsonRes = await shpUtil.toGeoJSON(layers);
      geojson_ori = rewind(geojsonRes, true);
      delete geojson_ori.fileName;
      return {
        data: geojson_ori,
        fileName: name,
        headers: Object.keys(geojson_ori.features[0]?.properties),
      };
    }
  } catch (error) { }
}

async function handle_upload_kml(e) {
  try {
    const file = get_file(e);
    let geojson_ori = [];
    const name = file.name.split(".")[0];
    if (file) {
      const blob = new Blob([file], {
        type: "application/json",
      });
      const fr = new FileReader();
      return await new Promise((resolve, reject) => {
        fr.addEventListener("load", () => {
          const tj = toGeoJSON;
          let kml = new DOMParser().parseFromString(fr.result, "text/xml");
          geojson_ori = rewind(tj.kml(kml), true);
          resolve({
            data: geojson_ori,
            fileName: name,
            headers: Object.keys(geojson_ori.features[0]?.properties),
          });
        });
        fr.readAsText(blob);
      });
    }
  } catch (error) { }
}

async function handle_upload_geojson(e) {
  try {
    const file = get_file(e);
    let geojson_ori = [];
    const name = file.name.split(".")[0];
    if (file) {
      const blob = new Blob([file], { type: "application/json" });
      const fr = new FileReader();
      return await new Promise((resolve, reject) => {
        fr.addEventListener("load", () => {
          geojson_ori = rewind(JSON.parse(fr.result), true);
          const { filtered_features, validation_result } =
            geojson_validation(geojson_ori);

          let subtitude_validation_result = geojson_ori;
          subtitude_validation_result["features"] = filtered_features || [];

          // get headers
          let headers = new Set();
          geojson_ori?.features?.forEach(
            (feature) =>
            (headers = new Set([
              ...headers,
              ...Object.keys(feature.properties),
            ]))
          );
          headers = Array.from(headers);

          if (validation_result.is_valid_in_general) {
            resolve({
              data: subtitude_validation_result,
              fileName: name,
              headers,
              validation_result,
            });
          } else {
            resolve({
              validation_result,
            });
          }
        });
        fr.readAsText(blob);
      });
    }
  } catch (error) { }
}

export {
  define_extension,
  handle_upload_csv,
  handle_upload_excel,
  handle_upload_geojson,
  handle_upload_kml,
  handle_upload_zip,
  get_file,
};
