import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import capitalize_first_letter from "../../App/validation/capitalize_first_letter";
import useLanguage from "../common_hooks/useLanguage";

function RecoveryTab({
  width,
  actived_tab,
  handle_sort,
  is_multiple,
  toggle_view,
  toggle_tab,
  mode_view,
  selected,
  set_selected,
  recovery_of_layers,
  recovery_of_projects,
  toggle_modal_restore_confirmation,
  toggle_modal_delete_confirmation,
  set_restore_page,
}) {
  const { dict, language } = useLanguage();
  const [checked, set_checked] = useState(false);

  useEffect(() => {
    if (actived_tab === "project") {
      if (
        selected?.length === recovery_of_projects?.length &&
        selected?.length !== 0
      ) {
        set_checked(true);
      } else {
        set_checked(false);
      }
    } else {
      if (
        selected?.length === recovery_of_layers?.length &&
        selected?.length !== 0
      ) {
        set_checked(true);
      } else {
        set_checked(false);
      }
    }
  }, [selected?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handle_check_all = () => {
    if (actived_tab === "project") {
      if (selected?.length === 0) {
        set_selected(recovery_of_projects);
      } else {
        set_selected([]);
      }
    } else {
      if (selected?.length === 0) {
        set_selected(recovery_of_layers);
      } else {
        set_selected([]);
      }
    }
  };

  return (
    <main className="flex justify_between gap_10">
      <section
        className={`flex gap_20 ${width < 320 ? "flex_col" : "flex_row"}`}
      >
        <button
          onClick={() => {
            toggle_tab("project");
            set_selected([]);
          }}
          className={`bold font_18`}
        >
          <label className="pointer">{dict["Projects"][language]}</label>
          {actived_tab === "project" && <hr className="margin_0 border_blue" />}
        </button>
        <button
          onClick={() => {
            toggle_tab("layer");
            set_selected([]);
          }}
          className={`bold font_18`}
        >
          <label className="pointer">{dict["Layers"][language]}</label>
          {actived_tab === "layer" && <hr className="margin_0 border_blue" />}
        </button>
      </section>
      <section
        className={`flex gap_10 ${width < 320 ? "flex_col" : "flex_row"}`}
      >
        {is_multiple && selected.length > 0 && (
          <>
            <button
              onClick={() => {
                if (actived_tab === "project") {
                  set_restore_page(2);
                  toggle_modal_restore_confirmation();
                } else {
                  set_restore_page(1);
                  toggle_modal_restore_confirmation();
                }
              }}
              className="button_inactive padding_x_10 h_30 rounded_5 bg_green text_white"
            >
              {dict["Recover"][language]}
            </button>
            <button
              onClick={toggle_modal_delete_confirmation}
              className="button_inactive padding_x_10 h_30 rounded_5 bg_red text_white"
            >
              {dict["Delete"][language]}
            </button>
          </>
        )}
        <select
          onChange={handle_sort}
          className={`${width > 400 && "w_200"} underline_input`}
        >
          <option value="newest">{dict["Latest"][language]}</option>
          <option value="oldest">{dict["Oldest"][language]}</option>
          <option value="name_asc">
            {dict[`${capitalize_first_letter(actived_tab)} Name`][language]} A-Z
          </option>
          <option value="name_desc">
            {dict[`${capitalize_first_letter(actived_tab)} Name`][language]} Z-A
          </option>
        </select>
        {width > 630 && (
          <select
            className="max_w_100 underline_input"
            value={mode_view}
            onChange={(e) => toggle_view(e?.target?.value)}
          >
            <option key="card" value="card">
              Card
            </option>
            <option key="list" value="list">
              List
            </option>
          </select>
        )}
        {is_multiple && (
          <Checkbox
            onChange={handle_check_all}
            checked={checked}
            style={{ padding: 0, margin: 0 }}
          />
        )}
      </section>
    </main>
  );
}

export default RecoveryTab;
