//Library modul
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { getNotifByProject } from "../../App/actions/authActions";
//Picture Asset
//General Function
import formatDate from "../../App/validation/format-date";
import dict from "../../Data/dict.json";
class NotificationProject extends Component {
  componentDidMount() {
    const { geo_project_link } = this.props;
    if (geo_project_link) {
      this.props.getNotifByProject(geo_project_link);
    }
  }
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { user, loadingProcess, itemLoading } = this.props.auth;
    const { geo_project_link } = this.props;
    const notification_list = this.props.auth?.notification_list_project
      ? this.props.auth?.notification_list_project
      : [];
    //Step 1. Pisahkan notif log aktifitas project, group dengan lainnya
    const project_type = [
      "project_create",
      "project_edit",
      "project_push_folder",
      "project_delete_folder",
      "project_push_member",
      "project_push_group",
      "project_edit_member",
      "project_delete_member",
      "project_delete_group",
      "project_delete",
    ];
    const layer_type = [
      "layer_create",
      "layer_import",
      "layer_edit_general",
      "layer_push_project_attach",
      "layer_delete_layer_attach",
      "layer_delete_original",
      "layer_ordering_fields",
      "layer_push_feature",
      "layer_push_feature_form",
      "layer_edit_geometry",
      "layer_edit_properties",
      "layer_delete_feature",
      "layer_push_field",
      "layer_clone_fields",
      "layer_edit_field",
      "layer_delete_field",
    ];
    //Step 2. Generate notif_induk
    let notif_induk = notification_list.map((n) => {
      let induk_id;
      let type = n.type;
      if (project_type.includes(n.type)) {
        induk_id = n?.geo_projects[0]?.geo_project?._id
          ? n?.geo_projects[0]?.geo_project?._id
          : "";
        type = "project_notif";
      } else if (layer_type.includes(n.type)) {
        induk_id = n?.gis_layers?.[0]?.gis_layer?._id
          ? n?.gis_layers?.[0]?.gis_layer?._id
          : "";
        type = "layer_notif";
      } else {
        induk_id = n._id;
        type = n.type;
      }
      const final_obj = {
        ...n,
        induk_id,
        type,
      };
      return final_obj;
    });
    //Step 3. Hilangkan duplikat di notif_induk
    notif_induk = notif_induk.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.induk_id === thing.induk_id)
    );
    //Step 4. Kelompokan notif array jika project atau group nya sama
    notif_induk = notif_induk.map((n) => {
      let notif_array = [];
      let final_notif = { ...n };
      if (n.type === "project_notif") {
        notif_array = notification_list.filter(
          (f) =>
            f?.geo_projects[0]?.geo_project?._id === n.induk_id &&
            project_type.includes(f.type)
        );
        //filter notif yang sama, pelakunya sama, waktunya berdekatan
        notif_array = notif_array.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.type === thing.type &&
                t.users_active?.[0]?.user?._id ===
                  thing.users_active?.[0]?.user?._id &&
                t.gis_layers?.[0]?.gis_layer?._id ===
                  thing.gis_layers?.[0]?.gis_layer?._id
            )
        );
        final_notif = { ...notif_array[0], type: "project_notif", notif_array };
      } else if (n.type === "layer_notif") {
        notif_array = notification_list.filter(
          (f) =>
            f?.gis_layers?.[0]?.gis_layer?._id === n?.induk_id &&
            layer_type.includes(f?.type)
        );
        //filter notif yang sama, pelakunya sama, waktunya berdekatan
        notif_array = notif_array.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.type === thing.type &&
                t.users_active?.[0]?.user?._id ===
                  thing.users_active?.[0]?.user?._id
            )
        );
        final_notif = { ...notif_array[0], type: "layer_notif", notif_array };
      }
      return final_notif;
    });
    let list_content;
    if (loadingProcess && itemLoading === "get_notif_by_project") {
      list_content = (
        <div className="text_medium">
          {dict?.["Loading project notifications"]?.[language]}...
        </div>
      );
    } else {
      list_content = (
        <main>
          {notif_induk.map((notification, idx) => {
            const { type, users, gis_layers, date, blogs, geo_projects } =
              notification;
            const notif_array = notification?.notif_array
              ? notification?.notif_array
              : [];
            let unit_content = null;
            if (type === "layer_comment") {
              const users_filtered = users.filter(
                (user_element) => user_element.user._id !== user._id
              );
              const last_user = users_filtered[users_filtered.length - 1]?.user;
              const first_user_name = last_user?.full_name
                ? last_user?.full_name
                : last_user?.name
                ? last_user?.name
                : dict["You"][language];
              let other_user;
              if (users_filtered.length > 1) {
                other_user = (
                  <div>
                    {dict["and"][language]} <b>{users_filtered.length - 1}</b>{" "}
                    {dict["other people"][language]}
                  </div>
                );
              }
              const gis_layer = gis_layers?.[0]?.gis_layer;
              unit_content = (
                <main className="container_notification" key={idx}>
                  <section className="text_inferior">
                    {dict["New comment"][language]}
                  </section>
                  <section className="text_bold">
                    {gis_layer?.name
                      ? gis_layer?.name
                      : `(${dict["layer has been deleted"][language]})`}
                  </section>
                  <section className="text_inferior">
                    {dict["New comment in"][language]}{" "}
                    <Link
                      to={`/editor/${gis_layer?.geo_project?.link}/${gis_layer?.link}/chat`}
                    >
                      <b>
                        {gis_layer?.name
                          ? gis_layer?.name
                          : `(${dict["layer has been deleted"][language]})`}
                      </b>
                    </Link>{" "}
                    {dict["by"][language]} <b>{first_user_name}</b> {other_user}
                  </section>
                  <Link
                    className="button_very_small"
                    id="blue"
                    to={`/editor/${gis_layer?.geo_project?.link}/${gis_layer?.link}/chat`}
                  >
                    {dict["Open"][language]}
                  </Link>
                  <section className="text_inferior">
                    {formatDate(date)}
                  </section>
                </main>
              );
            } else if (type === "project_notif" && notif_array.length > 0) {
              const geo_project = geo_projects?.[0]?.geo_project;
              const gis_layer = gis_layers?.[0]?.gis_layer;
              unit_content = (
                <main className="container_notification" key={idx}>
                  <section className="text_inferior">
                    {notif_array.length} {dict["project activities"][language]}
                  </section>
                  <section className="text_bold">
                    {geo_project?.name
                      ? geo_project?.name
                      : `(${dict["project has been deleted"][language]})`}
                  </section>
                  {notif_array.map((n, idx) => {
                    const { users_active, type, date } = n;
                    const user = users_active?.[0]?.user;
                    let content = (
                      <main
                        key={idx}
                        className="text_inferior two_container_auto"
                      >
                        <section>
                          {user?.full_name ? user?.full_name : user?.name}{" "}
                          <b>{dict?.[type]?.[language]}</b> {gis_layer?.name}{" "}
                          {notification?.text}
                        </section>
                        <section style={{ textAlign: "right" }}>
                          {formatDate(date)}
                        </section>
                      </main>
                    );
                    return content;
                  })}
                </main>
              );
            } else if (type === "layer_notif" && notif_array.length > 0) {
              const gis_layer = gis_layers?.[0]?.gis_layer;
              unit_content = (
                <main className="container_notification" key={idx}>
                  <section className="text_inferior">
                    {notif_array.length} {dict["layer activities"][language]}
                  </section>
                  <section className="text_bold">
                    {gis_layer?.name
                      ? gis_layer?.name
                      : `(${dict["layer has been deleted"][language]})`}
                  </section>
                  {notif_array.map((n, idx) => {
                    const { users_active, type, date } = n;
                    const user = users_active?.[0]?.user;
                    let content = (
                      <main
                        key={idx}
                        className="text_inferior two_container_auto"
                      >
                        <section>
                          {user?.full_name ? user?.full_name : user?.name}{" "}
                          <b>{dict?.[type]?.[language]}</b>
                        </section>
                        <section style={{ textAlign: "right" }}>
                          {formatDate(date)}
                        </section>
                      </main>
                    );
                    return content;
                  })}
                </main>
              );
            } else if (type === "request_free_import") {
              const blog = blogs?.[0]?.blog;
              unit_content = (
                <section className="container_notification" key={idx}>
                  {
                    dict["Someone requests access to data on your blog"][
                      language
                    ]
                  }
                  <Link to={`blog_list/${blog?.link}`}>
                    <section
                      onClick={this.onClickPathname.bind(
                        this,
                        `blog_list/${blog?.link}`
                      )}
                    >
                      <b>{blog?.title}</b>
                    </section>
                  </Link>
                  <section className="text_inferior">
                    {formatDate(date)}
                  </section>
                </section>
              );
            } else if (type === "action_request_free_import") {
              const blog = blogs?.[0]?.blog;
              unit_content = (
                <section className="container_notification" key={idx}>
                  {dict["Your request to access has been accepted"][language]}

                  <Link to={`blog_read/${blog?.link}`}>
                    <section
                      onClick={this.onClickPathname.bind(
                        this,
                        `blog_read/${blog?.link}`
                      )}
                    >
                      <b>{blog?.title}</b>
                    </section>
                  </Link>
                  <section className="text_inferior">
                    {formatDate(date)}
                  </section>
                </section>
              );
            }
            return unit_content;
          })}
        </main>
      );
    }

    return (
      <main style={{ marginBottom: "100px" }}>
        <Link
          className="button_very_small"
          id="blue"
          to={`/editor/${geo_project_link}`}
        >
          ← {dict?.["Back"]?.[language]}
        </Link>
        <h1>Notif project</h1>
        {list_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});
export default connect(mapStateToProps, { getNotifByProject })(
  NotificationProject
);
