/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { rename_bi } from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class RENAME_BI extends Component {
  state = {
    name: "",
  };

  componentDidMount() {
    this.setState({ name: this?.props?.bi?.bi_selected?.name });
  }

  on_change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  on_submit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const { bi_selected } = this.props.bi;
    const body = {
      _id: bi_selected?._id,
      name,
    };
    this.props.rename_bi(body);
    this.props.toggle_rename();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { name } = this.state;

    //global props

    //content
    return (
      <main>
        <form onSubmit={this.on_submit}>
          <h2 className="margin_bottom">{dict?.["Rename"]?.[language]} BI</h2>
          <p>{dict?.["BI name"]?.[language]}</p>
          <input
            name="name"
            value={name}
            onChange={this.on_change}
            type="text"
            className="margin_bottom"
            placeholder={dict?.["BI name"]?.[language]}
          />
          <br />
          <button type="submit" className="button background_blue margin_top">
            Submit
          </button>
        </form>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { rename_bi })(RENAME_BI);
