import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import Dialog from "@mui/material/Dialog";

import icon_download from "../../Assets/svg/icon_download.svg";

import { which_laporan_cell_download_will_be_active } from "../../App/validation/all_about_client";
import SetupDownloadLaporanPdfCilicis from "../laporan_pdf/cilicis/SetupDownloadLaporanPdfCilicis";
import SetupDownloadLaporanPdfDanamas from "../laporan_pdf/danamas/SetupDownloadLaporanPdfDanamas";

export default class DownloadLaporanPdf extends Component {
  constructor(props) {
    super(props);
    this.component_ref = React.createRef();
    this.state = {
      modal_preview: false,
    };
  }

  toggle_modal_preview = (value) => {
    const { modal_preview } = this.state;
    this.setState({
      modal_preview: value || !modal_preview,
    });
  };

  setRef = (response) => {
    this.component_ref = response;
  };

  render() {
    const { modal_preview } = this.state;
    const { row } = this.props.row;
    const domain = window.location.hostname;

    const setupDownloadLaporanPdf = which_laporan_cell_download_will_be_active({
      SetupDownloadLaporanPdfCilicis,
      SetupDownloadLaporanPdfDanamas,
      domain,
      setRef: this.setRef,
      row,
    });

    const modal_preview_content = (
      <Dialog
        // fullScreen
        PaperProps={{
          sx: {
            maxHeight: "98%",
          },
        }}
        maxWidth="lg"
        fullWidth
        open={modal_preview}
        onClose={this.toggle_modal_preview}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => this.toggle_modal_preview()}
            aria-label="close"
            className="button h_35 w_35 font_20 fixed"
            id="delete"
          >
            x
          </button>
        </div>

        <div>
          <section className="flex justify_center">
            <ReactToPrint
              bodyClass="margin_pdf"
              content={() => this.component_ref}
              onBeforeGetContent={(x) => {
                const element_pdf = document.getElementById("kop_cilicis");
                if (element_pdf)
                  element_pdf.setAttribute("class", "w_full block");

                const date = new Date();

                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                // This arrangement can be altered based on how we want the date's format to appear.
                let currentDate = `${day}/${month}/${year}`;

                const date_element_pdf =
                  document.getElementById("date_footer_pdf");
                if (date_element_pdf)
                  date_element_pdf.appendChild(
                    document.createTextNode(currentDate)
                  );
              }}
              onAfterPrint={() => {
                const element_pdf = document.getElementById("kop_cilicis");
                if (element_pdf) element_pdf.setAttribute("class", "hidden");
              }}
              trigger={() => (
                <button className="button marginTop_30">Print PDF</button>
              )}
            />
          </section>
          <section className="margin_25 center_perfect">
            <div className="max_w_a4">{setupDownloadLaporanPdf}</div>
          </section>
        </div>
      </Dialog>

      // <ModalFullScreen
      //   modalSize="medium"
      //   id="modal"
      //   isOpen={modal_preview}
      //   onClose={this.toggle_modal_preview}
      // >

      // </ModalFullScreen>
    );

    // console.log(window.location.hostname);

    return (
      <>
        <div className="row_surveyor">
          <div
            className="row_surveyor_img hover_bigger pointer"
            onClick={() => {
              this.toggle_modal_preview();
            }}
          >
            <img className="" src={icon_download} alt="download" />
          </div>
          Download PDF
          {modal_preview_content}
        </div>
      </>
    );
  }
}
