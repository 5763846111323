import React from "react";

function SINI_ILUSTRATION() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="158.408"
      version="1.1"
      viewBox="0 0 52.917 41.912"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
      </defs>
      <g transform="translate(-271.305 -57.11)">
        <path
          fill="#21a5f6"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M281.094 66.433c-3.88-.7-10.817 2.763-9.08 9.597 3.171 12.468-3.178 10.955.448 18.802 4.77 10.323 22.022-1.605 30.766-2.184 3.56-.235 7.248 2.721 10.597 1.492 4.145-1.522 7.247-5.763 8.806-9.894 2.106-5.577 2.167-12.367-.092-17.883-2.574-6.283-14.937-13.326-22.525-6.378-6.892 6.311-11.398 7.803-18.92 6.448z"
        ></path>
        <path
          fill="#a0d061"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M292.382 65.446c-3.452 1.594-6.844 1.788-11.288.987-.69-.125-1.478-.116-2.297.019l-.033.005a9.16 9.16 0 00-1.338.333c.006.017.003.019-.007.002-3.357 1.109-6.615 4.253-5.428 9.137a.59.59 0 00.084-.004c2.098.042 4.196.015 6.294.023.203-.006.338-.135.404-.301.089.246.392.349.649.303 3.43-.012 6.862-.006 10.29-.028 1.036-.23 1.996-.909 2.427-1.897.175-.385.324-.79.272-1.22 0-2.403.026-4.81.011-7.21a.415.415 0 00-.039-.149z"
        ></path>
        <path
          fill="#a0d061"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M291.718 83.773c-.259.001-.539.045-.753.017l-4.066.02c-1.841.055-3.7-.082-5.53.168-1.46.412-2.566 1.892-2.487 3.423-.057 3.867-.015 7.734-.028 11.601 3.93.224 8.874-1.522 13.588-3.243a.657.657 0 00-.022-.16V84.224c-.074-.385-.37-.454-.702-.452z"
        ></path>
        <g
          strokeWidth="1.5"
          transform="matrix(.66667 0 0 .66667 90.435 19.037)"
        >
          <path
            fill="#a0d061"
            fillOpacity="1"
            strokeWidth="0.397"
            d="M4335.55-185.354a3.938 3.938 0 100-7.875 3.938 3.938 0 000 7.875z"
            transform="matrix(.49978 0 0 .49978 -1833.022 175.219)"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="1.125"
            d="M333.788 84.731V79.81"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="1.125"
            d="M333.788 81.45l1.22-.806"
          ></path>
          <path
            stroke="#e65f62"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 82.09v-2.28"
          ></path>
        </g>
        <path
          fill="#a0d061"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M310.513 76.374c-1.045.003-2.088.019-3.126.053h-8.284c-1.198.029-2.406-.037-3.598.09-1.424.299-2.566 1.634-2.558 3.103a60.085 60.085 0 00-.016 3.86c.121.57.825.332 1.241.38 1.323-.012 2.654.026 3.971-.018.311-.11.648.1 1.007.019 1.942-.012 10.04.02 12.925-.01 3.199.104 6.419-.047 9.601-.187.428.028.869-.023 1.226-.18a25.46 25.46 0 001.278-6.707c-1.172-.618-2.835-.125-4.12-.346-2.883.028-5.8-.05-8.701-.057h-.846z"
        ></path>
        <g
          strokeWidth="1.5"
          transform="matrix(.66667 0 0 .66667 93.472 27.336)"
        >
          <path
            fill="#fff"
            fillOpacity="1"
            strokeWidth="0.397"
            d="M4335.55-185.354a3.938 3.938 0 100-7.875 3.938 3.938 0 000 7.875z"
            transform="matrix(.49978 0 0 .49978 -1833.022 175.219)"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 84.731V79.81"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 81.45l1.22-.806"
          ></path>
          <path
            stroke="#e65f62"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 82.09v-2.28"
          ></path>
        </g>
        <g strokeWidth="1.5" transform="matrix(.66667 0 0 .66667 62.265 19.45)">
          <path
            fill="#fff"
            fillOpacity="1"
            strokeWidth="0.397"
            d="M4335.55-185.354a3.938 3.938 0 100-7.875 3.938 3.938 0 000 7.875z"
            transform="matrix(.49978 0 0 .49978 -1833.022 175.219)"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 84.731V79.81"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 81.45l1.22-.806"
          ></path>
          <path
            stroke="#e65f62"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 82.09v-2.28"
          ></path>
        </g>
        <path
          fill="#008cd4"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M299.845 70.141l-2.118 2.262v3.121h8.327v-3.121l-2.118-2.262z"
        ></path>
        <path
          fill="#1e4f7e"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M299.845 70.141l2.118 2.262h4.091l-2.118-2.262z"
        ></path>
        <path
          fill="#fff"
          strokeWidth="0.132"
          d="M297.727 72.928l2.118-2.261 2.118 2.261v2.596h-4.236z"
        ></path>
        <path
          fill="#1e4f7e"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M299.845 73.929a.733.733 0 100-1.465.733.733 0 000 1.465z"
        ></path>
        <path
          fill="#1e4f7e"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M301.89 69.2a1.858 1.858 0 100-3.716 1.858 1.858 0 000 3.715z"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          strokeWidth="0.132"
          d="M301.847 68.244a.22.22 0 01-.16-.066.216.216 0 01-.065-.159c0-.061.021-.114.065-.157a.22.22 0 01.16-.066c.06 0 .112.022.157.066a.215.215 0 01.035.27.243.243 0 01-.081.082.213.213 0 01-.11.03z"
        ></path>
        <path
          fill="#fff"
          stroke="none"
          strokeWidth="0.132"
          d="M301.662 67.644v-.032c0-.109.01-.196.029-.261a.393.393 0 01.084-.157.645.645 0 01.133-.11.872.872 0 00.104-.077.339.339 0 00.073-.09.228.228 0 00.027-.11.206.206 0 00-.114-.188.253.253 0 00-.23.001.22.22 0 00-.089.079.242.242 0 00-.037.128h-.383a.552.552 0 01.09-.31.516.516 0 01.226-.182.771.771 0 01.31-.06c.125 0 .235.02.33.061a.476.476 0 01.306.457.472.472 0 01-.137.345.777.777 0 01-.152.117c-.05.03-.09.06-.122.092a.312.312 0 00-.07.11.479.479 0 00-.023.155v.032z"
        ></path>
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#1e4f7e"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.397"
          d="M307.275 81.223l.937.937"
        ></path>
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#1e4f7e"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.397"
          d="M304.795 78.742l-.435-.435"
        ></path>
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#1e4f7e"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.397"
          d="M305.635 78.394v-.615"
        ></path>
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#1e4f7e"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.397"
          d="M306.474 78.742l.435-.435"
        ></path>
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#1e4f7e"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.397"
          d="M304.795 80.421l-.435.435"
        ></path>
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#1e4f7e"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.397"
          d="M304.447 79.582h-.615"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#1e4f7e"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.397"
          d="M306.61 82.397l.665-1.174 1.174-.666-2.893-1.054 1.053 2.894"
          paintOrder="markers fill stroke"
        ></path>
        <g
          strokeWidth="2.404"
          transform="matrix(.41596 0 0 .41596 143.699 40.591)"
        >
          <path
            fill="#fff"
            fillOpacity="1"
            strokeWidth="0.636"
            d="M4335.55-185.354a3.938 3.938 0 100-7.875 3.938 3.938 0 000 7.875z"
            transform="matrix(.49978 0 0 .49978 -1833.022 175.219)"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="1.201"
            d="M333.788 84.731V79.81"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="1.201"
            d="M333.788 81.45l1.22-.806"
          ></path>
          <path
            stroke="#e65f62"
            strokeOpacity="1"
            strokeWidth="1.201"
            d="M333.788 82.09v-2.28"
          ></path>
        </g>
        <path
          fill="#fff"
          strokeWidth="0.132"
          d="M293.34 64.97a18.268 18.268 0 01-1.318.638s-.003 0-.005.002v7.049a2.844 2.844 0 01-2.865 2.865h-9.974v-.004h-.793v.004h-6.482c.033.167.07.335.113.506.07.28.135.55.197.816h16.94a2.844 2.844 0 012.865 2.861v3.733h-9.646a3.923 3.923 0 00-3.923 3.922v11.61c.127.011.256.021.386.029h.016c.127.008.255.014.384.017h.007V87.363c0-1.74 1.39-3.13 3.13-3.13h9.646v11.682l1.322-.483V84.233h29.297a21.712 21.712 0 00.28-.793c-10.203-.06-19.568 0-29.578 0V79.71v-.003a2.845 2.845 0 012.865-2.862c9.327.04 18.572 0 27.973 0v-.005c.012-.213.022-.427.029-.64v-.034c.007-.214.011-.428.013-.643h-28.015a2.844 2.844 0 01-2.865-2.865z"
        ></path>
        <path
          fill="#1e4f7e"
          fillOpacity="1"
          strokeWidth="0.132"
          d="M292.678 74.912c.328.51.762.946 1.273 1.273-.51.328-.946.763-1.273 1.273a4.221 4.221 0 00-1.272-1.273c.51-.327.945-.762 1.272-1.273z"
        ></path>
        <g
          strokeWidth="1.5"
          transform="matrix(.66667 0 0 .66667 93.478 19.037)"
        >
          <path
            fill="#a0d061"
            fillOpacity="1"
            strokeWidth="0.397"
            d="M4335.55-185.354a3.938 3.938 0 100-7.875 3.938 3.938 0 000 7.875z"
            transform="matrix(.49978 0 0 .49978 -1833.022 175.219)"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 84.731V79.81"
          ></path>
          <path
            stroke="#e65f62"
            strokeLinecap="round"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 81.45l1.22-.806"
          ></path>
          <path
            stroke="#e65f62"
            strokeOpacity="1"
            strokeWidth="0.75"
            d="M333.788 82.09v-2.28"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SINI_ILUSTRATION;
