/* eslint-disable */
import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";
import SetupTableC10Rumah from "./SetupTableC10Rumah";

export default class C10_InfrastrukturDanFasilitas extends Component {
  render() {
    const { mode } = this.props;
    return (
      <>
        <table className="table_pdf">
          <tbody>
            <tr>
              <td colSpan={12} className="tr_border_bottom_pdf">
                <p className="center_perfect subtitle_pdf">
                  INFRASTRUKTUR &amp; FASILITAS
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Jalan Masuk Menuju Objek</p>
              </td>
              <td>
                <DropdownOrData
                  id="jalan_masuk_menuju_objek_pdf"
                  {...this.props}
                />
              </td>
              <td>
                <p>- Milik </p>
              </td>
              <td>
                <DropdownOrData id="milik_pdf" {...this.props} />
              </td>
            </tr>
            <tr>
              <td>
                <p>Lebar Jalan Depan Objek</p>
              </td>
              <td>
                <DropdownOrData
                  id="lebar_jalan_depan_objek_pdf"
                  {...this.props}
                />
              </td>
              <td>
                <p>Kondisi</p>
              </td>
              <td>
                <DropdownOrData
                  id="kondisi_lebar_jalan_depan_objek_pdf"
                  {...this.props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Lebar Jalan Menuju Objek</p>
              </td>
              <td>
                <DropdownOrData
                  id="lebar_jalan_menuju_objek_pdf"
                  {...this.props}
                />
              </td>
              <td>
                <p>Kondisi</p>
              </td>
              <td>
                <DropdownOrData
                  id="kondisi_lebar_jalan_menuju_objek_pdf"
                  {...this.props}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Lampu Jalan</p>
              </td>
              <td>
                <DropdownOrData id="lampu_jalan_pdf" {...this.props} />
              </td>
            </tr>
            <tr>
              <td>
                <p>Jalan Utama (Main Road)</p>
              </td>
              <td>
                <DropdownOrData id="jalan_utama_pdf" {...this.props} />
              </td>
              <td>
                <p>Jalan Penghubung</p>
              </td>
              <td>
                <DropdownOrData id="jalan_penghubung_pdf" {...this.props} />
              </td>
            </tr>
            <tr>
              <td>
                <p>Jalan Nadi</p>
              </td>
              <td>
                <DropdownOrData id="jalan_nadi_pdf" {...this.props} />
              </td>
              <td>
                <p>Jalan Setempat</p>
              </td>
              <td>
                <DropdownOrData id="jalan_setempat_pdf" {...this.props} />
              </td>
            </tr>
            <tr className="tr_border_bottom_pdf">
              <td>
                <p>Material Jalan</p>
              </td>
              <td>
                <DropdownOrData id="material_jalan_pdf" {...this.props} />
              </td>
            </tr>

            <tr>
              <td>
                <p>Listrik</p>
              </td>
              <td>
                <DropdownOrData id="listrik_pdf" {...this.props} />
              </td>
              <td>
                <p>Remarks</p>
              </td>
              <td>
                <DropdownOrData id="remarks_pln_pdf" {...this.props} />
              </td>
              <td>
                <p>Watt</p>
              </td>
              <td>
                <DropdownOrData id="remarks_pln_watt_pdf" {...this.props} />
              </td>
            </tr>

            <tr>
              <td>
                <p>Air Bersih</p>
              </td>
              <td>
                <DropdownOrData id="air_bersih_pdf" {...this.props} />
              </td>
              <td>
                <p>Remarks</p>
              </td>
              <td>
                <DropdownOrData id="remarks_air_bersih_pdf" {...this.props} />
              </td>
              <td></td>
            </tr>

            <tr>
              <td>
                <p>Telephone</p>
              </td>
              <td>
                <DropdownOrData id="telephone_pdf" {...this.props} />
              </td>
              <td>
                <p>Remarks</p>
              </td>
              <td>
                <DropdownOrData id="remarks_telephone_pdf" {...this.props} />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p>Jaringan Gas</p>
              </td>
              <td>
                <DropdownOrData id="jaringan_gas_pdf" {...this.props} />
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p>Lampu Jalan</p>
              </td>
              <td>
                <DropdownOrData id="lampu_jalan_2_pdf" {...this.props} />
              </td>
              <td>
                <p>Tempat Hiburan</p>
              </td>
              <td>
                <DropdownOrData id="tempat_hiburan_pdf" {...this.props} />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p>Angkutan Umum</p>
              </td>
              <td>
                <DropdownOrData id="angkutan_umum_pdf" {...this.props} />
              </td>
              <td>
                <p>Perkantoran</p>
              </td>
              <td>
                <DropdownOrData id="perkantoran_pdf" {...this.props} />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p>Pasar</p>
              </td>
              <td>
                <DropdownOrData id="pasar_pdf" {...this.props} />
              </td>
              <td>
                <p>Pertokoan</p>
              </td>
              <td>
                <DropdownOrData id="pertokoan_pdf" {...this.props} />
              </td>
              <td></td>
            </tr>

            <tr>
              <td>
                <p>Sekolah</p>
              </td>
              <td>
                <DropdownOrData id="sekolah_pdf" {...this.props} />
              </td>
              <td>
                <p>Sarana Olahraga</p>
              </td>
              <td>
                <DropdownOrData id="sarana_olahraga_pdf" {...this.props} />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <p>Rumah Sakit / Puskesmas</p>
              </td>
              <td>
                <DropdownOrData
                  id="rumah_sakit_atau_puskesmas_pdf"
                  {...this.props}
                />
              </td>
              <td>
                <p>Tempat Ibadah</p>
              </td>
              <td>
                <DropdownOrData id="tempat_ibadah_pdf" {...this.props} />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        {mode === "setup" && (
          <SetupTableC10Rumah
            id="c10_setup_kumpulan_rumah_pdf"
            {...this.props}
          />
        )}

        {mode === "preview" && (
          <table id="c10_table_kumpulan_rumah_pdf" className="table_pdf">
            <thead></thead>
          </table>
        )}
      </>
    );
  }
}
