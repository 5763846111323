/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  searchLayersOnly,
  searchLayersOnlyPublic,
  downloadLayer,
  clearSearchLayer,
} from "../../App/actions/layerActions";
import { attachLayer } from "../../App/actions/layerNewActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class SEARCH_LAYER_JOIN extends Component {
  state = {
    search_params: "",
    geo_layer: {},
    mode: "private",
    selectedId: "",
  };

  componentDidMount() {
    this.timer = null;
  }

  componentWillUnmount() {
    this.setState({
      search_params: "",
      geo_layer: {},
      mode: "private",
      selectedId: "",
    });
  }

  handleMode = (mode) => {
    this.setState({ mode, search_params: "" });
    this.props.clearSearchLayer();
  };

  handlePushProject = (geo_layer) => {
    this.setState({ geo_layer }, () => {
      const { geo_project } = this.props.project;
      const geo_project_id = geo_project._id;
      const body = {
        geo_project_id,
        geo_layer,
      };
      this.props.attachLayer(body);
    });
  };

  handleDownload = (geo_layer) => {
    this.setState({ geo_layer }, () => {
      const { geo_project } = this.props.project;
      const { user } = this.props.auth;
      const link = geo_layer.link;
      const body = {
        link: link,
        geo_project_id: geo_project._id,
        user_id: user._id,
      };
      this.props.downloadLayer(body);
    });
  };

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  triggerChange() {
    const { search_params, mode } = this.state;
    if (search_params) {
      if (mode === "private") {
        this.props.searchLayersOnly(search_params);
      } else {
        this.props.searchLayersOnlyPublic(search_params);
      }
    }
  }

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { search_params, mode } = this.state;

    //global props
    const { search_result_list, loadingProcess, itemLoading } =
      this.props.layer;
    // const { geo_project } = this.props.project;

    //content
    // const geo_project_id = geo_project._id;
    // const geo_layer_state = this.state.geo_layer;
    const { geo_layer_list } = search_result_list;
    const geo_layer_list_no_current_project = geo_layer_list.slice();

    let loadingContent;
    if (loadingProcess && itemLoading === "search_layer") {
      loadingContent = dict["Searching"][language];
    } else {
      loadingContent = dict["Select Layer"][language];
    }
    let resultContent;
    if (loadingProcess && itemLoading === "search_layer") {
      resultContent = (
        <div className="container_grey">{dict["Searching"][language]}...</div>
      );
    } else if (
      isEmpty(geo_layer_list_no_current_project) &&
      !(loadingProcess && itemLoading === "search_layer")
    ) {
      resultContent = (
        <div className="container_grey">{dict["No result"][language]}</div>
      );
    } else {
      resultContent = (
        <div>
          {geo_layer_list_no_current_project.map((geo_layer) => {
            const { _id, name, geo_project } = geo_layer;
            const geo_project_name = geo_project?.name;
            let buttonAttach = null;
            let buttonDownload =
              this.state.selectedId === _id ? (
                <div>
                  <div className="button background_green">
                    {dict["Selected"][language]}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    onClick={(e) => {
                      this.setState({ selectedId: geo_layer._id });
                      this.props.selectData(geo_layer);
                    }}
                    className="button background_grey"
                  >
                    {dict["Select"][language]}
                  </div>
                </div>
              );

            return (
              <div
                key={_id}
                className="container_grey"
                style={{ marginBottom: "10px" }}
              >
                <div className="two_container_auto">
                  <div style={{ textAlign: "left" }}>
                    <div className="text_bold">{name}</div>
                    <div className="text_inferior">
                      {dict["Project"][language]}: {geo_project_name}
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    {buttonAttach}
                    {buttonDownload}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    const content = (
      <div>
        <div className="text_bold">{loadingContent}</div>
        <div className="text_inferior" style={{ marginBottom: "10px" }}>
          {
            dict[
              "Please select layer you want to join with your file before. You can search public layer or from your own layer collection."
            ][language]
          }
        </div>
        <div style={{ textAlign: "center" }}>
          <div
            onClick={this.handleMode.bind(this, "private")}
            className="button_very_small"
            id={mode === "private" ? "active" : "non_active"}
            style={{
              border: "1.5px solid rgb(232, 232, 232)",
              marginRight: "10px",
            }}
          >
            {dict["Your Layers"][language]}
          </div>
          <div
            onClick={this.handleMode.bind(this, "public")}
            className="button_very_small"
            id={mode === "public" ? "active" : "non_active"}
            style={{ border: "1.5px solid rgb(232, 232, 232)" }}
          >
            {dict["Public Layers"][language]}
          </div>
        </div>
        <input
          type="text"
          placeholder="Search"
          autoComplete="off"
          name="search_params"
          value={search_params}
          onChange={this.handleChange.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        />
        {search_params && resultContent}
      </div>
    );
    return <div style={{ minHeight: "70vh" }}>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
});

export default connect(mapStateToProps, {
  searchLayersOnly,
  searchLayersOnlyPublic,
  attachLayer,
  downloadLayer,
  clearSearchLayer,
})(SEARCH_LAYER_JOIN);
