import React, { Component } from "react";
import phoneNumberList from "../../Data/PhoneNumber.json";
import Modal from "../common_modal/Modal";
import dict from "../../Data/dict.json";
import { get_country_code_and_phone_number } from "../../App/validation/phone_number";

export default class PhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: 62,
      phoneNumber: "",
      modalNotification: false,
    };
  }

  toggleModalNotification = () => {
    this.setState({ modalNotification: !this.state.modalNotification });
  };

  componentDidMount = () => {
    const { countryCode, phoneNumber } = get_country_code_and_phone_number(
      this.props.params.value
    );

    this.setState({
      countryCode: countryCode || 62,
      phoneNumber,
    });
  };

  mergeCountryAndPhoneNumber = () => {
    return Number(`${this.state.countryCode}${this.state.phoneNumber}`);
  };

  handleSubmit = () => {
    const phoneNumber = parseInt(this.state.phoneNumber);
    if (!Number.isInteger(phoneNumber)) {
      this.toggleModalNotification();
    } else if (this.state.phoneNumber === "" || Number.isInteger(phoneNumber)) {
      const result = this.mergeCountryAndPhoneNumber();
      let newParams = this.props.params;
      newParams.value = result;
      newParams.row.value = result;
      this.props.handleEditCellChange(
        this.props.editProperties,
        this.props.layer_id,
        newParams
      );
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { countryCode, phoneNumber } = this.state;

    const modalNotification = this.state.modalNotification && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modalNotification}
        onClose={(params) => this.toggleModalNotification(params)}
      >
        <main style={{ margin: 20 }}>
          {dict["Invalid time input, please try again!"][language]}
          <button
            className="button"
            id="deleteBright"
            style={{
              marginTop: "1rem",
              display: "block",
              width: "100%",
              justifyContent: "center",
            }}
            onClick={this.toggleModalNotification}
          >
            OK
          </button>
        </main>
      </Modal>
    );
    return (
      <section>
        <div className="parent_phone_number">
          <select
            className="local_phone_in_table"
            style={{ width: 60, borderRight: "1 solid silver" }}
            value={countryCode}
            onChange={(params) => {
              this.setState({ countryCode: params.target.value }, () => {
                this.handleSubmit();
              });
            }}
          >
            {phoneNumberList?.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>

          <input
            type="number"
            className="phone_in_table"
            style={{ marginLeft: 5, width: 150 }}
            value={phoneNumber}
            onChange={(params) => {
              if (
                params.nativeEvent.inputType === "deleteContentBackward" ||
                phoneNumber.length < 15
              ) {
                this.setState({ phoneNumber: params.target.value });
              }
            }}
            onKeyDown={(params) => {
              if (params.key === "Enter") {
                this.handleSubmit();
                params.target.blur();
              }
            }}
            onBlur={(params) => {
              this.handleSubmit();
            }}
          />
        </div>
        {/* <div>{`+${this.state.countryCode} ${this.state.phoneNumber}`}</div> */}
        {modalNotification}
      </section>
    );
  }
}
