import React, { useState, useEffect } from "react";
import RestoreAndDeleteButton from "./RestoreAndDeleteButton";
import format_date_formal from "../../App/validation/format_date_formal";
import format_time_formal from "../../App/validation/format_time_formal";
import LongPress from "../common_trivial/LongPress";
import Checkbox from "@mui/material/Checkbox";
import useLanguage from "../common_hooks/useLanguage";

function RecoveryCard({
  content,
  toggle_is_multiple,
  is_multiple,
  is_checked = false,
  push_selected,
  actived_tab,
  set_recovery_of_projects,
  set_recovery_of_layers,
  delete_permanent,
  toggle_modal_restore_confirmation,
}) {
  const { name, trash, isPublic } = content;
  const { updated_at } = trash;
  const [checked, set_checked] = useState(false);
  const { dict, language } = useLanguage();

  useEffect(() => {
    set_checked(is_checked);
  }, [is_checked]);

  const toggle_checkbox = () => {
    set_checked(!is_checked);
    push_selected(content);
  };

  return (
    <LongPress
      delay={800}
      onLongPress={() => {
        toggle_is_multiple();
      }}
      className="recovery_card"
    >
      <div className="margin_20 flex flex_col gap_5">
        <header className="flex justify_between">
          <label className="title_recovery_card">{name}</label>
          <div className="h_20">
            {is_multiple ? (
              <Checkbox
                onChange={toggle_checkbox}
                checked={checked}
                style={{ margin: 0, padding: 0 }}
              />
            ) : (
              <RestoreAndDeleteButton
                actived_tab={actived_tab}
                content={content}
                set_recovery_of_projects={set_recovery_of_projects}
                set_recovery_of_layers={set_recovery_of_layers}
                delete_permanent={delete_permanent}
                toggle_modal_restore_confirmation={
                  toggle_modal_restore_confirmation
                }
              />
            )}
          </div>
        </header>
        <section>
          <label>{dict["Deleted Date"][language]}:</label>
          <div>
            {format_date_formal(updated_at)} {format_time_formal(updated_at)}
          </div>
        </section>
        <section className="bg_lightGrey rounded padding_y_5 paddingTop rounded_10 w_75 center_perfect">
          {isPublic ? "Public" : "Private"}
        </section>
      </div>
    </LongPress>
  );
}

export default RecoveryCard;
