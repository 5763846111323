import React, { Component } from "react";
import DropdownOrData from "../DropdownOrData";
import SetupTableC7DataLegal from "./SetupTableC7DataLegal";
import SetupTableC7LegalitasBangunan from "./SetupTableC7LegalitasBangunan";

export default class C7_DataLegal extends Component {
  render() {
    const { mode } = this.props;
    return (
      <>
        <table className="table_pdf">
          <tbody>
            <tr className="tr_border_bottom_pdf">
              <td colSpan={8}>
                <p className="subtitle_pdf center_perfect">DATA LEGAL</p>
              </td>
            </tr>
            <tr className="tr_border_bottom_pdf">
              <td colSpan={8}>
                <p className="subtitle_pdf">LEGALITAS KEPEMILIKAN</p>
              </td>
            </tr>
            <tr className="tr_border_bottom_pdf">
              <td colSpan={3}>
                <p>Nama Jalan Sesuai Sertifikat</p>
                <p>Nomor Identifikasi Bidang (NIB)</p>
              </td>
              <td colSpan={5}>
                <DropdownOrData
                  id="c7_nama_jalan_sesuai_sertifikat_pdf"
                  {...this.props}
                />
                <DropdownOrData
                  id="c7_nomor_identifikasi_bidang_pdf"
                  {...this.props}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {mode === "setup" ? (
          <SetupTableC7DataLegal id="c7_setup_data_legal_pdf" {...this.props} />
        ) : (
          <table id="c7_table_data_legal_pdf" className="table_pdf side_border">
            <thead>
              <tr>
                <td>
                  <p>No.</p>
                </td>
                <td>
                  <p>Jenis Dokumen</p>
                </td>
                <td>
                  <p>No.Dokumen</p>
                </td>
                <td>
                  <p>Atas Nama</p>
                </td>
                <td>
                  <p>Tgl Terbit</p>
                </td>
                <td>
                  <p>Tgl akhir Hak</p>
                </td>
                <td>
                  <p>No./Tgl GS/SU</p>
                </td>
                <td>
                  <p>Luas</p>
                </td>
                <td>
                  <p>Hubungan dengan Debitur</p>
                </td>
              </tr>
            </thead>
          </table>
        )}
        {mode === "setup" ? (
          <SetupTableC7LegalitasBangunan
            id="c7_setup_legalitas_bangunan_pdf"
            {...this.props}
          />
        ) : (
          <table
            id="c7_table_legalitas_bangunan_pdf"
            className="table_pdf"
          ></table>
        )}
      </>
    );
  }
}
