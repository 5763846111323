const get_kai_net_cash_flow = (geo_layer) => {
  const timeseries_mode = geo_layer?.timeseries_object?.timeseries_mode;
  let chart_js_data_net_cash_flow = {};
  if (timeseries_mode === "kai_net_cash_flow") {
    //STEP 1 GENERATE CHART JS DATA
    const timeseries_object = geo_layer?.timeseries_object || {};
    const {
      year_array, // [ 2022, 2023, 2024, 2025, 2026, 2027]
      field_name_year_array,
    } = timeseries_object;
    const fields = geo_layer?.fields || [];
    const features = geo_layer?.geojson?.features || [];
    const first_feature = features?.[0] || {};
    let field_key_year_array = [];
    field_name_year_array.forEach((field_name, idx) => {
      field_key_year_array[idx] = fields.find(
        (item) => item.name === field_name
      )?.key;
    });
    let data = [];
    for (let index = 0; index < year_array.length; index++) {
      data[index] = Number(
        first_feature?.properties?.[field_key_year_array?.[index]]
      );
    }
    //mencari nilai maksimum maksimum dari data_array
    const y_max = Math.max(...data);
    //mendapatkan index dari nilai maksimum
    const index_max = data.findIndex((value) => value === y_max);
    //mendapatkan nilai dari sumbu x yang sumbu y nya maksimum
    const x_max = year_array[index_max];

    //STEP 2 GENERATE FORMULA NMV
    let npv_array = [];
    data.forEach((value, idx) => {
      npv_array[idx] = parseInt(value / Math.pow(1 + 0.1022, idx + 1));
    });

    const npv_sum = Number(
      npv_array
        .reduce((accumulator, current) => accumulator + current, 0)
        .toFixed(0)
    );

    chart_js_data_net_cash_flow = {
      npv_array,
      npv_sum,
      labels: year_array,
      y_max,
      x_max,
      index_max,
      datasets: [
        {
          label: "NET CASH FLOW",
          labels: year_array,
          data: data,
          backgroundColor: "#373784", //warna dark_blue KAI
        },
      ],
    };
  }
  return chart_js_data_net_cash_flow;
};
export default get_kai_net_cash_flow;
