const get_hour_kai = (geo_layer) => {
  const timeseries_mode = geo_layer?.timeseries_object?.timeseries_mode;

  let passenger_hour_list = [];
  let train_hour_list = [];

  let hour_list = {};

  let labels = [];

  if (timeseries_mode === "kai") {
    const timeseries_object = geo_layer?.timeseries_object || {};
    const {
      field_name_jam_berangkat,
      field_name_jam_datang,
      field_name_jumlah_pnp,
      field_name_jumlah_kereta,
    } = timeseries_object;

    const fields = geo_layer?.fields || [];
    const features_original = geo_layer?.geojson?.features || [];

    const field_key_jam_berangkat = fields.find(
      (item) => item.name === field_name_jam_berangkat
    )?.key;
    const field_key_jam_datang = fields.find(
      (item) => item.name === field_name_jam_datang
    )?.key;
    const field_key_jumlah_pnp = fields.find(
      (item) => item.name === field_name_jumlah_pnp
    )?.key;
    const field_key_jumlah_kereta = fields.find(
      (item) => item.name === field_name_jumlah_kereta
    )?.key;

    const total = 24;
    const step = 1;
    const iteration = Math.ceil(total / step);

    for (let i = 0; i < iteration; i++) {
      passenger_hour_list.push({
        start: i * step,
        end: i * step + step,
        value: 0,
      });

      train_hour_list.push({
        start: i * step,
        end: i * step + step,
        value: 0,
      });

      labels.push(i * step);
    }

    features_original.forEach((feature) => {
      //8:00:00
      //12:00:00
      //output yang diinginkan -> 8 || 12

      let jam_string =
        feature?.properties?.[field_key_jam_datang] ||
        feature?.properties?.[field_key_jam_berangkat];

      const value_passenger = Number(
        feature?.properties?.[field_key_jumlah_pnp]
      );
      const value_train = Number(
        feature?.properties?.[field_key_jumlah_kereta]
      );

      if (jam_string) {
        let time_array = jam_string?.split(":");

        /*
        konversi menit & detik ke jam
        1 jam = 1/1 jam
        1 menit = 1/60 jam
        1 detik = 1/3600 jam
        */

        const hour_number = Number(time_array[0]);
        const minute_number = Number(time_array[1]);
        const second_number = Number(time_array[2]);

        const hour_total =
          hour_number + minute_number / 60 + second_number / 60;

        const index_hour = passenger_hour_list.findIndex(
          (item) =>
            /*
          misal jam 1 maka dia ikut jam 1
          misal jam 1.30 maka dia ikut jam 1
          misal jam 2 maka dia ikut jam 2
          */

            hour_total >= item.start && hour_total < item.end
        );

        if (passenger_hour_list?.[index_hour]?.value !== undefined) {
          passenger_hour_list[index_hour].value =
            passenger_hour_list[index_hour].value + value_passenger;
        }

        if (train_hour_list?.[index_hour]?.value !== undefined) {
          train_hour_list[index_hour].value =
            train_hour_list[index_hour].value + value_train;
        }
      }
    });

    //konversi dari array menjadi chart_js_data bar chart
    const data_array_passenger = passenger_hour_list.map((item) =>
      Number(item.value)
    );
    const data_array_train = train_hour_list.map((item) => Number(item.value));

    //mencari nilai maksimum maksimum dari data_array_passenger & data_array_train
    const y_max_passenger = Math.max(...data_array_passenger);
    const y_max_train = Math.max(...data_array_train);

    //mendapatkan index dari nilai maksimum
    const index_max_passenger = data_array_passenger.findIndex(
      (value) => value === y_max_passenger
    );
    const index_max_train = data_array_train.findIndex(
      (value) => value === y_max_train
    );

    //mendapatkan nilai dari sumbu x yang sumbu y nya maksimum
    const x_max_passenger = labels[index_max_passenger];
    const x_max_train = labels[index_max_train];

    const chart_js_data_passenger = {
      labels: labels,
      y_max: y_max_passenger,
      x_max: x_max_passenger,
      index_max: index_max_passenger,
      datasets: [
        {
          label: "Passenger",
          labels: labels,
          data: data_array_passenger,
          backgroundColor: "#373784", //warna dark_blue KAI
        },
      ],
    };

    const chart_js_data_train = {
      labels: labels,
      y_max: y_max_train,
      x_max: x_max_train,
      index_max: index_max_train,
      datasets: [
        {
          label: "Train",
          labels: labels,
          data: data_array_train,
          backgroundColor: "#373784", //warna dark_blue KAI
        },
      ],
    };

    hour_list = {
      chart_js_data_passenger,
      chart_js_data_train,
    };
  }
  return hour_list;
};

export default get_hour_kai;
