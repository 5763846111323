import {
  getChildrenFeaturesByColumnParentUuidAndRowParentUuid,
  getGeoLayer,
  getGeoLayerById,
} from "../../../../App/reduxHelper/layer";
import { createTd } from "../CreateTd";

import icon_type_image from "../../../../Assets/svg/icon_type_image.svg";
import { dot_every_3_digits } from "../../../../App/validation/format_number";
import { c13_setup_object_penilaian_dan_data_pembanding } from "./ListOfC13id";

export default function createSpecialC13TablePembanding({
  layer,
  feature,
  parent_table_id,
  body,
  luas_tanah,
  luas_bangunan,
}) {
  const geo_layer = getGeoLayer(layer);

  let tr = null;

  const tbody = document.createElement("tbody");
  const thead = document.createElement("thead");
  const table = document.getElementById(parent_table_id);

  table.appendChild(thead);

  // pembanding data pasar
  tr = thead.insertRow();
  tr.setAttribute("class", "tr_border_bottom_pdf");

  createTd({
    tr,
    text: "PEMBANDING DATA PASAR",
    tdClassName: "no_left_border",
    pClassName: "text_left",
    colspan: 6,
  });

  tr = thead.insertRow();

  createTd({
    tr,
    text: "NO.",
    tdClassName: "no_left_border w_10",
    pClassName: "text_center",
  });

  createTd({
    tr,
    text: "URAIAN",
    tdClassName: "no_left_border w_150",
    pClassName: " text_center",
  });

  createTd({
    tr,
    text: "OBJEK PENILAIAN",
    tdClassName: "no_left_border",
    pClassName: " text_center",
  });

  const geo_layer_data_pembanding = getGeoLayerById({
    layer: layer,
    layer_id: geo_layer?.layer_data_list?.[0]?._id,
  });

  const selected_features_data_pembanding =
    geo_layer_data_pembanding?.geojson_filtered?.features?.filter((row) =>
      feature?.data_pembanding_list?.includes(row?.key)
    ) || [];

  // header DATA 1, DATA 2, dst...
  selected_features_data_pembanding?.forEach((item, index) => {
    createTd({
      tr,
      text: `DATA ${index + 1}`,
      tdClassName: "no_left_border",
      pClassName: "text_center",
    });
  });

  table.appendChild(tbody);

  createValueTableDataPembanding({
    from: 0,
    to: 9,
    subtitle: "pembanding_data_pasar",
    body,
    tbody,
    selected_features_data_pembanding,
    feature,
  });

  tr = tbody.insertRow();
  tr.setAttribute("class", "tr_border_bottom_pdf");
  createTd({
    tr,
    text: "SPESIFIKASI DATA",
    tdClassName: "no_left_border",
    pClassName: "text_left",
    colspan: 6,
  });

  createValueTableDataPembanding({
    from: 9,
    to: 25,
    subtitle: "spesifikasi_data",
    tr,
    body,
    tbody,
    selected_features_data_pembanding,
    feature,
    luas_tanah,
    luas_bangunan,
  });

  table.appendChild(tbody);
  tr = tbody.insertRow();
  tr.setAttribute("class", "last_child");

  const td = tr.insertCell();
  td.setAttribute("colspan", 3);

  selected_features_data_pembanding.forEach((item) => {
    const data_pembanding =
      c13_setup_object_penilaian_dan_data_pembanding?.["data_pembanding"];

    let value_data_pembanding =
      item?.properties?.[
        body?.[data_pembanding?.[data_pembanding?.length - 1]]
      ];

    if (value_data_pembanding === undefined || value_data_pembanding === null) {
      value_data_pembanding = "";
    }

    const td = tr.insertCell();
    td.setAttribute("class", "max_w_150 max_h_100");
    const img = document.createElement("img");
    img.setAttribute("src", value_data_pembanding || icon_type_image);
    img.setAttribute("class", "object_contain");
    td.appendChild(img);
  });
}

export function createValueTableDataPembanding({
  from,
  to,
  subtitle,
  body,
  tbody,
  selected_features_data_pembanding,
  feature,
  luas_tanah,
  luas_bangunan,
}) {
  for (let index_row = from; index_row < to; index_row++) {
    const tr = tbody.insertRow();
    createTd({
      tr,
      text: `${
        subtitle === "pembanding_data_pasar" ? index_row + 1 : index_row - 8
      }.`,
      tdClassName: "no_left_border",
      pClassName: "text_center",
    });

    createTd({
      tr,
      text: c13_setup_object_penilaian_dan_data_pembanding?.["label"]?.[
        index_row
      ],
      tdClassName: "no_left_border",
      pClassName: "text_center",
    });

    let text_align = "";
    if (
      c13_setup_object_penilaian_dan_data_pembanding?.["object_penilaian"]?.[
        index_row
      ] === "c13_indikasi_nilai_tanah_m2_object_penilaian_pdf"
    ) {
      let key_indikasi_nilai_tanah =
        body?.["c13_indikasi_nilai_tanah_m2_object_penilaian_pdf"];

      const children_informasi_bangunan =
        getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
          feature,
          column_parent_uuid: body?.["c4_setup_sesuai_fisik_peraturan_pdf"],
        });

      let value =
        children_informasi_bangunan?.[0]?.properties?.[
          key_indikasi_nilai_tanah
        ];

      if (!isNaN(value)) {
        value = dot_every_3_digits(value);
        text_align = "text_right";
      } else {
        text_align = "text_left";
      }

      createTd({
        tr,
        text: value,
        tdClassName: "no_left_border",
        pClassName: text_align,
      });
    } else if (
      c13_setup_object_penilaian_dan_data_pembanding?.["object_penilaian"]?.[
        index_row
      ] === "c13_legalitas_kepemilikan_object_penilaian_pdf"
    ) {
      const id =
        c13_setup_object_penilaian_dan_data_pembanding?.["object_penilaian"]?.[
          index_row
        ];

      const key = body?.[id];

      let parent_field_key = body?.["c7_setup_data_legal_pdf"];

      const children = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
        feature,
        column_parent_uuid: parent_field_key,
      });

      let value = children?.[0]?.properties?.[key];

      if (!isNaN(value)) {
        if (id !== "c13_telepon_object_penilaian_pdf")
          value = dot_every_3_digits(value);
        text_align = "text_right";
      } else {
        text_align = "text_left";
      }

      createTd({
        tr,
        text: value,
        tdClassName: "no_left_border",
        pClassName: text_align,
      });
    } else if (
      c13_setup_object_penilaian_dan_data_pembanding?.["object_penilaian"]?.[
        index_row
      ] === "c13_luas_tanah_object_penilaian_pdf"
    ) {
      let value = "";
      if (!isNaN(value)) {
        value = dot_every_3_digits(luas_tanah);
        text_align = "text_right";
      } else {
        text_align = "text_left";
      }

      createTd({
        tr,
        text: value,
        tdClassName: "no_left_border",
        pClassName: text_align,
      });
    } else if (18 === index_row) {
      let value = "";
      if (!isNaN(value)) {
        value = dot_every_3_digits(luas_bangunan);
        text_align = "text_right";
      } else {
        text_align = "text_left";
      }

      createTd({
        tr,
        text: value,
        tdClassName: "no_left_border",
        pClassName: text_align,
      });
    } else {
      let value_objek_penilaian =
        feature?.properties?.[
          body?.[
            c13_setup_object_penilaian_dan_data_pembanding?.[
              "object_penilaian"
            ]?.[index_row]
          ]
        ];

      if (
        value_objek_penilaian === undefined ||
        value_objek_penilaian === null
      ) {
        value_objek_penilaian = "";
      } else {
        if (!isNaN(value_objek_penilaian)) {
          value_objek_penilaian = dot_every_3_digits(value_objek_penilaian);
          text_align = "text_right";
        } else {
          text_align = "text_left";
        }
      }

      createTd({
        tr,
        text: value_objek_penilaian,
        tdClassName: "no_left_border",
        pClassName: text_align,
      });
    }

    selected_features_data_pembanding?.forEach((item) => {
      let value_data_pembanding =
        item?.properties?.[
          body?.[
            c13_setup_object_penilaian_dan_data_pembanding?.[
              "data_pembanding"
            ]?.[index_row]
          ]
        ];

      if (
        value_data_pembanding === undefined ||
        value_data_pembanding === null
      ) {
        value_data_pembanding = "";
      } else {
        if (!isNaN(value_data_pembanding)) {
          if (
            c13_setup_object_penilaian_dan_data_pembanding?.[
              "data_pembanding"
            ]?.[index_row] !== "c13_telepon_data_pembanding_pdf"
          )
            value_data_pembanding = dot_every_3_digits(value_data_pembanding);
          text_align = "text_right";
        } else {
          text_align = "text_left";
        }
      }

      createTd({
        tr,
        text: value_data_pembanding,
        tdClassName: "no_left_border",
        pClassName: text_align,
      });
    });
  }
}
