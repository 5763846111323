import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*POST METHODE*/

export const create_share_link = (body) => async (dispatch) => {
  //create_share_link
  /*
    body = {
        link,
        layer_id,
        mode_features,
        feature_key_array,
        role,
        auth_mode,
        is_use_password,
        password,
        is_one_time_access_only,
        is_accessed,
        user_id_array,
        group_id_array,
        user_email_array
    }
  */
  try {
    dispatch(set_loading("create_share_link"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/shared_links/create_share_link",
      body,
      config
    );

    dispatch({
      type: "push_value_properties",
      payload: { key: "shared_link_list", value: res.data },
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*GET METHODE*/

export const get_share_link_check = (params) => async (dispatch) => {
  //get_share_link_check
  /*
  params = { link }
  */
  try {
    dispatch(set_loading("get_share_link_check"));
    const { link } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/shared_links/get_share_link_check?link=${link}`,
      config
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "shared_link_check",
        value: res.data,
      },
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_layer_by_share_link = (params) => async (dispatch) => {
  //get_layer_by_share_link
  /*
  params = {
    link,
    password_param
}
*/
  try {
    dispatch(set_loading("get_layer_by_share_link"));
    const { link, password_param } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL +
        `/shared_links/get_layer_by_share_link?link=${link}&password_param=${password_param}`,
      config
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "shared_link_check",
        value: res.data.shared_link,
      },
    });
    let geo_layer = res.data.layer_response;
    geo_layer.geojson_filtered = geo_layer.geojson;

    dispatch({
      type: "set_value_layer",
      payload: {
        key: "layer_id",
        value: geo_layer._id,
      },
    });
    dispatch({
      type: "set_value_layer",
      payload: {
        key: "layer_id_active",
        value: [geo_layer._id],
      },
    });
    dispatch({
      type: "SET_TABEL_STATUS",
      payload: true,
    });
    const layers = [
      {
        geo_layer,
      },
    ];
    dispatch({
      type: "GET_LAYER_LIST",
      payload: layers,
    });
    dispatch({
      type: "GET_PROJECT_DETAIL",
      payload: {
        _id: "share_link",
        link: "share_link",
        name: res?.data?.layer_response?.name,
        isPublic: true,
      },
    });
    dispatch(clear_loading());
    dispatch({
      type: "fly_action",
    });
    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_list_share_link_by_layer_id = (params) => async (dispatch) => {
  //get_list_share_link_by_layer_id
  /*
    params = {
    geo_layer_id,
    feature_key
  }
  */
  try {
    dispatch(set_loading("get_list_share_link_by_layer_id"));
    const { geo_layer_id, feature_key } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL +
        `/shared_links/get_list_share_link_by_layer_id?geo_layer_id=${geo_layer_id}&feature_key=${feature_key}`,
      config
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "shared_link_list",
        value: res.data,
      },
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_user_by_email = (params) => async (dispatch) => {
  //get_user_by_email
  /*
  params = { email}
  */
  try {
    dispatch(set_loading("get_user_by_email"));
    const { email } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/shared_links/get_user_by_email?email=${email}`,
      config
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "user_search_object",
        value: res.data,
      },
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*DELETE METHODE*/

export const delete_share_link = (params) => async (dispatch) => {
  //delete_share_link
  /*
  params = {
    link
}
*/
  try {
    dispatch(set_loading("delete_share_link"));
    const { link } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      SERVER_URL + `/shared_links/delete_share_link?link=${link}`,
      config
    );
    dispatch({
      type: "pull_value_properties_object",
      payload: {
        key: "shared_link_list",
        value: link,
        id: "link",
      },
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const set_loading = (itemLoading) => {
  return {
    type: "set_loading",
    payload: itemLoading,
  };
};

export const clear_loading = () => {
  return {
    type: "clear_loading",
  };
};
